import { defineMessages } from 'react-intl';

export default defineMessages({
  'admin.page.modules.config.timeReport': {
    id: 'admin.page.modules.config.timeReport',
    defaultMessage: 'Time report',
    description: 'Modules config option',
  },
  'admin.page.modules.config.projectReporting': {
    id: 'admin.page.modules.config.projectReporting',
    defaultMessage: 'Project reporting',
    description: 'Modules config option',
  },
  'admin.page.modules.config.payslips': {
    id: 'admin.page.modules.config.payslips',
    defaultMessage: 'Payslips',
    description: 'Modules config option',
  },
  'admin.page.title': {
    id: 'admin.page.title',
    defaultMessage: 'Admin. Customers management',
    description: 'Admin tab title',
  },
  'admin.page.add.button': {
    id: 'admin.page.add.button',
    defaultMessage: 'add new customer',
    description: 'Button text for adding new customer (Admin tab)',
  },
  'admin.page.back.button': {
    id: 'admin.page.back.button',
    defaultMessage: 'Customer List',
    description: 'Button text for back arrow (Admin flow)',
  },
  'admin.page.edit.button': {
    id: 'admin.page.edit.button',
    defaultMessage: 'Edit',
    description: 'Edit button text within mini menu for each customer row',
  },
  'admin.page.table.column.org.number': {
    id: 'admin.page.table.column.org.number',
    defaultMessage: 'Org number',
    description: 'One of customer table column',
  },
  'admin.page.table.column.name': {
    id: 'admin.page.table.column.name',
    defaultMessage: 'Name',
    description: 'One of customer table column',
  },
  'admin.page.table.column.payroll.system': {
    id: 'admin.page.table.column.payroll.system',
    defaultMessage: 'Payroll System',
    description: 'One of customer table column',
  },
  'admin.page.table.column.tenant': {
    id: 'admin.page.table.column.tenant',
    defaultMessage: 'Tenant',
    description: 'One of customer table column',
  },
  'admin.page.table.column.employees': {
    id: 'admin.page.table.column.employees',
    defaultMessage: 'Employees',
    description: 'One of customer table column',
  },
  'admin.page.table.column.status': {
    id: 'admin.page.table.column.status',
    defaultMessage: 'Status',
    description: 'One of customer table column',
  },
  'admin.page.table.column.empty': {
    id: 'admin.page.table.column.empty',
    defaultMessage: '',
    description: 'One of customer table column',
  },
  'admin.page.table.customer.status.active': {
    id: 'admin.page.table.customer.status.active',
    defaultMessage: 'Active',
    description: 'One of customer status in Customers table: active, inactive, incomplete',
  },
  'admin.page.table.customer.status.inactive': {
    id: 'admin.page.table.customer.status.inactive',
    defaultMessage: 'Inactive',
    description: 'One of customer status in Customers table: active, inactive, incomplete',
  },
  'admin.page.table.customer.status.incomplete': {
    id: 'admin.page.table.customer.status.incomplete',
    defaultMessage: 'Incomplete',
    description: 'One of customer status in Customers table: active, inactive, incomplete',
  },
  'admin.page.general.step.title': {
    id: 'admin.page.general.step.title',
    defaultMessage: 'General',
    description: 'General step #1 title',
  },
  'admin.page.compensation.types.step.title': {
    id: 'admin.page.compensation.types.step.title',
    defaultMessage: 'Compensation Types',
    description: 'Compensation Types step #2 title',
  },
  'admin.page.employment.categories.step.title': {
    id: 'admin.page.employment.categories.step.title',
    defaultMessage: 'Employment categories',
    description: 'Employment categories step #3 title',
  },
  'admin.page.users.import.success': {
    id: 'admin.page.users.import.success',
    defaultMessage: 'Import successful!',
    description: 'Users import success message',
  },
  'admin.page.users.import.reminder': {
    id: 'admin.page.users.import.reminder',
    defaultMessage: 'Don\'t forget to assign which departments managers and approvers should approve. This is done in the Employee menu.',
    description: 'Users import reminder',
  },
  'admin.page.users.import.step.title': {
    id: 'admin.page.users.import.step.title',
    defaultMessage: 'Users import',
    description: 'Users import step #4 title',
  },
  'admin.page.projects.step.title': {
    id: 'admin.page.projects.step.title',
    defaultMessage: 'Projects',
    description: 'Projects step #5 title',
  },
  'admin.page.users.import.step.tooltip': {
    id: 'admin.page.users.import.step.tooltip',
    defaultMessage: 'When importing users with role Approvers, please leave EmploymentNumber, Employment category and Department blank.',
    description: 'Users import tooltip',
  },
  'admin.page.users.import.step.example.tooltip': {
    id: 'admin.page.users.import.step.example.tooltip',
    defaultMessage: 'Download file example',
    description: 'Users import download file example tooltip',
  },
  'admin.page.users.import.noData': {
    id: 'admin.page.users.import.noData',
    defaultMessage: 'No data to import. Please add some data into uploaded file or check column names.',
    description: 'Users import no data message',
  },
  'admin.page.dimensions.worktypes.step.title': {
    id: 'admin.page.dimensions.worktypes.step.title',
    defaultMessage: 'Dimensions and worktypes',
    description: 'Dimensions and worktypes step #5 title',
  },
  'admin.page.step.next.button': {
    id: 'admin.page.step.next.button',
    defaultMessage: 'Next',
    description: 'The next button on customer wizard',
  },
  'admin.page.step.back.button': {
    id: 'admin.page.step.back.button',
    defaultMessage: 'Back',
    description: 'The back button on customer wizard',
  },
  'admin.page.step.finish.button': {
    id: 'admin.page.step.finish.button',
    defaultMessage: 'Finish',
    description: 'The finish button on customer wizard',
  },
  'admin.page.step.save.button': {
    id: 'admin.page.step.save.button',
    defaultMessage: 'Save',
    description: 'The save button on customer wizard (Step 1 only)',
  },
  'admin.page.step.general.copy.button': {
    id: 'admin.page.step.general.copy.button',
    defaultMessage: 'Copy settings from existing customer',
    description: 'Copy button on General step #1',
  },
  'admin.page.step.general.copy.customer': {
    id: 'admin.page.step.general.copy.customer',
    defaultMessage: 'Copy settings',
    description: 'Title of Copy settings dialog on General step #1',
  },
  'admin.page.step.general.copy.placeholder': {
    id: 'admin.page.step.general.copy.placeholder',
    defaultMessage: 'Type customer name or number to search',
    description: 'placeholder of Copy settings dialog input on General step #1',
  },
  'admin.page.step.general.copy.confirmation': {
    id: 'admin.page.step.general.copy.confirmation',
    defaultMessage: 'Are you sure you want to copy settings from',
    description: 'Confirmation message for copy settings dialog on General step #1',
  },
  'admin.page.step.general.customer.name': {
    id: 'admin.page.step.general.customer.name',
    defaultMessage: 'Customer name',
    description: 'One of field on General step #1',
  },
  'admin.page.step.general.org.number': {
    id: 'admin.page.step.general.org.number',
    defaultMessage: 'Org. number',
    description: 'One of field on General step #1',
  },
  'admin.page.step.general.payroll.system': {
    id: 'admin.page.step.general.payroll.system',
    defaultMessage: 'Payroll System',
    description: 'One of field on General step #1',
  },
  'admin.page.step.general.tenant': {
    id: 'admin.page.step.general.payroll.tenant',
    defaultMessage: 'Tenant',
    description: 'One of field on General step #1',
  },
  'admin.page.step.general.is.active': {
    id: 'admin.page.step.general.payroll.is.active',
    defaultMessage: 'Is Active',
    description: 'One of field on General step #1',
  },
  'admin.page.step.general.is.billable': {
    id: 'admin.page.step.general.payroll.is.billable',
    defaultMessage: 'Is Billable',
    description: 'One of field on General step #1',
  },
  'admin.page.step.general.inactive.date': {
    id: 'admin.page.step.general.inactive.date',
    defaultMessage: 'Inactive Date',
    description: 'One of field on General step #1',
  },
  'admin.page.step.general.country': {
    id: 'admin.page.step.general.country',
    defaultMessage: 'Country',
    description: 'One of field on General step #1',
  },
  'admin.page.step.general.period.reports': {
    id: 'admin.page.step.general.period.reports',
    defaultMessage: 'Period Reports',
    description: 'One of subtitles on General step #1',
  },
  'admin.page.step.general.label.monthly': {
    id: 'admin.page.step.general.label.monthly',
    defaultMessage: 'Monthly',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.label.bi-weekly': {
    id: 'admin.page.step.general.label.bi-weekly',
    defaultMessage: 'Bi-weekly',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.label.weekly': {
    id: 'admin.page.step.general.label.weekly',
    defaultMessage: 'Weekly',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.label.start.from': {
    id: 'admin.page.step.general.label.start.from',
    defaultMessage: 'Start from',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.generated.periods': {
    id: 'admin.page.step.general.generated.periods',
    defaultMessage: 'Generated periods',
    description: 'Generated periods label on General step #1',
  },
  'admin.page.step.general.period.cut.off.date': {
    id: 'admin.page.step.general.period.cut.off.date',
    description: 'Input period.cutOffDate on customer admin form step #1',
    defaultMessage: 'Closing day',
  },
  'admin.page.step.general.period.cut.off.date.info': {
    id: 'admin.page.step.general.period.cut.off.date.info',
    description: 'Text info about period.cutOffDate on customer admin form step #1',
    defaultMessage: 'Closing day info',
  },
  'admin.page.step.general.period.cut.off.date.info.description': {
    id: 'admin.page.step.general.period.cut.off.date.info.description',
    description: 'Text info description about period.cutOffDate on customer admin form step #1',
    defaultMessage: 'Displayed in the mobile app to show by which date the user must close their period',
  },
  'admin.page.step.general.copy.period.cut.off.date': {
    id: 'admin.page.step.general.copy.period.cut.off.date',
    description: 'Checkbox text to copy period.cutOffDate from notification on customer admin form step #1',
    defaultMessage: 'Make closing day as notification day'
  },
  'admin.page.step.general.notifications': {
    id: 'admin.page.step.general.notifications',
    defaultMessage: 'Notifications',
    description: 'One of subtitles on General step #1',
  },
  'admin.page.step.general.email.notifications': {
    id: 'admin.page.step.general.email.notifications',
    defaultMessage: 'Email notifications',
    description: 'One of subtitles on General step #1',
  },
  'admin.page.step.general.add.notification.button': {
    id: 'admin.page.step.general.add.notification.button',
    defaultMessage: 'Add new notification',
    description: 'Button for adding notifications on General step #1',
  },
  'admin.page.step.general.notification.type.employee': {
    id: 'admin.page.step.general.notification.type.employee',
    defaultMessage: 'Employee',
    description: 'General step #1 notification type',
  },
  'admin.page.step.general.notification.type.manager': {
    id: 'admin.page.step.general.notification.type.manager',
    defaultMessage: 'Manager',
    description: 'General step #1 notification type',
  },
  'admin.page.step.general.label.notification.type': {
    id: 'admin.page.step.general.notification.label.type',
    defaultMessage: 'Type',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.label.notification.deadline': {
    id: 'admin.page.step.general.label.notification.deadline',
    defaultMessage: 'Deadline',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.label.notification.day': {
    id: 'admin.page.step.general.label.notification.day',
    defaultMessage: 'Notification day',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.label.notification.time': {
    id: 'admin.page.step.general.label.notification.time',
    defaultMessage: 'Time',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.label.notification.repeatable': {
    id: 'admin.page.step.general.label.notification.repeatable',
    defaultMessage: 'Repeatable',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.label.notification.repeatable.tooltip': {
    id: 'admin.page.step.general.label.notification.repeatable.tooltip',
    defaultMessage: `Repeatable ON indicates that notifications will repeat every month
      in the same date and time. Repeatable OFF indicates that notification will
      be sent only once at spcified date.`,
    description: 'Help text for repeatable field',
  },
  'admin.page.step.general.holidays': {
    id: 'admin.page.step.general.holidays',
    defaultMessage: 'Holidays',
    description: 'One of subtitles on General step #1',
  },
  'admin.page.step.general.add.holiday.button': {
    id: 'admin.page.step.general.add.holiday.button',
    defaultMessage: 'Add day',
    description: 'Button for adding holiday on General step #1',
  },
  'admin.page.step.general.label.holidays.day': {
    id: 'admin.page.step.general.label.holidays.day',
    defaultMessage: 'Day',
    description: 'One of label on General step #1',
  },
  'admin.page.step.general.reset.button': {
    id: 'admin.page.step.general.reset.button',
    defaultMessage: 'Reset',
    description: 'Reset button for public holidays',
  },
  'admin.page.step.general.holiday.full.day': {
    id: 'admin.page.step.general.holiday.full.day',
    defaultMessage: 'Full day holiday',
    description: 'Tooltip for public holidays',
  },
  'admin.page.step.general.holiday.half.day': {
    id: 'admin.page.step.general.holiday.half.day',
    defaultMessage: 'Half day holiday',
    description: 'Tooltip for public holidays',
  },
  'admin.page.step.general.holiday.menu.edit': {
    id: 'admin.page.step.general.holiday.menu.edit',
    defaultMessage: 'Edit',
    description: 'Menu item name when editing a holiday',
  },
  'admin.page.step.general.holiday.menu.remove': {
    id: 'admin.page.step.general.holiday.menu.remove',
    defaultMessage: 'Remove',
    description: 'Menu item name when editing a holiday',
  },
  'admin.page.step.general.popup.change.country.title': {
    id: 'admin.page.step.general.popup.change.country.title',
    defaultMessage: 'Change country',
    description: 'Title on popup when change country',
  },
  'admin.page.step.general.popup.change.country.text': {
    id: 'admin.page.step.general.popup.change.country.text',
    defaultMessage: 'When you change country, the new country has different public holidays. Do you want to keep your holidays?',
    description: 'Text on popup when change country',
  },
  'admin.page.step.general.popup.change.country.reset.button': {
    id: 'admin.page.step.general.popup.change.country.reset.button',
    defaultMessage: 'Reset',
    description: 'Button name when change country',
  },
  'admin.page.step.general.popup.change.country.keep.button': {
    id: 'admin.page.step.general.popup.change.country.keep.button',
    defaultMessage: 'Keep holidays',
    description: 'Button name when change country',
  },
  'admin.page.step.general.popup.reset.holidays.title': {
    id: 'admin.page.step.general.popup.reset.holidays.title',
    defaultMessage: 'Reset public holidays',
    description: 'Title on popup when reset country public holidays',
  },
  'admin.page.step.general.popup.reset.holidays.text': {
    id: 'admin.page.step.general.popup.reset.holidays.text',
    defaultMessage: 'Are you sure you want to reset public holidays?',
    description: 'Text on popup when reset country public holidays',
  },
  'admin.page.step.general.popup.reset.holidays.reset.button': {
    id: 'admin.page.step.general.popup.reset.holidays.reset.button',
    defaultMessage: 'Reset',
    description: 'Button name when reset country public holidays',
  },
  'admin.page.step.general.popup.reset.holidays.cancel.button': {
    id: 'admin.page.step.general.popup.reset.holidays.cancel.button',
    defaultMessage: 'Cancel',
    description: 'Button name when reset country public holidays',
  },

  'admin.page.step.general.popup.edit.holiday.title': {
    id: 'admin.page.step.general.popup.edit.holiday.title',
    defaultMessage: 'Public holiday',
    description: 'Title on popup when add/edit public holiday',
  },
  'admin.page.step.general.popup.edit.holiday.field.name': {
    id: 'admin.page.step.general.popup.edit.holiday.field.name',
    defaultMessage: 'Holiday name',
    description: 'Field label when add/edit public holiday',
  },
  'admin.page.step.general.popup.edit.holiday.field.date': {
    id: 'admin.page.step.general.popup.edit.holiday.field.date',
    defaultMessage: 'Date',
    description: 'Field label when add/edit public holiday',
  },
  'admin.page.step.general.popup.edit.holiday.field.full.day': {
    id: 'admin.page.step.general.popup.edit.holiday.field.full.day',
    defaultMessage: 'Full day',
    description: 'Field label when add/edit public holiday',
  },
  'admin.page.step.general.popup.edit.holiday.field.half.day': {
    id: 'admin.page.step.general.popup.edit.holiday.field.half.day',
    defaultMessage: 'Half day',
    description: 'Field label when add/edit public holiday',
  },
  'admin.page.step.general.popup.edit.holiday.cancel.button': {
    id: 'admin.page.step.general.popup.edit.holiday.cancel.button',
    defaultMessage: 'Cancel',
    description: 'Button name when add/edit public holiday',
  },
  'admin.page.step.general.popup.edit.holiday.save.button': {
    id: 'admin.page.step.general.popup.edit.holiday.save.button',
    defaultMessage: 'Save',
    description: 'Button name when add/edit public holiday',
  },
  'admin.page.step.compensation.types.column.code': {
    id: 'admin.page.step.compensation.types.column.code',
    defaultMessage: 'Code',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.name': {
    id: 'admin.page.step.compensation.types.column.name',
    defaultMessage: 'Name',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.type': {
    id: 'admin.page.step.compensation.types.column.type',
    defaultMessage: 'Type',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.comment': {
    id: 'admin.page.step.compensation.types.column.comment',
    defaultMessage: 'Comment',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.reporting.type': {
    id: 'admin.page.step.compensation.types.column.reporting.type',
    defaultMessage: 'Reporting type',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.intervals': {
    id: 'admin.page.step.compensation.types.column.intervals',
    defaultMessage: 'Intervals',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.periodic': {
    id: 'admin.page.step.compensation.types.column.periodic',
    defaultMessage: 'Periodic',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.in.summary': {
    id: 'admin.page.step.compensation.types.column.in.summary',
    defaultMessage: 'In summary',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.global.type': {
    id: 'admin.page.step.compensation.types.column.global.type',
    defaultMessage: 'Global type',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.column.description': {
    id: 'admin.page.step.compensation.types.column.description',
    defaultMessage: 'Description',
    description: 'One of column in Compensation type table',
  },
  'admin.page.step.compensation.types.add.compensation.type': {
    id: 'admin.page.step.compensation.types.add.compensation.type',
    defaultMessage: 'Add new compensation type',
    description: 'Add new compensation type button',
  },
  'admin.page.step.compensation.types.type.absence': {
    id: 'admin.page.step.compensation.types.type.absence',
    defaultMessage: 'Absence',
    description: 'One of types in Compensation type',
  },
  'admin.page.step.compensation.types.type.presence': {
    id: 'admin.page.step.compensation.types.type.presence',
    defaultMessage: 'Presence',
    description: 'One of types in Compensation type',
  },
  'admin.page.step.compensation.types.comment.required': {
    id: 'admin.page.step.compensation.types.comment.required',
    defaultMessage: 'Required',
    description: 'One of comments in Compensation type',
  },
  'admin.page.step.compensation.types.comment.no': {
    id: 'admin.page.step.compensation.types.comment.no',
    defaultMessage: 'No',
    description: 'One of comments in Compensation type',
  },
  'admin.page.step.compensation.types.comment.optional': {
    id: 'admin.page.step.compensation.types.comment.optional',
    defaultMessage: 'Optional',
    description: 'One of comments in Compensation type',
  },
  'admin.page.step.compensation.types.reporting.type.overlapping': {
    id: 'admin.page.step.compensation.types.reporting.type.overlapping',
    defaultMessage: 'Overlapping',
    description: 'One of reporting type in Compensation type',
  },
  'admin.page.step.compensation.types.reporting.type.full.day': {
    id: 'admin.page.step.compensation.types.reporting.type.full.day',
    defaultMessage: 'Full day',
    description: 'One of reporting type in Compensation type',
  },
  'admin.page.step.compensation.types.reporting.type.interval': {
    id: 'admin.page.step.compensation.types.reporting.type.interval',
    defaultMessage: 'Interval',
    description: 'One of reporting type in Compensation type',
  },
  'admin.page.step.compensation.types.reporting.type.duration': {
    id: 'admin.page.step.compensation.types.reporting.type.duration',
    defaultMessage: 'Duration',
    description: 'One of reporting type in Compensation type',
  },
  'admin.page.step.compensation.types.reporting.type.full.month': {
    id: 'admin.page.step.compensation.types.reporting.type.full.month',
    defaultMessage: 'Full month',
    description: 'One of reporting type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.vacation': {
    id: 'admin.page.step.compensation.types.time.type.vacation',
    defaultMessage: 'Vacation',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.overtime': {
    id: 'admin.page.step.compensation.types.time.type.overtime',
    defaultMessage: 'Overtime',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.off.duty': {
    id: 'admin.page.step.compensation.types.time.type.off.duty',
    defaultMessage: 'Off duty',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.more.time': {
    id: 'admin.page.step.compensation.types.time.type.more.time',
    defaultMessage: 'More time',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.sickleave': {
    id: 'admin.page.step.compensation.types.time.type.sickleave',
    defaultMessage: 'Sickleave',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.workhours': {
    id: 'admin.page.step.compensation.types.time.type.workhours',
    defaultMessage: 'Workhours',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.no.deviation': {
    id: 'admin.page.step.compensation.types.time.type.no.deviation',
    defaultMessage: 'No deviation',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.care.of.child': {
    id: 'admin.page.step.compensation.types.time.type.care.of.child',
    defaultMessage: 'Care of child',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.time.type.parental.leave': {
    id: 'admin.page.step.compensation.types.time.type.parental.leave',
    defaultMessage: 'Parental leave',
    description: 'One of time type in Compensation type',
  },
  'admin.page.step.compensation.types.delete.button': {
    id: 'admin.page.step.compensation.types.delete.button',
    defaultMessage: 'Delete',
    description: 'Delete button within compensation type row',
  },
  'admin.page.step.employment.categories.add.employment.category': {
    id: 'admin.page.step.employment.categories.add.employment.category',
    defaultMessage: 'Add new employment category',
    description: 'Add new employment category',
  },
  'admin.page.step.employment.categories.column.category': {
    id: 'admin.page.step.employment.categories.column.category',
    defaultMessage: 'Category',
    description: 'One of column in Employment categories table',
  },
  'admin.page.step.employment.categories.column.modules.config': {
    id: 'admin.page.step.employment.categories.column.modules.config',
    defaultMessage: 'Modules config',
    description: 'One of column in Employment categories table',
  },
  'admin.page.step.employment.categories.column.norm.time': {
    id: 'admin.page.step.employment.categories.column.norm.time',
    defaultMessage: 'Norm time',
    description: 'One of column in Employment categories table',
  },
  'admin.page.step.employment.categories.column.exclude.weekends': {
    id: 'admin.page.step.employment.categories.column.exclude.weekends',
    defaultMessage: 'Exclude weekends',
    description: 'One of column in Employment categories table',
  },
  'admin.page.step.employment.categories.column.reporting.method': {
    id: 'admin.page.step.employment.categories.column.reporting.method',
    defaultMessage: 'Reporting method',
    description: 'One of column in Employment categories table',
  },
  'admin.page.step.employment.categories.column.compensation.types': {
    id: 'admin.page.step.employment.categories.column.compensation.types',
    defaultMessage: 'Compensation Types',
    description: 'One of column in Employment categories table',
  },
  'admin.page.step.employment.categories.delete.button': {
    id: 'admin.page.step.employment.categories.delete.button',
    defaultMessage: 'Delete',
    description: 'Delete button within employment categories row',
  },
  'admin.page.step.employment.users.column.employmentNumber': {
    id: 'admin.page.step.employment.users.employmentNumber',
    defaultMessage: 'Employment number',
    description: 'One of column in Employment users import table',
  },
  'admin.page.step.employment.users.column.employmentCategoryName': {
    id: 'admin.page.step.employment.users.column.employmentCategoryName',
    defaultMessage: 'Employment category',
    description: 'One of column in Employment users import table',
  },
  'admin.page.step.employment.users.column.firstName': {
    id: 'admin.page.step.employment.users.column.firstName',
    defaultMessage: 'First name',
    description: 'One of column in Employment users import table',
  },
  'admin.page.step.employment.users.column.lastName': {
    id: 'admin.page.step.employment.users.column.lastName',
    defaultMessage: 'Last name',
    description: 'One of column in Employment users import table',
  },
  'admin.page.step.employment.users.column.email': {
    id: 'admin.page.step.employment.users.column.email',
    defaultMessage: 'Email',
    description: 'One of column in Employment users import table',
  },
  'admin.page.step.employment.users.column.role': {
    id: 'admin.page.step.employment.users.column.role',
    defaultMessage: 'Role',
    description: 'One of column in Employment users import table',
  },
  'admin.page.step.employment.users.column.status': {
    id: 'admin.page.step.employment.users.column.status',
    defaultMessage: 'Status',
    description: 'One of column in Employment users import table, shows error',
  },
  'admin.page.step.employment.users.column.departmentName': {
    id: 'admin.page.step.employment.users.column.departmentName',
    defaultMessage: 'Department',
    description: 'One of column in Employment users import table',
  },
  'admin.page.step.employment.users.column.employeeStart': {
    id: 'admin.page.step.employment.users.column.employeeStart',
    defaultMessage: 'Start date',
    description: 'One of column in Employment users import table',
  },
  'admin.page.step.employment.projects.column.projectNumber': {
    id: 'admin.page.step.employment.projects.column.projectNumber',
    defaultMessage: 'Project number',
    description: 'One of column in Employment projects table',
  },
  'admin.page.step.employment.projects.column.projectName': {
    id: 'admin.page.step.employment.projects.column.projectName',
    defaultMessage: 'Project name',
    description: 'One of column in Employment projects table',
  },
  'admin.page.step.employment.projects.column.billable': {
    id: 'admin.page.step.employment.projects.column.billable',
    defaultMessage: 'Billable',
    description: 'One of column in Employment projects table',
  },
  'admin.page.step.employment.projects.column.enabled': {
    id: 'admin.page.step.employment.projects.column.enabled',
    defaultMessage: 'Enabled',
    description: 'One of column in Employment projects table',
  },
  'admin.page.step.employment.projects.column.activities': {
    id: 'admin.page.step.employment.projects.column.activities',
    defaultMessage: 'Activities',
    description: 'One of column in Employment projects table',
  },
  'admin.page.step.employment.projects.add.project': {
    id: 'admin.page.step.employment.projects.add.project',
    defaultMessage: 'Add new project',
    description: 'Add project button',
  },
  'admin.page.step.employment.projects.add.activity': {
    id: 'admin.page.step.employment.projects.add.activity',
    defaultMessage: 'Add activity',
    description: 'Add activity placeholder',
  },
  'admin.error.add.empty.project': {
    id: 'admin.error.add.empty.project',
    defaultMessage: 'Fill in the Project number field in an empty project',
    description: 'Error message if exist empty project',
  },
  'admin.error.remove.used.compensation.type': {
    id: 'admin.error.remove.compensation.type',
    defaultMessage: 'Can not remove compensation type which is already in use',
    description: 'Error message for removing compensation type which is already in use',
  },
  'admin.error.update.duplicated.project.number': {
    id: 'admin.error.update.duplicated.project.number',
    defaultMessage: 'Can not update project number, project number is already taken',
    description: 'Error message for updating project number which is already exists',
  },
  'admin.error.remove.used.employment.category': {
    id: 'admin.error.remove.used.employment.category',
    defaultMessage: 'Can not remove employment category which is already in use',
    description: 'Error message for removing employment category which is already in use',
  },
  'admin.error.remove.used.project': {
    id: 'admin.error.remove.used.project',
    defaultMessage: 'Can not remove project which is already in use',
    description: 'Error message for removing project which is already in use',
  },
  'admin.error.add.project.validation': {
    id: 'admin.error.add.project.validation',
    defaultMessage: 'Please make sure you filled all required fields',
    description: 'Error message for adding new project',
  },
  'admin.error.edit.project.number.validation': {
    id: 'admin.error.edit.project.number.validation',
    defaultMessage: 'Project number can contain only numbers',
    description: 'Error message when editing project number',
  },
  'admin.error.edit.project.number.same.validation': {
    id: 'admin.error.edit.project.number.same.validation',
    defaultMessage: 'Project number is already taken',
    description: 'Error message when adding/editing project number as already exists',
  },
  'admin.error.remove.activity': {
    id: 'admin.error.remove.activity',
    defaultMessage: 'Can not remove activity which is already in use',
    description: 'Error message for removing activity which is already in use',
  },
  'admin.success.notification.saved': {
    id: 'admin.success.notification.saved',
    defaultMessage: 'Saved',
    description: 'Success notification error when user\'s data is successfully saved',
  },
  'admin.error.notification.ops.something.went.wrong': {
    id: 'admin.error.notification.ops.something.went.wrong',
    defaultMessage: 'Ops something went wrong',
    description: 'Error notification error when something unexpected is happened',
  },
  'admin.page.stepper.customer.name': {
    id: 'admin.stepper.page.customer.name',
    defaultMessage: 'Customer name',
    description: 'Label for customer name in admin self service',
  },
  'admin.page.stepper.customer.org.number': {
    id: 'admin.page.stepper.customer.org.number',
    defaultMessage: 'Org. number',
    description: 'Label for customer org. number in admin self service',
  },
  'admin.page.stepper.custom.error.duplicated.notifications': {
    id: 'admin.page.stepper.custom.error.duplicated.notifications',
    defaultMessage: 'Ops, seems like you have duplicated notifications',
    description: 'Error message for duplicated notifications',
  },
  'admin.page.stepper.custom.error.duplicated.holidays': {
    id: 'admin.page.stepper.custom.error.duplicated.holidays',
    defaultMessage: 'Ops, seems like you have duplicated holidays',
    description: 'Error message for duplicated holidays',
  },
  'admin.page.stepper.custom.error.invalid.org.number': {
    id: 'admin.page.stepper.custom.error.invalid.org.number',
    defaultMessage: 'Oops, you have invalid organization number. It should has numbers with optional hyphen.',
    description: 'Error message for invalid org number',
  },
  'admin.error.org.number.not.found.in.nettlonn': {
    id: 'admin.error.org.number.not.found.in.nettlonn',
    defaultMessage: 'No company found for provided organization number in Nettlonn',
    description: 'Error message to show when customer not found in nettlonn',
  },
  'admin.page.stepper.custom.error.duplicated.org.number.within.country': {
    id: 'admin.page.stepper.custom.error.duplicated.org.number.within.country',
    defaultMessage: 'Customer with this org number within this country already exist',
    description: 'Error message to show when we have duplicated org number within country',
  },
  'admin.error.portal.admin.invalid.org.number': {
    id: 'admin.error.portal.admin.invalid.org.number',
    defaultMessage: 'Portal Admin: Incorrect organization number format for given country',
    description: 'Error message to show when cannot create customer in Portal Admin, invalid org number',
  },
  'admin.error.portal.admin.tenant.not.synced': {
    id: 'admin.error.portal.admin.tenant.not.synced',
    defaultMessage: 'Portal Admin: Selected tenant is not synced',
    description: 'Error message to show when cannot create customer and tenant is not synced with Portal Admin',
  },
  'admin.error.portal.admin.customer.not.synced': {
    id: 'admin.error.portal.admin.customer.not.synced',
    defaultMessage: 'Portal Admin: Selected customer is not synced',
    description: 'Error message to show when cannot create user and customer is not synced with Portal Admin',
  },
  'admin.error.portal.admin.integration.error': {
    id: 'admin.error.portal.admin.integration.error',
    defaultMessage: 'Portal Admin Integration Error',
    description: 'Error message to show when cannot create customer in Portal Admin',
  },
});
