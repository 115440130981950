import React from 'react';
import { connect } from 'react-redux';
import PageTemplate from '../../../../components/PageTemplate';
import { setAuth } from '../../../../actions/auth';
import { setUserAdditional } from '../../../../actions/context';

const Layout = ({ children, ...rest }) => (
  <PageTemplate
    {...rest}
    editable
    activeRoute="Departments"
  >
    {children(rest)}
  </PageTemplate>
);

const mapStateToProps = ({
  auth: {
    user: {
      selectedCustomer: defaultCustomer,
      selectedTenant: defaultTenant,
    },
  },
  context: additional,
}) => ({ additional, defaultCustomer, defaultTenant });

const MainLayout = connect(
  mapStateToProps,
  { setAuth, setUserAdditional },
)(Layout);

export default MainLayout;
