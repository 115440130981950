import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Typography } from '@material-ui/core';
import { getApolloClient } from '../services/apollo';
import LoadingCircle from '../components/LoadingCircle';
import { apiUrl } from '../config/urls'
import { useEnvironmentConfig } from "../hooks/useEnvironmentConfig";


export const AuthorizedApolloProvider = ({ children }) => {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    isLoading,
    error,
    isAuthenticated,
  } = useAuth0();

  const { GATEWAY_API_URL, AUTH0_AUDIENCE } = useEnvironmentConfig()

  if (isLoading) {
    return (
      <Grid container justify="center" alignItems="center" style={{ height: '100%'}}>
        <LoadingCircle />
      </Grid>
    );
  }

  if (!isAuthenticated) {
    // bug: blank page after logout
    // https://community.auth0.com/t/blank-page-when-redirect-to-domain-auth0-com-u-login/44443/4
    setTimeout(loginWithRedirect, 100);

    return null;
  }

  if (error) {
    return (
      <Grid container justify="center" alignItems="center" style={{ height: '100%'}}>
        <Typography variant="h5">
          Oops, something went wrong, please contact our support.
        </Typography>
      </Grid>
    )
  }

  const client = getApolloClient({
    getAccessTokenSilently,
    logout,
    audience: AUTH0_AUDIENCE,
    uri: GATEWAY_API_URL,
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};
