import React from 'react';
import {Button, Drawer as MuiDrawer, Grid, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import pab from '../../config/intlMessageSelectors/payroll-admin-base';

const FOOTER_HEIGHT = 76;
const styles = () => ({
  root: {
    width: 350,
    maxWidth: 350,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden'
  },
  header: {
    height: 72,
    width: '100%',
    borderBottom: '1px solid #DADADA',
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF',
    padding: 25,
    fontSize: 20
  },
  content: {
    display: 'flex',
    width: '100%',
    flex: 1,
    background: '#F5F5F5',
    overflow: 'auto',
    marginBottom: FOOTER_HEIGHT
  },
  footer: {
    height: FOOTER_HEIGHT,
    width: '100%',
    background: '#FFFFFF',
    position: 'absolute',
    bottom: 0,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.14), 0px -2px 2px rgba(0, 0, 0, 0.12), 0px -1px 3px rgba(0, 0, 0, 0.05)',
    borderRadius: '2px'
  }
});

const ButtonContainer = ({ children }) => (
  <Grid item xs={3} md={3} lg={3} style={{ marginRight: '10px' }}>
    { children }
  </Grid>
);

const Drawer = ({
  open,
  onClose,
  onChange,
  title = '',
  children,
  classes
}) => {
  const {
    root,
    header,
    content,
    footer
  } = classes;
  const { formatMessage: f } = useIntl();

  return (
    <MuiDrawer anchor="right" open={open} onClose={onClose}>
      <div className={root}>
        {
          title && (
            <div className={header}>
              <Typography variant='h6'>
                { title }
              </Typography>
            </div>
          )
        }
        <div className={content}>
          { children }
        </div>
        <div className={footer}>
          <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
            <ButtonContainer>
              <Button
                fullWidth
                variant="outlined"
                onClick={onClose}
              >
                {f(pab['app.base.button.cancel'])}
              </Button>
            </ButtonContainer>
            <ButtonContainer>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={onChange}
                >
                  {f(pab['app.base.button.apply'])}
                </Button>
            </ButtonContainer>
          </Grid>
        </div>
      </div>
    </MuiDrawer>
  );
}

export default withStyles(styles)(Drawer);
