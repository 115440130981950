import React from 'react';

export const WizzardContext = React.createContext({
  showProjectsStep: undefined,
  setShowProjectsStep(showProjectsStep) {},
})

export const WizzardProvider = ({ children }) => {
  const [showProjectsStep, setShowProjectsStep] = React.useState(undefined);

  return (
    <WizzardContext.Provider value={{
      showProjectsStep,
      setShowProjectsStep,
    }}>
      {children}
    </WizzardContext.Provider>
  );
};
