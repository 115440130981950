import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { DropDownList } from './DropDownList/DropDownList';
import { CHANGE_LANGUAGE } from '../queries/users';

const LANGUAGES = {
  en: { title: 'ENG (English)', locale: 'en', i: 'ENG' },
  sv: { title: 'SWE (Svenska)', locale: 'sv', i: 'SWE' },
  da: { title: 'DAN (Dansk)', locale: 'da', i: 'DAN' },
  nb: { title: 'NOR (Norsk)', locale: 'nb', i: 'NOR' },
};

const LocaleDropDown = ({ locale, changeUserLocale }) => {
  const [isShown, setIsShow] = useState(false);
  const [setLang] = useMutation(CHANGE_LANGUAGE);

  const toggle = () => setIsShow(!isShown);
  const onKeyDown = ({ keyCode }) => keyCode === 13 && toggle();

  React.useEffect(() => {
    moment.updateLocale(locale, {
      week: {
        dow : 1,
      }
    });
  }, [locale])

  const onChangeLocale = async ({ locale }) => {
    await setLang({ variables: { lang: locale.toUpperCase() } })
    toggle();
    changeUserLocale({ locale })
  }

  return (
    <div className="locale-wrapper">
      <span
        role="button"
        onClick={toggle}
        onKeyDown={onKeyDown}
        tabIndex={0}
        className="language-indicator"
      >
        {pathOr('ENG', [locale, 'i'], LANGUAGES)}
      </span>

      <DropDownList
        toggleLanguageDropdown={toggle}
        onItemClick={onChangeLocale}
        isShown={isShown}
        isChecked={item => item.locale === locale}
      />
    </div>
  );
};

LocaleDropDown.defaultProps = {
  locale: 'en',
  changeUserLocale: () => {},
};

LocaleDropDown.propTypes = {
  locale: PropTypes.string,
  changeUserLocale: PropTypes.func,
};

export default LocaleDropDown;
