import React, {useCallback} from 'react';
import { Table, Paper } from '@material-ui/core';
import debounce from 'lodash/debounce';
import ProjectsEditableTbody from './ProjectsEditableTbody';
import { useMutation } from 'react-apollo';
import { useIntl } from 'react-intl';
import { TableHead } from '../../../components/Table';
import AddNew from "../../../components/NewButton/NewButton";
import pac
  from '../../../../../config/intlMessageSelectors/payroll-admin-customers';
import {
  CREATE_OR_UPDATE_PROJECT,
  DELETE_PROJECT,
} from '../../../../../queries/customers';
import { path } from 'ramda';
import { getErrorMessage, getErrorObjs } from '../../../../../utils/error';
import { NOTIFICATION } from '../../../../../components/NotificationSnackbar';
import { showNotification } from '../../../../../actions/notifications';
import { connect } from 'react-redux';

const getLabel = title => pac[`admin.page.step.employment.projects.column.${title}`];
const headers = [
  {
    id: 'projectNumber',
    label: getLabel('projectNumber'),
    tableCellProps: { style: { minWidth: '150px', paddingLeft: '15px' } },
  },
  {
    id: 'projectName',
    label: getLabel('projectName'),
    tableCellProps: { style: { minWidth: '150px' } },
  },
  {
    id: 'billable',
    label: getLabel('billable'),
  },
  {
    id: 'enabled',
    label: getLabel('enabled'),
  },
  {
    id: 'activities',
    label: getLabel('activities'),
    tableCellProps: { style: { minWidth: '300px' } },
  },
  {
    id: 'menu',
    label: '',
    tableCellProps: {
      style: {
        width: '56px',
        minHeight: '56px',
        paddingRight: '4px',
        paddingLeft: '4px',
      },
    },
  },
];

export const prepareProjectData = (data = {}) => ({
  dimensionNumber: data.projectNumber,
  name: data.projectName,
  billable: !!(+data.billable),
  enabled: !!(+data.enabled),
  workTypes: data.activities
});

const projectKeyMapper = {
  projectNumber: 'dimensionNumber',
  projectName: 'name',
  billable: 'billable',
  enabled: 'enabled',
  activities: 'workTypes'
};

const projectValueMapper = {
  projectNumber: (value) => +value,
  projectName: (value) => value,
  billable: (value) => value,
  enabled: (value) => value,
  activities: (value) => value,
}

const ProjectsEditableTable = ({
   data,
   customerId,
   refetch,
   showNotification
}) => {
  const { formatMessage } = useIntl();

  const [createOrUpdateProject] = useMutation(
    CREATE_OR_UPDATE_PROJECT
  );
  const [deleteProject] = useMutation(
    DELETE_PROJECT
  );

  const onError = useCallback(e => {
    const errorObj = getErrorObjs(e)[0];

    showNotification({
      [Date.now()]: {
        message: getErrorMessage(errorObj, formatMessage),
        type: NOTIFICATION.ERROR,
      },
    });
  }, []);

  const onRowUpdate = debounce(async ({ key, value, form, setListInit, isDrawer }) => {
    const data = {
      [projectKeyMapper[key]]: projectValueMapper[key](value)
    };

    try {
      await createOrUpdateProject({
        variables: {
          input: {
            customerId: +customerId,
            id: path(['values', 'id'], form),
            ...data,
          }}
      });

      if (isDrawer) {
        showNotification({
          [Date.now()]: {
            message: formatMessage(pac['admin.success.notification.saved']),
            type: NOTIFICATION.SUCCESS,
          },
        });
      }

      await refetch()
    } catch (e) {
      setListInit && setListInit();
      onError(e);
    }
  }, 300)

  const onRowRemove = async id => {
    try {
      await deleteProject({ variables: { id, customerId } });
      refetch();
    } catch (e) {
      onError(e);
    }
  }

  const onAdd = async () => {
    const isEmptyProject = data.some(el => el.projectNumber === '');

    if (isEmptyProject) {
      showNotification({
        [Date.now()]: {
          message: formatMessage(pac['admin.error.add.empty.project']),
          type: NOTIFICATION.ERROR,
        },
      });
    } else {
      try {
        await createOrUpdateProject({
          variables: {input: {customerId: +customerId}}
        });
        refetch();
      } catch (e) {
        onError(e);
      }
    }
  }

  const AddNewRowComponent = () => (
    <AddNew
      onClick={onAdd}
      text={pac['admin.page.step.employment.projects.add.project']}
    />
  )

  return (
    <Paper
      style={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        marginBottom: '72px',
      }}
    >
      <Table>
        <TableHead headers={headers} />
        <ProjectsEditableTbody
          data={data}
          AddNewRowComponent={AddNewRowComponent}
          onRowUpdate={onRowUpdate}
          onRowRemove={onRowRemove}
        />
      </Table>
    </Paper>
  );
}

export default connect(null, { showNotification })(ProjectsEditableTable);
