import React, {useCallback, useContext} from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import Footer from '../components/Footer';
import { CUSTOMER_STATUSES } from '../Customer';
import { showNotification } from '../../../actions/notifications';
import { NOTIFICATION } from '../../../components/NotificationSnackbar';
import pac from '../../../config/intlMessageSelectors/payroll-admin-customers';
import {
  UPDATE_CUSTOMER_STATUS
} from '../../../queries/customers';
import { useIntl } from 'react-intl';
import { WizzardContext } from '../../../providers/WizzardProvider';

const EmploymentCategoryFooter = ({
  loading,
  customerId,
  step = {},
  updateCustomerStatus,
  history,
  isNextDisabled,
  showNotification,
}) => {
  const { formatMessage: f } = useIntl();
  const { showProjectsStep } = useContext(WizzardContext);

  const onNext = async () => {
    await updateCustomerStatus({
      variables: {
        customerId,
        status: CUSTOMER_STATUSES.EMPLOYMENT_CATEGORIES,
      },
    });

    showNotification({
      [Date.now()]: {
        message: f(pac['admin.success.notification.saved']),
        type: NOTIFICATION.SUCCESS,
      },
    });

    history.push(`/customers/${customerId}/step/${showProjectsStep ? step.next[0] : step.next[1]}`);
  }

  return (
    <Footer
      isBackDisabled={loading}
      isNextDisabled={loading || isNextDisabled}
      isLastStep={false}
      onBack={() => history.push(step.back, { customerId })}
      onNext={onNext}
    />
  );
}

export default compose(
  connect(null, { showNotification }),
  graphql(UPDATE_CUSTOMER_STATUS, { name: 'updateCustomerStatus' }),
)(EmploymentCategoryFooter);
