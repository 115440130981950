import types from './types';

export function setSelectedPeriod(payload) {
  return {
    type: types.SET_SELECTED_PERIOD,
    payload,
  };
}

export function setSelectedDepartment(payload) {
  return {
    type: types.SET_SELECTED_DEPARTMENT,
    payload,
  };
}

export function setUserAdditional(payload) {
  return {
    type: types.SET_USER_ADDITIONAL,
    payload,
  };
}

export function changeUserLocale(payload) {
  return {
    type: types.CHANGE_USER_LOCALE,
    payload,
  };
}
