import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  path,
  slice,
  compose,
  toLower,
  pathOr,
  pick,
  values,
  map,
  head,
  join,
} from 'ramda';
import {
  Paper,
  Popover,
} from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react'
import { injectIntl } from 'react-intl';
import { clearAuth } from '../actions/auth';
import exitIcon from '../styles/headerStyles/exit.svg';
import pab from '../config/intlMessageSelectors/payroll-admin-base';
import { useLoggedUser } from '../providers/IdentityProvider';

const getUserAvatarLink = (user, loggedUser) => {
  const picture = path(['picture'], user);
  if (picture) return picture;

  const initials = compose(
    join(''),
    map(head),
    values,
    pick(['firstName', 'lastName']),
  )(loggedUser)

  return [
    'https://cdn.auth0.com/avatars/',
    compose(
      toLower,
      slice(0, 2),
      pathOr(initials, ['nickname']),
    )(user),
    '.png',
  ].join('');
};

const UserLogOutInfo = ({ intl  }) => {
  const { user, logout } = useAuth0()
  const loggedUser = useLoggedUser()
  const avatar = getUserAvatarLink(user, loggedUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const onClick = ({ currentTarget }) => setAnchorEl(currentTarget);
  const onKeyDownPopOver = ({ keyCode, currentTarget }) => {
    if (keyCode === 13) onClick({ currentTarget });
  };

  const onClose = React.useCallback(
    e => {
      e.stopPropagation();
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [],
  );

  // eslint-disable-next-line
  const isOpen = React.useMemo(() => Boolean(anchorEl), [Boolean(anchorEl)]);

  const onKeyDownLogout = React.useCallback(
    ({ keyCode }) => keyCode === 13 && logout(),
    // eslint-disable-next-line
    [],
  );

  return (
    <div className="user-info-wrapper">
      <input
        type="image"
        src={avatar}
        className="avatar"
        alt="user avatar"
        onClick={onClick}
        onKeyDown={onKeyDownPopOver}
      />
      <Popover
        id="simple-popover"
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Paper>
          <div style={{ display: 'flex' }}>
            <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
              <img
                src={avatar}
                className="popup-avatar"
                alt="user avatar"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
              <span className="username-text">
                {`${loggedUser.firstName} ${loggedUser.lastName}`}
              </span>
              <span className="username-email">{user.email}</span>
              <span
                role="button"
                className="logout-text"
                onClick={logout}
                tabIndex="0"
                onKeyDown={onKeyDownLogout}
              >
                <span>{intl.formatMessage(pab['app.base.logout'])}</span>
                <img
                  src={exitIcon}
                  alt="exit"
                />
              </span>
            </div>
          </div>
        </Paper>
      </Popover>
    </div>
  );
};

export default connect(null, { clearAuth })(injectIntl(UserLogOutInfo));
