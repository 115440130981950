export default {
  "app.notification.success.create": "Created",
  "app.notification.success.delete": "Deleted",
  "app.notification.success.update": "Updated",
  "app.notification.success.default": "Success",
  "app.notification.error.default": "Something went wrong",
  "app.notification.error.notNull": "can not be empty",
  "app.notification.error.invalid": "is invalid",
  "app.notification.error.field": "Field",
  "app.notification.default": "Something went wrong",
  "notification.error.notNull": "can not be null",
  "notification.error.invalid": "is invalid"
}
