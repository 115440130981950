import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { fromPairs, is } from 'ramda';
import {
  ArrowRightAlt as ArrowRightIcon,
  InfoRounded as InfoIcon,
} from '@material-ui/icons';
import { Delete as DeleteIcon } from '../../../icons';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel, FormHelperText,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FastField, Field, FieldArray } from 'formik';

import HolidaysSection from "./HolidaysSection";
import Footer from '../components/Footer';
import pac from '../../../config/intlMessageSelectors/payroll-admin-customers';
import { DatePicker, Select, TextField, TimePicker } from '../../../components/FormikFields';
import { DEFAULT_DATE_FORMAT } from "../../../utils/times";

const styles = theme => ({
  paper: { width: '100%', overflow: 'auto', marginBottom: '72px' },
  wrapper: { padding: '20px 32px' },
  wrapperRow: { marginBottom: 20 },
  notificationBlock: {
    padding: '8px 16px',
    background: '#FFFFFF',
    border: '1px solid #D6D6D6',
    borderRadius: '3px 3px 0px 0px',
  },
  inputs: { marginTop: '4px' },
  selects: { marginTop: '4px' },
  checkbox: { padding: '4px' },
  checkboxWrapper: { marginLeft: 0 },
  formControl: { minWidth: 150 },
  selectEmpty: { marginTop: theme.spacing.unit * 2 },
  notificationFormControl: { minWidth: 150, marginRight: 20 },
  datePickerIconButtons: { padding: 1 },
  iconButton: { padding: 5 },
  secondaryButton: { color: '#3B77B5', fontWeight: 400 },
  underline: { '&:before': { borderBottom: 'none' } },
  periodsWrapper: { margin: '15px 0' },
  periodsTitle: { display: 'flex', flexDirection: 'row' },
  periodsTitleText: { marginLeft: 8, marginTop: 2 },
  periodsTitleIcon: { marginBottom: -7 },
  periodCutOffSelect: { width: 130, marginTop: 0 },
  periodCutOffInfo: { display: 'flex', alignItems: 'center' },
  infoIcon: { fontSize: 17, marginLeft: 4 },
  copyNotificationWrapper: { paddingTop: 18 },
});

const PERIOD_FREQUENCY = fromPairs(
  ['MONTHLY', 'WEEKLY', 'BI_WEEKLY'].map(k => [k, k]),
);

const maxCutOffDate = 15;

export const NOTIFICATIONS = { TYPES: fromPairs(['EMPLOYEE', 'MANAGER'].map(k => [k, k])) };

const GeneralForm = ({
  values,
  defaults: { notifications },
  tenants,
  payrollSystems,
  countries,
  period,
  classes,
  isLoading,
  handleSubmit,
  onBack,
  isSubmitting,
  isSuperAdmin,
  setFieldValue,
}) => {
  const { formatMessage: f } = useIntl();
  const [isHolidayLoading, setIsHolidayLoading] = useState(false);
  const isNewCustomer = !!values?.customer?.id;

  const isBillableInActiveState = (inactiveDate) => {
    if (!inactiveDate) return true;

    const dateString = is(String, inactiveDate)
      ? moment(inactiveDate).format(DEFAULT_DATE_FORMAT)
      : inactiveDate.format(DEFAULT_DATE_FORMAT);

    return values.customer.isActive || dateString >= moment().format(DEFAULT_DATE_FORMAT);
  };

  useEffect(() => {
    const isRepeatable = values.notifications.some(notification =>
      notification.type === NOTIFICATIONS.TYPES.EMPLOYEE && notification.repeatable
    )

    if (!isRepeatable) {
      setFieldValue('isNotificationRepeatable', false);
      setFieldValue('copyNotification', false);
    } else {
      setFieldValue('isNotificationRepeatable', true);
    }
  },[values])

  const periodCutOffOptions = useMemo(() => {
    const items = [];

    for (let i = 1; i <= maxCutOffDate; i++) {
      items.push({value: i, label: moment().date(i).format('Do')});
    }

    return items;
  }, []);

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container item className={classes.wrapper}>
          <Grid
            item
            xs={12}
            container
            spacing={16}
            alignItems="center"
            className={classes.wrapperRow}
          >
            <Grid item xs={12} md={3}>
              <FastField
                overrided
                fullWidth
                name="customer.name"
                label={f(pac['admin.page.step.general.customer.name'])}
                component={TextField}
                variant="outlined"
                classes={{ root: classes.inputs }}
                disabled={isNewCustomer}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FastField
                overrided
                fullWidth
                variant="outlined"
                name="customer.organizationNumber"
                label={f(pac['admin.page.step.general.org.number'])}
                component={TextField}
                classes={{ root: classes.inputs }}
                disabled={isNewCustomer}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Field
                overrided
                fullWidth
                variant="outlined"
                name="customer.countryId"
                label={f(pac['admin.page.step.general.country'])}
                component={Select}
                options={countries}
                className={classes.selects}
                disabled={isNewCustomer || isHolidayLoading}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Field
                overrided
                fullWidth
                variant="outlined"
                name="customer.payrollSystemId"
                label={f(pac['admin.page.step.general.payroll.system'])}
                component={Select}
                options={payrollSystems}
                className={classes.selects}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Field
                overrided
                fullWidth
                variant="outlined"
                name="customer.tenantId"
                label={f(pac['admin.page.step.general.tenant'])}
                component={Select}
                options={tenants}
                className={classes.selects}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={16}
            alignItems="center"
          >
            {isSuperAdmin && (
              <Grid item xs={12} md={1}>
                <Field name="customer.isBillable">
                  {({ field: { name, value }, form }) => (
                    <FormControlLabel
                      checked={value}
                      control={(
                        <Checkbox
                          className={classes.checkbox}
                          disabled={!isBillableInActiveState(values.customer.inactiveDate)}
                          onChange={
                            ({ target: { checked } }) => {
                              form.setFieldValue(name, checked);
                            }
                          }
                        />
                      )}
                      className={classes.checkboxWrapper}
                      label={f(pac['admin.page.step.general.is.billable'])}
                      labelPlacement="start"
                    />
                  )}
                </Field>
              </Grid>
            )}

            <Grid item xs={12} md={1}>
              <FastField name="customer.isActive">
                {({ field: { name, value }, form }) => (
                  <FormControlLabel
                    checked={value}
                    control={(
                      <Checkbox
                        className={classes.checkbox}
                        onChange={
                          ({ target: { checked } }) => {
                            form.setFieldValue(name, checked);
                            form.setFieldValue('customer.inactiveDate', moment());
                          }
                        }
                      />
                    )}
                    className={classes.checkboxWrapper}
                    label={f(pac['admin.page.step.general.is.active'])}
                    labelPlacement="start"
                  />
                )}
              </FastField>
            </Grid>

            <Grid item xs={12} md={2}>
              <Field
                autoOk
                disabled={values.customer.isActive}
                name="customer.inactiveDate"
                label={f(pac['admin.page.step.general.inactive.date'])}
                format="DD MMM YYYY"
                variant="outlined"
                component={DatePicker}
                onChange={
                  ({ key, value, form }) => {
                    form.setFieldValue('customer.isBillable', isBillableInActiveState(value));
                  }
                }
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="column"
            justify="center"
            className={classes.periodsWrapper}
          >
            <div className={classes.periodsTitle}>
              <Typography variant="h5" gutterBottom>
                {f(pac['admin.page.step.general.period.reports'])}
              </Typography>
              {values.period.endDate && (
                <Typography
                  variant="subtitle1"
                  className={classes.periodsTitleText}
                > (
                  {moment.utc(values.period.startDate).format('DD MMM YYYY')}
                  <ArrowRightIcon className={classes.periodsTitleIcon} />
                  {moment.utc(values.period.endDate).format('DD MMM YYYY')})
                </Typography>
              )}
            </div>

            <Grid container spacing={16} alignItems="flex-start">
              <Grid item>
                <FastField name="period.frequency">
                  {({ field: { name, value }, form }) => (
                    <FormControl className={classes.formControl}>
                      <RadioGroup
                        row
                        aria-label="frequency"
                        name="frequency"
                        className={classes.group}
                        value={value}
                        onChange={({ target: { value } }) =>
                          form.setFieldValue(name, value)}
                      >
                        <FormControlLabel
                          value={PERIOD_FREQUENCY.MONTHLY}
                          control={<Radio />}
                          label={f(pac['admin.page.step.general.label.monthly'])}
                        />
                        <FormControlLabel
                          value={PERIOD_FREQUENCY.BI_WEEKLY}
                          control={<Radio disabled />}
                          label={f(
                            pac['admin.page.step.general.label.bi-weekly'],
                          )}
                        />
                        <FormControlLabel
                          value={PERIOD_FREQUENCY.WEEKLY}
                          control={<Radio disabled />}
                          label={f(pac['admin.page.step.general.label.weekly'])}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </FastField>
              </Grid>
              <Grid item>
                <FastField
                  autoOk
                  name="period.startDate"
                  label={f(pac['admin.page.step.general.label.start.from'])}
                  format="MMMM YYYY"
                  views={['month']}
                  component={DatePicker}
                  variant="outlined"
                />
              </Grid>

              <Grid item>
                <Field
                  overrided
                  fullWidth
                  variant="outlined"
                  name="period.cutOffDate"
                  label={f(pac['admin.page.step.general.period.cut.off.date'])}
                  component={Select}
                  disabled={values.copyNotification}
                  options={periodCutOffOptions}
                  className={[classes.selects, classes.periodCutOffSelect]}
                />
                <FormHelperText className={classes.periodCutOffInfo}>
                  {f(pac['admin.page.step.general.period.cut.off.date.info'])} <Tooltip
                  title={f(pac['admin.page.step.general.period.cut.off.date.info.description'])}
                >
                  <InfoIcon color="secondary" className={classes.infoIcon} />
                </Tooltip>
                </FormHelperText>
              </Grid>

              {values.isNotificationRepeatable && (
                <Field name={`copyNotification`}>
                  {({ field: { name, value }, form }) => (
                    <FormControlLabel
                      checked={value}
                      control={(
                        <Checkbox
                          className={classes.checkbox}
                          onChange={
                            ({ target: { checked } }) => {
                              form.setFieldValue(name, checked);

                              if (checked) {
                                const employeeNotification = values.notifications.find(notification => notification.type === NOTIFICATIONS.TYPES.EMPLOYEE && notification.repeatable);
                                const deadline = employeeNotification ? moment(employeeNotification.deadline).date() : 5;
                                setFieldValue('period.cutOffDate', deadline <= maxCutOffDate ? deadline : maxCutOffDate);
                              }
                            }
                          }
                        />
                      )}
                      label={f(pac['admin.page.step.general.copy.period.cut.off.date'])}
                      labelPlacement="start"
                      className={classes.copyNotificationWrapper}
                    />
                  )}
                </Field>
              )}
            </Grid>
          </Grid>

          <FieldArray name="notifications">
            {helpers => (
              <Grid item container className={classes.periodsWrapper}>
                <Grid item container justify="space-between" alignItems="center">
                  <Typography variant="h5" gutterBottom>
                    {f(pac['admin.page.step.general.email.notifications'])} ({values.notifications.length})
                  </Typography>
                  <Button
                    classes={{ root: classes.secondaryButton }}
                    onClick={() => helpers.push(notifications)}
                  >
                    + {f(pac['admin.page.step.general.add.notification.button'])}
                  </Button>
                </Grid>

                {values.notifications.map((notification, i) => (
                  <Grid
                    container
                    key={i}
                    alignItems="center"
                    justify="space-between"
                    className={classes.notificationBlock}
                    style={{ ...(i > 0 && { borderTop: 'none' }) }}
                  >
                    <Grid
                      item
                      container
                      xs={10}
                      sm={11}
                      md={11}
                      lg={9}
                      spacing={8}
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={3} md={2}>
                        <FastField
                          fullWidth
                          label={f(
                            pac[
                              'admin.page.step.general.label.notification.type'
                              ],
                          )}
                          name={`notifications.${i}.type`}
                          InputProps={{ classes: { underline: classes.underline } }}
                          component={Select}
                          variant={'filled'}
                          options={[
                            {
                              value: NOTIFICATIONS.TYPES.EMPLOYEE,
                              label: f(
                                pac[
                                  'admin.page.step.general.notification.type.employee'
                                  ],
                              ),
                            },
                            {
                              value: NOTIFICATIONS.TYPES.MANAGER,
                              label: f(
                                pac[
                                  'admin.page.step.general.notification.type.manager'
                                  ],
                              ),
                            },
                          ]}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3} md={2}>
                        <Field
                          fullWidth
                          autoOk
                          name={`notifications.${i}.deadline`}
                          label={f(pac['admin.page.step.general.label.notification.day'])}
                          format={notification.repeatable ? "Do" : "DD MMMM"}
                          onChange={({ key, value, form }) => {
                            form.setFieldValue(key, value);
                            if (notification.type === NOTIFICATIONS.TYPES.EMPLOYEE && notification.repeatable && values.copyNotification) {
                              const day = moment(value).date();
                              form.setFieldValue('period.cutOffDate', day <= maxCutOffDate ? day : maxCutOffDate);
                            }
                          }}
                          component={DatePicker}
                          variant={'filled'}
                          InputProps={{
                            classes: { underline: classes.underline },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} md={2}>
                        <FastField
                          fullWidth
                          ampm={false}
                          name={`notifications.${i}.time`}
                          label={f(pac['admin.page.step.general.label.notification.time'])}
                          overrided
                          variant={'filled'}
                          KeyboardButtonProps={{ 'aria-label': 'change time' }}
                          component={TimePicker}
                          InputProps={{ classes: { underline: classes.underline } }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} md={2}>
                        <FastField name={`notifications.${i}.repeatable`}>
                          {({ field: { name, value }, form }) => (
                            <FormControlLabel
                              checked={value}
                              control={(
                                <Checkbox
                                  className={classes.checkbox}
                                  onChange={
                                    ({ target: { checked } }) => {
                                      form.setFieldValue(name, checked);
                                    }
                                  }
                                />
                              )}
                              label={(
                                <React.Fragment>
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      {f(pac['admin.page.step.general.label.notification.repeatable'])}
                                    </Grid>

                                    <Tooltip
                                      title={f(pac['admin.page.step.general.label.notification.repeatable.tooltip'])}
                                    >
                                      <InfoIcon color="secondary" className={classes.infoIcon} />
                                    </Tooltip>
                                  </Grid>
                                </React.Fragment>
                              )}
                              labelPlacement="start"
                            />
                          )}
                        </FastField>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <IconButton
                        aria-label="Delete"
                        color="secondary"
                        onClick={() => helpers.remove(i)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </FieldArray>

          <HolidaysSection
            values={values}
            setFieldValue={setFieldValue}
            isHolidayLoading={isHolidayLoading}
            setIsHolidayLoading={setIsHolidayLoading}
          />
        </Grid>
      </Paper>

      <Footer
        onBack={onBack}
        isNextDisabled={isLoading || isSubmitting}
        onNext={handleSubmit}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(GeneralForm);
