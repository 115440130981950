import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isNil, map, compose, path } from 'ramda';
import * as Yup from 'yup';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { CustomerLoader } from '../components/CustomerLoader';
import { numbersWithDash } from '../../../utils/regexp';
import { setUserAdditional } from '../../../actions/context';
import GeneralForm, { NOTIFICATIONS } from './GeneralForm';
import { showNotification } from '../../../actions/notifications';
import { NOTIFICATION } from '../../../components/NotificationSnackbar';
import { ROLES } from '../../../config/role-configs';
import CopyCustomerButton from '../components/CopyCustomerButton';
import pac from '../../../config/intlMessageSelectors/payroll-admin-customers';
import { getErrorEntityCode, getErrorMessageCode } from '../../../utils/error';

import {
  CREATE_OR_UPDATE_CUSTOMER,
  GET_CUSTOMER_FORM_INFO,
  GET_CUSTOMERS,
  GET_TENANTS_AND_PAYROLL_SYSTEMS,
} from '../../../queries/customers';
import { DEFAULT_DATE_FORMAT } from "../../../utils/times";

const ERROR_CODES = {
  DUPLICATED_NOTIFICATIONS: 8,
  DUPLICATED_HOLIDAYS: 9,
  OPS_SOMETING_WENT_WRONG: 0,
  INVALID_ORG_NUMBER: 7,
  DUPLICATED_ORG_NUMBER_WITHIN_COUNTRY: 30,
  PORTAL_ADMIN_INTEGRATION: 16,
};

const MESSAGE_CODE = {
  PORTAL_ADMIN_INVALID_ORG_NUMBER: 31,
  PORTAL_ADMIN_TENANT_NOT_SYNCED: 32,
  PORTAL_ADMIN_INTEGRATION_ERROR: 33,
}

const ERROR_MESSAGES = {
  [ERROR_CODES.DUPLICATED_NOTIFICATIONS]: 'admin.page.stepper.custom.error.duplicated.notifications',
  [ERROR_CODES.DUPLICATED_HOLIDAYS]: 'admin.page.stepper.custom.error.duplicated.holidays',
  [ERROR_CODES.OPS_SOMETING_WENT_WRONG]: 'admin.error.notification.ops.something.went.wrong',
  [ERROR_CODES.INVALID_ORG_NUMBER]: 'admin.page.stepper.custom.error.invalid.org.number',
  [ERROR_CODES.DUPLICATED_ORG_NUMBER_WITHIN_COUNTRY]: 'admin.page.stepper.custom.error.duplicated.org.number.within.country',
  [MESSAGE_CODE.PORTAL_ADMIN_INVALID_ORG_NUMBER]: 'admin.error.portal.admin.invalid.org.number',
  [MESSAGE_CODE.PORTAL_ADMIN_TENANT_NOT_SYNCED]: 'admin.error.portal.admin.tenant.not.synced',
  [MESSAGE_CODE.PORTAL_ADMIN_INTEGRATION_ERROR]: 'admin.error.portal.admin.integration.error',
};

const getErrorMessage = code => ERROR_MESSAGES[code];

const now = moment();
const { hours, minutes } = now.toObject();

const asMilliseconds = hours * 60000 * 60 + minutes * 60000;

const defaultNotification = {
  type: NOTIFICATIONS.TYPES.EMPLOYEE,
  deadline: now,
  time: asMilliseconds,
  repeatable: false,
};

const GeneralSchema = Yup.object().shape({
  customer: Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short')
      .max(30, 'Too long')
      .required('Required'),
    organizationNumber: Yup.string()
      .matches(numbersWithDash, 'Should have numbers with optional hyphen.')
      .min(2, 'Too short')
      .max(30, 'Too long')
      .required('Required'),
    tenantId: Yup.number()
      .nullable()
      .required('Required'),
    payrollSystemId: Yup.number()
      .nullable()
      .required('Required'),
    countryId: Yup.number()
      .nullable()
      .required('Required'),
  }),
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_TENANTS_AND_PAYROLL_SYSTEMS_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'FETCH_TENANTS_AND_PAYROLL_SYSTEMS_SUCCESS': {
      const { tenants, payrollSystems, countries } = action.payload;
      return {
        ...state,
        isLoading: false,
        tenants,
        payrollSystems,
        countries,
      };
    }
    case 'FETCH_TENANTS_FAILED':
      return {
        ...state,
        tenants: [],
        isLoading: false,
        error: action.payload,
      };
    case 'FETCH_CUSTOMER_INFO_REQUEST':
      return {
        ...state,
        // notification, period, holidays & customer
        ...action.payload,
        isLoading: true,
      };
    case 'FETCH_CUSTOMER_INFO_SUCCESS':
      // eslint-disable-next-line
      const customer = path(
        ['payload', 'customer'],
        action,
      );
      const customerIdToCopy = path(
        ['payload', 'customerIdToCopy'],
        action,
      );
      const  { inactiveDate } = customer;

      return {
        ...state,
        ...action.payload,
        customer: customerIdToCopy ? customer : {
          ...customer,
          isActive: isNil(inactiveDate)
            || (now.format(DEFAULT_DATE_FORMAT) < moment(inactiveDate).format(DEFAULT_DATE_FORMAT)),
        },
        isLoading: false,
      };
    case 'FETCH_CUSTOMER_INFO_FAILURE':
      return {
        ...state,
        period: [],
        notifications: [],
        holidays: [],
        customer: {},
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCustomer = {
  name: '',
  organizationNumber: '',
  payrollSystemId: null,
  countryId: null,
  tenantId: null,
  isActive: true,
  inactiveDate: now,
  isBillable: true,
};

const initialState = {
  tenants: [],
  payrollSystems: [],
  countries: [],
  period: {
    frequency: 'MONTHLY',
    startDate: now,
    cutOffDate: 5,
  },
  notifications: [defaultNotification],
  holidays: [],
  customer: initialCustomer,
  isLoading: false,
  error: {},
  customerIdToCopy: undefined,
  isNotificationRepeatable: false,
  copyNotification: false,
};

const init = selectedTenant => state => ({
  ...state,
  customer: {
    ...state.customer,
    tenantId: selectedTenant,
  },
});

const General = ({ setUserAdditional, showNotification, selectedTenant, tenants, client, step, params: { id }, isSuperAdmin, setupRightPlace }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState, init(selectedTenant));
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const [dataToCopy, setDataToCopy] = useState({});

  const fetchTenants = React.useCallback(async () => {
    dispatch({ type: 'FETCH_TENANTS_AND_PAYROLL_SYSTEMS_REQUEST' });
    const {
      data: {
        tenants,
        getPayrollSystems: payrollSystems,
        countries,
      },
    } = await client.query({
      query: GET_TENANTS_AND_PAYROLL_SYSTEMS,
      variables: { input: {} },
    });

    dispatch({
      type: 'FETCH_TENANTS_AND_PAYROLL_SYSTEMS_SUCCESS',
      payload: {
        tenants: map(({ id, name }) => ({ value: id, label: name }), tenants),
        payrollSystems: map(({ id, name }) => ({ value: id, label: name }), payrollSystems),
        countries: map(({ id, name }) => ({ value: id, label: name }), countries),
      },
    });
    // eslint-disable-next-line
  }, []);

  const fetchCustomerInfoByCustomerId = React.useCallback(async (customerId, isCopy = false, dataToCopy) => {
    dispatch({ type: 'FETCH_CUSTOMER_INFO_REQUEST' });

    const {
      data: {
        notifications,
        periodsV2: period,
        customerHolidays: holidays,
        customerById: customer,
      },
    } = await client.query({
      query: GET_CUSTOMER_FORM_INFO,
      variables: { customerId },
    });

    dispatch({
      type: 'FETCH_CUSTOMER_INFO_SUCCESS',
      payload: {
        notifications: notifications.map(({ deadline, ...rest }) => {
          const h = moment(deadline).hours();
          const m = moment(deadline).minutes();

          return {
            ...rest,
            deadline: moment(deadline),
            time: (h * 60 * 60 + m * 60) * 1000,
          };
        }),
        period: isCopy ? initialState.period : period,
        holidays,
        customer: isCopy ? {
          ...initialCustomer,
          id: dataToCopy?.id,
          name: dataToCopy.name,
          organizationNumber: dataToCopy.organizationNumber,
          countryId: dataToCopy.countryId,
          payrollSystemId: dataToCopy.payrollSystemId,
          tenantId: dataToCopy.tenantId,
        } : customer,
        customerIdToCopy: isCopy ? customerId : undefined,
        isNotificationRepeatable: notifications.some(notification =>
          notification.type === NOTIFICATIONS.TYPES.EMPLOYEE && notification.repeatable
        ),
        copyNotification: notifications.some(notification =>
          notification.type === NOTIFICATIONS.TYPES.EMPLOYEE && notification.repeatable && moment(notification.deadline).date() === period.cutOffDate
        ),
      },
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const isCreate = id.toUpperCase() === 'NEW';

    fetchTenants();

    // eslint-disable-next-line
    !isCreate && fetchCustomerInfoByCustomerId(+id);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const isCreate = id.toUpperCase() === 'NEW';
    const canCopyCustomer = isCreate || dataToCopy?.status === 1;

    if (canCopyCustomer) {
      setupRightPlace(() => (
        <CopyCustomerButton dataToCopy={dataToCopy} onChange={async (id, cb, dataToCopy) => {
          await fetchCustomerInfoByCustomerId(id, true, dataToCopy);
          if(cb) cb();
        }} />
      ));
    }
    // eslint-disable-next-line
  }, [dataToCopy]);

  const onSubmit = async v => {
    const {
      customer: {
        id,
        name,
        organizationNumber,
        payrollSystemId,
        countryId,
        tenantId,
        isActive,
        inactiveDate,
        isBillable,
      },
      period: { frequency, startDate, cutOffDate },
      holidays,
      notifications,
    } = v;

    const { customerIdToCopy } = state;

    try {
      const { data: { createOrUpdateCustomerConfig: { customerId } } } = await client.mutate({
        mutation: CREATE_OR_UPDATE_CUSTOMER,
        variables: {
          customer: {
            id,
            name,
            organizationNumber,
            payrollSystemId,
            countryId,
            tenantId,
            inactiveDate: (!isActive && inactiveDate) || null,
            isBillable,
          },
          period: {
            frequency,
            startDate: moment(startDate)
              .startOf('month')
              .format('YYYY-MM-DD HH:mm:ss'),
            cutOffDate,
          },
          notifications: map(
            ({ type, deadline, time, repeatable }) => {
              return {
                type,
                repeatable,
                deadline: moment(deadline.set({
                  hours: moment(time).utc().hours(),
                  minutes: moment(time).utc().minutes(),
                })).toISOString(),
              }
            },
            notifications,
          ),
          holidays: map(
            ({ id, name, date, duration }) => ({
              ...Number(id) && {id: +id},
              name,
              date,
              duration,
            }),
            holidays,
          ),
          customerIdToCopy,
        },
      });

      const { data: { customers } } = await client.query({
        query: GET_CUSTOMERS,
        variables: { input: { filters: { tenantId: tenants.map(({ id }) => id) } } },
      });

      setUserAdditional({ customers });

      showNotification({
        [Date.now()]: {
          message: f(pac['admin.success.notification.saved']),
          type: NOTIFICATION.SUCCESS,
        },
      });

      history.push(`/customers/${customerId}/step/${step.next}`);
    } catch (e) {
      const errorCode = getErrorEntityCode(e);
      const messageCode = getErrorMessageCode(e);

      let message = pac[getErrorMessage(errorCode)];

      if (ERROR_CODES.PORTAL_ADMIN_INTEGRATION === errorCode) {
        message = pac[getErrorMessage(messageCode)]
      }

      showNotification({
        [Date.now()]: {
          message: f(message),
          type: NOTIFICATION.ERROR,
        },
      });
    }
    // eslint-disable-next-line
  };

  // eslint-disable-next-line
  const onBack = React.useCallback(() => history.push(step.back), []);

  if (state.isLoading) {
    return (
      <CustomerLoader />
    );
  }

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          customer: state.customer,
          period: state.period,
          notifications: state.notifications,
          holidays: state.holidays,
          isNotificationRepeatable: state.isNotificationRepeatable,
          copyNotification: state.copyNotification,
        }}
        onSubmit={onSubmit}
        validationSchema={GeneralSchema}
      >
        {props => {
          setDataToCopy(props.values.customer);

          return (
            <Form>
              <GeneralForm
                {...props}
                defaults={{ notifications: defaultNotification }}
                tenants={state.tenants}
                payrollSystems={state.payrollSystems}
                period={state.period}
                countries={state.countries}
                isLoading={state.isLoading}
                onBack={onBack}
                isSuperAdmin={isSuperAdmin}
              />
            </Form>
          )
        }}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  auth: { user: { selectedTenant, role } },
  context: { tenants, customer },
}) => ({ selectedTenant, tenants, customer, isSuperAdmin: role === ROLES.SUPERADMIN });

export default compose(
  connect(mapStateToProps, { setUserAdditional, showNotification }),
  withApollo,
)(General);
