import gql from 'graphql-tag';

export const GET_TENANTS = gql`
  query GET_TENANTS($input: ListInputType){
    tenants(input: $input){
      id
      name
    }
  }
`;

export const GET_MULTI_TENANCY = gql`
  query GET_TENANTS($tenantFilter: ListInputType, $customerFilter: ListInputType){
    tenants(input: $tenantFilter){
      id
      name
    }
    customers(input: $customerFilter){
      id
      name
      tenantId
      isManager
      periodId
      organizationNumber
    }
  }
`



export const CHANGE_SELECTED_TENANT = gql`
  mutation changeSelectedTenant($tenantId: Int!) {
    changeSelectedTenant(tenantId: $tenantId)
  }
`
