export const getEnvs = () => {
  const envs = window['HRM_ENVS'] || {} ;


  return {
    AUTH0_CLIENT_ID: envs.AUTH0_CLIENT_ID || 'yPVRx6qZeBdP0D7ypq5xRQTvhi1qEbNw',
    AUTH0_DOMAIN: envs.AUTH0_DOMAIN || 'ecitapps-dev.eu.auth0.com',
    AUTH0_AUDIENCE: envs.AUTH0_AUDIENCE || 'https://clockinapiserver.appspot.com',
    GATEWAY_API_URL: envs.GATEWAY_API_URL || 'http://localhost:4000/graphql',
    PUBLIC_URL: envs.PUBLIC_URL || '/'
  }
}
