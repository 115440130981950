export default {
  "app.timeReport.compensationType": "Lønnsartnavn",
  "app.timeReport.dateRange": "Dato/Periode",
  "app.timeReport.transactionStatus": "Status",
  "app.timeReport.transactionStatusRejectBtn": "Avvis",
  "app.timeReport.period": "Periode",
  "app.timeReport.closed": "Ferdigstilt",
  "app.timeReport.open": "Åpen",
  "app.timeReport.closePeriodBtn": "Ferdigstill periode",
  "app.timeReport.periodIsClosedBtn": "Periode er allerede ferdigstilt",
  "app.timeReport.periodIsClosedStatus": "Periode er ferdigstilt",
  "app.timeReport.transactionStatusApproveBtn": "Godkjenn",
  "app.timeReport.transactionStatusApproved": "GODKJENT",
  "app.timeReport.transactionStatusExported": "EKSPORTERT",
  "app.timeReport.transactionStatusRejected": "AVVIST",
  "app.timeReport.transactionStatusPendingApproval": "VENTER PÅ GODKJENNING",
  "app.timeReport.transactionStatusArchived": "ARKIVERT",
  "app.timeReport.transactionStatusSubmitted": "INNSENDT",
  "app.timeReport.createDeviation": "Opprett avvik",
  "app.timeReport.comment": "Kommentar",
  "app.timeReport.showLess": "Vis mindre",
  "app.timeReport.showMore": "Vis mer",
  "app.timeReport.rejectMessage": "Avvistmelding",
  "app.timeReport.deviationsTable": "AVVIK",
  "app.timeReport.details": "DETALJER",
  "app.timeReport.singleDayToggle": "Dag",
  "app.timeReport.periodToggle": "Periode",
  "app.timeReport.formDuration": "Varighet",
  "app.timeReport.formStart": "Start",
  "app.timeReport.formEnd": "Slutt",
  "app.timeReport.formDate": "Dato",
  "app.timeReport.user": "Bruker",
  "app.timeReport.employees": "Ansatte",
  "app.timeReport.status": "Status",
  "app.timeReport.absence": "Fravær",
  "app.timeReport.presence": "Tilstedeværelse",
  "app.timeReport.employee": "Ansatt",
  "app.timeReport.allDepartments": "Alle avdelinger",
  "app.timeReport.department": "Avdeling",
  "app.timeReport.all": "Alle",
  "app.timeReport.notReported": "Ikke rapportert",
  "app.timeReport.waitingForApproval": "Venter på godkjenning",
  "app.timeReport.approved": "Godkjent",
  "app.timeReport.exported": "Eksportert",
  "app.timeReport.timeReportsTitle": "Timelister",
  "app.timeReport.description": "Beskrivelse",
  "app.timeReport.project": "Prosjekt",
  "app.timeReport.projectCompensationType": "Tidstype",
  "app.timeReport.workType": "Aktivitet",
  "app.timeReport.projectHours": "Project reporting",
  "app.timeReport.active": "Aktiv",
  "app.timeReport.archived": "Arkivert",
  "app.timeReport.reportingMoreThan24H": "Det kan kun registreres 24 timer på en dato",
  "app.timeReport.overlappedAbsence": "Du kan kun registrere fravær som er mindre enn, eller tilsvarer antall arbeidstimer i en arbeidsdag",
  "app.timeReport.reportingOnWeekendIsNotAllowed": "Ikke mulig å rapportere fravær på disse dagene. Velg en annen dag.",
  "app.time.report.transations.report.subtitle": "Tranksaksjonsrapport for avdeling",
  "app.time.report.transation.report.download.button": "Last ned rapport",
  "app.time.report.transations.error.no.transactions": "Ingen transaksjoner funnet for denne perioden",
  "app.time.report.transations.error.no.departments": "Ingen avdelinger funnet for denne kunden",
  "app.time.report.transations.removeTransactionModal.title": "Er du sikker på at du vil fjerne denne endringen?",
  "app.time.report.transations.removeTransactionModal.body": "Indsendte transaksjoner vil fjernes. Eventuelle tidligere eksporterte deler av endringene vil beholdes.",
  "app.timeReport.userNotes": "Notater",
  "app.timeReport.transactionStatusPendingSubmit": "VENTER PÅ INNSENDING",
  "app.timeReport.createTransaction": "Opprett transaksjon",
  "app.timeReport.formTime": "Timer",
  "app.timeReport.editTransaction": "Rediger transaksjon",
  "app.timeReport.createTransactionModalTitle": "Opprett transaksjon",
  "app.timeReport.editTransactionModalTitle": "Rediger transaksjon"
}
