import React from 'react';
import { useIntl } from 'react-intl';
import { flatten, compose, length, equals } from 'ramda';
import { Grid, Checkbox, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import pab from '../../../../../../config/intlMessageSelectors/payroll-admin-base';

const MarkAll = ({ onCheckAll, selected, options, classes }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      classes={{ container: classes.header }}
    >
      <Checkbox
        checked={compose(equals(length(selected)), length, flatten)(options)}
        onChange={({ target: { checked } }) => onCheckAll(checked)}
      />
      <Typography>{f(pab['app.base.button.mark.all'])}</Typography>
    </Grid>
  );
};

export default withStyles(() => ({ header: { height: '59px', padding: '10px' } }))(MarkAll);
