import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import mapValues from 'lodash/mapValues';
import pan from '../config/intlMessageSelectors/payroll-admin-notifications';
import { showNotification } from '../actions/notifications';
import { NOTIFICATION } from './NotificationSnackbar';
import {
  getErrorMessage,
  getErrorObjs,
  getErrorStr
} from '../utils/error';

class EcitMutation extends Component {
  selectMessage(action) {
    const { intl } = this.props;

    switch (action) {
      case 'create':
        return intl.formatMessage(pan['app.notification.success.create']);
      case 'update':
        return intl.formatMessage(pan['app.notification.success.update']);
      case 'delete':
        return intl.formatMessage(pan['app.notification.success.delete']);
      default:
        return intl.formatMessage(pan['app.notification.success.default']);
    }
  }

  selectMessageError(errorObj) {
    const { intl } = this.props;

    return getErrorMessage(errorObj, intl.formatMessage);
  }

  emulateMutation(request) {
    const {
      ignoreGlobalNotifySuccess,
      ignoreGlobalNotifyError,
      showNotification,
      action,
      selectedCustomer,
      shouldSkipFilter = false
    } = this.props;
    return data => {
      let adjustedData = data;

      if (action !== 'create') {
        adjustedData = mapValues(data, el => {
          if (el.input && !shouldSkipFilter) {
            if (!el.input.filters) {
              el.input.filters = {};
            }

            el.input.filters.customerId = selectedCustomer;
          }

          return el;
        });
      }

      return request(adjustedData)
        .then(() => {
          if (!ignoreGlobalNotifySuccess) {
            showNotification({
              [Date.now()]: {
                message: this.selectMessage(action),
                type: NOTIFICATION.SUCCESS,
              },
            });
          }
          return this.selectMessage(action);
        })
        .catch(err => {
          let errString = '';
          if (err.graphQLErrors[0]) {
            err.graphQLErrors[0].messageCodeObjs.forEach(element => {
              if (!ignoreGlobalNotifyError) {
                showNotification({
                  [Date.now()]: {
                    message: this.selectMessageError(element || {}),
                    type: NOTIFICATION.ERROR,
                  },
                });
              }

              errString += `${this.selectMessageError(element || {})} `;
            });
          } else {
            const messageErrObj = getErrorObjs(err)[0] || { messageCode: getErrorStr(err) };

            if (ignoreGlobalNotifyError) errString = this.selectMessageError(messageErrObj);
            if (!ignoreGlobalNotifyError) {
              showNotification({
                [Date.now()]: {
                  message: this.selectMessageError(messageErrObj),
                  type: NOTIFICATION.ERROR,
                },
              });
            }
          }

          throw errString;
        });
    };
  }

  render() {
    const {
      mutation,
      refetchQueries,
      children,
    } = this.props;
    return (
      <Mutation
        mutation={mutation}
        refetchQueries={refetchQueries}
        notifyOnNetworkStatusChange
      >
        {runMutation => children(this.emulateMutation(runMutation))}
      </Mutation>
    );
  }
}

const mapStateToProps = ({ auth: { user: { selectedCustomer } } }) => ({ selectedCustomer });

export default connect(
  mapStateToProps,
  { showNotification },
)(injectIntl(EcitMutation));
