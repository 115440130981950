export default {
  "app.employees": "Ansatte",
  "app.departments": "Avdelinger",
  "app.projects": "Prosjekter",
  "app.efficiency.timeReport": "Timeliste",
  "app.efficiency.admin": "Admin",
  "app.base.importInfo": "Importer fra fil",
  "app.base.month.january": "januar",
  "app.base.month.february": "februar",
  "app.base.month.march": "mars",
  "app.base.month.april": "april",
  "app.base.month.may": "mai",
  "app.base.month.june": "juni",
  "app.base.month.july": "juli",
  "app.base.month.august": "august",
  "app.base.month.september": "september",
  "app.base.month.october": "oktober",
  "app.base.month.november": "november",
  "app.base.month.december": "desember",
  "app.base.btns.confirm": "Bekreft",
  "app.base.btns.cancel": "Avbryt",
  "app.base.btns.ok": "OK",
  "app.base.btns.remove": "Fjern",
  "app.base.select.placeholder": "Velg...",
  "app.base.tablePaginationRowsPerPage": "Rader per side",
  "app.base.tablePaginationDisplayedRows": "av",
  "app.base.tableNoData": "Ikke noe innhold opprettet",
  "app.base.tenantSelect": "Velg kontor",
  "app.base.customerSelect": "Velg kunde",
  "app.base.logout": "Logg ut",
  "app.base.button.apply": "Anvend",
  "app.base.button.add": "Legg til",
  "app.base.button.cancel": "Avbryt",
  "app.base.button.import": "Import",
  "app.base.button.choseFile": "Velg fil",
  "app.base.button.mark.all": "Velg alle",
  "app.base.message.noModuleConfig": "Du har ingen moduler konfigurert. Kontakt lederen din eller ECIT for å aktivere en modul.",
  "app.base.message.noProjectReporting": "Prosjektrapportering er deaktivert. Vennligst ta kontakt med din leder eller ECIT for å aktivere funksjonen.",
  "app.base.message.noPeriods": "Det finnes ingen tilgjengelige perioder. Kontakt administratoren din.",
  "app.base.label.search": "Søk",
  "app.base.message.noEmployees": "Du har ikke tilgang til ansatt. Kontakt din administrator",
  "admin.page.modules.config.timeReport": "Time report",
  "admin.page.modules.config.projectReporting": "Prosjektrapportering",
  "admin.page.modules.config.payslips": "Lønnsslipper",
  "admin.page.title": "Administrasjon kunder",
  "admin.page.add.button": "Legg til ny kunde",
  "admin.page.back.button": "Kundeliste",
  "admin.page.edit.button": "Rediger",
  "admin.page.table.column.org.number": "Org. nummer",
  "admin.page.table.column.name": "Navn",
  "admin.page.table.column.payroll.system": "Lønnssystem",
  "admin.page.table.column.tenant": "Kontor",
  "admin.page.table.column.employees": "Ansatte",
  "admin.page.table.column.status": "Status",
  "admin.page.table.column.empty": "Udefinert",
  "admin.page.table.customer.status.active": "Aktiv",
  "admin.page.table.customer.status.inactive": "Inaktiv",
  "admin.page.table.customer.status.incomplete": "Ufullstendig",
  "admin.page.general.step.title": "Generelt",
  "admin.page.compensation.types.step.title": "Kompenasjonstyper",
  "admin.page.employment.categories.step.title": "Ansattkategorier",
  "admin.page.users.import.success": "Import vellykket!",
  "admin.page.users.import.reminder": "Ikke glem å tildele hvilke avdelingsledere og godkjennere som skal godkjenne timer. Dette gjøres i Medarbeider-menyen.",
  "admin.page.users.import.step.title": "Importer brukere",
  "admin.page.projects.step.title": "Prosjekter",
  "admin.page.users.import.step.tooltip": "Når du importerer brukere med godkjennere roll, må du la Ansattnummer, Ansattkategori og Avdeling være tomme.",
  "admin.page.users.import.step.example.tooltip": "Last ned eksempelfil",
  "admin.page.users.import.noData": "Fant ingen data å importere. Vennligst legg inn data i importert fil eller sjekk kolonnenavn.",
  "admin.page.dimensions.worktypes.step.title": "Dimensjoner og arbeidstyper",
  "admin.page.step.next.button": "Neste",
  "admin.page.step.back.button": "Tilbake",
  "admin.page.step.finish.button": "Avslutt",
  "admin.page.step.save.button": "Lagre",
  "admin.page.step.general.copy.button": "Kopier innstillinger fra eksisterende kunde",
  "admin.page.step.general.copy.customer": "Kopier oppsett",
  "admin.page.step.general.copy.placeholder": "Søk etter kundenavn eller org. nummer",
  "admin.page.step.general.copy.confirmation": "Vennligst bekreft at du vil kopiere oppsett fra",
  "admin.page.step.general.customer.name": "Kundenavn",
  "admin.page.step.general.org.number": "Org. nummer",
  "admin.page.step.general.payroll.system": "Lønnssystem",
  "admin.page.step.general.payroll.tenant": "Kontor",
  "admin.page.step.general.payroll.is.active": "Aktive",
  "admin.page.step.general.payroll.is.billable": "Fakturerbar",
  "admin.page.step.general.inactive.date": "Inaktiv dato",
  "admin.page.step.general.country": "Land",
  "admin.page.step.general.period.reports": "Perioder",
  "admin.page.step.general.label.monthly": "Månedlig",
  "admin.page.step.general.label.bi-weekly": "Annenhver uke",
  "admin.page.step.general.label.weekly": "Ukentlig",
  "admin.page.step.general.label.start.from": "Start fra",
  "admin.page.step.general.generated.periods": "Genererte perioder",
  "admin.page.step.general.notifications": "Varsler",
  "admin.page.step.general.add.notification.button": "Legg til ny varsling",
  "admin.page.step.general.notification.type.employee": "Ansatt",
  "admin.page.step.general.notification.type.manager": "Leder",
  "admin.page.step.general.notification.label.type": "Type",
  "admin.page.step.general.label.notification.deadline": "Frist",
  "admin.page.step.general.label.notification.time": "Tid",
  "admin.page.step.general.label.notification.repeatable": "Gjentakende",
  "admin.page.step.general.label.notification.repeatable.tooltip": "Aktivert gjentakelse indikerer at varsler gjentas månedlig på samme tidspunkt. Deaktivert gjentakelse indikerer at varsling kun vil bli sendt en gang på spesifisert tidspunkt.",
  "admin.page.step.general.holidays": "Offentlige fridager",
  "admin.page.step.general.add.holiday.button": "Legg til ny ferie",
  "admin.page.step.general.label.holidays.day": "Dag",
  "admin.page.step.general.reset.button": "Reset",
  "admin.page.step.general.holiday.full.day": "Heldags ferie",
  "admin.page.step.general.holiday.half.day": "Halvdags ferie",
  "admin.page.step.general.holiday.menu.edit": "Endre",
  "admin.page.step.general.holiday.menu.remove": "Fjern",
  "admin.page.step.general.popup.change.country.title": "Endre land",
  "admin.page.step.general.popup.change.country.text": "Når du endrer land, så vil det påvirke helligdager. Ønsker du å beholde eksisterende helligdager?",
  "admin.page.step.general.popup.change.country.reset.button": "Reset",
  "admin.page.step.general.popup.change.country.keep.button": "Behold helligdager",
  "admin.page.step.general.popup.reset.holidays.title": "Reset offentlige helligdager",
  "admin.page.step.general.popup.reset.holidays.text": "Er du sikker på at du ønsker å resette offentlige helligdager?",
  "admin.page.step.general.popup.reset.holidays.reset.button": "Reset",
  "admin.page.step.general.popup.reset.holidays.cancel.button": "Kanseller",
  "admin.page.step.general.popup.edit.holiday.title": "Offentlig helligdag",
  "admin.page.step.general.popup.edit.holiday.field.name": "Helligdag navn",
  "admin.page.step.general.popup.edit.holiday.field.date": "Dato",
  "admin.page.step.general.popup.edit.holiday.field.full.day": "Full dag",
  "admin.page.step.general.popup.edit.holiday.field.half.day": "Halv dag",
  "admin.page.step.general.popup.edit.holiday.cancel.button": "Kanseller",
  "admin.page.step.general.popup.edit.holiday.save.button": "Lagre",
  "admin.page.step.compensation.types.column.code": "Lønnsart",
  "admin.page.step.compensation.types.column.name": "Navn",
  "admin.page.step.compensation.types.column.type": "Type",
  "admin.page.step.compensation.types.column.comment": "Kommentar",
  "admin.page.step.compensation.types.column.reporting.type": "Rapporteringstype",
  "admin.page.step.compensation.types.column.intervals": "Intervall",
  "admin.page.step.compensation.types.column.periodic": "Periodisk",
  "admin.page.step.compensation.types.column.in.summary": "Vis sammendrag",
  "admin.page.step.compensation.types.column.global.type": "Global type",
  "admin.page.step.compensation.types.column.description": "Beskrivelse",
  "admin.page.step.compensation.types.add.compensation.type": "Legg til ny kompensasjonstype",
  "admin.page.step.compensation.types.type.absence": "Fravær",
  "admin.page.step.compensation.types.type.presence": "Tilstedeværelse",
  "admin.page.step.compensation.types.comment.required": "Påkrevd",
  "admin.page.step.compensation.types.comment.no": "Nei",
  "admin.page.step.compensation.types.comment.optional": "Valgfri",
  "admin.page.step.compensation.types.reporting.type.overlapping": "Overlappende",
  "admin.page.step.compensation.types.reporting.type.full.day": "Full dag",
  "admin.page.step.compensation.types.reporting.type.interval": "Intervall",
  "admin.page.step.compensation.types.reporting.type.duration": "Varighet",
  "admin.page.step.compensation.types.reporting.type.full.month": "Full måned",
  "admin.page.step.compensation.types.time.type.vacation": "Ferie",
  "admin.page.step.compensation.types.time.type.overtime": "Overtid",
  "admin.page.step.compensation.types.time.type.off.duty": "Ikke på jobb",
  "admin.page.step.compensation.types.time.type.more.time": "Mer tid",
  "admin.page.step.compensation.types.time.type.sickleave": "Sykefravær",
  "admin.page.step.compensation.types.time.type.workhours": "Arbeidstimer",
  "admin.page.step.compensation.types.time.type.no.deviation": "Ingen avvik",
  "admin.page.step.compensation.types.time.type.care.of.child": "Sykt barn",
  "admin.page.step.compensation.types.time.type.parental.leave": "Foreldrepermisjon",
  "admin.page.step.compensation.types.delete.button": "Slett",
  "admin.page.step.employment.categories.add.employment.category": "Legg til ny ansattkategori",
  "admin.page.step.employment.categories.column.category": "Kategori",
  "admin.page.step.employment.categories.column.modules.config": "Moduler",
  "admin.page.step.employment.categories.column.norm.time": "Normaltid",
  "admin.page.step.employment.categories.column.exclude.weekends": "Ekskluder helger",
  "admin.page.step.employment.categories.column.reporting.method": "Rapporteringsmetode",
  "admin.page.step.employment.categories.column.compensation.types": "Kompensasjonstyper",
  "admin.page.step.employment.categories.delete.button": "Slett",
  "admin.page.step.employment.users.employmentNumber": "Ansattnummer",
  "admin.page.step.employment.users.column.employmentCategoryName": "Ansattkategori",
  "admin.page.step.employment.users.column.firstName": "Fornavn",
  "admin.page.step.employment.users.column.lastName": "Etternavn",
  "admin.page.step.employment.users.column.email": "E-post",
  "admin.page.step.employment.users.column.role": "Rolle",
  "admin.page.step.employment.users.column.status": "Status",
  "admin.page.step.employment.users.column.departmentName": "Avdeling",
  "admin.page.step.employment.users.column.employeeStart": "Startdato",
  "admin.page.step.employment.projects.column.projectNumber": "Prosjektnummer",
  "admin.page.step.employment.projects.column.projectName": "Prosjekt",
  "admin.page.step.employment.projects.column.billable": "Fakturerbart",
  "admin.page.step.employment.projects.column.enabled": "Aktivert",
  "admin.page.step.employment.projects.column.activities": "Aktiviteter",
  "admin.page.step.employment.projects.add.project": "Legg til prosjekt",
  "admin.page.step.employment.projects.add.activity": "Legg til aktivitet",
  "admin.error.remove.compensation.type": "Kan ikke slette kompensasjonstype som allerede er i bruk",
  "admin.error.update.duplicated.project.number": "Prosjektnummeret er allerede tatt. Velg et unikt nummer.",
  "admin.error.remove.used.employment.category": "Kan ikke slette ansattkategori som allerede er i bruk",
  "admin.error.remove.used.project": "Du kan ikke slette et prosjekt som har vært brukt tidligere. ",
  "admin.error.add.project.validation": "Vennligst fyll ut alle obligatoriske felter.",
  "admin.error.edit.project.number.validation": "Prosjektnummer kan bare inneholde tall",
  "admin.error.edit.project.number.same.validation": "Prosjektnummeret er allerede tatt",
  "admin.error.remove.activity": "Du kan ikke slette en aktivitet som er tatt i bruk.",
  "admin.success.notification.saved": "Lagret",
  "admin.error.notification.ops.something.went.wrong": "Obs, noe gikk galt",
  "admin.stepper.page.customer.name": "Kundenavn",
  "admin.page.stepper.customer.org.number": "Org.nummer",
  "admin.page.stepper.custom.error.duplicated.notifications": "Beklager, det virker som om du har dupliserte varsler",
  "admin.page.stepper.custom.error.duplicated.holidays": "Beklager, det virker som om du har duplisert helligdager\n",
  "admin.page.stepper.custom.error.invalid.org.number": "Ugyldig org. nummer",
  "admin.error.org.number.not.found.in.nettlonn": "Inget selskap funnet for oppgitt organisasjonsnummer i Nettlonn",
  "admin.page.stepper.custom.error.duplicated.org.number.within.country": "Kunde med dette organisasjons nummeret i dette landet eksisterer allerede",
  "app.departments.createDepartment": "Opprett avdeling",
  "app.departments.department": "Avdeling",
  "app.departments.approvers": "Timegodkjennere",
  "app.departments.approver": "Godkjenner",
  "app.departments.noApproverAssigned": "Ingen godkjenner registrert",
  "app.departments.employees": "Ansatte",
  "app.departments.firstName": "Fornavn",
  "app.departments.lastName": "Etternavn",
  "app.departments.phone": "Telefon",
  "app.departments.role": "Rolle",
  "app.departments.email": "E-post",
  "app.departments.createUserInsideDepartment": "Opprett ansatt i avdeling",
  "app.departments.addExistingUserDepartment": "Legg til eksisterende ansatt i avdelingen",
  "app.departments.removeUserFromDepartment": "Fjern bruker fra avdelingen",
  "app.departments.removeDepartment": "Er du sikker på at du vil slette denne avdelingen? Ansatte som hører til avdelingen vil ikke kunne rapportere timer eller fravær før de knyttes til en annen avdeling.",
  "app.departments.removeDepartmentTitle": "Fjern avdeling",
  "app.departments.remove.user": "Fjern bruker",
  "app.departments.edit.user": "Endre bruker",
  "app.employees.userModalCreate": "Opprett ansatt",
  "app.employees.userModalEdit": "Rediger ansatt",
  "app.employees.userModalConfirm": "Lagre",
  "app.employees.userModalCancel": "Avbryt",
  "app.employees.timeReportingMethod": "Rapporteringsmetode",
  "app.employees.userModalGeneral": "Generelt",
  "app.employees.employmentDetails": "Ansattforhold",
  "app.employees.employmentCategory": "Ansattkategori",
  "app.employees.employmentNumber": "Ansattnummer",
  "app.employees.firstName": "Fornavn",
  "app.employees.lastName": "Etternavn",
  "app.employees.phone": "Telefon",
  "app.employees.department": "Avdeling",
  "app.employees.role": "Rolle",
  "app.employees.createUserButton": "Opprett ansatt\n",
  "app.employees.email": "E-post",
  "app.employees.personalId": "Personnummer",
  "app.employees.bankAccount": "Kontonummer",
  "app.employees.contactPerson": "Kontaktperson",
  "app.employees.contactPersonPhone": "Kontaktperson telefon",
  "app.employees.authLogin": "Brukernavn/passord",
  "app.employees.smsLogin": "Logger på med SMS",
  "app.employees.address": "Adresse",
  "app.employees.postalCity": "Poststed",
  "app.employees.postalNumber": "Postnummer",
  "app.employees.userTimezone": "Tidssone",
  "app.employees.userNationality": "Nasjonalitet",
  "app.employees.userLanguage": "Språk",
  "app.employees.admin": "Admin",
  "app.employees.manager": "Leder",
  "app.employees.approver": "Godkjenner",
  "app.employees.employee": "Ansatt",
  "app.employees.superadmin": "Superadmin",
  "app.employees.employmentType": "Ansettelsestype",
  "app.employees.fullTime": "Heltid",
  "app.employees.partTime": "Deltid",
  "app.employees.yearSalary": "Årslønn",
  "app.employees.employeeStartDate": "Ansatt startdato",
  "app.employees.suspended": "Deaktivert",
  "app.employees.all": "alle",
  "app.employees.noDepartmentAssigned": "Ikke tilknyttet en avdeling",
  "app.employees.employeeEndDate": "Ansatt Sluttdato",
  "app.employees.country": "Land",
  "app.employees.city": "Poststed",
  "app.employees.home.address": "Hjemmeadresse",
  "app.employees.removeEmployeeTitle": "Fjern ansatt",
  "app.employees.removeEmployeeConfirmationText": "Er du sikker på at du vil fjerne den ansatte?",
  "app.employees.cannotRemoveHasTransaction": "Can't remove employee with transactions, please suspend employee.",
  "app.employees.errorEmploymentNumber": "Ansattnummer er allerede i bruk, vennligst benytt et annet nummer",
  "app.employees.emailAddressInUse": "E-postadresse er allerede brukt",
  "app.employees.create.employee": "Opprett ansatt",
  "app.employees.edit.employee": "Rediger ansatt",
  "app.employees.approve.own.timereport": "Godkjenn egen timerapport",
  "app.employees.access.rights": "Tilgangsrettighet",
  "app.employees.button.add.new.department.to.approve": "Opprett ny avdeling for godkjenning",
  "app.employees.button.remove.department.to.approve": "Fjern avdeling",
  "app.employees.tenant": "Kontor",
  "app.employees.customer": "Kunde",
  "app.employees.departments": "Avdelinger",
  "app.employees.subtitle.location": "Lokasjon",
  "app.employees.button.save": "Lagre",
  "app.employees.notitication.success.save": "Lagret",
  "app.employees.error.no.deviation": "Kan ikke rapportere \"Ingen Endring\", det eksisterer allerede transaksjoner for denne perioden",
  "app.employees.error.national.holiday": "Fravær kan ikke rapporteres på offentlige fridager",
  "app.employees.dialog.future.reports.title": "Tidsrapportering etter sluttdato",
  "app.employees.dialog.future.reports.description": "har noen tidsrapporter rapportert på og/eller etter gitt sluttdato.\nVennligst se om de skal beholdes eller fjernes. Brukeren vil ikke kunne rapportere på datoer etter den valgte sluttdatoen.",
  "app.employees.dialog.future.reports.date": "Rapporterte datoer",
  "app.notification.success.create": "Opprettet",
  "app.notification.success.delete": "Slettet",
  "app.notification.success.update": "Oppdatert",
  "app.notification.success.default": "Vellykket",
  "app.notification.error.default": "Noe gikk galt",
  "app.notification.error.notNull": "Feltet kan ikke være tomt",
  "app.notification.error.invalid": "er ugyldig",
  "app.notification.error.field": "Felt",
  "app.notification.period.reminded": "Påminnelse sendt",
  "app.notification.periods.reminded": "Alle perioder er påminnet",
  "app.timeReport.compensationType": "Lønnsartnavn",
  "app.timeReport.dateRange": "Dato/Periode",
  "app.timeReport.amount": "Beløp",
  "app.timeReport.transactionStatus": "Status",
  "app.timeReport.transactionStatusRejectBtn": "Avvis",
  "app.timeReport.period": "Periode",
  "app.timeReport.closed": "Ferdigstilt",
  "app.timeReport.open": "Åpen",
  "app.timeReport.closePeriodBtn": "Ferdigstill periode",
  "app.timeReport.periodIsClosedBtn": "Periode er allerede ferdigstilt",
  "app.timeReport.periodIsClosedStatus": "Periode er ferdigstilt",
  "app.timeReport.transactionStatusApproveBtn": "Godkjenn",
  "app.timeReport.periodTakeBackBtn": "Gjenåpne",
  "app.timeReport.transactionStatusApproved": "GODKJENT",
  "app.timeReport.transactionStatusExported": "EKSPORTERT",
  "app.timeReport.transactionStatusRejected": "AVVIST",
  "app.timeReport.transactionStatusPendingApproval": "VENTER PÅ GODKJENNING",
  "app.timeReport.transactionStatusArchived": "ARKIVERT",
  "app.timeReport.transactionStatusSubmitted": "INNSENDT",
  "app.timeReport.createDeviation": "Opprett avvik",
  "app.timeReport.createPresence": "Tilstedeværelse",
  "app.timeReport.comment": "Kommentar",
  "app.timeReport.showLess": "Vis mindre",
  "app.timeReport.showMore": "Vis mer",
  "app.timeReport.rejectMessage": "Avvistmelding",
  "app.timeReport.deviationsTable": "AVVIK",
  "app.timeReport.details": "DETALJER",
  "app.timeReport.singleDayToggle": "Dag",
  "app.timeReport.periodToggle": "Periode",
  "app.timeReport.copyDatesBelow": "Kopier til alle dager",
  "app.timeReport.formDuration": "Varighet",
  "app.timeReport.formStart": "Start",
  "app.timeReport.formEnd": "Slutt",
  "app.timeReport.formDate": "Dato",
  "app.timeReport.user": "Bruker",
  "app.timeReport.employees": "Ansatte",
  "app.timeReport.status": "Status",
  "app.timeReport.absence": "Fravær",
  "app.timeReport.presence": "Tilstedeværelse",
  "app.timeReport.employee": "Ansatt",
  "app.timeReport.employeeNumber": "Ansatt #",
  "app.timeReport.allDepartments": "Alle avdelinger",
  "app.timeReport.department": "Avdeling",
  "app.timeReport.all": "Alle",
  "app.timeReport.notReported": "Ikke rapportert",
  "app.timeReport.waitingForApproval": "Venter på godkjenning",
  "app.timeReport.approved": "Godkjent",
  "app.timeReport.exported": "Eksportert",
  "app.timeReport.timeReportsTitle": "Timelister",
  "app.timeReport.description": "Beskrivelse",
  "app.timeReport.project": "Prosjekt",
  "app.timeReport.projectCompensationType": "Tidstype",
  "app.timeReport.dimensionNumber": "Prosjekt #",
  "app.timeReport.workType": "Aktivitet",
  "app.timeReport.projectHours": "Project reporting",
  "app.timeReport.active": "Aktiv",
  "app.timeReport.archived": "Arkivert",
  "app.timeReport.reportingMoreThan24H": "Det kan kun registreres 24 timer på en dato",
  "app.timeReport.overlappedAbsence": "Du kan kun registrere fravær som er mindre enn, eller tilsvarer antall arbeidstimer i en arbeidsdag",
  "app.timeReport.reportingOnWeekendIsNotAllowed": "Ikke mulig å rapportere fravær på disse dagene. Velg en annen dag.",
  "app.time.report.transactions.reports.subtitle": "Tidsrapporter - Excel",
  "app.time.report.transactions.report.by.deviation.menu.item": "Avvik",
  "app.time.report.transactions.report.by.project.menu.item": "Prosjekt",
  "app.time.report.transactions.error.no.transactions": "Ingen transaksjoner funnet for valgt periode",
  "app.time.report.transactions.error.no.departments": "Ingen avdeling funnet for denne kunden",
  "app.time.report.transactions.removeTransactionModal.title": "Er du sikker på at du vil fjerne dette avviket?",
  "app.time.report.transactions.removeTransactionModal.body": "Valgte avvik vil fjernes. Tidligere eksporterte avvik vil bli beholdt.",
  "app.timeReport.nextEmployeeBtn": "NESTE",
  "app.timeReport.prevEmployeeBtn": "FORRIGE",
  "app.timeReport.periodStatusOpen": "ÅPEN",
  "app.timeReport.periodStatusApproved": "GODKJENT",
  "app.timeReport.periodStatusRejected": "AVVIST",
  "app.timeReport.periodStatusSubmitted": "SENDT",
  "app.timeReport.reviewedBy": "Godkjent av",
  "app.timeReport.approvedBy": "Godkjent av",
  "app.timeReport.rejectedBy": "Avvist av",
  "app.timeReport.periodTypeDeviation": "Avvik",
  "app.timeReport.periodTypeDimension": "Registrert tilstede",
  "app.timeReport.expectedHours": "Estimerte timer",
  "app.timeReport.periodRemindAllBtn": "Påminn",
  "app.timeReport.deviationOnWeekend": "Avviket overlapper med en helg",
  "app.timeReport.deviationOnHoliday": "Avviket overlapper med en helligdag",
  "app.timeReport.departmentNotFound": "Det er ikke mulig å opprette et avvik for en bruker uten en avdeling.",
  "app.timeReport.deviationOverlapsWithExisting": "Avviket du forsøker å legge til overlapper med et eksisterende avvik",
  "admin.page.step.general.label.notification.when": "Når",
  "admin.page.step.general.notification.when.before.deadline": "Før tidsfrist",
  "admin.page.step.general.notification.when.after.deadline": "Etter tidsfrist",
  "admin.page.step.general.notification.when.on.deadline": "På tidsfrist",
  "admin.page.step.general.label.notification.period": "Periode",
  "admin.page.step.general.notification.period.day": "dag",
  "admin.page.step.general.notification.period.days": "dager",
  "admin.page.step.employment.categories.column.project.reporting": "Prosjektrapportering",
  "admin.page.step.employment.categories.column.work.day": "Arbeidsdag",
  "admin.page.step.projects.add.project": "Legg til prosjekt",
  "app.time.report.transations.report.subtitle": "Tranksaksjonsrapport for avdeling",
  "app.time.report.transation.report.download.button": "Last ned rapport",
  "app.time.report.transations.error.no.transactions": "Ingen transaksjoner funnet for denne perioden",
  "app.time.report.transations.error.no.departments": "Ingen avdelinger funnet for denne kunden",
  "app.time.report.transations.removeTransactionModal.title": "Er du sikker på at du vil fjerne denne endringen?",
  "app.time.report.transations.removeTransactionModal.body": "Indsendte transaksjoner vil fjernes. Eventuelle tidligere eksporterte deler av endringene vil beholdes."
}
