import React from 'react';
import {
  compose,
  toLower,
  path,
  reduce,
  all,
  values,
  anyPass,
  isEmpty,
  replace,
} from 'ramda';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { CustomerLoader } from '../components/CustomerLoader';
import { getErrorMessageCode } from '../../../utils/error';
import AddNew from '../components/NewButton';
import Footer from '../components/Footer';
import CompensationTypeTable from './CompensationTypeTable';

import {
  UPDATE_CUSTOMER_STATUS,
  CREATE_COMPENSATION_TYPE,
  DELETE_COMPENSATION_TYPE,
  GET_COMPENSATION_TYPES_AND_INTERVALS_BY_CUSTOMER,
} from '../../../queries/customers';

import { CUSTOMER_STATUSES } from '../Customer';
import { showNotification } from '../../../actions/notifications';
import { NOTIFICATION } from '../../../components/NotificationSnackbar';
import pac from '../../../config/intlMessageSelectors/payroll-admin-customers';

const ERROR_CODES = { REMOVE_COMPENSATION_TYPE_IS_NOT_ALLOWED: 11 };

const ERROR_MESSAGES = { [ERROR_CODES.REMOVE_COMPENSATION_TYPE_IS_NOT_ALLOWED]: 'admin.error.remove.used.compensation.type' };

const getErrorMessage = code =>
  ERROR_MESSAGES[code] || 'Ops something went wrong';

const headers = [
  {
    id: 'code',
    label: pac['admin.page.step.compensation.types.column.code'],
    tableCellProps: { style: { minWidth: '100px', width: '100px', paddingLeft: '15px' } },
  },
  {
    id: 'name',
    label: pac['admin.page.step.compensation.types.column.name'],
    tableCellProps: { style: { minWidth: '150px' } },
  },
  {
    id: 'type',
    label: pac['admin.page.step.compensation.types.column.type'],
    tableCellProps: { style: { width: '133px', minWidth: '133px' } },
  },
  {
    id: 'comment',
    label: pac['admin.page.step.compensation.types.column.comment'],
    tableCellProps: { style: { width: '133px', minWidth: '133px' } },
  },
  {
    id: 'reportingType',
    label: pac['admin.page.step.compensation.types.column.reporting.type'],
    tableCellProps: { style: { width: '133px', minWidth: '133px' } },
  },
  {
    id: 'interval',
    label: pac['admin.page.step.compensation.types.column.intervals'],
    tableCellProps: { style: { width: '105px', minWidth: '105px' } },
  },
  {
    id: 'multipleDays',
    label: pac['admin.page.step.compensation.types.column.periodic'],
    tableCellProps: { style: { width: '64px', minWidth: '64px' } },
  },
  {
    id: 'displayInSummary',
    label: pac['admin.page.step.compensation.types.column.in.summary'],
    tableCellProps: { style: { width: '120px', minWidth: '120px' } },
  },
  {
    id: 'isExcludeWeekend',
    label: pac['admin.page.step.employment.categories.column.exclude.weekends'],
    tableCellProps: { style: { width: '130px', minWidth: '130px' } },
  },
  {
    id: 'timeType',
    label: pac['admin.page.step.compensation.types.column.global.type'],
    tableCellProps: { style: { width: '120px', minWidth: '120px' } },
  },
  {
    id: 'description',
    label: pac['admin.page.step.compensation.types.column.description'],
    tableCellProps: { style: { width: '26%' } },
  },
  {
    id: 'menu',
    label: '',
    tableCellProps: {
      style: {
        width: '56px',
        minHeight: '56px',
        paddingRight: '4px',
        paddingLeft: '4px',
      },
    },
  },
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'TEMPORARY_TYPES_SETUP':
      return {
        ...state,
        types: reduce(
          (r, { id, temporary }) => ({
            ...r,
            [id]: temporary,
          }),
          {},
          action.payload,
        ),
      };
    case 'TEMPORARY_TYPES_CHANGE':
      return {
        ...state,
        types: {
          ...state.types,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

const CompensationTypes = ({
  loading,
  types,
  intervals,
  createOrUpdate,
  updateCustomerStatus,
  deleteType,
  showNotification,
  refetch,
  customerId,
  setupRightPlace,
  history,
  step,
}) => {
  const { formatMessage: f } = useIntl();
  const [state, dispatch] = React.useReducer(reducer, { types: {} });

  const add = async () => {
    await createOrUpdate({ variables: { input: { customerId } } });
    refetch();
  };

  const remove = async id => {
    try {
      await deleteType({ variables: { id, customerId } });
      refetch();
    } catch (e) {
      const errorCode = getErrorMessageCode(e);

      showNotification({
        [Date.now()]: {
          message: f(pac[getErrorMessage(errorCode)]),
          type: NOTIFICATION.ERROR,
        },
      });
    }
  };

  const isNextDisabled = compose(
    anyPass([isEmpty, all(t => !!t)]),
    values,
    path(['types']),
  )(state);

  const update = debounce(async ({ value, key, form }) => {
    const { data: { addCompensationTypeTemp: { temporary, id } } } = await createOrUpdate({
      variables: {
        input: {
          id: path(['values', 'id'], form),
          [key]: value,
          customerId,
        },
      },
    });

    dispatch({
      type: 'TEMPORARY_TYPES_CHANGE',
      payload: { key: id, value: temporary },
    });
  }, 300);

  React.useEffect(() => {
    setupRightPlace(() => (
      <AddNew
        onClick={add}
        text={pac['admin.page.step.compensation.types.add.compensation.type']}
      />
    ));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch({ type: 'TEMPORARY_TYPES_SETUP', payload: types });
    // eslint-disable-next-line
  }, [types.length]);

  return (
    <React.Fragment>
      {loading ? (
        <CustomerLoader />
      ) : (
        <CompensationTypeTable
          headers={headers}
          types={types}
          intervals={intervals}
          onRowRemove={remove}
          onRowUpdate={update}
          AddNewRowComponent={() => (
            <AddNew
              onClick={add}
              text={pac['admin.page.step.compensation.types.add.compensation.type']}
            />
          )}
        />
      )}

      <Footer
        isBackDisabled={loading}
        isNextDisabled={loading || isNextDisabled}
        isLastStep={false}
        onBack={() => history.push(`/customers/${customerId}/step/${step.back}`)}
        onNext={async () => {
          await updateCustomerStatus({
            variables: {
              customerId,
              status: CUSTOMER_STATUSES.COMPENSATION_TYPES,
            },
          });

          showNotification({
            [Date.now()]: {
              message: f(pac['admin.success.notification.saved']),
              type: NOTIFICATION.SUCCESS,
            },
          });

          history.push(`/customers/${customerId}/step/${step.next}`);
        }}
      />
    </React.Fragment>
  );
};

export default compose(
  connect(null, { showNotification }),
  withRouter,
  graphql(DELETE_COMPENSATION_TYPE, { name: 'deleteType' }),
  // create/update new compensation type
  graphql(CREATE_COMPENSATION_TYPE, { name: 'createOrUpdate' }),
  // update customer status
  graphql(UPDATE_CUSTOMER_STATUS, { name: 'updateCustomerStatus' }),
  // fetch list of compensation types
  graphql(GET_COMPENSATION_TYPES_AND_INTERVALS_BY_CUSTOMER, {
    options: ({ params: { id } }) => ({ variables: { customerId: +id } }),
    props: ({
      // ql props
      data: {
        loading,
        compensationTypesV2: types = [],
        refetch,
        intervals = [],
      },
      // our props
      ownProps: {
        params: { id: customerId },
        history,
        step,
        setRightPlaceComponent,
        setupCustomerNameAndNumber,
        name,
        organizationNumber,
      },
    }) => ({
      loading,
      types,
      refetch,
      customerId: +customerId,
      history,
      step,
      setRightPlaceComponent,
      intervals: intervals.map(({ id, valueV2, }) => ({
        value: id,
        code: valueV2,
        label: compose(toLower, replace('_', ' '))(valueV2),
      })),
      name,
      organizationNumber,
      setupCustomerNameAndNumber,
    }),
  }),
)(CompensationTypes);
