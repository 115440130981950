import React from 'react';
import { withStyles, TextField as MuiTextField } from '@material-ui/core';

// compare next & prev values
// eslint-disable-next-line
const areEqual = ({ field: pField, form: pForm }, { field: nField, form: nForm }) => {
  return pField.value === nField.value
    && nForm.errors[nField.name] === pForm.errors[pField.name]
    && nForm.touched[nField.name] === pForm.touched[pField.name];
};

const StyledMuiTextField = withStyles(() => ({
  root: {
    marginTop: '12px',
    width: '97%',
  },
  underline: { '&:before': { borderBottom: 'none' } },
  pointer: { cursor: 'pointer !important' },
}))(({ classes: { underline, pointer, ...rest }, value, onClick, ...props }) => (
  <MuiTextField
    classes={rest}
    className={pointer}
    InputProps={{
      classes: { underline },
      readOnly: true,
    }}
    value={value}
    onClick={onClick}
    {...props}
  />
));

export default StyledMuiTextField;
