import React, { Component } from 'react';
import {
  equals,
  compose,
  path,
  not,
} from 'ramda';
import { TableRow } from '@material-ui/core';
import { TableRowCell } from './components';

class TableBodyRow extends Component {
  shouldComponentUpdate(nextProps) {
    return compose(
      not,
      equals(nextProps.row),
      path(['row']),
    )(this.props);
  }

  render() {
    const {
      row,
      i,
      passDown,
      schema,
      onRowClick,
    } = this.props;

    return (
      <TableRow hover onClick={() => onRowClick(row, i, passDown)} selected={false}>
        {schema.map((config, j) => (
          <TableRowCell
            key={j}
            config={config}
            row={row}
            passDown={passDown}
            i={i}
          />
        ))}
      </TableRow>
    );
  }
}

export default TableBodyRow;
