import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  baseline: {
    padding: 18,
    minWidth: 80,
    textAlign: 'center'
  },
  colon: {
    margin: '0 2px'
  },
})

const withLeadingZero = (number) => {
  const s = number.toString();

  return s.length === 1 ? `0${s}` : s
}

const BaseLine = ({ onClick, hour, minutes, classes, disabled }) => (
  <div
    onClick={onClick}
    className={classes.baseline}
    style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)' }}
  >
    {withLeadingZero(hour)}
    <span className={classes.colon}>
      :
    </span>
    {withLeadingZero(minutes)}
  </div>
)

export default withStyles(styles)(BaseLine)
