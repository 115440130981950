import React from 'react';
import { useIntl } from 'react-intl';
import {
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';

import SimpleDialog from '../Dialog'
import pab from '../../config/intlMessageSelectors/payroll-admin-base';
import ptr from "../../config/intlMessageSelectors/payroll-admin-timeReport";

const DialogRemoveDeviation = ({ classes, deviationId, onClose, onConfirm }) => {
  const { formatMessage: f } = useIntl();

  return (
    <SimpleDialog
      overflowReactSelect={false}
      closeOnCross={false}
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <Typography
        variant="h5"
        style={{ padding: '10px 0' }}
      >
        {f(ptr[`app.time.report.transactions.removeTransactionModal.title`])}
        <br />
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ padding: '10px 0' }}
      >
        {f(ptr[`app.time.report.transactions.removeTransactionModal.body`])}
      </Typography>
      <DialogActions style={{ margin: '10px -4px' }}>
        <Button
          type="button"
          onClick={onClose}
          variant="outlined"
          classes={{ outlined: classes.outlined }}
        >
          {f(pab[`app.base.btns.cancel`])}
        </Button>

        <Button
          variant="contained"
          type="submit"
          classes={{ contained: classes.contained }}
          style={{ marginLeft: '10px' }}
          onClick={() => onConfirm(deviationId)}
        >
          {f(pab[`app.base.btns.confirm`])}
        </Button>
      </DialogActions>
    </SimpleDialog>
  )
}

export default DialogRemoveDeviation;
