import React from 'react';
import { range } from 'ramda';
import {
  TimePopup,
  Hours,
  Minutes,
  BaseLine,
} from './components';

const DEFAULT_OPTIONS = {
  hours: range(0, 25),
  minutes: range(0, 60),
}

const TimePicker = ({ step, value, disabled, onChange, maxTime }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [anchor, setAnchor] = React.useState(null)

  const timez = {
    // handle 24h
    hour: value === 86400000
      ? 24
      : parseInt((value / (1000 * 60 * 60)) % 24, 10),
    minutes: parseInt((value / (1000 * 60)) % 60, 10),
  }

  const [time, setTime] = React.useState(timez)

  const [options, setOptions] = React.useState({
    hours: DEFAULT_OPTIONS.hours.filter(h => h <= maxTime.hour),
    minutes: DEFAULT_OPTIONS.minutes
      .filter(m => (time.hour === maxTime.hour && m === maxTime.minutes) || !(m % step))
      .filter(m => time.hour === maxTime.hour
        ? m <= maxTime.minutes
        : true
      )
  })

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
    onChange(time.hour * 3600 * 1000 + time.minutes * 60 * 1000);
    // eslint-disable-next-line
  }, [time.hour, time.minutes])

  const handleClick = ({ target }) => {
    if (disabled) return;
    setAnchor(target);
    setIsOpen(true);
  }

  const handleHoursClick = React.useCallback(({ target: { value } }) => {
    const opts = {
      hours: options.hours,
      minutes: value === 24
        ? [0]
        : value === maxTime.hour
          ? options.minutes.filter(m => m <= maxTime.minutes)
          : DEFAULT_OPTIONS.minutes
            .filter(m => (value === maxTime.hour && m === maxTime.minutes) || !(m % step))
            .filter(m => value === maxTime.hour
              ? m <= maxTime.minutes
              : true
            )
    }

    setOptions(opts)

    setTime(({ minutes }) => {
      if (value === 24) {
        return {
          hour: value,
          minutes: 0,
        }
      }

      if (value === maxTime.hour && minutes >= maxTime.minutes) {
        return {
          hour: value,
          minutes: maxTime.minutes,
        }
      }

      if(value !== maxTime.hour && !opts.minutes.includes(minutes)) {
        return {
          hour: value,
          minutes: 0,
        }
      }

      return {
        hour: value,
        minutes: minutes,
      }
    })
    // eslint-disable-next-line
  }, [])

  const handleMinutesClick = React.useCallback(({ target: { value } }) => {
    setTime(({ hour }) => ({
      hour,
      minutes: value
    }))
  }, [])

  return (
    <React.Fragment>
      <BaseLine
        hour={time.hour}
        minutes={time.minutes}
        onClick={handleClick}
        disabled={disabled}
      />
        <TimePopup
          anchor={anchor}
          isOpen={isOpen}
          onClose={handleClose}
          hours={(
            <Hours
              hours={options.hours}
              selected={time.hour}
              onHourClick={handleHoursClick}
            />
          )}
          minutes={(
            <Minutes
              minutes={options.minutes}
              selected={time.minutes}
              onMinutesClick={handleMinutesClick}
            />
          )}
        />
    </React.Fragment>
  )
}

export default TimePicker
