import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { PERIOD_STATUSES } from "../../utils/timeReports";
import ptr from "../../config/intlMessageSelectors/payroll-admin-timeReport";
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import { Popover, Typography } from "@material-ui/core";

const { APPROVED } = PERIOD_STATUSES;

const StatusInfo = ({ currentPeriod: { approvedBy, approvedAt, status, comment }, classes }) => {
  const { formatMessage: f } = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <InfoIcon
        className={classes.icon}
        color="secondary"
        fontSize="small"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        className={classes.popover}
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.wrapper}>
          <Typography variant="subtitle1">
            {status === APPROVED
              ? f(ptr[`app.timeReport.approvedBy`])
              : f(ptr[`app.timeReport.rejectedBy`])
            } {approvedBy}
          </Typography>
          <Typography variant="subtitle1">
            {f(ptr['app.timeReport.formDate'])}: {approvedAt}
          </Typography>
          {comment && (
            <Typography variant="subtitle1">
              {f(ptr['app.timeReport.comment'])}: {comment}
            </Typography>
          )}
        </div>
      </Popover>
    </>
  );
};

export default withStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px'
  },
  icon: { verticalAlign: 'sub' },
  popover: {
    pointerEvents: 'none',
    margin: 12
  }
}))(StatusInfo);
