import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Drawer from './Drawer';
import Header from './Header';
import { pageUrl } from '../config/urls';

class PageTemplate extends Component {
  componentWillMount() {
    const {
      activeRoute,
      editable,
      history,
      auth: { user: { selectedCustomer } },
    } = this.props;

    if (!editable && !selectedCustomer && activeRoute !== 'Customers') {
      switch (activeRoute) {
        case 'TimeReport': {
          history.push('/time-reports');
          return;
        }
        case 'Departments': {
          history.push(pageUrl.departments);
          return;
        }
        default: {
          history.push(pageUrl.root);
          return;
        }
      }
    }
  }

  render() {
    const {
      additional,
      setUserAdditional,
      auth,
      auth: { user: { selectedCustomer } },
      roleConfigs,
      children,
      activeRoute,
      editable,
      setAuth,
      location,
    } = this.props;

    const isSelfService = !location || !location.pathname.includes('/customers/');

    return (
      <Fragment>
        <Header
          editableTenantSwitch={editable}
          auth={auth}
          setAuth={setAuth}
          additional={additional}
          setUserAdditional={setUserAdditional}
          isSelfService={isSelfService}
        />
        {isSelfService && (
          <Drawer roleConfigs={roleConfigs} active={activeRoute} />
        )}

        <div className={isSelfService ? 'content-wrapper' : 'maximized-content-wrapper'}>
          {!selectedCustomer && activeRoute !== 'Customers' && <div>Please select a customer</div>}
          {(selectedCustomer || activeRoute === 'Customers') && children}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(PageTemplate)
