import React, {useContext} from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { CUSTOMER_STATUSES } from '../../../Customer';
import { showNotification } from '../../../../../actions/notifications';
import { NOTIFICATION } from '../../../../../components/NotificationSnackbar';
import pac from '../../../../../config/intlMessageSelectors/payroll-admin-customers';
import {
  UPDATE_CUSTOMER_STATUS
} from '../../../../../queries/customers';
import { useIntl } from 'react-intl';
import { WizzardContext } from '../../../../../providers/WizzardProvider';

const UsersImportFooter = ({
 loading,
 customerId,
 step = {},
 updateCustomerStatus,
 history
}) => {
  const { formatMessage: f } = useIntl();
  const { showProjectsStep } = useContext(WizzardContext);

  return (
    <Footer
      isBackDisabled={loading}
      isNextDisabled={loading}
      isLastStep
      onBack={() => history.push(`/customers/${customerId}/step/${showProjectsStep ? step.back[0] : step.back[1]}`)}
      onNext={async () => {
        await updateCustomerStatus({
          variables: {
            customerId,
            status: CUSTOMER_STATUSES.USERS_IMPORT,
          },
        });

        showNotification({
          [Date.now()]: {
            message: f(pac['admin.success.notification.saved']),
            type: NOTIFICATION.SUCCESS,
          },
        });

        history.push(step.next, { customerId });
      }}
    />
  );
};

export default compose(
  connect(null, { showNotification }),
  withRouter,
  graphql(UPDATE_CUSTOMER_STATUS, { name: 'updateCustomerStatus' }),
)(UsersImportFooter);
