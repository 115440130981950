import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { CUSTOMER_STATUSES } from '../../../Customer';
import { showNotification } from '../../../../../actions/notifications';
import { NOTIFICATION } from '../../../../../components/NotificationSnackbar';
import pac from '../../../../../config/intlMessageSelectors/payroll-admin-customers';
import {
  UPDATE_CUSTOMER_STATUS
} from '../../../../../queries/customers';
import { useIntl } from 'react-intl';

const ProjectsFooter = ({
 loading,
 customerId,
 step = {},
 updateCustomerStatus,
 history,
 showNotification,
}) => {
  const { formatMessage: f } = useIntl();

  return (
    <Footer
      isBackDisabled={loading}
      isNextDisabled={loading}
      isLastStep={false}
      onBack={() => history.push(`/customers/${customerId}/step/${step.back}`)}
      onNext={async () => {
        // TODO
        // await updateCustomerStatus({
        //   variables: {
        //     customerId,
        //     status: CUSTOMER_STATUSES.PROJECTS,
        //   },
        // });

        showNotification({
          [Date.now()]: {
            message: f(pac['admin.success.notification.saved']),
            type: NOTIFICATION.SUCCESS,
          },
        });

        history.push(`/customers/${customerId}/step/${step.next}`);
      }}
    />
  );
}

export default compose(
  connect(null, { showNotification }),
  withRouter,
  graphql(UPDATE_CUSTOMER_STATUS, { name: 'updateCustomerStatus' }),
)(ProjectsFooter);
