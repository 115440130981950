import React from 'react';
import { useIntl } from 'react-intl';
import pab from '../config/intlMessageSelectors/payroll-admin-base';
import Typography from "@material-ui/core/Typography";

export const Warning = ({ id }) => {
  const { formatMessage: f } = useIntl();

  return (
    <div
      style={{
        maxWidth: '600px',
        textAlign: 'center',
        margin: 'auto',
        paddingTop: '150px',
      }}
    >
      <Typography variant="h5" style={{ fontWeight: 200 }}>
        { f(pab[id]) }
      </Typography>
    </div>
  );
}
