import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles/index.css';
import tick from './styles/tick.svg';

export class DropDownList extends Component {
  _renderListItems = (items = [], onItemClick, isChecked) => {
    return items.map((item, i) => {
      return (
        <div
          key={i}
          className="drop-down-item"
          onClick={() => onItemClick(item)}
        >
          <img
            className={`${
              !isChecked(item) ? 'hidden' : ''
            } drop-down-item-tick-icon`}
            src={tick}
            alt="tick"
          />
          <span className="drop-down-item-title">{item.title}</span>
        </div>
      );
    });
  };

  render() {
    const {
      items,
      onItemClick,
      isShown,
      toggleLanguageDropdown,
      isChecked,
    } = this.props;

    return isShown ? (
      <div
        ref={node => isShown && node && node.focus()}
        className="drop-down-list-wrapper"
        tabIndex="0"
        onBlur={toggleLanguageDropdown}
      >
        <div className="arrow-up" />
        {this._renderListItems(items, onItemClick, isChecked)}
      </div>
    ) : null;
  }
}

DropDownList.propTypes = {
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  isChecked: PropTypes.func,
  toggleLanguageDropdown: PropTypes.func.isRequired,
};

DropDownList.defaultProps = {
  items: [
    { title: 'ENG (English)', locale: 'en', i: 'ENG' },
    { title: 'SWE (Svenska)', locale: 'sv', i: 'SWE' },
    { title: 'DAN (Dansk)', locale: 'da', i: 'DAN' },
    { title: 'NOR (Norsk)', locale: 'nb', i: 'NOR' },
  ],
  onItemClick: () => {},
  isShown: true,
  isChecked: () => false,
  toggleLanguageDropdown: () => {},
};

export default DropDownList;
