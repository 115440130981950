import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from "react-intl";
import {
  find, propEq, isEmpty, compose, head, filter, pathEq, path, pathOr
} from 'ramda';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import ptr from "../config/intlMessageSelectors/payroll-admin-timeReport";
import { setSelectedPeriod } from '../actions/context';
import { DEFAULT_DATE_FORMAT, msToHMS } from '../utils/times';
import { Select } from "./Select";
import {
  getPeriodStatusColor,
  getPeriodStatusMsg,
  PERIOD_STATUSES
} from "../utils/timeReports";
import RemindButton from "./TimeReport/RemindButton";
import { useLoggedUser } from "../providers/IdentityProvider";
import { ROLES } from "../config/role-configs";
import StatusInfo from "./TimeReportDetails/StatusInfo";

const { APPROVED, REJECTED } = PERIOD_STATUSES;

const handleUserChange = (history, userId, dates) => {
  history.push(`/time-report-details/${dates}/${userId}`);
};

const EmployeeCard = ({
  currentPeriod,
  agg,
  users,
  periods,
  currentUser,
  setSelectedPeriod,
  selectedPeriod,
  isManager,
  canManage,
}) => {
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const { userId, month } = useParams();
  const { role } = useLoggedUser();

  const periodOptions = periods.filter(p => p.startDate < moment(currentUser.employeeEnd).format(DEFAULT_DATE_FORMAT)).map(el => {
    return {
      value: +el.id,
      label: moment(el.startDate).format('MMM YYYY').toUpperCase(),
    };
  });

  let options = users && users.filter(user => user.role !== ROLES.APPROVER).map(el => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  }));

  if (isManager && !canManage && isEmpty(options)) {
    options = [{
      label: `${currentUser.firstName} ${currentUser.lastName}`,
      value: currentUser.id,
    }];
  }

  const defaultSelectedPeriod = find(
    propEq('value', +selectedPeriod),
    periodOptions,
  );

  const periodMessage = ptr[getPeriodStatusMsg(currentPeriod.status)]
    && f(ptr[getPeriodStatusMsg(currentPeriod.status)]);

  const isRemindBtnVisible = (
    role === ROLES.EMPLOYEE && currentPeriod.status === PERIOD_STATUSES.SUBMITTED
  ) || (
    role !== ROLES.EMPLOYEE && [
      PERIOD_STATUSES.OPEN,
      PERIOD_STATUSES.REJECTED
    ].includes(currentPeriod.status)
  );

  const isStatusInfoVisible = [APPROVED, REJECTED].includes(currentPeriod.status);

  return (
    <Grid container spacing={16} alignItems="center">
      <Grid item>
        <Select
          value={options.find(el => el.value === currentUser?.id)?.value}
          onChange={(_, { key }) => handleUserChange(history, key, defaultSelectedPeriod.value)}
          options={options}
        />
      </Grid>
      <Grid item>
        <Select
          value={defaultSelectedPeriod.value}
          onChange={(_, { key }) => {
            setSelectedPeriod(Number(key));
            history.push(`/time-report-details/${key}/${currentUser.id}`);
          }}
          options={periodOptions}
        />
      </Grid>

      {isRemindBtnVisible && (
        <Grid item style={{ padding: 0 }}>
          <RemindButton employeeId={+userId} employeePeriodId={+month} disabled={currentPeriod.reminded} />
        </Grid>
      )}

      <Grid item>
        <Typography
          variant="h6"
          align="center"
          style={{ color: getPeriodStatusColor(currentPeriod.status)}}
        >
          {periodMessage}
        </Typography>
      </Grid>

      {isStatusInfoVisible && (
        <Grid item style={{ padding: 0 }}>
          <StatusInfo currentPeriod={currentPeriod} />
        </Grid>
      )}


      {!!agg.length && (
        <Grid item container spacing={16} style={{ marginLeft: '4px' }}>
          {agg.slice(0, 5).map((el, i) => (
            <Grid key={i} item>
              <Typography variant="body1">
                {el.name}
              </Typography>
              <Typography variant="subheading">
                {msToHMS(el.duration)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({
  auth,
  context,
  auth: { user: { role } },
}) => {
  const selectedCustomer = path(['user', 'selectedCustomer'], auth);
  const isManager = pathEq(['user', 'role'], 'manager', auth);

  const canManage = compose(
    pathOr(false, ['isManager']),
    head,
    filter(pathEq(['id'], selectedCustomer)),
    path(['customers']),
  )(context);

  return {
    selectedCustomer,
    isManager,
    canManage,
    userId: auth.user.id,
    role,
  };
};

export default connect(mapStateToProps, { setSelectedPeriod })(EmployeeCard);
