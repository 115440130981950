import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';

const NewButton = ({ onClick, text }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Button color="secondary" onClick={onClick}>
      <Typography variant="subtitle1" color="secondary">
        + {f(text)}
      </Typography>
    </Button>
  );
};

export default React.memo(NewButton, () => true);
