import React from 'react';
import moment from 'moment';
import { getIn } from 'formik';
import { TextField as MuiTextField } from '@material-ui/core';
import { InlineTimePicker as MuiTimePicker } from 'material-ui-pickers';

class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(d) {
    const {
      field: { name },
      onChange,
      form,
    } = this.props;

    const {
      hours,
      minutes,
    } = d.toObject();

    const value = hours * 60000 * 60 + minutes * 60000;

    form.setFieldValue(name, value);

    if (onChange) {
      onChange({
        key: name,
        value,
        form,
      });
    }
  }

  render() {
    const {
      field,
      children,
      form,
      InputProps,
      classes,
      overrided,
      ...props
    } = this.props;

    const error = getIn(form.errors, field.name);
    const touch = getIn(form.touched, field.name);

    return (
      <MuiTimePicker
        {...props}
        {...field}
        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
        keyboard
        FormHelperTextProps={{
          ...!overrided
            ? { style: { position: 'absolute', top: '24px' } }
            : { style: { position: 'absolute', top: '40px' } },
        }}
        value={moment()
          .hours(parseInt((field.value / (1000 * 60 * 60)) % 24, 10))
          .minutes(parseInt((field.value / (1000 * 60)) % 60, 10))}
        onChange={this.onChange}
        TextFieldComponent={p => (
          <MuiTextField
            {...p}
            InputProps={{
              ...p.InputProps,
              classes: InputProps.classes,
            }}
            classes={classes}
            error={(touch && !!error) || p.error}
            helperText={(touch && error ? error : null) || p.helperText}
          />
        )}
      />
    );
  }
}

export default TimePicker;
