import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import { Grid, Paper, Button, TextField, withStyles } from '@material-ui/core';
import Loader from '../../components/LoadingCircle';
import pac from '../../config/intlMessageSelectors/payroll-admin-customers';
import pab from '../../config/intlMessageSelectors/payroll-admin-base';
import {
  MainLayout,
  CustomersTable,
  CustomersTabs,
  withCustomers,
  DEFAULT_ORDER,
} from './components';
import { compose } from "ramda";
import { TABLE_ROWS_PER_PAGE } from "../../utils/table";
import { useLoggedUser } from "../../providers/IdentityProvider";
import { ROLES } from "../../config/role-configs";

const styles = {
  search: {height: 40, padding: 10},
  label: {transform: 'translate(14px, 14px) scale(1)'}
};

const initialState = {
  page: 0,
  sorting: DEFAULT_ORDER,
  rowsPerPage: TABLE_ROWS_PER_PAGE,
  state: 'ALL',
  search: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_PAGE_REQUEST':
      return {
        ...state,
        page: action.payload,
      };
    case 'CHANGE_SORTING_REQUEST':
      return {
        ...state,
        sorting: action.payload,
      };
    case 'CHANGE_ROW_PER_PAGE_REQUEST':
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    case 'CHANGE_STATE_REQUEST':
      return {
        ...state,
        state: action.payload,
      };
    case 'SEARCH_CHANGE_REQUEST':
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

const useTable = onFiltersChange => {
  const [{
    sorting,
    rowsPerPage,
    page,
    state,
    search
  }, dispatch] = React.useReducer(
    reducer,
    initialState,
  );

  const [orderBy, order] = sorting;

  React.useEffect(() => {
    onFiltersChange({
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      order: sorting.join(' '),
      state,
      search,
    });
    // eslint-disable-next-line
  }, [order, orderBy, page, rowsPerPage, state, search]);

  const onChangePage = React.useCallback(
    page => dispatch({
      type: 'CHANGE_PAGE_REQUEST',
      payload: page,
    }),
    [],
  );

  const onChangeRowsPerPage = React.useCallback(
    rowsPerPage => dispatch({
      type: 'CHANGE_ROW_PER_PAGE_REQUEST',
      payload: rowsPerPage,
    }),
    [],
  );

  const onRequestSort = React.useCallback(
    sorting => dispatch({
      type: 'CHANGE_SORTING_REQUEST',
      payload: sorting,
    }),
    [],
  );

  const onStateChange = React.useCallback(
    state => dispatch({
      type: 'CHANGE_STATE_REQUEST',
      payload: state,
    }),
    [],
  );

  const onSearchChange = React.useCallback(
    search => dispatch({
      type: 'SEARCH_CHANGE_REQUEST',
      payload: search,
    }),
    [],
  );

  return {
    orderBy,
    order,
    rowsPerPage,
    page,
    state,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    onStateChange,
    onSearchChange,
  };
};

const Customers = ({
                     customers = [],
                     count,
                     onFiltersChange,
                     loading,
                     classes
                   }) => {
  const {formatMessage: f} = useIntl();
  const {role} = useLoggedUser();
  const {
    orderBy,
    order,
    rowsPerPage,
    page,
    state,
    search,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    onStateChange,
    onSearchChange,
  } = useTable(onFiltersChange);

  return (
    <Grid container alignItems="center">
      <Paper style={{width: '100%', padding: '20px 24px'}}>
        <Grid item container alignItems="center" justify="space-between">
          <Grid item container xs={8}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              component={Link}
              to="/customers/new/step/general"
            >
              {f(pac['admin.page.add.button'])}
            </Button>
            <Button
              size="large"
              variant="outlined"
              style={{marginLeft: '10px'}}
              onClick={() => {
                setTimeout(() => {
                  window.location.href = `${window.location.origin}/payslip`;
                }, 0);
              }}
            >
              {f(pac['admin.page.modules.config.payslips'])}
            </Button>
            {[ROLES.SUPERADMIN, ROLES.ADMIN].includes(role) && (
              <Button
                size="large"
                variant="outlined"
                style={{marginLeft: '10px'}}
                onClick={() => {
                  setTimeout(() => {
                    window.location.href = `${window.location.origin}/payslip/access`;
                  }, 0);
                }}
              >
                Access Control
              </Button>
            )}
          </Grid>

          <Grid item xs={4} sm={3} md={2}>
            <TextField
              fullWidth
              label={f(pab['app.base.label.search'])}
              type="search"
              variant="outlined"
              value={search}
              InputProps={{className: classes.search}}
              InputLabelProps={{className: classes.label}}
              onChange={
                debounce(
                  ({target: {value}}) => onSearchChange(value),
                  300
                )
              }
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid item xs={12} style={{margin: '20px 0'}}>
        <CustomersTabs state={state} onStateChange={onStateChange}/>
      </Grid>

      <Grid item xs={12}>
        {loading && <Loader/>}
        {!loading && (
          <CustomersTable
            customers={customers}
            count={count}
            loading={loading}
            page={page}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onRequestSort={onRequestSort}
          />
        )}
      </Grid>
    </Grid>
  );
};

const CustomersContainer = compose(
  withStyles(styles),
  withCustomers,
)(Customers)

const CustomersPage = props => (
  <MainLayout {...props}>
    <CustomersContainer/>
  </MainLayout>
);

export default CustomersPage;
