import React, { Component, memo } from 'react';
import {
  MainLayout,
} from './components';
import { Warning } from '../../components/Warning';
import { Grid, Typography } from '@material-ui/core';
import { Projects } from '../Customer/Projects';
import { compose } from 'ramda';
import { graphql } from 'react-apollo';
import { GET_CUSTOMER_BY_ID } from '../../queries/customers';
import { connect } from 'react-redux';

const Information = memo(({ name, number }) => (
  <Grid container item xs={12} alignItems="center" justify="flex-end">
    <Typography variant="h6">
      {name}&nbsp;
    </Typography>
    {number && (
      <Typography variant="subtitle1">
        ({number})
      </Typography>
    )}
  </Grid>
), () => true);

class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RightPlace: null,
    };

    this.setupRightPlace = this.setupRightPlace.bind(this);
  }

  setupRightPlace(RightPlace) {
    this.setState({ RightPlace });
  }

  render() {
    const {
      id,
      name,
      hasProjectReporting,
      organizationNumber: number,
      loading,
    } = this.props;
    const { RightPlace } = this.state;
    const toPass = {
      setupRightPlace: this.setupRightPlace,
    };

    if (!id) {
      return null;
    }

    return hasProjectReporting ? (
      <React.Fragment>
        <Grid container justify="space-between" alignItems="center" style={{ minHeight: 72 }}>
          <Grid item xs={7} />
          <Grid
            container
            direction="column"
            item
            xs={3}
            style={{ marginBottom: 8 }}
            alignItems="flex-end"
          >
            {!loading && (
              <Information name={name} number={number} />
            )}

            {!loading && RightPlace && (
              <Grid item xs={12}>
                <RightPlace />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Projects {...toPass} id={id} />
      </React.Fragment>
    ) : (
      <Warning id="app.base.message.noModuleConfig" />
    )
  }
}

const ProjectsPage = compose(
  connect(({ auth: { user: { selectedCustomer } }}) => ({ selectedCustomer })),
  graphql(GET_CUSTOMER_BY_ID, {
    options: ({ selectedCustomer }) => ({
      variables: { customerId: +selectedCustomer },
      fetchPolicy: 'no-cache',
    }),
    props: ({ data: { customerById, loading, refetch } }) => ({
      ...customerById,
      loading,
      refetch,
    }),
  }),
)(Project);

export default (props) => (
  <MainLayout {...props}>
    {(r) => <ProjectsPage {...r} />}
  </MainLayout>
);
