import gql from 'graphql-tag';

export const c = gql`
  query GET_TRANSACTIONS_AND_COMP_TYPES($input: ListInputType!,$input2:CountInputType){
    transactionsWithChildren(input:$input){
      id
      userId
      compensationTypeId
      start
      end
      comment
      status
      duration
      isParent
      date
      children{
        date
        duration
      }
    },
    transactionsCount(input:$input2){
      count
    }
    compensationTypes{
      name
      id
      displayInSummary
      interval{
        id
        type
        value
      }
    }
  }
`;

export const GET_PERIOD_REPORTS_AND_COMP_TYPES_AND_DEPTS = gql`
  query GET_PERIOD_REPORTS_AND_COMP_TYPES_AND_DEPTS(
    $input: ListInputType!
  ){
    compensationTypes(input:$input){
      name
      id
      displayInSummary
      type
      interval{
        id
        type
        value
      }
    }
  }
`;

export const GET_PERIOD_REPORTS_AND_TRANSACTIONS = gql`
  query GET_PERIOD_REPORTS_AND_TRANSACTIONS(
    $input: ListInputType!,
    $input2:ListInputType!,
    $count: CountInputType
  ){
    periodReports(input:$input){
      byUser{
        data{
          name
          duration
          compensationTypeId
        }
        availableStatuses
        userId
      }
      general{
        name
        duration
        compensationTypeId
      }
    }
    transactionsWithChildren(input:$input2){
      id
      userId
      compensationTypeId
      start
      end
      status
      updatedAt
      duration
      isParent
      date
      comment
      rejectMessage
      type
      dimensionId
      dimension {
        name
        dimensionNumber
      }
      workType {
        name
      }
      workTypeId
      normalWorkDay
      parentStatus
      children {
        date
        rejectMessage
        duration
        status
      }
      compensationType {
        id
        name
        reportingType
        comment
        multipleDays
        timeType
        type
        interval {
          value
          type
          valueV2
        }
      }
    },
    transactionsCount(input:$count){
      count
    }
  }
`;

export const CLOSE_PERIODS = gql`
  mutation CLOSE_PERIODS($input: ClosePeriodInputType){
    closePeriod(input: $input){
      userId,
      periodId
    }
  }
`;

export const PERIOD_REMIND = gql`
  mutation PERIOD_REMIND($employeeId: Int!, $employeePeriodId: Int!){
    periodRemind(employeeId: $employeeId, employeePeriodId: $employeePeriodId){
      id
    }
  }
`;

export const PERIOD_REMIND_ALL = gql`
  mutation PERIOD_REMIND_ALL($periodId: Int!, $departmentId: Int){
    periodRemindAll(periodId: $periodId, departmentId: $departmentId){
      id
    }
  }
`;

export const GET_DIMENSIONS = gql`
  query GET_DIMENSIONS($customerId: Int!, $userId: Int!){
    dimensions(customerId: $customerId, userId: $userId, enabled: true){
      id,
      name,
      dimensionNumber,
      customerId
    }
  }
`;

export const GET_WORK_TYPES = gql`
  query GET_WORK_TYPES($input: ListInputType){
    workTypes(input: $input){
      id,
      name,
      customerId,
      dimensionId
    }
  }
`;

export const GET_EMPLOYEE_DIMENSIONS = gql`
  query GET_EMPLOYEE_DIMENSIONS($filter: EmployeeDimensionsFilter!){
    employeeDimensions(filter: $filter){
      id,
      name,
      dimensionNumber
    }
  }
`;

export const GET_PROJECT_ACTIVITIES = gql`
  query GET_PROJECT_ACTIVITIES($filter: ProjectActivitiesFilter!){
    projectActivities(filter: $filter){
      id
      name
    }
  }
`;

export const GET_PERIODS = gql`
  query GET_PERIODS($input: ListInputType) {
    periods(input: $input){
      startDate
      endDate
      cutOffDate
      id
    }
  }
`;

export const GET_TIME_REPORTS_OVERVIEW = gql`
  query GET_TIME_REPORTS_OVERVIEW (
    $input: TimeReportInputType!,
    $options: Options!,
    $types:ListInputType!
   ) {
    timeReportOverview(input: $input, options: $options) {
      count,
      rows {
        id
        employmentNumber
        fullName
        status
        reviewedBy
        reminded
        compensationTypesTotals {
          name
          total
        }
      }
      totals {
        name
        total
      }
      periodId
    }
    compensationTypes(input:$types){
      name
      displayInSummary
    }
  }
`;
