import React from 'react';
import { useIntl } from 'react-intl';
import pac from '../../../../config/intlMessageSelectors/payroll-admin-customers';
import pae from '../../../../config/intlMessageSelectors/payroll-admin-employees';
import { Tabs, Tab } from '@material-ui/core';

const EmployeesTabs = ({ state, onStateChange }) => {
  const { formatMessage: f } = useIntl();

  return (
    <Tabs
      value={state}
      onChange={(_, v) => onStateChange(v)}
      indicatorColor="primary"
    >
      <Tab value="ACTIVE" label={f(pac['admin.page.table.customer.status.active'])} />
      <Tab value="SUSPENDED" label={f(pae['app.employees.suspended'])} />
    </Tabs>
  );
}

export default EmployeesTabs;
