import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  SvgIcon,
  Typography,
  withWidth,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import LocaleDropDown from './LocaleDropDown';
import TenantCustomerSwitch from './TenantCustomerSwitch';
import UserLogOutInfo from './UserLogOutInfo';
import '../styles/headerStyles/index.css';
import pac from '../config/intlMessageSelectors/payroll-admin-customers';
import logo from '../styles/headerStyles/logo.svg'

export const ArrowLeftIcon = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <svg width="24" height="24" fill="none">
      <path
        d="M19 12H5"
        stroke="#25282B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19L5 12L12 5"
        stroke="#25282B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

const Back = withWidth()(({ width }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Grid container alignItems="center" spacing={16}>
      <Grid item>
        <Link to="/customers">
          <ArrowLeftIcon />
        </Link>
      </Grid>
      {!['xs', 'sm'].includes(width) && (
        <Grid item>
          <Typography variant="h6" align="center">
            {f(pac['admin.page.back.button'])}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
});

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.changeLocale = this.changeLocale.bind(this);
  }

  changeLocale({ locale }) {
    const { auth, setAuth } = this.props;

    setAuth({
      user: {
        ...auth.user,
        locale,
      },
    });
  }

  render() {
    const {
      editableTenantSwitch,
      auth,
      additional,
      setUserAdditional,
      setAuth,
      isSelfService,
    } = this.props;
    const locale = auth.user.locale || 'en';

    return (
      <header>
        <div
          style={{
            width: '100%',
            height: '3px',
            display: 'flex',
            position: 'fixed',
            top: 0,
          }}
        >
          <div style={{ width: '60%', backgroundColor: '#0D2D4E' }} />
          <div style={{ width: '20%', backgroundColor: '#3B77B5' }} />
          <div style={{ width: '20%', backgroundColor: '#73B783' }} />
        </div>
        <nav className="header-container">
          <Link className="menu-logo-container" to="/">
            <img
              className="header-logo"
              src={logo}
              alt="logo"
            />
          </Link>

          {!isSelfService && <Back />}
          <div className="user-info-container">
            <LocaleDropDown
              locale={locale}
              changeUserLocale={this.changeLocale}
            />
            <TenantCustomerSwitch
              editable={editableTenantSwitch}
              auth={auth}
              additional={additional}
              setUserAdditional={setUserAdditional}
              setAuth={setAuth}
            />

            <UserLogOutInfo />
          </div>
        </nav>
      </header>
    );
  }
}

export default HeaderContainer;
