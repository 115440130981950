import employeeIcon from '../../styles/sidebar/employee.svg';
import employeeIconActive from '../../styles/sidebar/employee-active.svg';
import timeReportIcon from '../../styles/sidebar/time-report.svg';
import timeReportIconActive from '../../styles/sidebar/time-report-active.svg';
import departmentsIcon from '../../styles/sidebar/departments.svg';
import departmentsIconActive from '../../styles/sidebar/departments-active.svg';
import adminIcon from '../../styles/sidebar/admin.svg';
import adminIconActive from '../../styles/sidebar/admin-active.svg';

import { pageUrl } from '../urls';

export default function () {
  const {
    root,
    timeReportDetails,
    transactions,
    timeReport,
  } = pageUrl;

  const routes = [
    root,
    timeReportDetails,
    transactions,
    timeReport,
    '/employees',
    '/employees/:id',
    '/departments',
    '/departments/:departmentId',
    '/customers',
    '/customers/:id/step/:step(general|types|categories|users)',
  ];
  const renderNavigation = [
    {
      name: 'Employees',
      activeIcon: employeeIconActive,
      icon: employeeIcon,
      path: '/employees',
      selector: 'app.navigation.employees',
    },
    {
      name: 'TimeReport',
      activeIcon: timeReportIconActive,
      icon: timeReportIcon,
      path: '/time-reports',
      selector: 'app.navigation.timeReport',
    },
    {
      name: 'Departments',
      activeIcon: departmentsIconActive,
      icon: departmentsIcon,
      path: '/departments',
      selector: 'app.navigation.departments',
    },
    {
      name: 'Customers',
      activeIcon: adminIconActive,
      icon: adminIcon,
      path: '/customers',
      selector: 'app.navigation.admin',
    },
  ];

  const manageRoles = ['employee', 'manager', 'approver', 'customeradmin'];
  const createUsers = true;
  const editUsers = true;
  const deleteUsers = false;
  const createDepartments = true;
  const editDepartments = true;
  const deleteDepartments = true;

  const indexToRedirectTo = '/employees';

  return {
    departmentId: undefined,
    routes,
    usersToApprove: undefined,
    departmentsToApprove: undefined,
    indexToRedirectTo,
    manageRoles,
    createUsers,
    editUsers,
    deleteUsers,
    editDepartments,
    deleteDepartments,
    createDepartments,
    renderNavigation,
  };
}
