import React from 'react';
import { useIntl } from 'react-intl';
import {
  TableHead as MuiTableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const TableHead = ({ headers, classes }) => {
  const { formatMessage: f } = useIntl();
  return (
    <MuiTableHead>
      <TableRow>
        {headers.map(({ id, label, tableCellProps }) => (
          <TableCell
            key={id}
            classes={{ root: classes.cell }}
            variant="head"
            {...tableCellProps}
          >
            {label && f(label)}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

const StyledTableHead = withStyles(() => ({ cell: { padding: '4px' } }))(TableHead);

export default React.memo(StyledTableHead, (p, n) => p.headers.length === n.headers.length);
