export default {
  "app.employees.userModalCreate": "Opprett ansatt",
  "app.employees.userModalEdit": "Rediger ansatt",
  "app.employees.userModalConfirm": "Lagre",
  "app.employees.userModalCancel": "Avbryt",
  "app.employees.timeReportingMethod": "Rapporteringsmetode",
  "app.employees.userModalGeneral": "Generelt",
  "app.employees.employmentDetails": "Ansattforhold",
  "app.employees.employmentCategory": "Ansattkategori",
  "app.employees.employmentNumber": "Ansattnummer",
  "app.employees.firstName": "Fornavn",
  "app.employees.lastName": "Etternavn",
  "app.employees.phone": "Telefon",
  "app.employees.department": "Avdeling",
  "app.employees.role": "Rolle",
  "app.employees.createUserButton": "Opprett ansatt",
  "app.employees.email": "E-post",
  "app.employees.personalId": "Personnummer",
  "app.employees.bankAccount": "Kontonummer",
  "app.employees.contactPerson": "Kontaktperson",
  "app.employees.contactPersonPhone": "Kontaktperson telefon",
  "app.employees.authLogin": "Brukernavn/passord",
  "app.employees.smsLogin": "Logger på med SMS",
  "app.employees.address": "Adresse",
  "app.employees.postalCity": "Poststed",
  "app.employees.postalNumber": "Postnummer",
  "app.employees.userTimezone": "Tidssone",
  "app.employees.userNationality": "Nasjonalitet",
  "app.employees.userLanguage": "Språk",
  "app.employees.admin": "Admin",
  "app.employees.manager": "Leder",
  "app.employees.approver": "Godkjenner",
  "app.employees.employee": "Ansatt",
  "app.employees.superadmin": "Superadmin",
  "app.employees.employmentType": "Ansettelsestype",
  "app.employees.fullTime": "Heltid",
  "app.employees.partTime": "Deltid",
  "app.employees.yearSalary": "Årslønn",
  "app.employees.employeeStartDate": "Ansatt startdato",
  "app.employees.suspended": "Deaktivert",
  "app.employees.all": "alle",
  "app.employees.noDepartmentAssigned": "Ikke tilknyttet en avdeling",
  "app.employees.employeeEndDate": "Ansatt Sluttdato",
  "app.employees.country": "Land",
  "app.employees.city": "Poststed",
  "app.employees.home.address": "Hjemmeadresse",
  "app.employees.removeEmployeeTitle": "Fjern ansatt",
  "app.employees.removeEmployeeConfirmationText": "Er du sikker på at du vil fjerne den ansatte?",
  "app.employees.cannotRemoveHasTransaction": "Can't remove employee with transactions, please suspend employee.",
  "app.employees.errorEmploymentNumber": "Ansattnummer er allerede i bruk, vennligst benytt et annet nummer",
  "app.employees.emailAddressInUse": "E-postadresse er allerede brukt",
  "app.employees.create.employee": "Opprett ansatt",
  "app.employees.edit.employee": "Rediger ansatt",
  "app.employees.approve.own.timereport": "Godkjenn egen timerapport",
  "app.employees.access.rights": "Tilgangsrettighet",
  "app.employees.button.add.new.department.to.approve": "Opprett ny avdeling for godkjenning",
  "app.employees.button.remove.department.to.approve": "Fjern avdeling",
  "app.employees.tenant": "Kontor",
  "app.employees.customer": "Kunde",
  "app.employees.departments": "Avdelinger",
  "app.employees.subtitle.location": "Lokasjon",
  "app.employees.button.save": "Lagre",
  "app.employees.notitication.success.save": "Lagret",
  "app.employees.error.no.deviation": "Kan ikke rapportere \"Ingen Endring\", det eksisterer allerede transaksjoner for denne perioden",
  "app.employees.error.national.holiday": "Fravær kan ikke rapporteres på offentlige fridager",
  "app.employees.tableHeader.firstName": "Fornavn",
  "app.employees.suspend": "Deaktiver"
}
