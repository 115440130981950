import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

export const getApolloClient = ({ getAccessTokenSilently, audience, uri }) => {
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  };

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently({ audience })

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = new HttpLink({ uri });

  return new ApolloClient({
    shouldBatch: true,
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions,
    name: 'hrm-web'
  })
}

export const client = {}
