import React from 'react';
import { compose, pathOr, split, nth } from 'ramda';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core'
import LoadingCircle from '../components/LoadingCircle';
import { AUTH_USER } from '../queries/users'
import { setAuth } from '../actions/auth';
import { useAuth0 } from "@auth0/auth0-react";
import * as PropTypes from "prop-types";

export const IdentityContext = React.createContext({})

export const useLoggedUser = () => React.useContext(IdentityContext);

const CONTEXT_FAILED_MESSAGE = 'Context creation failed:'

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node
};
const Provider = ({ children, setAuth }) => {
  const { logout } = useAuth0()
  const { data, error, refetch } = useQuery(AUTH_USER);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(
    () => {
      if (!data) return;
      setAuth({ user: data.userByToken })
      setIsLoading(false)
    },
    [data]
  )

  if (error) {
    let message = compose(
      pathOr('Ops something went wrong', ['networkError', 'result', 'errors', 0, 'message'])
    )(error)

    if (message.includes('Context creation failed:')) {
      message = compose(
        nth(1),
        split(CONTEXT_FAILED_MESSAGE)
      )(message)
    }

    return (
      <Grid container justify="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <Typography variant="h5" style={{ maxWidth: 500, marginBottom: 18 }} align="center">
          {message}
        </Typography>
        <Button variant={'contained'} onClick={() => logout()}>To login page</Button>
      </Grid>
    );
  }

  if (isLoading) {
    return (
      <Grid container justify="center" alignItems="center" style={{ height: '100%'}}>
        <LoadingCircle />
      </Grid>
    );
  }

  return (
    <IdentityContext.Provider value={{ ...data.userByToken, refetch }}>
      {children}
    </IdentityContext.Provider>
  );
};

export const IdentityProvider = connect(null, { setAuth })(Provider)
