import React from 'react';
import MuiTableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const TableCell = ({ classes, children }) => (
  <MuiTableCell classes={{ root: classes.cell }}>
    {children}
  </MuiTableCell>
);

const StyledTableCell = withStyles(() => ({
  cell: {
    padding: '4px 4px 8px 4px',
    '&:last-child': { paddingRight: '4px', paddingLeft: '4px' },
    '&:first-child': { paddingLeft: '15px' },
  },
}))(TableCell);

export default StyledTableCell;
