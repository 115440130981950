import React from 'react';
import { FastField } from 'formik';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { TextField } from '../../../../components/FormikFields';
import pae from '../../../../config/intlMessageSelectors/payroll-admin-employees';

const General = ({ isEdit }) => {
  const { formatMessage: f } = useIntl();

  return (
    <Grid item container alignItems="center" xs={12} spacing={32}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          color="default"
          style={{ marginTop: '16px' }}
        >
          {f(pae['app.employees.userModalGeneral'])}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.firstName'])}
          name="firstName"
          component={TextField}
        />
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.lastName'])}
          name="lastName"
          component={TextField}
        />
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.email'])}
          name="email"
          component={TextField}
          disabled={isEdit}
        />
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          placeholder="+"
          label={f(pae['app.employees.phone'])}
          name="phone"
          component={TextField}
        />
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          label={f(pae['app.employees.personalId'])}
          name="personalId"
          component={TextField}
        />
      </Grid>
      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.bankAccount'])}
          name="bankAccountNumber"
          component={TextField}
        />
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.contactPerson'])}
          name="contactPerson"
          component={TextField}
        />
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          placeholder="+"
          label={f(pae['app.employees.contactPersonPhone'])}
          name="contactPersonPhone"
          component={TextField}
        />
      </Grid>
    </Grid>
  );
};

export default General;
