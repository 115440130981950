import React from 'react';
import {
  map,
  filter,
  find,
  pathOr,
  compose,
  isNil,
  path,
  reject,
  includes,
  pathEq,
  pluck,
  propEq,
  join,
  flatten,
} from 'ramda';
import { useApolloClient } from 'react-apollo';
import { Grid, TextField, Button,Typography } from '@material-ui/core';
import { Field } from 'formik';
import { useIntl } from 'react-intl';
import {
  GET_TENANT_CUSTOMERS,
  GET_CUSTOMER_DEPARTMENTS,
} from '../../../../../../queries/customers';
import {
  Select,
  MultiMenu,
} from '../../../../../../components/FormikFields';
import { BaseLine } from './components';
import pae from '../../../../../../config/intlMessageSelectors/payroll-admin-employees';

const AccessRules = ({ i, v, tenants, selected, options, onAdd, onRemove }) => {
  const client = useApolloClient();
  const { formatMessage: f } = useIntl();
  const [state, setState] = React.useState({
    customers: {
      original: [],
      filtered: [],
    },
    departments: [],
  });

  const fetchCustomers = React.useCallback(async value => {
    const { data: { customersV2: { customers } } } = await client.query({
      query: GET_TENANT_CUSTOMERS,
      variables: { tenantId: value },
    });

    const original = customers.map(({ id, name }) => ({ value: id, label: name }));
    const filtered = reject(
      ({ value }) => includes(value, selected) && !pathEq(['customerId'], value, v),
      original,
    );

    setState(s => ({
      ...s,
      customers: {
        filtered,
        original,
      },
    }));
    // eslint-disable-next-line
  }, [v.tenantId]);

  const canAccessTenant = compose(
    includes(v.tenantId),
    pluck('value'),
  )(tenants)

  const canAccessCustomer = compose(
    includes(v.customerId),
    pluck('value'),
  )(state.customers.filtered)

  const fetchDepartments = React.useCallback(async value => {
    const { data: { departments } } = await client.query({
      query: GET_CUSTOMER_DEPARTMENTS,
      variables: { input: { filters: { customerId: value } } },
    });

    const dptms = departments.map(({ id, name }) => ({ value: id, label: name }));

    setState(s => ({
      ...s,
      departments: dptms,
    }));
    // eslint-disable-next-line
  }, [v.customerId]);

  // initial load on edit usually
  React.useEffect(() => {
    // eslint-disable-next-line
    v.tenantId && canAccessTenant && fetchCustomers(v.tenantId);
    // eslint-disable-next-line
    v.customerId && canAccessCustomer && fetchDepartments(v.customerId);
    // eslint-disable-next-line
  }, [canAccessCustomer, canAccessTenant]);

  React.useEffect(() => {
    const filtered = compose(
      reject(({ value }) => includes(value, selected) && !pathEq(['customerId'], value, v)),
      path(['customers', 'original']),
    )(state);

    setState({
      ...state,
      customers: {
        ...state.customers,
        filtered,
      },
    });
    // eslint-disable-next-line
  }, [selected.join()]);

  const onTenantChange = React.useCallback(async ({ value, form }) => {
    form.setFieldValue(`accessRules.${i}.customerId`, null);
    form.setFieldValue(`accessRules.${i}.departmentIds`, []);

    const { data: { customersV2: { customers } } } = await client.query({
      query: GET_TENANT_CUSTOMERS,
      variables: { tenantId: value },
    });

    const original = customers.map(({ id, name }) => ({ value: id, label: name }));
    const filtered = reject(({ value }) => includes(value, selected), original);

    setState(s => ({
      ...s,
      customers: {
        filtered,
        original,
      },
    }));
    // eslint-disable-next-line
  }, [v.tenantId]);

  const onCustomerChange = React.useCallback(async ({ value, form }) => {
    form.setFieldValue(`accessRules.${i}.departmentIds`, []);

    const { data: { departments } } = await client.query({
      query: GET_CUSTOMER_DEPARTMENTS,
      variables: { input: { filters: { customerId: value } } },
    });

    setState(s => ({
      ...s,
      departments: departments.map(({ id, name }) => ({ value: id, label: name })),
    }));
    // eslint-disable-next-line
  }, []);

  const isNotSelectedTenant = React.useMemo(() => compose(
    isNil,
    path(['tenantId']),
    // eslint-disable-next-line
  )(v), [v.tenantId]);

  const isNotSelectedCustomer = React.useMemo(() => compose(
    isNil,
    path(['customerId']),
    // eslint-disable-next-line
  )(v), [v.customerId]);

  const deparments = compose(
    map(({ id, name }) => ({ id, name })),
    path(['departments'])
  )(options)

  const dontHaveAccessToDeparments = compose(
    join(', '),
    pluck('name'),
    flatten,
    filter(({ id }) => includes(id, v.departmentIds))
  )(deparments)

  return (
    <React.Fragment>
      <Grid item xs={3}>
        {(canAccessTenant && canAccessCustomer) || v.tenantId === null || v.customerId === null
          ? <Field
              fullWidth
              overrided
              label={f(pae['app.employees.tenant'])}
              name={`accessRules.${i}.tenantId`}
              variant="outlined"
              component={Select}
              options={tenants}
              onChange={onTenantChange}
            />
        : <TextField
            fullWidth
            label={f(pae['app.employees.tenant'])}
            disabled
            variant="outlined"
            value={compose(
              pathOr('Ops', ['name']),
              find(propEq('id', v.tenantId))
            )(options.tenants)}
          />
        }
      </Grid>
      <Grid item xs={3}>
        {canAccessCustomer || v.customerId === null
          ? <Field
              fullWidth
              overrided
              label={f(pae['app.employees.customer'])}
              name={`accessRules.${i}.customerId`}
              variant="outlined"
              disabled={isNotSelectedTenant}
              component={Select}
              options={state.customers.filtered}
              onChange={onCustomerChange}
            />
          : <TextField
              fullWidth
              label={f(pae['app.employees.customer'])}
              disabled
              variant="outlined"
              value={compose(
                pathOr('Ops', ['name']),
                find(propEq('id', v.customerId))
              )(options.customers)}
            />
        }
      </Grid>
      <Grid item xs={3}>
        {canAccessCustomer
          ? <Field
              key={v.customerId}
              name={`accessRules.${i}.departmentIds`}
              component={MultiMenu}
              BaseLine={BaseLine}
              BaseLineProps={{
                disabled: isNotSelectedCustomer,
                label: f(pae['app.employees.departments']),
              }}
              options={state.departments}
            />
          : <TextField
              fullWidth
              disabled
              variant="outlined"
              label={f(pae['app.employees.departments'])}
              value={dontHaveAccessToDeparments}
            />
        }
      </Grid>
      <Grid item xs={3}>
        {i === 0 && (
          <Button onClick={onAdd}>
            <Typography color="secondary">
              + {f(pae['app.employees.button.add.new.department.to.approve'])}
            </Typography>
          </Button>
        )}

        {i !== 0 && ((canAccessTenant && canAccessCustomer) || v.tenantId === null || v.customerId === null) && (
          <Button onClick={onRemove}>
            <Typography color="error" >
              - {f(pae['app.employees.button.remove.department.to.approve'])}
            </Typography>
          </Button>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default AccessRules;
