import React from 'react';
import { pathOr } from 'ramda';
import { Link, useLocation } from 'react-router-dom';
import { Field } from 'formik';
import {
  Switch,
  Grid,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ROLES } from '../../../../config/role-configs';
import { ArrowLeftIcon } from '../../../../components/Header';
import pae from '../../../../config/intlMessageSelectors/payroll-admin-employees';

const EmployeeTitle = ({ values, isEdit }) => {
  const location = useLocation();
  const { formatMessage: f } = useIntl();
  return (
    <Grid item xs={12} container alignItems="center" justify="space-between">
      <Grid item xs={6} container alignItems="center" spacing={16}>
        <Grid item>
          <Link to={pathOr('/employees', ['state', 'redirect'], location)}>
            <ArrowLeftIcon />
          </Link>
        </Grid>

        <Grid item>
          <Typography variant="h6">
            {isEdit
              ? f(pae['app.employees.edit.employee'])
              : f(pae['app.employees.create.employee'])}
          </Typography>
        </Grid>
      </Grid>

      <FormControlLabel
        control={(
          <Field name="approveOwnTimeReport">
            {({ field }) => (
              <Switch
                disabled={[ROLES.APPROVER, ROLES.EMPLOYEE].includes(values.role)}
                color="primary"
                name="approveOwnTimeReport"
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          </Field>
        )}
        label={f(pae['app.employees.approve.own.timereport'])}
      />
    </Grid>
  );
};

export default EmployeeTitle;
