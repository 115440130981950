import React from 'react';
import {
  TextField as MuiTextField,
  InputAdornment,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { DatePicker as MuiDatePicker } from 'material-ui-pickers';
import moment from 'moment';

const KeyboardIcon = props => (
  <SvgIcon {...props}>
    <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </SvgIcon>
);

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // set monday as first week day
    moment()._locale._week.dow = 1;
  }

  onChange(d) {
    const {
      field: { name },
      onChange,
      form,
    } = this.props;


    form.setFieldValue(name, d);

    if (onChange) {
      onChange({
        key: name,
        value: d,
        form,
      });
    }
  }

  render() {
    const {
      field,
      children,
      form: { errors },
      InputProps,
      disabled,
      noHelperText = false,
      ...props
    } = this.props;

    return (
      <MuiDatePicker
        {...props}
        {...field}
        disabled={disabled}
        TextFieldComponent={props => (
          <MuiTextField
            {...props}
            FormHelperTextProps={{
              // make sure that error doesn't shift the input itself
              style: { position: 'absolute', top: '50px' },
            }}
            error={!!errors[field.name]}
            helperText={!noHelperText && errors[field.name]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={disabled}>
                    <KeyboardIcon />
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: true,
              ...InputProps,
            }}
          />
        )}
        value={field.value}
        onChange={this.onChange}
      />
    );
  }
}

export default DatePicker;
