import React from 'react';
import { connect } from 'react-redux';
import {
  filter,
  compose,
  map,
  find,
  propEq,
  head,
  pathOr,
  pathEq,
  allPass,
  path,
} from 'ramda';
import { useMutation } from 'react-apollo';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import Typography from '@material-ui/core/Typography';
import { injectIntl } from 'react-intl';
import pab from '../config/intlMessageSelectors/payroll-admin-base';
import { CHANGE_SELECTED_TENANT } from '../queries/tenants';
import { CHANGE_SELECTED_CUSTOMER } from '../queries/customers';
import { setSelectedPeriod } from '../actions/context';

import {
  ENTITIES,
  COMMON,
} from '../config/role-configs';

import Can from './Can';

const switcherStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
};

const noRightsStyle = {
  height: '100%',
  borderRightWidth: '1px',
  borderRightColor: '#DADADA',
  borderRightStyle: 'solid',
  paddingLeft: '20px',
  paddingRight: '20px',
  display: 'flex',
  alignItems: 'center',
};

const customStyles = {
  container: provided => ({
    ...provided,
    borderRightWidth: '1px',
    borderRightColor: '#DADADA',
    borderRightStyle: 'solid',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px',
  }),
  control: provided => ({
    ...provided,
    width: 200,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
  }),

  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? 'grey' : 'black',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: () => ({ color: 'black' }),
};

const Switchers = ({
  auth: {
    user: {
      selectedTenant,
      selectedCustomer,
      role,
    },
    user,
  },
  setAuth,
  additional: {
    customers,
    tenants,
  },
  intl,
  editable,
  isManager,
  userId,
  setSelectedPeriod,
}) => {
  const [changeSelectedTenant] = useMutation(CHANGE_SELECTED_TENANT);
  const [changeSelectedCustomer] = useMutation(CHANGE_SELECTED_CUSTOMER);

  const [state, setState] = React.useState({
    tenants: [],
    customers: [],
  });

  const history = useHistory();
  const location = useLocation()

  React.useEffect(() => {
    const t = tenants.map(({ name, id }) => ({ label: name, value: id }));
    setState(s => ({
      ...s,
      tenants: t,
    }));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const c = compose(
      map(({ name, id }) => ({ label: name, value: id })),
      filter(propEq('tenantId', selectedTenant)),
    )(customers);

    setState(s => ({
      ...s,
      customers: c,
    }));
    // eslint-disable-next-line
  }, [selectedTenant]);

  const onTenantChange = React.useCallback(
    async ({ value }) => {

      await changeSelectedTenant({
        variables: { tenantId: value }
      })

      setAuth({
        user: {
          ...user,
          selectedTenant: value,
          selectedCustomer: null,
        },
      });
    },
    // eslint-disable-next-line
    [],
  );

  const onCustomerChange = React.useCallback(
    async ({ value }) => {
      await changeSelectedCustomer({
        variables: { customerId: value },
      });

      const periodId = compose(
        path([0, 'periodId']),
        filter(allPass([
          propEq('id', value),
          propEq('tenantId', selectedTenant),
        ])),
      )(customers);

      setSelectedPeriod(periodId || null);

      setAuth({
        user: {
          ...user,
          selectedCustomer: value,
        },
      });

      if (location.pathname.includes('time-report-details')) {
        history.push('/time-reports');
        return;
      }

      if (!isManager) return;

      const canManage = compose(
        pathOr(false, ['isManager']),
        head,
        filter(pathEq(['id'], value)),
      )(customers);

      if (canManage) {
        history.push('/time-reports');
        return;
      }

      if (['/employees', '/departments', '/time-reports'].includes(location.pathname)) {
        history.push(`/time-report-details/${periodId}/${userId}`);
      }
    },
    // eslint-disable-next-line
    [selectedTenant]
  );

  return (
    <div style={switcherStyle}>
      <Can
        role={role}
        entity={ENTITIES.TENANT}
        rules={[COMMON.CHANGE, COMMON.VIEW]}
        change={() => (
          <ReactSelect
            styles={customStyles}
            isDisabled={!editable}
            placeholder={intl.formatMessage(pab['app.base.tenantSelect'])}
            value={find(propEq('value', selectedTenant), state.tenants)}
            onChange={onTenantChange}
            options={state.tenants}
          />
        )}
        view={() => (
          <div style={noRightsStyle}>
            <Typography variant="h6" style={{ lineHeight: '46px' }}>
              Tenant:
              {' '}
              {tenants[0].name}
            </Typography>
          </div>
        )}
      />

      <Can
        role={role}
        entity={ENTITIES.CUSTOMER}
        rules={[COMMON.CHANGE, COMMON.VIEW]}
        change={() => (
          <ReactSelect
            styles={customStyles}
            isDisabled={!editable || !selectedTenant}
            placeholder={intl.formatMessage(pab['app.base.customerSelect'])}
            value={find(propEq('value', selectedCustomer), state.customers) || null}
            onChange={onCustomerChange}
            options={state.customers}
          />
        )}
        view={() => (
          <div style={noRightsStyle}>
            <Typography variant="h6" style={{ lineHeight: '46px' }}>
              Customer:
              {' '}
              {customers[0].name}
            </Typography>
          </div>
        )}
      />
    </div>
  );
};

export default compose(
  connect(
    ({ auth }) => ({
      isManager: pathEq(['user', 'role'], 'manager', auth),
      userId: auth.user.id,
    }),
    { setSelectedPeriod }
  ),
  injectIntl,
)(Switchers);
