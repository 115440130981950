import React from 'react';
import { getIn } from 'formik';
import MuiTextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(e) {
    const { field, onChange, form } = this.props;
    field.onChange(e);

    if (onChange) {
      onChange({
        value: e.target.value,
        key: field.name,
        form,
      });
    }
  }

  onBlur(e) {
    const { field, onBlur, form } = this.props;

    field.onBlur(e);

    if (onBlur) {
      onBlur({
        value: e.target.value,
        key: field.name,
        form,
      });
    }
  }

  render() {
    const {
      field,
      options,
      form,
      overrided,
      ...props
    } = this.props;

    const error = getIn(form.errors, field.name);
    const touch = getIn(form.touched, field.name);

    return (
      <MuiTextField
        select
        {...field}
        {...props}
        FormHelperTextProps={{
          // make sure that error doesn't shit the input itself
          ...!overrided
            ? { style: { position: 'absolute', top: '24px' } }
            : { style: { position: 'absolute', top: '52px' } },
        }}
        error={touch && !!error}
        helperText={touch && error ? error : null}
        value={field.value || ''}
        onChange={this.onChange}
      >
        {options.map(({ value, label, disabled }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </MuiTextField>
    );
  }
}

export default Select;
