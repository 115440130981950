import React from 'react';
import { Paper } from '@material-ui/core';
import CustomersVerticalMenu from '../CustomersVerticalMenu';
import CustomersStatus from '../CustomersStatus';
import Table from '../../../../components/Table/Table';
import pac from '../../../../config/intlMessageSelectors/payroll-admin-customers';
import { TABLE_ROWS_OPTION } from "../../../../utils/table";

const headers = [
  {
    id: 'organizationNumber',
    label: pac['admin.page.table.column.org.number'],
    tableCellProps: { style: { width: '12%', minWidth: '173px' } },
  },
  {
    id: 'name',
    label: pac['admin.page.table.column.name'],
  },
  {
    id: 'payrollSystem.name',
    label: pac['admin.page.table.column.payroll.system'],
    tableCellProps: { style: { width: '13%', minWidth: '190px' } },
  },
  {
    id: 'tenant.name',
    label: pac['admin.page.table.column.tenant'],
    tableCellProps: { style: { width: '15%', minWidth: '202px' } },
  },
  {
    id: 'employeesCount',
    label: pac['admin.page.table.column.employees'],
    tableCellProps: { style: { width: '12%' } },
  },
  {
    id: 'status',
    label: pac['admin.page.table.column.status'],
    tableCellProps: { style: { width: '9%' } },
  },
  {
    id: 'menu',
    label: '',
    tableCellProps: { style: { width: '72px' } },
  },
];

const mapper = [
  { id: 'organizationNumber' },
  { id: 'name' },
  { id: 'payrollSystem.name' },
  { id: 'tenant.name' },
  { id: 'employeesCount' },
  { id: 'status', component: CustomersStatus },
  { id: 'menu', component: CustomersVerticalMenu },
];

const CustomersTable = ({
  customers = [],
  count,
  onChangePage,
  onChangeRowsPerPage,
  onRequestSort,
  rowsPerPage,
  page,
  orderBy,
  order,
}) => (
  <Paper style={{ width: '100%' }}>
    <div style={{ overflow: 'auto' }}>
      <Table
        sortable
        pagination
        count={count}
        headers={headers}
        rows={customers}
        mapper={mapper}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        rowsPerPageOptions={TABLE_ROWS_OPTION}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRequestSort={onRequestSort}
      />
    </div>
  </Paper>
);

export default CustomersTable;
