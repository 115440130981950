import React, { useState } from 'react';
import { useMutation } from "react-apollo";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { compose } from "ramda";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { PERIOD_REMIND, PERIOD_REMIND_ALL } from "../../queries/time-report";
import { NOTIFICATION } from "../NotificationSnackbar";
import { showNotification } from '../../actions/notifications';
import pan from "../../config/intlMessageSelectors/payroll-admin-notifications";
import ptr from "../../config/intlMessageSelectors/payroll-admin-timeReport";
import { getErrorBackendMessage, } from "../../utils/error";

const RemindButton = ({
  employeeId,
  employeePeriodId,
  departmentId,
  isRemindAll = false,
  disabled = false,
  showNotification,
  refetch,
}) => {
  const { formatMessage: f } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [isReminded, setIsReminded] = useState(disabled);

  const [periodRemind] = useMutation(PERIOD_REMIND);
  const [periodRemindAll] = useMutation(PERIOD_REMIND_ALL);

  const onPeriodRemind = async (employeeId, employeePeriodId) => {
    try {
      setIsLoading(true);
      await periodRemind({
        variables: {
          employeeId,
          employeePeriodId
        },
      });
      setIsReminded(true);
      showNotification({
        [Date.now()]: {
          message: f(pan['app.notification.period.reminded']),
          type: NOTIFICATION.SUCCESS,
        },
      });
    } catch (e) {
      showNotification({
        [Date.now()]: {
          message: f(pan['app.notification.error.default']),
          type: NOTIFICATION.ERROR,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onPeriodRemindAll = async () => {
    try {
      setIsLoading(true);
      await periodRemindAll({
        variables: {
          periodId: employeePeriodId,
          ...departmentId ? { departmentId } : null,
        },
      });
      setIsLoading(false);
      refetch();
      showNotification({
        [Date.now()]: {
          message: f(pan['app.notification.periods.reminded']),
          type: NOTIFICATION.SUCCESS,
        },
      });
    } catch (e) {
      setIsLoading(false);
      const errObj = JSON.parse(JSON.stringify(e))
      showNotification({
        [Date.now()]: {
          message: getErrorBackendMessage(errObj) || f(pan['app.notification.error.default']),
          type: NOTIFICATION.ERROR,
        },
      });
    }
  }

  return isRemindAll ? (
    <Button
      variant="contained"
      color={isReminded ? "primary" : "secondary"}
      onClick={!isReminded ? onPeriodRemindAll : () => {}}
      disabled={isLoading || isReminded}
    >
      {isLoading ? <CircularProgress color='white' size={24} style={{ marginRight: 8 }} /> : <NotificationsNoneOutlinedIcon style={{ marginRight: 8 }} />}
      {f(ptr['app.timeReport.periodRemindAllBtn'])}
    </Button>
  ) : (
    <IconButton
      disabled={isLoading || isReminded}
      color={"secondary"}
      size='small'
      onClick={e => {
        e.stopPropagation();
        !isReminded && onPeriodRemind(employeeId, employeePeriodId);
      }}
    >
      <NotificationsNoneOutlinedIcon />
    </IconButton>
  );
};

export default compose(
  connect(null, { showNotification }),
)(RemindButton);
