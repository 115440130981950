export default {
  "app.notification.success.create": "Opprettet",
  "app.notification.success.delete": "Slettet",
  "app.notification.success.update": "Oppdatert",
  "app.notification.success.default": "Vellykket",
  "app.notification.error.default": "Noe gikk galt",
  "app.notification.error.notNull": "Feltet kan ikke være tomt",
  "app.notification.error.invalid": "er ugyldig",
  "app.notification.error.field": "Felt",
  "app.notification.default": "Noe gikk galt",
  "notification.error.notNull": "Kan ikke være null",
  "notification.error.invalid": "er ugyldig"
}
