import React from 'react';
import { withStyles } from '@material-ui/core';
import { TextField } from '../../../../../components/FormikFields';

// compare next & prev values
// eslint-disable-next-line
const areEqual = ({ field: pField, form: pForm }, { field: nField, form: nForm }) => {
  return pField.value === nField.value
    && nForm.errors[nField.name] === pForm.errors[pField.name]
    && nForm.touched[nField.name] === pForm.touched[pField.name];
};

const StyledTextField = React.memo(
  withStyles(() => ({
    root: {
      marginTop: '12px',
      width: '97%',
    },
    underline: { '&:before': { borderBottom: 'none' } },
  }))(({ classes: { underline, ...rest }, ...props }) => (
    <TextField
      classes={rest}
      InputProps={{ classes: { underline } }}
      {...props}
    />
  )),
  areEqual,
);

export default StyledTextField;
