import React, { useState } from 'react';
import Drawer from '../../../../components/Drawer/Drawer';
import { ListItem } from './components/ListItem';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { StyledMuiTextField } from '../../components/StyledFields';
import { ReactSortable } from "react-sortablejs";
import pac from '../../../../config/intlMessageSelectors/payroll-admin-customers';
import pab from '../../../../config/intlMessageSelectors/payroll-admin-base';

const styles = () => ({
  root: {
    padding: 10,
    width: '100%'
  }
});

const EmploymentCategoryDrawer = ({
  field,
  form,
  onChange,
  classes,
  options
}) => {
  const { value, name } = field;
  const { root } = classes;
  const selectedIds = value.map(item => item.id);
  const selectedLables = selectedIds.map(item =>
    options.find(val => val.value === item)?.label);

  const initialList = [
    ...value.map(item => ({
      value: item.id,
      label: options.find(option => option.value === item.id)?.label,
      checked: true
    })),
    ...options.reduce((acc, option) => {
      if(!selectedIds.includes(option.value)) {
        acc.push(option);
      }
      return acc;
    }, [])
  ];

  const [open, setOpen] = useState(false);
  const [list, setList] = useState(initialList);
  const { formatMessage: f } = useIntl();
  const selectedList = list.filter(item => item.checked);
  const unselectedList = list.filter(item => !item.checked).sort();
  const onSave = () => {
    const updatedValues = selectedList.map((item, index) => ({
      id: item.value,
      priority: index + 1
    }));

    form.setFieldValue(name, updatedValues);

    if (onChange) {
      onChange({
        key: name,
        value: updatedValues,
        form,
      });
    }

    toggleDrawer();
  };

  const toggleDrawer = () => {
    setOpen(!open);
  }

  const toggleSelectAll = checked => {
    setList(list.map(item => ({
      ...item,
      checked
    })))
  }

  const updateSelectedList = updatedList => {
    setList([
      ...updatedList,
      ...unselectedList
    ]);
  }

  const toggleSelectOne = (checked, selectedItem) => {
    setList(
      list.reduce((acc, item) => {
        if(item.value === selectedItem.value) {
          acc.push({...item, checked});
        } else {
          acc.push(item);
        }
        return acc;
      }, [])
    )
  }

  const renderList = data => data.map(item => (
    <ListItem
      key={item.value}
      name={item.label}
      onChange={({ target: { checked } }) => toggleSelectOne(checked, item)}
      checked={item.checked}
      draggable={item.checked}
    />
  ));

  const onClose = () => {
    setList(initialList);
    toggleDrawer();
  }

  return (
    <>
      <StyledMuiTextField value={selectedLables} onClick={toggleDrawer} />
      <Drawer
        open={open}
        onClose={onClose}
        title={f(pac['admin.page.compensation.types.step.title'])}
        onChange={onSave}
      >
        <div className={root}>
          <ListItem
            key={0}
            name={f(pab['app.base.button.mark.all'])}
            onChange={({ target: { checked } }) => toggleSelectAll(checked)}
            checked={list.filter(item => item.checked).length === list.length}
            disabled={!!list.length}
          />
          <ReactSortable list={selectedList} setList={updateSelectedList}>
            {
              renderList(selectedList)
            }
          </ReactSortable>
          { renderList(unselectedList) }
        </div>
      </Drawer>
    </>
  );
};

export default withStyles(styles)(EmploymentCategoryDrawer);
