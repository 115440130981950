import { pathOr } from 'ramda';
import pan from '../config/intlMessageSelectors/payroll-admin-notifications';
import pae from '../config/intlMessageSelectors/payroll-admin-employees';
import pat from '../config/intlMessageSelectors/payroll-admin-timeReport';
import ptr from '../config/intlMessageSelectors/payroll-admin-timeReport';
import pac from '../config/intlMessageSelectors/payroll-admin-customers';

export const getErrorMessage = (errorObj = {}, formatMessage) => {
  switch (errorObj.messageCode) {
    case 2:
      return `${formatMessage(pan['app.notification.error.field'])}: '${errorObj.field}'`
        + ` ${formatMessage(pan['app.notification.error.notNull'])}`;
    case 3:
      if (errorObj.field === 'duration') {
        return 'Duration can not be bigger than work day time';
      }
      if (['dimensionNumber', '2].dimensionNumber'].includes(errorObj.field)) {
        return formatMessage(pac['admin.error.edit.project.number.validation'])
      }
      return `${formatMessage(pan['app.notification.error.field'])}: '${errorObj.field}'`
        + ` ${formatMessage(pan['app.notification.error.invalid'])}`;
    case 4:
      if (errorObj.entityCode === 1) {
        return 'Please ensure user is assigned to a department to enable time reporting';
      }
      return ptr['app.time.report.transations.error.no.transactions'];
    case 5:
      return formatMessage(pat['app.timeReport.overlappedAbsence']);
    case 6:
      return formatMessage(pat['app.timeReport.reportingOnWeekendIsNotAllowed']);
    case 7:
      return formatMessage(pae['app.employees.cannotRemoveHasTransaction']);
    case 8:
      if (errorObj.entityCode === 10 && errorObj.field === 'dimensionNumber') {
        return formatMessage(pac['admin.error.edit.project.number.same.validation']);
      }
      if (errorObj.entityCode === 10) {
        return formatMessage(pac['admin.error.add.project.validation']);
      }
      return formatMessage(pae['app.employees.emailAddressInUse']);
    case 9:
      return formatMessage(pae['app.employees.errorEmploymentNumber']);
    case 16:
      return formatMessage(pae['app.employees.error.no.deviation']);
    case 22:
      if (errorObj.entityCode === 10) {
        return formatMessage(pac['admin.error.remove.used.project']);
      } else {
        return formatMessage(pan['app.notification.error.default']);
      }
    case 23:
      if (errorObj.entityCode === 14) {
        return formatMessage(pac['admin.error.remove.activity']);
      } else {
        return formatMessage(pan['app.notification.error.default']);
      }
    case 27:
      return formatMessage(pac['admin.error.org.number.not.found.in.nettlonn']);
    case 'deviation_on_weekend':
      return formatMessage(pat['app.timeReport.deviationOnWeekend']);
    case 'deviation_on_holidays':
      return formatMessage(pat['app.timeReport.deviationOnHoliday']);
    case 'department_not_found':
      return formatMessage(pat['app.timeReport.departmentNotFound']);
    case 'deviation_overlaps':
      return formatMessage(pat['app.timeReport.deviationOverlapsWithExisting']);
    case 'HOLIDAY_RULE':
      return formatMessage(pae['app.employees.error.national.holiday']);
    default:
      return formatMessage(pan['app.notification.error.default']);
  }
};

export const getErrorObjs = error => pathOr(
  '',
  [
    'networkError',
    'result',
    'errors',
    0,
    'extensions',
    'response',
    'body',
    'errors',
    0,
    'messageCodeObjs',
  ],
  error
);

export const getErrorStr = error =>
  pathOr(
    '',
    [
      'networkError',
      'result',
      'errors',
      0,
      'extensions',
      'exception',
      'response',
      'errorCode',
    ],
    error
  );

export const getErrorBackendMessage = error =>
  pathOr(
    '',
    [
      'networkError',
      'result',
      'errors',
      0,
      'message',
    ],
    error
  );

export const getErrorMessageCode = (error, defaultMsg = '') =>
  pathOr(
    defaultMsg,
    [
      0,
      'messageCode',
    ],
    getErrorObjs(error)
  );

export const getErrorEntityCode = (error, defaultMsg = '') =>
  pathOr(
    defaultMsg,
    [
      0,
      'entityCode',
    ],
    getErrorObjs(error)
  );
