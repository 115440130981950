import React from 'react';
import { connect } from 'react-redux';
import { pathOr, head, compose, path, filter, pathEq } from 'ramda';
import {
  Drawer,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom'
import pab from '../config/intlMessageSelectors/payroll-admin-base';

const ClippedDrawer = ({
  roleConfigs,
  intl,
  classes,
  active,
  isManager,
  canManage,
  role,
  selectedPeriod,
  hasProjectReporting,
  userId,
}) => {
  const isDisabled = isManager && !canManage;
  const history = useHistory()

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: classes.paper, docked: classes.docked }}
    >
      <MenuList classes={{ root: classes.listRoot }}>
        {roleConfigs.renderNavigation.map(route => {
          if (['/projects'].includes(route.path) && (!hasProjectReporting || isDisabled)) return

          return (
            <MenuItem
              classes={{ root: classes.root, selected: classes.selected }}
              key={route.name}
              disabled={['/employees', '/departments'].includes(route.path) && isDisabled}
              selected={active === route.name}
              onClick={() => {
                const redirectTo = role === 'employee' ? `${roleConfigs.indexToRedirectTo}/${selectedPeriod}/${userId}` : route.path;
                history.push(redirectTo);
              }}
            >
              <img
                width={36}
                height={36}
                src={active === route.name ? route.activeIcon : route.icon}
                alt="menu icon"
              />
              <div style={{
                marginTop: 10,
                fontSize: 12,
                color: active === route.name ? '#4AAF62' : '#2C2C2C',
              }}
              >
                {intl.formatMessage(pab[route.selector])}
              </div>
            </MenuItem>
          )
        })}
      </MenuList>
    </Drawer>
  );
};

const styles = () => ({
  root: {
    padding: '20px',
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    borderBottomWidth: '1px',
    borderBottomColor: '#DADADA',
    borderBottomStyle: 'solid',
    borderRightWidth: '2px',
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    backgroundColor: '#FFFFFF',
    '&$selected': { backgroundColor: '#FFFFFF' },
    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderRightWidth: '2px',
      borderRightColor: '#73B783',
      borderRightStyle: 'solid',
    },
    '&:focus': { backgroundColor: '#FFFFFF' },
  },
  selected: {
    borderRightWidth: '2px',
    borderRightColor: '#73B783',
    borderRightStyle: 'solid',
    '&:hover': { backgroundColor: '#FFFFFF' },
    '&:focus': { backgroundColor: '#FFFFFF' },
  },
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  paper: {
    position: 'fixed',
    left: 0,
    top: '73px',
    width: 100,
    overflow: 'visible',
  },
  docked: {
    height: '100%',
    position: 'fixed',
  },
});

export default compose(
  connect(({ auth, context }) => {
    const selectedCustomer = path(['user', 'selectedCustomer'], auth);
    const isManager = pathEq(['user', 'role'], 'manager', auth);
    const role = path(['user', 'role'], auth);
    const userId = path(['user', 'id'], auth);
    const selectedPeriod = path(['selectedPeriod'], context);
    const canManage = compose(
      pathOr(false, ['isManager']),
      head,
      filter(pathEq(['id'], selectedCustomer)),
      path(['customers'])
    )(context);

    const hasProjectReporting = compose(
      pathOr(false, ['hasProjectReporting']),
      head,
      filter(pathEq(['id'], selectedCustomer)),
      path(['customers'])
    )(context);

    return { isManager, canManage, role, selectedPeriod, userId, hasProjectReporting };
  }),
  injectIntl,
  withStyles(styles),
)(ClippedDrawer);
