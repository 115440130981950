import { combineReducers, createStore } from 'redux';
import { auth } from './reducers/auth';
import { notifications } from './reducers/notifications';
import { context } from './reducers/context';
import { isProduction } from './config/common';

const reducers = combineReducers({
  auth,
  context,
  notifications,
});

export const store = createStore(
  reducers,
  // eslint-disable-next-line
  !isProduction && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
