import React from 'react'
import Loader from '../../../../components/LoadingCircle';

const styles = {
  display: 'flex',
  height: 'calc(100vh - 450px)',
  alignItems: 'center',
};

const CustomerLoader = () => (
  <div style={styles}>
    <Loader />
  </div>
)

export default CustomerLoader;
