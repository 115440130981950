import { defineMessages } from 'react-intl';

export default defineMessages({
  'app.timeReport.compensationType': {
    id: 'app.timeReport.compensationType',
    defaultMessage: 'Type',
    description: 'Types of compensations for user to select while creating a deviation',
  },
  'app.timeReport.dateRange': {
    id: 'app.timeReport.dateRange',
    defaultMessage: 'Date/Range',
  },
  'app.timeReport.amount': {
    id: 'app.timeReport.amount',
    defaultMessage: 'Amount',
  },
  'app.timeReport.transactionStatus': {
    id: 'app.timeReport.transactionStatus',
    defaultMessage: 'Status',
  },

  'app.timeReport.transactionStatusRejectBtn': {
    id: 'app.timeReport.transactionStatusRejectBtn',
    defaultMessage: 'Reject',
  },
  'app.timeReport.period': {
    id: 'app.timeReport.period',
    defaultMessage: 'Period',
  },

  'app.timeReport.closed': {
    id: 'app.timeReport.closed',
    defaultMessage: 'Closed',
    description: 'Used to tell if the period is open or closed in the timereport overview table',
  },

  'app.timeReport.open': {
    id: 'app.timeReport.open',
    defaultMessage: 'Open',
    description: 'Used to tell if the period is open or closed in the timereport overview table',
  },

  'app.timeReport.closePeriodBtn': {
    id: 'app.timeReport.closePeriodBtn',
    defaultMessage: 'Close period',
  },

  'app.timeReport.periodIsClosedBtn': {
    id: 'app.timeReport.periodIsClosedBtn',
    defaultMessage: 'Period is already closed',
  },

  'app.timeReport.periodIsClosedStatus': {
    id: 'app.timeReport.periodIsClosedStatus',
    defaultMessage: 'Period is closed',
    description: 'Used to tell if the status for selected period is '
      + 'open or closed on the deviations screen',
  },

  'app.timeReport.transactionStatusApproveBtn': {
    id: 'app.timeReport.transactionStatusApproveBtn',
    defaultMessage: 'Approve',
  },

  'app.timeReport.periodTakeBackBtn': {
    id: 'app.timeReport.periodTakeBackBtn',
    defaultMessage: 'TAKE BACK',
    description: 'Time Report Details view - TAKE BACK button',
  },

  'app.timeReport.transactionStatusApproved': {
    id: 'app.timeReport.transactionStatusApproved',
    defaultMessage: 'APPROVED',
    description: 'Time Report Details view - Deviation status',
  },

  'app.timeReport.transactionStatusExported': {
    id: 'app.timeReport.transactionStatusExported',
    defaultMessage: 'EXPORTED',
    description: 'Time Report Details view - Deviation status',
  },
  'app.timeReport.transactionStatusRejected': {
    id: 'app.timeReport.transactionStatusRejected',
    defaultMessage: 'REJECTED',
    description: 'Time Report Details view - Deviation status',
  },
  'app.timeReport.transactionStatusPendingApproval': {
    id: 'app.timeReport.transactionStatusPendingApproval',
    defaultMessage: 'PENDING APPROVAL',
    description: 'Time Report Details view - Deviation status',
  },

  'app.timeReport.transactionStatusArchived': {
    id: 'app.timeReport.transactionStatusArchived',
    defaultMessage: 'ARCHIVED',
    description: 'Time Report Details view - Deviation status',
  },

  'app.timeReport.transactionStatusSubmitted': {
    id: 'app.timeReport.transactionStatusSubmitted',
    defaultMessage: 'SUBMITTED',
    description: 'Time Report Details view - Deviation status',
  },

  'app.timeReport.createDeviation': {
    id: 'app.timeReport.createDeviation',
    defaultMessage: 'Create deviation',
    description: 'Time Report Details view - Create deviation button',
  },
  'app.timeReport.createPresence': {
    id: 'app.timeReport.createPresence',
    defaultMessage: 'Presence',
    description: 'Time Report Details view - Create presence button',
  },

  'app.timeReport.comment': {
    id: 'app.timeReport.comment',
    defaultMessage: 'Comment',
    description: 'Time Report Details view - Comment to a deviation',
  },

  'app.timeReport.showLess': {
    id: 'app.timeReport.showLess',
    defaultMessage: 'Show less',
    description: 'Time Report Details view - Multiday deviation, go to collapsed mode',
  },

  'app.timeReport.showMore': {
    id: 'app.timeReport.showMore',
    defaultMessage: 'Show more',
    description: 'Time Report Details view - Multiday deviation, go to expanded mode',
  },

  'app.timeReport.rejectMessage': {
    id: 'app.timeReport.rejectMessage',
    defaultMessage: 'Rejection message',
    description: 'Time Report Details view - Label for the Rejection textbox',
  },
  'app.timeReport.deviationsTable': {
    id: 'app.timeReport.deviationsTable',
    defaultMessage: 'DEVIATIONS',
  },
  'app.timeReport.details': {
    id: 'app.timeReport.details',
    defaultMessage: 'DETAILS',
  },

  'app.timeReport.singleDayToggle': {
    id: 'app.timeReport.singleDayToggle',
    defaultMessage: 'Single day',
    description: 'Create Deviation Pop-up - Radiobutton to toggle '
      + 'single day/period when creating a deviation',
  },

  'app.timeReport.periodToggle': {
    id: 'app.timeReport.periodToggle',
    defaultMessage: 'Period',
    description: 'Create Deviation Pop-up - Radiobutton to toggle '
      + 'single day/period when creating a deviation',
  },
  'app.timeReport.copyDatesBelow': {
    id: 'app.timeReport.copyDatesBelow',
    defaultMessage: 'Copy to the days below',
  },
  'app.timeReport.formDuration': {
    id: 'app.timeReport.formDuration',
    defaultMessage: 'Duration',
  },
  'app.timeReport.formStart': {
    id: 'app.timeReport.formStart',
    defaultMessage: 'Start',
  },
  'app.timeReport.formEnd': {
    id: 'app.timeReport.formEnd',
    defaultMessage: 'End',
  },
  'app.timeReport.formDate': {
    id: 'app.timeReport.formDate',
    defaultMessage: 'Date',
  },
  'app.timeReport.user': {
    id: 'app.timeReport.user',
    defaultMessage: 'User',
  },

  'app.timeReport.employees': {
    id: 'app.timeReport.employees',
    defaultMessage: 'Employees',
  },
  'app.timeReport.status': {
    id: 'app.timeReport.status',
    defaultMessage: 'Status',
  },
  'app.timeReport.absence': {
    id: 'app.timeReport.absence',
    defaultMessage: 'Absence',
    description: 'Column name on time report overview table',
  },
  'app.timeReport.presence': {
    id: 'app.timeReport.presence',
    defaultMessage: 'Presence',
    description: 'Column name on time report overview table',
  },
  'app.timeReport.employee': {
    id: 'app.timeReport.employee',
    defaultMessage: 'Employee',
  },
  'app.timeReport.employeeNumber': {
    id: 'app.timeReport.employeeNumber',
    defaultMessage: 'Employee #',
  },
  'app.timeReport.allDepartments': {
    id: 'app.timeReport.allDepartments',
    defaultMessage: 'All departments',
  },
  'app.timeReport.department': {
    id: 'app.timeReport.department',
    defaultMessage: 'Department',
  },
  'app.timeReport.all': {
    id: 'app.timeReport.all',
    defaultMessage: 'All',
    description: "Time Report Overview - 'All' tab for timereports table",
  },
  'app.timeReport.notReported': {
    id: 'app.timeReport.notReported',
    defaultMessage: 'Not reported',
    description: "Time Report Overview - 'Not reported' tab for timereports table",
  },
  'app.timeReport.waitingForApproval': {
    id: 'app.timeReport.waitingForApproval',
    defaultMessage: 'Waiting for approval',
    description: "Time Report Overview - 'Waiting for approval' tab for timereports table",
  },
  'app.timeReport.approved': {
    id: 'app.timeReport.approved',
    defaultMessage: 'Approved',
    description: "Time Report Overview - 'Rejected' tab for timereports table",
  },
  'app.timeReport.exported': {
    id: 'app.timeReport.exported',
    defaultMessage: 'Exported',
    description: "Time Report Overview - 'Exported' tab for timereports table",
  },
  'app.timeReport.timeReportsTitle': {
    id: 'app.timeReport.timeReportsTitle',
    defaultMessage: 'Time reports',
    description: 'Time Report Overview - Time reports page title',
  },
  'app.timeReport.description': {
    id: 'app.timeReport.description',
    defaultMessage: 'Description',
    description: 'Time Report Details view - Description to dimensions',
  },
  'app.timeReport.project': {
    id: 'app.timeReport.project',
    defaultMessage: 'Project',
    description: 'Time Report Details view - Project to dimensions',
  },
  'app.timeReport.projectCompensationType': {
    id: 'app.timeReport.projectCompensationType',
    defaultMessage: 'Time type',
    description: 'Time Report Details view - Compensation Type to dimensions',
  },
  'app.timeReport.dimensionNumber': {
    id: 'app.timeReport.dimensionNumber',
    defaultMessage: 'Project #',
    description: 'Time Report Details view - Project number (Dimension number)',
  },
  'app.timeReport.workType': {
    id: 'app.timeReport.workType',
    defaultMessage: 'Activity',
    description: 'Time Report Details view - Work Type to dimensions',
  },
  'app.timeReport.projectHours': {
    id: 'app.timeReport.projectHours',
    defaultMessage: 'Project Hours',
    description: 'Time Report Details view - Project Hours (button label)',
  },
  'app.timeReport.active': {
    id: 'app.timeReport.active',
    defaultMessage: 'Active',
    description: 'Time Report Details view - used as tab label',
  },
  'app.timeReport.archived': {
    id: 'app.timeReport.archived',
    defaultMessage: 'Archived',
    description: 'Time Report Details view - used as tab label',
  },
  'app.timeReport.reportingMoreThan24H': {
    id: 'app.timeReport.reportingMoreThan24H',
    defaultMessage: 'Maximum number of hours is 24 hours on a day',
    description: 'An error message when user is trying to report more than 24h',
  },
  'app.timeReport.overlappedAbsence': {
    id: 'app.timeReport.overlappedAbsence',
    defaultMessage: 'You can only report absence which is less than or equal to the hours in a working day',
    description: 'An error message when user reports more hours to absence as it should be',
  },
  'app.timeReport.reportingOnWeekendIsNotAllowed': {
    id: 'app.timeReport.reportingOnWeekendIsNotAllowed',
    defaultMessage: 'Not possible to report absence on these days. Please select another day',
    description: 'An error message when user tries to \'absence\' report on a weekend',
  },
  'app.time.report.transactions.reports.subtitle': {
    id: 'app.time.report.transactions.reports.subtitle',
    defaultMessage: 'Department transactions reports',
    description: 'Download button on transactions report'
  },
  'app.time.report.transactions.report.by.deviation.menu.item': {
    id: 'app.time.report.transactions.report.by.deviation.menu.item',
    defaultMessage: 'By Deviation',
    description: 'Subtitle for department transactions report by deviation',
  },
  'app.time.report.transactions.report.by.project.menu.item': {
    id: 'app.time.report.transactions.report.by.project.menu.item',
    defaultMessage: 'By Project Summary',
    description: 'Subtitle for department transactions report by project',
  },
  'app.time.report.transactions.report.by.detailed.menu.item': {
    id: 'app.time.report.transactions.report.by.detailed.menu.item',
    defaultMessage: 'By Project Detailed',
    description: 'Subtitle for department transactions report detailed',
  },
  'app.time.report.transactions.error.no.transactions': {
    id: 'app.time.report.transactions.error.no.transactions',
    defaultMessage: 'No transactions found for this period',
    description: 'Error notification for user when there are no transactions for selected period',
  },
  'app.time.report.transactions.error.no.departments': {
    id: 'app.time.report.transactions.error.no.departments',
    defaultMessage: 'No departments found for this customer',
    description: 'Error notification for user when there are no departments for selected customer',
  },
  'app.time.report.transactions.removeTransactionModal.title': {
    id: 'app.time.report.transactions.removeTransactionModal.title',
    defaultMessage: 'Are you sure you want to remove this deviation?',
    description: 'Title inside Modal on transaction removal',
  },
  'app.time.report.transactions.removeTransactionModal.body': {
    id: 'app.time.report.transactions.removeTransactionModal.body',
    defaultMessage: 'Submitted transactions will be removed. Any previously exported parts of the deviations will be kept.',
    description: 'Main text inside Modal on transaction removal',
  },
  'app.timeReport.nextEmployeeBtn': {
    id: 'app.timeReport.nextEmployeeBtn',
    defaultMessage: 'NEXT',
    description: 'Time Report Details view - next employee button text',
  },
  'app.timeReport.prevEmployeeBtn': {
    id: 'app.timeReport.prevEmployeeBtn',
    defaultMessage: 'PREVIOUS',
    description: 'Time Report Details view - previous employee button text',
  },
  'app.timeReport.periodStatusOpen': {
    id: 'app.timeReport.periodStatusOpen',
    defaultMessage: 'OPEN',
    description: 'Time Report Details view - Period status in header',
  },
  'app.timeReport.periodStatusApproved': {
    id: 'app.timeReport.periodStatusApproved',
    defaultMessage: 'APPROVED',
    description: 'Time Report Details view - Period status in header',
  },
  'app.timeReport.periodStatusRejected': {
    id: 'app.timeReport.periodStatusRejected',
    defaultMessage: 'REJECTED',
    description: 'Time Report Details view - Period status in header',
  },
  'app.timeReport.periodStatusSubmitted': {
    id: 'app.timeReport.periodStatusSubmitted',
    defaultMessage: 'SUBMITTED',
    description: 'Time Report Details view - Period status in header',
  },
  'app.timeReport.reviewedBy': {
    id: 'app.timeReport.reviewedBy',
    defaultMessage: 'Reviewed by',
  },
  'app.timeReport.approvedBy': {
    id: 'app.timeReport.approvedBy',
    defaultMessage: 'Approved by',
  },
  'app.timeReport.rejectedBy': {
    id: 'app.timeReport.rejectedBy',
    defaultMessage: 'Rejected by',
  },
  'app.timeReport.periodTypeDeviation': {
    id: 'app.timeReport.periodTypeDeviation',
    defaultMessage: 'Deviations',
    description: 'Time Report Details view - Period summary type in header',
  },
  'app.timeReport.periodTypeDimension': {
    id: 'app.timeReport.periodTypeDimension',
    defaultMessage: 'Working hours',
    description: 'Time Report Details view - Period summary type in header',
  },
  'app.timeReport.expectedHours': {
    id: 'app.timeReport.expectedHours',
    defaultMessage: 'Expected hours',
    description: 'Time Report Details view - Expected hours type in header',
  },
  'app.timeReport.periodRemindAllBtn': {
    id: 'app.timeReport.periodRemindAllBtn',
    defaultMessage: 'Remind',
    description: 'Time Report Overview - Remind all button',
  },
  'app.timeReport.deviationOnWeekend': {
    id: 'app.timeReport.deviationOnWeekend',
    defaultMessage: 'Deviation you are trying to create overlaps with weekend day',
  },
  'app.timeReport.deviationOnHoliday': {
    id: 'app.timeReport.deviationOnHoliday',
    defaultMessage: 'Deviation you are trying to create overlaps with holiday',
  },
  'app.timeReport.departmentNotFound': {
    id: 'app.timeReport.departmentNotFound',
    defaultMessage: 'You can\'t create deviation for a user without department. Please go to \'Employees page\' and assign department',
  },
  'app.timeReport.deviationOverlapsWithExisting': {
    id: 'app.timeReport.deviationOverlapsWithExisting',
    defaultMessage: 'Deviation you are trying to create overlaps with existing'
  },
  'app.timeReport.dimensionNotActive': {
    id: 'app.timeReport.dimensionNotActive',
    defaultMessage: 'Project is disabled'
  },
  'app.timeReport.workTypeDisabled': {
    id: 'app.timeReport.workTypeDisabled',
    defaultMessage: 'Activity is disabled'
  }
});
