import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Grid, Paper, Button, TextField, withStyles } from '@material-ui/core';
import { path, compose, head, filter, pathOr, pathEq, allPass, propEq } from 'ramda';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/LoadingCircle';

import {
  MainLayout,
  EmployeesTabs,
  EmployeesTable,
  withEmployees,
  DEFAULT_ORDER,
} from './components';
import { Warning } from "../../components/Warning";
import pae from '../../config/intlMessageSelectors/payroll-admin-employees';
import pab from '../../config/intlMessageSelectors/payroll-admin-base';
import { TABLE_ROWS_PER_PAGE } from "../../utils/table";

const styles = {
  search: { height: 40, padding: 10 },
  label: { transform: 'translate(14px, 14px) scale(1)' }
};

const initialState = {
  page: 0,
  sorting: DEFAULT_ORDER,
  rowsPerPage: TABLE_ROWS_PER_PAGE,
  state: 'ACTIVE',
  search: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_PAGE_REQUEST':
      return {
        ...state,
        page: action.payload,
      };
    case 'CHANGE_SORTING_REQUEST':
      return {
        ...state,
        sorting: action.payload,
      };
    case 'CHANGE_ROW_PER_PAGE_REQUEST':
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    case 'CHANGE_STATE_REQUEST':
      return {
        ...state,
        state: action.payload,
      };
    case 'SEARCH_CHANGE_REQUEST':
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

const useTable = onFiltersChange => {
  const [{ sorting, rowsPerPage, page, state, search }, dispatch] = React.useReducer(
    reducer,
    initialState,
  );

  const [orderBy, order] = sorting;

  React.useEffect(() => {
    onFiltersChange({
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      order: sorting.join(' '),
      state,
      search,
    });
    // eslint-disable-next-line
  }, [order, orderBy, page, rowsPerPage, state, search]);

  const onChangePage = React.useCallback(
    page => dispatch({
      type: 'CHANGE_PAGE_REQUEST',
      payload: page,
    }),
    [],
  );

  const onChangeRowsPerPage = React.useCallback(
    rowsPerPage => dispatch({
      type: 'CHANGE_ROW_PER_PAGE_REQUEST',
      payload: rowsPerPage,
    }),
    [],
  );

  const onRequestSort = React.useCallback(
    sorting => dispatch({
      type: 'CHANGE_SORTING_REQUEST',
      payload: sorting,
    }),
    [],
  );

  const onStateChange = React.useCallback(
    state => dispatch({
      type: 'CHANGE_STATE_REQUEST',
      payload: state,
    }),
    [],
  );

  const onSearchChange = React.useCallback(
    search => dispatch({
      type: 'SEARCH_CHANGE_REQUEST',
      payload: search,
    }),
    [],
  );

  return {
    orderBy,
    order,
    rowsPerPage,
    page,
    state,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    onStateChange,
    onSearchChange,
  };
};

const Employees = withEmployees(({
  employees = [],
  count,
  onFiltersChange,
  loading,
  isManager,
  canManage,
  userId,
  roleConfigs,
  selectedCustomer,
  selectedTenant,
  customers,
  classes,
}) => {
  const history = useHistory()
  const { formatMessage: f }  = useIntl();
  const {
    orderBy,
    order,
    rowsPerPage,
    page,
    state,
    search,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    onStateChange,
    onSearchChange,
  } = useTable(onFiltersChange);

  const { createUsers } = roleConfigs

  React.useEffect(() => {
    if (!isManager || canManage) return;

    const periodId = compose(
      path([0, 'periodId']),
      filter(allPass([
        propEq('id', selectedCustomer),
        propEq('tenantId', selectedTenant),
      ])),
    )(customers);

    history.push(`/time-report-details/${periodId}/${userId}`);
    // eslint-disable-next-line
  }, [])

  const hasEmployees = !!count && !loading;

  return (
    <Grid container alignItems="center">
      <Paper style={{ width: '100%', padding: '20px 24px' }}>
        <Grid item container justify="space-between" alignItems="center">
          {createUsers
            ? (
              <Grid item xs={3}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/employees/new"
                  className="ga-create-employee-button"
                >
                  {f(pae['app.employees.createUserButton'])}
                </Button>
              </Grid>
            ) : (<div />)
          }
          <Grid item xs={4} sm={3} md={2}>
            <TextField
              fullWidth
              label={f(pab['app.base.label.search'])}
              type="search"
              variant="outlined"
              InputProps={{ className: classes.search }}
              InputLabelProps={{ className: classes.label }}
              value={search}
              onChange={debounce(
                ({ target: { value }}) => onSearchChange(value),
                300
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid item xs={12} style={{ margin: '20px 0' }}>
        <EmployeesTabs state={state} onStateChange={onStateChange} />
      </Grid>
      {loading ? <Loader /> : (
        <Grid item xs={12}>
        {hasEmployees ? (
          <EmployeesTable
            passDown={roleConfigs}
            employees={employees}
            count={count}
            loading={loading}
            page={page}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onRequestSort={onRequestSort}
          />
        ): (
          <Warning id="app.base.message.noEmployees"/>
        )}
      </Grid>
      )}
    </Grid>
  )
})

const EmployeeContainer = compose(
  connect(({ auth, context }) => {
    const selectedCustomer = path(['user', 'selectedCustomer'], auth);
    const selectedTenant = path(['user', 'selectedTenant'], auth);
    const userId = path(['user', 'id'], auth);
    const customers = path(['customers'], context);
    const isManager = pathEq(['user', 'role'], 'manager', auth);

    const canManage = compose(
      pathOr(false, ['isManager']),
      head,
      filter(pathEq(['id'], selectedCustomer)),
    )(customers);

    return { isManager, canManage, userId, selectedCustomer, selectedTenant, customers }
  }),
  withStyles(styles),
  withRouter,
)(Employees)

const EmployeesPage = props => (
  <MainLayout {...props}>
    {rest => (
      <EmployeeContainer {...rest}/>
    )}
  </MainLayout>
);

export default EmployeesPage
