import React from 'react';
import { Popover } from '@material-ui/core';

const Popup = ({ element, isOpen, onClose, children }) => (
  <Popover
    open={isOpen}
    anchorEl={element}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    onClose={onClose}
  >
    {children}
  </Popover>
);

export default Popup;
