import gql from 'graphql-tag';

export const GET_CUSTOMERS = gql`
  query GET_CUSTOMERS($input: ListInputType){
    customers(input: $input){
      id
      name
      tenantId
      isManager
      periodId
      organizationNumber
      hasProjectReporting
    }
  }
`;

export const GET_TENANT_CUSTOMERS = gql`
  query GET_TENANT_CUSTOMERS($tenantId: Int) {
    customersV2(tenantId: $tenantId){
      customers {
        id,
        name,
      }
    }
  }
`;

export const GET_CUSTOMER_DEPARTMENTS = gql`
  query GET_CUSTOMER_DEPARTMENTS($input: ListInputType) {
    departments(input: $input){
      id
      name
    }
  }
`;

export const GET_CUSTOMERS_V2 = gql`
  query GET_CUSTOMERS_V2(
    $tenantId: Int,
    $limit: Int,
    $offset: Int,
    $order: String,
    $state: String,
    $search: String
  ){
    customersV2(
      tenantId: $tenantId,
      limit: $limit,
      offset: $offset,
      order: $order,
      state: $state,
      search: $search
    ){
      customers {
        id,
        name,
        tenantId,
        organizationNumber,
        employeesCount,
        tenant {
          name
        },
        payrollSystem {
          name
        },
        inactiveDate
        isBillable
        status
        isFlowCompleted
      }
      count
    }
  }
`;

export const CREATE_OR_UPDATE_CUSTOMER = gql`
  mutation CreateOrUpdateCustomer(
    $customer: CustomerWizardInputType,
    $period: PeriodInputType,
    $notifications: [NotificationInputType],
    $holidays: [NationalHolidayInputType],
    $customerIdToCopy: Int
  ) {
    createOrUpdateCustomerConfig(
      customer: $customer
      period: $period
      notifications: $notifications
      holidays: $holidays
      customerIdToCopy: $customerIdToCopy
    ) {
      customerId
    }
  }
`;

export const CREATE_COMPENSATION_TYPE = gql`
  mutation CreateCompensationType($input: CompensationTypeInputType) {
    addCompensationTypeTemp(input: $input) {
      id
      temporary
    }
  }
`;

export const DELETE_COMPENSATION_TYPE = gql`
  mutation RemoveCompensationType($id: Int!, $customerId: Int!) {
    deleteCompensationType(id: $id, customerId: $customerId) {
      id
    }
  }
`;

export const GET_COMPENSATION_TYPES_AND_INTERVALS_BY_CUSTOMER = gql`
  query CompensationTypeListByCustomer($customerId: Int) {
    compensationTypesV2(customerId: $customerId) {
      id
      name
      code
      type
      description
      comment
      approvalRequired
      reportingType
      timeType
      requiresNotification
      multipleDays
      isExcludeWeekend
      deviation
      displayInSummary
      customerId
      intervalId
      temporary
      enabled
      used
    }

    intervals: intervals_deprecated {
      id
      type
      valueV2
    }
  }
`;

export const GET_COMPENSATION_TYPES_AND_EMPLOYMENT_CATEGORIES_BY_CUSTOMER = gql`
  query CompensationTypeAndEmploymentCategoryListsByCustomer($customerId: Int) {
    compensationTypesV2(customerId: $customerId, temporary: false, enabled: true) {
      id
      name
    }

    employmentCategoriesV2(customerId: $customerId) {
      id
      name
      customerId
      reportingMethod
      normalWorkDay
      compensationTypes {
        id,
        priority
      }
      modulesConfig {
        projectReporting
        payslips
        timeReports
      }
      temporary
    }
  }
`;

export const CREATE_EMPLOYMENT_CATEGORY = gql`
  mutation CreateEmploymentCategory($input: EmploymentCategoryInputType) {
    addEmploymentCategoryTemp(input: $input) {
      id
      temporary
    }
  }
`;

export const DELETE_EMPLOYMENT_CATEGORY = gql`
  mutation RemoveEmploymentCategory($id: Int!, $customerId: Int!) {
    deleteEmploymentCategory(id: $id, customerId: $customerId) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_STATUS = gql`
  mutation UpdateCustomerStatus($customerId: Int, $status: Int) {
    updateStatus(
      customerId: $customerId,
      status: $status
    ) {
      status
    }
  }
`;

export const CREATE_WIZARD_USERS = gql`
  mutation createWizardUsers($input: [CreateWizardUserInputType]) {
    createWizardUsers(input: $input)
  }
`;

export const GET_WIZARD_USERS = gql`
  query wizardUsers($customerId: Int, $limit: Int!, $offset: Int!, $order: String!) {
    wizardUsers(customerId: $customerId, limit: $limit, offset: $offset, order: $order) {
      users {
        id
        customerId
        tenantId
        role
        email
        lastName
        firstName
        employeeStart
        employmentCategoryName
        employmentNumber
        departmentName
      }
      count
    }
  }
`;

export const GET_NETTLONN_USERS = gql`
  query GET_NETTLONN_USERS($organizationNumber: String!) {
    nettlonnUsers(organizationNumber: $organizationNumber) {
      validUsers {
        role
        email
        lastName
        firstName
        employeeStart
        employeeEnd
        employmentCategoryName
        employmentNumber
        departmentName
      }
      invalidUsers {
        role
        email
        lastName
        firstName
        employeeStart
        employeeEnd
        employmentCategoryName
        employmentNumber
        departmentName
        errors
      }
    }
  }
`

export const GET_WIZARD_PROJECTS = gql`
  query dimensionsByCustomer($customerId: Int!, $limit: Int!, $offset: Int!) {
    dimensionsByCustomer(customerId: $customerId, limit: $limit, offset: $offset) {
      id
      name
      dimensionNumber
      billable
      enabled
      workTypes {
        id
        name
        enabled
      }
    }
  }
`;

export const CREATE_WIZARD_PROJECTS = gql`
  mutation createWizardProjects($input: [CreateDimensionInputType]) {
    addDimensions(input: $input)
  }
`;

export const CREATE_OR_UPDATE_PROJECT = gql`
  mutation createOrUpdateProject($input: CreateTempDimensionInputType) {
    addDimension(input: $input) {
      id
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: Int!, $customerId: Int!) {
    deleteDimension(id: $id, customerId: $customerId) {
      id
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query GET_CUSTOMER_BY_ID($customerId: Int!) {
    customerById(id: $customerId) {
      id
      name
      organizationNumber
      tenantId
      isFlowCompleted
      hasProjectReporting
      payrollSystem {
        code
      }
    }
  }
`;

export const GET_CUSTOMER_FORM_INFO = gql`
  query GET_CUSTOMER_FORM_INFO($customerId: Int!) {
    periodsV2(customerId: $customerId) {
      frequency
      startDate
      endDate
      cutOffDate
    }

    notifications(customerId: $customerId) {
      customerId
      type
      deadline
      repeatable
    }

    customerHolidays(filter: { customerId: $customerId }) {
      id
      name
      date
      duration
    }

    customerById(id: $customerId) {
      id
      name
      tenantId
      payrollSystemId
      countryId
      organizationNumber
      inactiveDate
      isBillable
      status
    }
  }
`;

export const GET_TENANTS_AND_PAYROLL_SYSTEMS = gql`
  query($input: ListInputType){
    tenants(input: $input){
      id
      name
    }
    getPayrollSystems {
      id
      name
    }
    countries {
      id
      name
    }
  }
`;

export const CHANGE_SELECTED_CUSTOMER = gql`
  mutation changeSelectedCustomer($customerId: Int!) {
    changeSelectedCustomer(customerId: $customerId)
  }
`;

export const GET_COUNTRY_HOLIDAYS = gql`
  query GET_COUNTRY_HOLIDAYS($filter: CountryHolidaysFilter!){
    countryHolidays(filter: $filter) {
      id
      name
      date
      isOfficial
    }
  }
`;
