export default {
  "app.employees": "Employees",
  "app.departments": "Departments",
  "app.projects": "Projects",
  "app.efficiency.timeReport": "Time Report",
  "app.efficiency.admin": "Admin",
  "app.base.importInfo": "Import from file",
  "app.base.month.january": "January",
  "app.base.month.february": "February",
  "app.base.month.march": "March",
  "app.base.month.april": "April",
  "app.base.month.may": "May",
  "app.base.month.june": "June",
  "app.base.month.july": "July",
  "app.base.month.august": "August",
  "app.base.month.september": "September",
  "app.base.month.october": "October",
  "app.base.month.november": "November",
  "app.base.month.december": "December",
  "app.base.btns.confirm": "Confirm",
  "app.base.btns.cancel": "Cancel",
  "app.base.btns.ok": "OK",
  "app.base.btns.remove": "Remove",
  "app.base.select.placeholder": "Select...",
  "app.base.tablePaginationRowsPerPage": "Rows per page",
  "app.base.tablePaginationDisplayedRows": "of",
  "app.base.tableNoData": "No data",
  "app.base.tenantSelect": "SELECT TENANT",
  "app.base.customerSelect": "SELECT CUSTOMER",
  "app.base.logout": "LOG OUT",
  "app.base.button.apply": "Apply",
  "app.base.button.add": "Add",
  "app.base.button.cancel": "Cancel",
  "app.base.button.import": "Import",
  "app.base.button.choseFile": "Choose file",
  "app.base.button.mark.all": "Select all",
  "app.base.message.noModuleConfig": "You have no modules configured. Please contact your manager or ECIT to activate a module.",
  "app.base.message.noProjectReporting": "Project reporting is disabled. Please contact your manager or ECIT to aktivate this.",
  "app.base.message.noPeriods": "There are no periods available. Please contact your administrator.",
  "app.base.label.search": "Search",
  "app.base.message.noEmployees": "There are no employees available. Please contact your administrator.",
  "admin.page.modules.config.timeReport": "Time report",
  "admin.page.modules.config.projectReporting": "Project reporting",
  "admin.page.modules.config.payslips": "Payslips",
  "admin.page.title": "Admin. Customers management",
  "admin.page.add.button": "add new customer",
  "admin.page.back.button": "Customer List",
  "admin.page.edit.button": "Edit",
  "admin.page.table.column.org.number": "Org number",
  "admin.page.table.column.name": "Name",
  "admin.page.table.column.payroll.system": "Payroll System",
  "admin.page.table.column.tenant": "Tenant",
  "admin.page.table.column.employees": "Employees",
  "admin.page.table.column.status": "Status",
  "admin.page.table.column.empty": "undefined",
  "admin.page.table.customer.status.active": "Active",
  "admin.page.table.customer.status.inactive": "Inactive",
  "admin.page.table.customer.status.incomplete": "Incomplete",
  "admin.page.general.step.title": "General",
  "admin.page.compensation.types.step.title": "Compensation Types",
  "admin.page.employment.categories.step.title": "Employment categories",
  "admin.page.users.import.success": "Import successful!",
  "admin.page.users.import.reminder": "Don't forget to assign which departments managers and approvers should approve. This is done in the Employee menu.",
  "admin.page.users.import.step.title": "Users import",
  "admin.page.projects.step.title": "Projects",
  "admin.page.users.import.step.tooltip": "When importing users with role Approvers, please leave EmploymentNumber, Employment category and Department blank.",
  "admin.page.users.import.step.example.tooltip": "Download example file",
  "admin.page.users.import.noData": "No data to import. Please add some data into uploaded file or check column names.",
  "admin.page.dimensions.worktypes.step.title": "Dimensions and worktypes",
  "admin.page.step.next.button": "Next",
  "admin.page.step.back.button": "Back",
  "admin.page.step.finish.button": "Finish",
  "admin.page.step.save.button": "Save",
  "admin.page.step.general.copy.button": "Copy settings from existing customer",
  "admin.page.step.general.copy.customer": "Copy settings",
  "admin.page.step.general.copy.placeholder": "Search by customer name or Org. number",
  "admin.page.step.general.copy.confirmation": "Please confirm copying of settings from",
  "admin.page.step.general.customer.name": "Customer name",
  "admin.page.step.general.org.number": "Org. number",
  "admin.page.step.general.payroll.system": "Payroll System",
  "admin.page.step.general.payroll.tenant": "Tenant",
  "admin.page.step.general.payroll.is.active": "Is Active",
  "admin.page.step.general.payroll.is.billable": "Billable",
  "admin.page.step.general.inactive.date": "Inactive Date",
  "admin.page.step.general.country": "Country",
  "admin.page.step.general.period.reports": "Periods",
  "admin.page.step.general.label.monthly": "Monthly",
  "admin.page.step.general.label.bi-weekly": "Bi-weekly",
  "admin.page.step.general.label.weekly": "Weekly",
  "admin.page.step.general.label.start.from": "Start from",
  "admin.page.step.general.generated.periods": "Generated periods",
  "admin.page.step.general.notifications": "Notifications",
  "admin.page.step.general.add.notification.button": "Add new notification",
  "admin.page.step.general.notification.type.employee": "Employee",
  "admin.page.step.general.notification.type.manager": "Manager",
  "admin.page.step.general.notification.label.type": "Type",
  "admin.page.step.general.label.notification.deadline": "Deadline",
  "admin.page.step.general.label.notification.time": "Time",
  "admin.page.step.general.label.notification.repeatable": "Repeatable",
  "admin.page.step.general.label.notification.repeatable.tooltip": "Repeatable ON indicates that notifications will repeat every month\n      in the same date and time. Repeatable OFF indicates that notification will\n      be sent only once at specified date.",
  "admin.page.step.general.holidays": "Public holidays",
  "admin.page.step.general.add.holiday.button": "Add new holiday",
  "admin.page.step.general.label.holidays.day": "Day",
  "admin.page.step.general.reset.button": "Reset",
  "admin.page.step.general.holiday.full.day": "Full day holiday",
  "admin.page.step.general.holiday.half.day": "Half day holiday",
  "admin.page.step.general.holiday.menu.edit": "Edit",
  "admin.page.step.general.holiday.menu.remove": "Remove",
  "admin.page.step.general.popup.change.country.title": "Change country",
  "admin.page.step.general.popup.change.country.text": "When you change country, the new country has different public holidays. Do you want to keep your holidays?",
  "admin.page.step.general.popup.change.country.reset.button": "Reset",
  "admin.page.step.general.popup.change.country.keep.button": "Keep holidays",
  "admin.page.step.general.popup.reset.holidays.title": "Reset public holidays",
  "admin.page.step.general.popup.reset.holidays.text": "Are you sure you want to reset public holidays?",
  "admin.page.step.general.popup.reset.holidays.reset.button": "Reset",
  "admin.page.step.general.popup.reset.holidays.cancel.button": "Cancel",
  "admin.page.step.general.popup.edit.holiday.title": "Public holiday",
  "admin.page.step.general.popup.edit.holiday.field.name": "Holiday name",
  "admin.page.step.general.popup.edit.holiday.field.date": "Date",
  "admin.page.step.general.popup.edit.holiday.field.full.day": "Full day",
  "admin.page.step.general.popup.edit.holiday.field.half.day": "Half day",
  "admin.page.step.general.popup.edit.holiday.cancel.button": "Cancel",
  "admin.page.step.general.popup.edit.holiday.save.button": "Save",
  "admin.page.step.compensation.types.column.code": "Salary code",
  "admin.page.step.compensation.types.column.name": "Name",
  "admin.page.step.compensation.types.column.type": "Type",
  "admin.page.step.compensation.types.column.comment": "Comment",
  "admin.page.step.compensation.types.column.reporting.type": "Reporting type",
  "admin.page.step.compensation.types.column.intervals": "Interval",
  "admin.page.step.compensation.types.column.periodic": "Periodic",
  "admin.page.step.compensation.types.column.in.summary": "Show summarized",
  "admin.page.step.compensation.types.column.global.type": "Global type",
  "admin.page.step.compensation.types.column.description": "Description",
  "admin.page.step.compensation.types.add.compensation.type": "Add new compensation type",
  "admin.page.step.compensation.types.type.absence": "Absence",
  "admin.page.step.compensation.types.type.presence": "Presence",
  "admin.page.step.compensation.types.comment.required": "Required",
  "admin.page.step.compensation.types.comment.no": "No",
  "admin.page.step.compensation.types.comment.optional": "Optional",
  "admin.page.step.compensation.types.reporting.type.overlapping": "Overlapping",
  "admin.page.step.compensation.types.reporting.type.full.day": "Full day",
  "admin.page.step.compensation.types.reporting.type.interval": "Interval",
  "admin.page.step.compensation.types.reporting.type.duration": "Duration",
  "admin.page.step.compensation.types.reporting.type.full.month": "Full month",
  "admin.page.step.compensation.types.time.type.vacation": "Vacation",
  "admin.page.step.compensation.types.time.type.overtime": "Overtime",
  "admin.page.step.compensation.types.time.type.off.duty": "Off duty",
  "admin.page.step.compensation.types.time.type.more.time": "More time",
  "admin.page.step.compensation.types.time.type.sickleave": "Sickleave",
  "admin.page.step.compensation.types.time.type.workhours": "Workhours",
  "admin.page.step.compensation.types.time.type.no.deviation": "No deviation",
  "admin.page.step.compensation.types.time.type.care.of.child": "Care of child",
  "admin.page.step.compensation.types.time.type.parental.leave": "Parental leave",
  "admin.page.step.compensation.types.delete.button": "Delete",
  "admin.page.step.employment.categories.add.employment.category": "Add new employment category",
  "admin.page.step.employment.categories.column.category": "Category",
  "admin.page.step.employment.categories.column.modules.config": "Modules config",
  "admin.page.step.employment.categories.column.norm.time": "Norm time",
  "admin.page.step.employment.categories.column.exclude.weekends": "Exclude weekends",
  "admin.page.step.employment.categories.column.reporting.method": "Reporting method",
  "admin.page.step.employment.categories.column.compensation.types": "Compensation Types",
  "admin.page.step.employment.categories.delete.button": "Delete",
  "admin.page.step.employment.users.employmentNumber": "Employment number",
  "admin.page.step.employment.users.column.employmentCategoryName": "Employment category",
  "admin.page.step.employment.users.column.firstName": "First name",
  "admin.page.step.employment.users.column.lastName": "Last name",
  "admin.page.step.employment.users.column.email": "Email",
  "admin.page.step.employment.users.column.role": "Role",
  "admin.page.step.employment.users.column.status": "Status",
  "admin.page.step.employment.users.column.departmentName": "Department",
  "admin.page.step.employment.users.column.employeeStart": "Start date",
  "admin.page.step.employment.projects.column.projectNumber": "Project number",
  "admin.page.step.employment.projects.column.projectName": "Project",
  "admin.page.step.employment.projects.column.billable": "Billable",
  "admin.page.step.employment.projects.column.enabled": "Enabled",
  "admin.page.step.employment.projects.column.activities": "Activities",
  "admin.page.step.employment.projects.add.project": "Add project",
  "admin.page.step.employment.projects.add.activity": "Add activity",
  "admin.error.add.empty.project": "Fill in the Project number field in an empty project",
  "admin.error.remove.compensation.type": "Can not remove compensation type which is already in use",
  "admin.error.update.duplicated.project.number": "Project number is already taken, please select a unique number.",
  "admin.error.remove.used.employment.category": "Can not remove employment category which is already in use",
  "admin.error.remove.used.project": "Projects which have been already reported on can't be deleted. ",
  "admin.error.add.project.validation": "Please make sure you filled all required fields",
  "admin.error.edit.project.number.validation": "Project number can contain only numbers",
  "admin.error.edit.project.number.same.validation": "Project number is already taken",
  "admin.error.remove.activity": "Can not remove activity which is already in use",
  "admin.success.notification.saved": "Saved",
  "admin.error.notification.ops.something.went.wrong": "Oops something went wrong",
  "admin.stepper.page.customer.name": "Customer name",
  "admin.page.stepper.customer.org.number": "Org. number",
  "admin.page.stepper.custom.error.duplicated.notifications": "Oops, seems like you have duplicated notifications",
  "admin.page.stepper.custom.error.duplicated.holidays": "Oops, seems like you have duplicated holidays",
  "admin.page.stepper.custom.error.invalid.org.number": "Invalid organization number. Please use numbers with optional hyphen.",
  "admin.error.org.number.not.found.in.nettlonn": "No company found for provided organization number in Nettlonn",
  "admin.page.stepper.custom.error.duplicated.org.number.within.country": "Customer with this org number within this country already exist",
  "admin.error.portal.admin.invalid.org.number": "Portal Admin: Incorrect organization number format for given country",
  "admin.error.portal.admin.tenant.not.synced": "Portal Admin: Selected tenant is not synced",
  "admin.error.portal.admin.customer.not.synced": "Portal Admin: Selected customer is not synced",
  "admin.error.portal.admin.integration.error": "Portal Admin Integration Error",
  "app.departments.createDepartment": "Create department",
  "app.departments.department": "Department",
  "app.departments.approvers": "Approvers",
  "app.departments.approver": "Approver",
  "app.departments.noApproverAssigned": "No approver assigned",
  "app.departments.employees": "Employees",
  "app.departments.firstName": "First Name",
  "app.departments.lastName": "Last Name",
  "app.departments.phone": "Phone",
  "app.departments.role": "Role",
  "app.departments.email": "Email",
  "app.departments.createUserInsideDepartment": "Create employee inside department",
  "app.departments.addExistingUserDepartment": "Add existing employee to the department",
  "app.departments.removeUserFromDepartment": "Remove user from department",
  "app.departments.removeDepartment": "Are you sure you want to remove this department? Employees won't be able to time report until they have been reassinged to another department.",
  "app.departments.removeDepartmentTitle": "Remove Department",
  "app.departments.remove.user": "Remove user",
  "app.departments.edit.user": "Edit user",
  "app.employees.userModalCreate": "Create employee",
  "app.employees.userModalEdit": "Edit employment details",
  "app.employees.userModalConfirm": "Confirm",
  "app.employees.userModalCancel": "Cancel",
  "app.employees.timeReportingMethod": "Time reporting method",
  "app.employees.userModalGeneral": "General",
  "app.employees.employmentDetails": "Employment details",
  "app.employees.employmentCategory": "Employment category",
  "app.employees.employmentNumber": "Employment number",
  "app.employees.firstName": "First Name",
  "app.employees.lastName": "Last Name",
  "app.employees.phone": "Phone",
  "app.employees.department": "Department",
  "app.employees.role": "Role",
  "app.employees.createUserButton": "Create Employee ",
  "app.employees.email": "Email",
  "app.employees.personalId": "Personal Id",
  "app.employees.bankAccount": "Bank account number",
  "app.employees.contactPerson": "Contact person",
  "app.employees.contactPersonPhone": "Contact person phone",
  "app.employees.authLogin": "Auth login",
  "app.employees.smsLogin": "Sms login",
  "app.employees.address": "Address",
  "app.employees.postalCity": "Postal city",
  "app.employees.postalNumber": "Postal number",
  "app.employees.userTimezone": "Timezone",
  "app.employees.userNationality": "Nationality",
  "app.employees.userLanguage": "Language",
  "app.employees.admin": "Admin",
  "app.employees.manager": "Manager",
  "app.employees.approver": "Approver",
  "app.employees.employee": "Employee",
  "app.employees.superadmin": "Super admin",
  "app.employees.employmentType": "Employment type",
  "app.employees.fullTime": "Full time",
  "app.employees.partTime": "Part time",
  "app.employees.yearSalary": "Year salary",
  "app.employees.employeeStartDate": "Employee start date",
  "app.employees.suspended": "Suspended",
  "app.employees.all": "all",
  "app.employees.noDepartmentAssigned": "No department assigned",
  "app.employees.employeeEndDate": "Employee end date",
  "app.employees.country": "Country",
  "app.employees.city": "City",
  "app.employees.home.address": "Home Address",
  "app.employees.removeEmployeeTitle": "Remove Employee",
  "app.employees.removeEmployeeConfirmationText": "Are you sure you want to remove this employee?",
  "app.employees.cannotRemoveHasTransaction": "Cannot remove the user with transactions",
  "app.employees.errorEmploymentNumber": "Employment number is already used, please select another one",
  "app.employees.emailAddressInUse": "Email address is already in use.",
  "app.employees.create.employee": "Create employee",
  "app.employees.edit.employee": "Edit employee",
  "app.employees.approve.own.timereport": "Approve own timereport",
  "app.employees.access.rights": "Access Rights",
  "app.employees.button.add.new.department.to.approve": "Add new department to approve",
  "app.employees.button.remove.department.to.approve": "Remove department to approve",
  "app.employees.tenant": "Tenant",
  "app.employees.customer": "Customer",
  "app.employees.departments": "Departments",
  "app.employees.subtitle.location": "Location",
  "app.employees.button.save": "Save",
  "app.employees.notitication.success.save": "Saved",
  "app.employees.error.no.deviation": "Can not report No Deviations, transactions already exist for this period",
  "app.employees.error.national.holiday": "Absence can not be reported on national holidays",
  "app.employees.dialog.future.reports.title": "Timereports past enddate",
  "app.employees.dialog.future.reports.description": "has some time reports reported on and/or after the given end date.\n     Please review if they should be kept or removed. User will not be able to report on dates after the selected end date.",
  "app.employees.dialog.future.reports.date": "Reported dates",
  "admin.error.notification.user.isNotActive": "User is not activated yet",
  "app.notification.success.create": "Created",
  "app.notification.success.delete": "Deleted",
  "app.notification.success.update": "Updated",
  "app.notification.success.default": "Success",
  "app.notification.error.default": "Something went wrong",
  "app.notification.error.notNull": "can not be empty",
  "app.notification.error.invalid": "is invalid",
  "app.notification.error.field": "Field",
  "app.notification.period.reminded": "Period reminded",
  "app.notification.periods.reminded": "All periods reminded",
  "app.timeReport.compensationType": "Type",
  "app.timeReport.dateRange": "Date/Range",
  "app.timeReport.amount": "Amount",
  "app.timeReport.transactionStatus": "Status",
  "app.timeReport.transactionStatusRejectBtn": "Reject",
  "app.timeReport.period": "Period",
  "app.timeReport.closed": "Closed",
  "app.timeReport.open": "Open",
  "app.timeReport.closePeriodBtn": "Finalize period",
  "app.timeReport.periodIsClosedBtn": "Period is already closed",
  "app.timeReport.periodIsClosedStatus": "Period is closed",
  "app.timeReport.transactionStatusApproveBtn": "Approve",
  "app.timeReport.periodTakeBackBtn": "REOPEN",
  "app.timeReport.transactionStatusApproved": "APPROVED",
  "app.timeReport.transactionStatusExported": "EXPORTED",
  "app.timeReport.transactionStatusRejected": "REJECTED",
  "app.timeReport.transactionStatusPendingApproval": "PENDING APPROVAL",
  "app.timeReport.transactionStatusArchived": "ARCHIVED",
  "app.timeReport.transactionStatusSubmitted": "SUBMITTED",
  "app.timeReport.createDeviation": "Create deviation",
  "app.timeReport.createPresence": "Presence",
  "app.timeReport.comment": "Comment",
  "app.timeReport.showLess": "Show less",
  "app.timeReport.showMore": "Show more",
  "app.timeReport.rejectMessage": "Rejection message",
  "app.timeReport.deviationsTable": "DEVIATIONS",
  "app.timeReport.details": "DETAILS",
  "app.timeReport.singleDayToggle": "Single day",
  "app.timeReport.periodToggle": "Period",
  "app.timeReport.copyDatesBelow": "Copy to the days below",
  "app.timeReport.formDuration": "Duration",
  "app.timeReport.formStart": "Start",
  "app.timeReport.formEnd": "End",
  "app.timeReport.formDate": "Date",
  "app.timeReport.user": "User",
  "app.timeReport.employees": "Employees",
  "app.timeReport.status": "Status",
  "app.timeReport.absence": "Absence",
  "app.timeReport.presence": "Presence",
  "app.timeReport.employee": "Employee",
  "app.timeReport.employeeNumber": "Employee #",
  "app.timeReport.allDepartments": "All departments",
  "app.timeReport.department": "Department",
  "app.timeReport.all": "All",
  "app.timeReport.notReported": "Not reported",
  "app.timeReport.waitingForApproval": "Waiting for approval",
  "app.timeReport.approved": "Approved",
  "app.timeReport.exported": "Exported",
  "app.timeReport.timeReportsTitle": "Time reports",
  "app.timeReport.description": "Description",
  "app.timeReport.project": "Project",
  "app.timeReport.projectCompensationType": "Time type",
  "app.timeReport.dimensionNumber": "Project #",
  "app.timeReport.workType": "Activity",
  "app.timeReport.projectHours": "Project Hours",
  "app.timeReport.active": "Active",
  "app.timeReport.archived": "Archived",
  "app.timeReport.reportingMoreThan24H": "Maximum number of hours is 24 hours on a day",
  "app.timeReport.overlappedAbsence": "You can only report absence which is less than or equal to the hours in a working day",
  "app.timeReport.reportingOnWeekendIsNotAllowed": "Not possible to report absence on these days. Please select another day",
  "app.time.report.transactions.reports.subtitle": "Time reports - Excel",
  "app.time.report.transactions.report.by.deviation.menu.item": "By Deviation",
  "app.time.report.transactions.report.by.project.menu.item": "By Project Summary",
  "app.time.report.transactions.report.by.detailed.menu.item": "By Project Detailed",
  "app.time.report.transactions.error.no.transactions": "No transactions found for this period",
  "app.time.report.transactions.error.no.departments": "No departments found for this customer",
  "app.time.report.transactions.removeTransactionModal.title": "Are you sure you want to remove this deviation?",
  "app.time.report.transactions.removeTransactionModal.body": "Submitted transactions will be removed. Any previously exported parts of the deviations will be kept.",
  "app.timeReport.nextEmployeeBtn": "NEXT",
  "app.timeReport.prevEmployeeBtn": "PREVIOUS",
  "app.timeReport.periodStatusOpen": "OPEN",
  "app.timeReport.periodStatusApproved": "APPROVED",
  "app.timeReport.periodStatusRejected": "REJECTED",
  "app.timeReport.periodStatusSubmitted": "SUBMITTED",
  "app.timeReport.reviewedBy": "Reviewed by",
  "app.timeReport.approvedBy": "Approved by",
  "app.timeReport.rejectedBy": "Rejected by",
  "app.timeReport.periodTypeDeviation": "Deviations",
  "app.timeReport.periodTypeDimension": "Working hours",
  "app.timeReport.expectedHours": "Estimated hours",
  "app.timeReport.periodRemindAllBtn": "Remind",
  "app.timeReport.deviationOnWeekend": "Deviation you are trying to create overlaps with weekend day",
  "app.timeReport.deviationOnHoliday": "Deviation you are trying to create overlaps with holiday",
  "app.timeReport.departmentNotFound": "You can't create deviation for a user without department. Please go to 'Employees page' and assign department",
  "app.timeReport.deviationOverlapsWithExisting": "Deviation you are trying to create overlaps with existing",
  "app.timeReport.dimensionNotActive": "Project is disabled",
  "app.timeReport.workTypeDisabled": "Activity is disabled",
  "admin.error.edit.project.empty.project": "Project number is already taken",
  "admin.page.step.general.label.notification.when": "When",
  "admin.page.step.general.notification.when.before.deadline": "Before deadline",
  "admin.page.step.general.notification.when.after.deadline": "After deadline",
  "admin.page.step.general.notification.when.on.deadline": "On deadline",
  "admin.page.step.general.label.notification.period": "Period",
  "admin.page.step.general.notification.period.day": "day",
  "admin.page.step.general.notification.period.days": "days",
  "admin.page.step.employment.categories.column.project.reporting": "Project reporting",
  "admin.page.step.employment.categories.column.work.day": "Work day",
  "admin.page.step.projects.add.project": "Add project",
  "app.time.report.transations.report.subtitle": "Department transactions report",
  "app.time.report.transation.report.download.button": "Download Report",
  "app.time.report.transations.error.no.transactions": "No transactions found for this period",
  "app.time.report.transations.error.no.departments": "No departments found for this customer",
  "app.time.report.transations.removeTransactionModal.title": "Are you sure you want to remove this deviation?",
  "app.time.report.transations.removeTransactionModal.body": "Submitted transactions will be removed. Any previously exported parts of the deviations will be kept."
}
