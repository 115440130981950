import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const downloadExcel = ({ content, headers, filename, sheetName }) => {
  const wb = new ExcelJS.Workbook()
  const ws = wb.addWorksheet(sheetName)

  const dateIndexes = [];
  if(content.length) {
    const firstRow = content[0];
    for (const prop in firstRow) {
      if (firstRow[prop] && firstRow[prop].toString().endsWith(':00')) {
        const dIndex = Object.keys(firstRow).indexOf(prop);
        if (!dateIndexes.includes(dIndex)) {
          dateIndexes.push(dIndex);
        }
      }
    }
  }

  const formattedContent = [];

  content.forEach((row) => {
    dateIndexes.forEach((dIndex) => {
      const propName = Object.keys(row)[dIndex];
      const timeStr = row[propName];
      const [hours, minutes] = timeStr.split(':');

      row[propName] = new Date(Date.UTC(1899, 11, 30, hours, minutes, 0, 0))
    })

    formattedContent.push(Object.values(row));
  });

  ws.addRows([headers])
  ws.addRows(formattedContent)

  dateIndexes.forEach((tIndex) => {
    const columnTime = ws.getColumn(tIndex + 1);
    columnTime.eachCell((cell) => cell.numFmt = '[H]:mm');
  })

  wb.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'text/xlsx' });
    saveAs(blob, filename);
  });
};
