import { graphql } from 'react-apollo';
import { GET_EMPLOYEES } from '../../../../queries/users';
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/table";

export const DEFAULT_ORDER = ['employmentNumber', 'asc'];

export default graphql(GET_EMPLOYEES, {
  options({ roleConfigs, defaultCustomer }) {
    return {
      variables: {
        customerId: defaultCustomer,
        state: 'ACTIVE',
        order: DEFAULT_ORDER.join(' '),
        limit: TABLE_ROWS_PER_PAGE,
        offset: 0,
        withApprovers: true,
        roles: roleConfigs.manageRoles,
      },
    }
  },
  props({
    data: {
      fetchMore,
      variables: { customerId },
      variables,
      usersV2: {
        users: employees,
        count,
      } = { customers: [], count: 0 },
      loading,
    },
    ownProps: { roleConfigs }
  }) {
    return {
      count,
      employees,
      variables,
      loading,
      onFiltersChange: ({ limit, offset, order, state, search }) => fetchMore({
        variables: {
          customerId,
          state,
          limit,
          offset,
          order,
          search,
          roles: roleConfigs.manageRoles
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;
          return ({
            ...previousResult,
            usersV2: fetchMoreResult.usersV2,
          });
        },
      }),
    }
  },
});
