import React, { useEffect } from 'react';
import { pathOr, compose, filter, propEq, map, concat, fromPairs } from 'ramda';
import { Tabs, Tab, Paper, Grid } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../components/LoadingCircle';
import Table from '../../../../components/Table/Table';
import { GET_TIME_REPORTS_OVERVIEW } from '../../../../queries/time-report';
import {
  Approved as ApprovedIcon,
  NotReported as NotReportedIcon,
  Waiting as WaitingIcon,
  Rejected as RejectedIcon,
} from '../../../../icons';
import ptr from '../../../../config/intlMessageSelectors/payroll-admin-timeReport';
import { capitilize } from '../../../../utils/string';
import { withStyles } from "@material-ui/core/styles";
import RemindButton from "../../../../components/TimeReport/RemindButton";
import { PERIOD_STATUSES } from "../../../../utils/timeReports";
import {
  TABLE_ROWS_OPTION,
  TABLE_ROWS_PER_PAGE
} from "../../../../utils/table";

const tabs = [
  { label: ptr['app.timeReport.all'], value: 'ALL' },
  { label: ptr['app.timeReport.notReported'], value: 'OPEN' },
  { label: ptr['app.timeReport.approved'], value: 'APPROVED' },
  { label: ptr['app.timeReport.waitingForApproval'], value: 'SUBMITTED' },
];

const DEFAULT_HEADERS = [
  {
    id: 'employmentNumber',
    label: ptr['app.timeReport.employeeNumber'],
    tableCellProps: { style: { width: 100, minWidth: 100, maxWidth: 100, paddingRight: 0 } },
  },
  {
    id: 'fullName',
    label: ptr['app.timeReport.employee'],
    tableCellProps: { style: { minWidth: '237px' } },
  },
  {
    id: 'status',
    label: ptr['app.timeReport.status'],
    tableCellProps: { style: { minWidth: '172px' } },
  },
  {
    id: 'reviewedBy',
    label: ptr['app.timeReport.reviewedBy'],
    tableCellProps: { style: { minWidth: '160px' } },
  },
];

const Mapper = {
  APPROVED: {
    icon: ApprovedIcon,
    label: ptr['app.timeReport.approved']
  },
  OPEN: {
    icon: NotReportedIcon,
    label: ptr['app.timeReport.open']
  },
  SUBMITTED: {
    icon: WaitingIcon,
    label: ptr['app.timeReport.waitingForApproval']
  },
  REJECTED: {
    icon: RejectedIcon,
    label: ptr['app.timeReport.transactionStatusRejected']
  },
};

const Default = () => <div>default</div>;

const Status = ({ row: { status, id, selectedPeriod, reminded } }) => {
  const { formatMessage: f } = useIntl();
  const Icon = Mapper[status].icon || Default;
  const label = Mapper[status].label || '';

  return (
    <React.Fragment>
      {[
        PERIOD_STATUSES.OPEN,
        PERIOD_STATUSES.REJECTED,
      ].includes(status) && (
        <>
          <span style={{ marginTop: -4, marginLeft: -50 }}>
            <RemindButton
              employeeId={id}
              employeePeriodId={selectedPeriod}
              disabled={reminded}
            />
          </span>
        </>
      )}
      <Icon style={{ position: 'relative', top: 8, marginRight: 8 }} />
      <span>
        {capitilize(f(label))}
      </span>
    </React.Fragment>
  );
};

const DEFAULT_MAPPER = [
  { id: 'employmentNumber' },
  { id: 'fullName' },
  { id: 'status', component: Status },
  { id: 'reviewedBy'},
];

const DEFAULT_ORDER = ['fullName', 'asc'];

const OverviewTable = ({ selectedCustomer, selectedPeriod, selectedDepartment, classes }) => {
  const { formatMessage: f } = useIntl();
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState('ALL');
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [limit, setLimit] = React.useState(TABLE_ROWS_PER_PAGE);
  const [rows, setRows] = React.useState([]);
  const [refetchLoading, setRefetchLoading] = React.useState(false);

  const { data, loading, error, refetch } = useQuery(GET_TIME_REPORTS_OVERVIEW, {
    variables: {
      input: {
        customerId: selectedCustomer,
        ...selectedPeriod ? { periodId: selectedPeriod } : null,
        ...activeTab !== 'ALL' ? { status: activeTab } : null,
        ...selectedDepartment ? { departmentIds: [selectedDepartment] } : null,
      },
      options: {
        limit,
        offset: limit * page,
        order: order.join(' '),
      },
      types: { filters: { customerId: selectedCustomer } },
    },
    fetchPolicy: "no-cache",
  });

  const count = pathOr(null, ['timeReportOverview', 'count'], data);
  const totals = pathOr([], ['timeReportOverview', 'totals'], data);

  const types = compose(
    filter(propEq('displayInSummary', true)),
    pathOr([], ['compensationTypes']),
  )(data);

  const toMap = compose(
    fromPairs,
    map(({ name }) => [name, 0]),
  )(types);

  useEffect(() => {
    const rows = compose(
      map(({ compensationTypesTotals = {}, ...r }) => ({
        ...r,
        ...toMap,
        ...compose(
          fromPairs,
          map(({ name, total }) => [name, total]),
        )(compensationTypesTotals),
        selectedPeriod,
      })),
      pathOr([], ['timeReportOverview', 'rows']),
    )(data);

    if (error) {
      setRows([]);
    } else {
      setRows(rows);
    }
  }, [data]);

  const headers = compose(
    concat(DEFAULT_HEADERS),
    map(({ name }) => ({
      id: name,
      label: name,
      translate: false,
      tableCellProps: { style: { width: 72, overflow: 'auto', whiteSpace: 'nowrap', padding: '4px 12px' } },
    })),
  )(types);

  const mapper = compose(
    concat(DEFAULT_MAPPER),
    map(({ name }) => ({ id: name, noSplit: true })),
  )(types);

  const handleRowClick = ({ id }) => history.push([
    '/time-report-details/',
    pathOr(null, ['timeReportOverview', 'periodId'], data),
    '/',
    id,
  ].join(''));

  const refetchRows = async () => {
    setRefetchLoading(true);
    await refetch();
    setRefetchLoading(false);
  }

  return (
    <React.Fragment>
      <Grid container spacing={32}>
        {totals.map(({ name, total }) => (
          <Grid item key={name} style={{ margin: '4px 0 12px 0' }}>
            {name}: {total}
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={16} justify="space-between" alignItems="center">
        <Grid item>
          <Tabs
            indicatorColor="primary"
            value={activeTab}
            onChange={(_, active) => setActiveTab(active)}
          >
            {tabs.map(({ label, value}) => (
              <Tab
                key={value}
                label={f(label)}
                value={value}
                style={{
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 400,
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item>
          <RemindButton
            employeeId={selectedCustomer}
            employeePeriodId={selectedPeriod}
            departmentId={selectedDepartment}
            isRemindAll={true}
            refetch={refetchRows}
          />
        </Grid>
      </Grid>

      <br />

      <Paper style={{ width: '100%', boxShadow: 'none', border: '1px solid #DADADA' }}>
        {loading || refetchLoading || !rows
          ? <Loader />
          : (
            <div style={{ overflow: 'auto' }} className={classes.wrapper}>
              <Table
                pagination
                count={count || 0}
                headers={headers}
                rows={rows}
                mapper={mapper}
                page={page}
                rowsPerPage={limit}
                order={order[1]}
                orderBy={order[0]}
                rowsPerPageOptions={TABLE_ROWS_OPTION}
                onChangePage={setPage}
                onChangeRowsPerPage={setLimit}
                onRequestSort={setOrder}
                onRowClick={handleRowClick}
              />
            </div>
          )}
      </Paper>
    </React.Fragment>
  );
};

export default withStyles(() => ({
  wrapper: {
    '& table tbody:hover': {
      cursor: 'pointer'
    }
  }
}))(React.memo(
  OverviewTable,
  (p, n) => p.selectedCustomer === n.selectedCustomer
    && p.selectedPeriod === n.selectedPeriod
    && p.selectedDepartment === n.selectedDepartment,
));
