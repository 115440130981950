export default {
  "app.employees.userModalCreate": "Opret medarbejder",
  "app.employees.userModalEdit": "Rediger medarbejder detaljer",
  "app.employees.userModalConfirm": "Godkend",
  "app.employees.userModalCancel": "Annuller",
  "app.employees.timeReportingMethod": "Tidsrapporteringmetode",
  "app.employees.userModalGeneral": "Generelt",
  "app.employees.employmentDetails": "Medarbejder detaljer",
  "app.employees.employmentCategory": "Medarbejder kategori",
  "app.employees.employmentNumber": "Medarbejder nummer",
  "app.employees.firstName": "Fornavn",
  "app.employees.lastName": "Efternavn",
  "app.employees.phone": "Telefon",
  "app.employees.department": "Afdeling",
  "app.employees.role": "Rolle",
  "app.employees.createUserButton": "Opret medarbejder",
  "app.employees.email": "E-mail",
  "app.employees.personalId": "Personligt Id",
  "app.employees.bankAccount": "Bankkontonummer",
  "app.employees.contactPerson": "Kontaktperson",
  "app.employees.contactPersonPhone": "Kontaktperson telefon",
  "app.employees.authLogin": "Autoriseret log ind",
  "app.employees.smsLogin": "Sms log ind",
  "app.employees.address": "Adresse",
  "app.employees.postalCity": "By",
  "app.employees.postalNumber": "Postnr.",
  "app.employees.userTimezone": "Tidszone",
  "app.employees.userNationality": "Nationalitet",
  "app.employees.userLanguage": "Sprog",
  "app.employees.admin": "Administrator",
  "app.employees.manager": "Leder",
  "app.employees.approver": "Godkender",
  "app.employees.employee": "Medarbejder",
  "app.employees.superadmin": "Super administrator",
  "app.employees.employmentType": "Medarbejdertype",
  "app.employees.fullTime": "Fuldtid",
  "app.employees.partTime": "Deltid",
  "app.employees.yearSalary": "Årsløn",
  "app.employees.employeeStartDate": "Medarbejder startdato",
  "app.employees.suspended": "Suspenderet",
  "app.employees.all": "Alle",
  "app.employees.noDepartmentAssigned": "Afdeling mangler",
  "app.employees.employeeEndDate": "Medarbejder slutdato",
  "app.employees.country": "Land",
  "app.employees.city": "By",
  "app.employees.home.address": "Hjemmeadresse",
  "app.employees.removeEmployeeTitle": "Fjern medarbejder",
  "app.employees.removeEmployeeConfirmationText": "Er du sikker på at du vil fjerne denne medarbejder?",
  "app.employees.cannotRemoveHasTransaction": "Kan ikke fjerne en medarbejder/bruger der er transaktioner på",
  "app.employees.errorEmploymentNumber": "Medarbejdernummer er allerede brugt, vælg et andet nummer",
  "app.employees.emailAddressInUse": "Email-adresse bruges allerede",
  "app.employees.create.employee": "Opret medarbejder",
  "app.employees.edit.employee": "Rediger medarbejder",
  "app.employees.approve.own.timereport": "Godkend egen tidsrapport",
  "app.employees.access.rights": "Adgangsrettigheder",
  "app.employees.button.add.new.department.to.approve": "Tilføj ny afdeling til godkendelse",
  "app.employees.button.remove.department.to.approve": "Fjern afdeling der skal til godkendelse",
  "app.employees.tenant": "Lejer",
  "app.employees.customer": "Kunde",
  "app.employees.departments": "Afdelinger",
  "app.employees.subtitle.location": "Sted",
  "app.employees.button.save": "Gemme",
  "app.employees.notitication.success.save": "Gemt",
  "app.employees.error.no.deviation": "Kan ikke rapportere - Ingen afvigelser -, der findes allerede transaktioner i den givne periode\n",
  "app.employees.error.national.holiday": "Fravær kan ikke rapporteres på helligdage",
  "app.employees.tableHeader.firstName": "Fornavn",
  "app.employees.suspend": "Deaktiver"
}
