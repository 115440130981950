export default {
  "app.employees.userModalCreate": "Create employee",
  "app.employees.userModalEdit": "Edit employment details",
  "app.employees.userModalConfirm": "Confirm",
  "app.employees.userModalCancel": "Cancel",
  "app.employees.timeReportingMethod": "Time reporting method",
  "app.employees.userModalGeneral": "General",
  "app.employees.employmentDetails": "Employment details",
  "app.employees.employmentCategory": "Employment category",
  "app.employees.employmentNumber": "Employment number",
  "app.employees.firstName": "First Name",
  "app.employees.lastName": "Last Name",
  "app.employees.phone": "Phone",
  "app.employees.department": "Department",
  "app.employees.role": "Role",
  "app.employees.createUserButton": "Create Employee",
  "app.employees.email": "Email",
  "app.employees.personalId": "Personal Id",
  "app.employees.bankAccount": "Bank account number",
  "app.employees.contactPerson": "Contact person",
  "app.employees.contactPersonPhone": "Contact person phone",
  "app.employees.authLogin": "Auth login",
  "app.employees.smsLogin": "Sms login",
  "app.employees.address": "Address",
  "app.employees.postalCity": "Postal city",
  "app.employees.postalNumber": "Postal number",
  "app.employees.userTimezone": "Timezone",
  "app.employees.userNationality": "Nationality",
  "app.employees.userLanguage": "Language",
  "app.employees.admin": "Admin",
  "app.employees.manager": "Manager",
  "app.employees.approver": "Approver",
  "app.employees.employee": "Employee",
  "app.employees.superadmin": "Super admin",
  "app.employees.employmentType": "Employment type",
  "app.employees.fullTime": "Full time",
  "app.employees.partTime": "Part time",
  "app.employees.yearSalary": "Year salary",
  "app.employees.employeeStartDate": "Employee start date",
  "app.employees.suspended": "Suspended",
  "app.employees.all": "all",
  "app.employees.noDepartmentAssigned": "No department assigned",
  "app.employees.employeeEndDate": "Employee end date",
  "app.employees.country": "Country",
  "app.employees.city": "City",
  "app.employees.home.address": "Home Address",
  "app.employees.removeEmployeeTitle": "Remove Employee",
  "app.employees.removeEmployeeConfirmationText": "Are you sure you want to remove this employee?",
  "app.employees.cannotRemoveHasTransaction": "Cannot remove the user with transactions",
  "app.employees.errorEmploymentNumber": "Employment number is already used, please select another one",
  "app.employees.emailAddressInUse": "Email address is already in use.",
  "app.employees.create.employee": "Create employee",
  "app.employees.edit.employee": "Edit employee",
  "app.employees.approve.own.timereport": "Approve own timereport",
  "app.employees.access.rights": "Access Rights",
  "app.employees.button.add.new.department.to.approve": "Add new department to approve",
  "app.employees.button.remove.department.to.approve": "Remove department to approve",
  "app.employees.tenant": "Tenant",
  "app.employees.customer": "Customer",
  "app.employees.departments": "Departments",
  "app.employees.subtitle.location": "Location",
  "app.employees.button.save": "Save",
  "app.employees.notitication.success.save": "Saved",
  "app.employees.error.no.deviation": "Can not report No Deviations, transactions already exist for this period",
  "app.employees.error.national.holiday": "Absence can not be reported on national holidays",
  "app.employees.tableHeader.firstName": "First Name",
  "app.employees.suspend": "Suspend"
}
