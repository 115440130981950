import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  pathOr,
  compose,
  intersection,
} from 'ramda';

import { RULES } from '../config/role-configs';

// return permitted rules
const check = ({ rules, role, entity }) => compose(
  intersection(rules),
  pathOr([], [role, entity]),
)(RULES);

const Can = props => {
  // takes dynamic functions with the same names as rules
  // if rule satisfy for this role
  // dynamic functions will be invoked
  const { rules, role, entity } = props;

  return (
    <Fragment>
      {check({ rules, role, entity })
        .map(rule => (
          <Fragment key={rule}>
            {typeof props[rule] === 'function' && props[rule]()}
          </Fragment>
        ))}
    </Fragment>
  );
};

Can.defaultProps = {
  rules: [],
  role: '',
  entity: '',
};

Can.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.string),
  role: PropTypes.string,
  entity: PropTypes.string,
};

export default Can;
