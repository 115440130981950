export default {
  "admin.page.title": "Kundadministration",
  "admin.tabs.all": "Alla",
  "admin.tabs.inactive": "Inaktiva",
  "admin.page.add.button": "Lägg till kund",
  "admin.page.back.button": "Lista Kunder",
  "admin.page.edit.button": "Ändra",
  "admin.page.table.column.org.number": "Org nummer",
  "admin.page.table.column.name": "Namn",
  "admin.page.table.column.payroll.system": "Lönesystem",
  "admin.page.table.column.tenant": "Tenant",
  "admin.page.table.column.employees": "Anställda",
  "admin.page.table.column.status": "Status",
  "admin.page.table.column.empty": "Odefinierad",
  "admin.page.table.customer.status.active": "Aktiv",
  "admin.page.table.customer.status.inactive": "Inaktiv",
  "admin.page.table.customer.status.incomplete": "Ofullständig",
  "admin.page.general.step.title": "Allmän",
  "admin.page.compensation.types.step.title": "Kompensationstyper",
  "admin.page.employment.categories.step.title": "Anställningskategorier",
  "admin.page.users.import.step.title": "Användarimport",
  "admin.page.dimensions.worktypes.step.title": "Dimensioner och arbetstyper",
  "admin.page.step.next.button": "Nästa",
  "admin.page.step.back.button": "Tillbaka",
  "admin.page.step.finish.button": "Slutför",
  "admin.page.step.save.button": "Spara",
  "admin.page.step.general.copy.button": "Kopiera inställningar från befintlig kund",
  "admin.page.step.general.customer.name": "Kundnamn",
  "admin.page.step.general.org.number": "Org nummer",
  "admin.page.step.general.payroll.system": "Lönesystem",
  "admin.page.step.general.payroll.tenant": "Tenant",
  "admin.page.step.general.payroll.is.active": "Aktiv",
  "admin.page.step.general.inactive.date": "Inaktiveringsdatum",
  "admin.page.step.general.period.reports": "Perioder",
  "admin.page.step.general.label.monthly": "Månadsvis",
  "admin.page.step.general.label.bi-weekly": "Varannan vecka",
  "admin.page.step.general.label.weekly": "Veckovis",
  "admin.page.step.general.label.start.from": "Börja från",
  "admin.page.step.general.generated.periods": "Genererade perioder",
  "admin.page.step.general.notifications": "Påminnelser",
  "admin.page.step.general.add.notification.button": "Ny påminnelse",
  "admin.page.step.general.notification.type.employee": "Anställd",
  "admin.page.step.general.notification.type.manager": "Attestor",
  "admin.page.step.general.notification.label.type": "Typ",
  "admin.page.step.general.label.notification.deadline": "Deadline",
  "admin.page.step.general.label.notification.time": "Tid",
  "admin.page.step.general.label.notification.repeatable": "Repeterbar",
  "admin.page.step.general.label.notification.repeatable.tooltip": "Repeterbar PÅ innebär att notifieringar kommer att upprepas varje månad vid samma datum och tid. Repeterbar AV innebär att notifieringar endast skickas ut vid specifierat datum.",
  "admin.page.step.general.holidays": "Helgdagar",
  "admin.page.step.general.add.holiday.button": "Lägg till helgdag",
  "admin.page.step.general.label.holidays.day": "Dag",
  "admin.page.step.compensation.types.column.code": "Löneart",
  "admin.page.step.compensation.types.column.name": "Namn",
  "admin.page.step.compensation.types.column.type": "Typ",
  "admin.page.step.compensation.types.column.comment": "Kommentar",
  "admin.page.step.compensation.types.column.reporting.type": "Rapporteringstyp",
  "admin.page.step.compensation.types.column.intervals": "Intervall",
  "admin.page.step.compensation.types.column.periodic": "Flerdagars",
  "admin.page.step.compensation.types.column.in.summary": "Visa summerat",
  "admin.page.step.compensation.types.column.global.type": "Global typ",
  "admin.page.step.compensation.types.column.description": "Beskrivning",
  "admin.page.step.compensation.types.add.compensation.type": "Lägg till kompensationstyp",
  "admin.page.step.compensation.types.type.absence": "Frånvaro",
  "admin.page.step.compensation.types.type.presence": "Närvaro",
  "admin.page.step.compensation.types.comment.required": "Obligatorisk",
  "admin.page.step.compensation.types.comment.no": "Ingen kommentar",
  "admin.page.step.compensation.types.comment.optional": "Valfri",
  "admin.page.step.compensation.types.reporting.type.overlapping": "Överlappande",
  "admin.page.step.compensation.types.reporting.type.full.day": "Heldag",
  "admin.page.step.compensation.types.reporting.type.interval": "Intervall",
  "admin.page.step.compensation.types.reporting.type.duration": "Normtid",
  "admin.page.step.compensation.types.reporting.type.full.month": "Hel månad",
  "admin.page.step.compensation.types.time.type.vacation": "Semester",
  "admin.page.step.compensation.types.time.type.overtime": "Övertid",
  "admin.page.step.compensation.types.time.type.off.duty": "Inte i tjänst",
  "admin.page.step.compensation.types.time.type.more.time": "Mertid",
  "admin.page.step.compensation.types.time.type.sickleave": "Sjukfrånvaro",
  "admin.page.step.compensation.types.time.type.workhours": "Arbetstimmar",
  "admin.page.step.compensation.types.time.type.no.deviation": "Inga avvikelser",
  "admin.page.step.compensation.types.time.type.care.of.child": "Vård av barn",
  "admin.page.step.compensation.types.time.type.parental.leave": "Föräldraledigt",
  "admin.page.step.compensation.types.delete.button": "Ta bort",
  "admin.page.step.employment.categories.add.employment.category": "Lägg till anställningskategori",
  "admin.page.step.employment.categories.column.category": "Kategori",
  "admin.page.step.employment.categories.column.project.reporting": "Projektrapportering",
  "admin.page.step.employment.categories.column.norm.time": "Normtid",
  "admin.page.step.employment.categories.column.exclude.weekends": "Exludera helger",
  "admin.page.step.employment.categories.column.reporting.method": "Rapporteringsmetod",
  "admin.page.step.employment.categories.column.compensation.types": "Kompensationstyper",
  "admin.page.step.employment.categories.delete.button": "Ta bort",
  "admin.error.remove.compensation.type": "Kompensationstyp används och kan ej tas bort",
  "admin.error.remove.used.employment.category": "Anställningskategori används och kan ej tas bort",
  "admin.success.notification.saved": "Sparad",
  "admin.error.notification.ops.something.went.wrong": "Något gick fel",
  "admin.stepper.page.customer.name": "Kundnamn",
  "admin.page.stepper.customer.org.number": "Organisationsnummer",
  "admin.page.stepper.custom.error.duplicated.notifications": "Du har dubbla notifieringar",
  "admin.page.stepper.custom.error.duplicated.holidays": "Oups, Det verkar som att du har dubbelregistrerad semester.",
  "admin.page.step.general.label.notification.when": "När",
  "admin.page.step.general.notification.when.before.deadline": "Före deadline",
  "admin.page.step.general.notification.when.after.deadline": "Efter deadline",
  "admin.page.step.general.notification.when.on.deadline": "På deadline",
  "admin.page.step.general.label.notification.period": "Period",
  "admin.page.step.general.notification.period.day": "dag",
  "admin.page.step.general.notification.period.days": "dagar",
  "admin.page.step.employment.categories.column.work.day": "Arbetsdag",
  "admin.success.notification.save": "Spara"
}
