import types from './types';

export function setAuth(payload) {
  return {
    type: types.SET_AUTH,
    payload,
  };
}

export function clearAuth() {
  return { type: types.CLEAR_AUTH };
}
