export default {
  "app.departments.createDepartment": "Opprett avdeling",
  "app.departments.department": "Avdeling",
  "app.departments.approvers": "Timegodkjennere",
  "app.departments.approver": "Godkjenner",
  "app.departments.noApproverAssigned": "Ingen godkjenner registrert",
  "app.departments.employees": "Ansatte",
  "app.departments.firstName": "Fornavn",
  "app.departments.lastName": "Etternavn",
  "app.departments.phone": "Telefon",
  "app.departments.role": "Rolle",
  "app.departments.email": "E-post",
  "app.departments.createUserInsideDepartment": "Opprett ansatt i avdeling",
  "app.departments.addExistingUserDepartment": "Legg til eksisterende ansatt i avdelingen",
  "app.departments.removeUserFromDepartment": "Fjern bruker fra avdelingen",
  "app.departments.removeDepartment": "Er du sikker på at du vil slette denne avdelingen? Ansatte som hører til avdelingen vil ikke kunne rapportere timer eller fravær før de knyttes til en annen avdeling.",
  "app.departments.removeDepartmentTitle": "Fjern avdeling",
  "app.departments.remove.user": "Fjern bruker",
  "app.departments.edit.user": "Endre bruker"
}
