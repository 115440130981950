import React from 'react';
import {
  ifElse,
  length,
  __,
  includes,
  slice,
  compose,
  trim,
  concat,
  identity,
  gt,
} from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Divider, Checkbox, Typography } from '@material-ui/core';

const withDots = compose(concat(__, '...'), trim, slice(0, 20));
const labelWithDots = ifElse(compose(gt(__, 17), length), withDots, identity);

const Option = React.memo(
  ({ value, label, selected, disabled, onCheck, classes }) => (
    <Grid key={value} item container alignItems="center">
      <Grid item>
        <Checkbox
          classes={{ root: classes.checkbox }}
          checked={includes(value, selected)}
          onChange={({ target: { checked } }) => onCheck({ checked, value })}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <Typography>{labelWithDots(label)}</Typography>
      </Grid>
    </Grid>
  ),
  (p, n) => includes(p.value, p.selected) === includes(n.value, n.selected),
);

const OptionWithStyle = withStyles(() => ({ checkbox: { width: 36, height: 36 } }))(Option);

const Options = ({ options, selected, onCheckOne, classes }) => (
  <Grid item xs={12}>
    <Divider variant="fullWidth" />
    <Grid container classes={{ container: classes.container }}>
      {options.map((part, i) => (
        <Grid key={i} item xs={12 / options.length}>
          {part.map(({ value, label, disabled }) => (
            <OptionWithStyle
              key={value}
              value={value}
              label={label}
              disabled={disabled}
              selected={selected}
              onCheck={onCheckOne}
            />
          ))}
        </Grid>
      ))}
    </Grid>
    <Divider variant="fullWidth" />
  </Grid>
);

export default withStyles(() => ({ container: { padding: '10px 16px' } }))(Options);
