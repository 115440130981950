import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  paper: {
    overflowY: 'auto',
    maxHeight: 'calc(100% - 300px)',
  },
  paperVisibleOverflow: { overflowY: 'visible' },
  contentRoot1: {
    overflowY: 'initial',
    padding: '10px 24px 24px',
  },
  contentRoot2: {
    overflowY: 'auto',
    padding: '10px 24px 24px',
  },
});

const DialogComponent = ({
  classes,
  onClose,
  errorMessage,
  showError,
  overflowReactSelect,
  selectedValue,
  closeOnCross,
  ...other
}) => (
  <Dialog
    classes={{
      paper: overflowReactSelect ? classes.paperVisibleOverflow : classes.paper,
      container: classes.container,
    }}
    onClose={() => onClose()}
    aria-labelledby="simple-dialog-title"
    {...other}
  >
    {closeOnCross && (
      <CloseIcon
        style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '18px' }}
        onClick={onClose}
      />
    )}
    {(other.title || showError) && (
      <DialogTitle id="simple-dialog-title">
        {other.title || ''}
        {' '}
        {showError && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </DialogTitle>
    )}
    <DialogContent
      classes={{
        root: overflowReactSelect
          ? classes.contentRoot1
          : classes.contentRoot2,
      }}
    >
      {other.children}
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(DialogComponent);
