import React, { useState } from 'react';
import { compose } from "ramda";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useMutation } from "react-apollo";
import TextField from "@material-ui/core/TextField";
import { Button, DialogActions, Typography } from "@material-ui/core";

import SimpleDialog from "../Dialog";
import ptr from "../../config/intlMessageSelectors/payroll-admin-timeReport";
import pab from "../../config/intlMessageSelectors/payroll-admin-base";
import pan from "../../config/intlMessageSelectors/payroll-admin-notifications";
import {
 PERIOD_APPROVE, PERIOD_REJECT, PERIOD_SUBMIT, PERIOD_TAKE_BACK
} from "../../queries/transactions";
import { PERIOD_STATUSES } from "../../utils/timeReports";
import { showNotification } from "../../actions/notifications";
import { NOTIFICATION } from "../NotificationSnackbar";
import { useLoggedUser } from "../../providers/IdentityProvider";
import { ROLES } from "../../config/role-configs";
import { getErrorBackendMessage } from "../../utils/error";

const { OPEN, APPROVED, REJECTED, SUBMITTED } = PERIOD_STATUSES;

const PeriodActions = ({
  users,
  isShowComponent,
  classes,
  period: { status },
  showNotification,
  refetch,
}) => {
  const { userId, month } = useParams();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const { role } = useLoggedUser();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitModal, setIsSubmitModal] = useState(false);
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [rejectComment, setRejectComment] = useState('');

  const [periodTakeBack] = useMutation(PERIOD_TAKE_BACK);
  const [periodApprove] = useMutation(PERIOD_APPROVE);
  const [periodSubmit] = useMutation(PERIOD_SUBMIT);
  const [periodReject] = useMutation(PERIOD_REJECT);

  const onPeriodTakeBack = async () => {
    try {
      setIsLoading(true);
      await periodTakeBack({
        variables: {
          employeeId: +userId,
          employeePeriodId: +month
        },
      });
      await refetch();
    } catch (e) {
      const message = getErrorBackendMessage(e);
      showNotification({
        [Date.now()]: {
          message: message || f(pan['app.notification.error.default']),
          type: NOTIFICATION.ERROR,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onPeriodReject = async () => {
    try {
      setIsLoading(true);
      await periodReject({
        variables: {
          employeeId: +userId,
          employeePeriodId: +month,
          comment: rejectComment
        },
      });
      await refetch();
      setRejectComment('');
      setIsRejectModal(false);
    } catch (e) {
      const message = getErrorBackendMessage(e);
      showNotification({
        [Date.now()]: {
          message: message || f(pan['app.notification.error.default']),
          type: NOTIFICATION.ERROR,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onPeriodApprove = async () => {
    try {
      setIsLoading(true);
      await periodApprove({
        variables: {
          employeeId: +userId,
          employeePeriodId: +month
        },
      });
      await refetch();
    } catch (e) {
      const message = getErrorBackendMessage(e);
      showNotification({
        [Date.now()]: {
          message: message || f(pan['app.notification.error.default']),
          type: NOTIFICATION.ERROR,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onPeriodSubmit = async () => {
    try {
      setIsLoading(true);
      await periodSubmit({
        variables: {
          employeeId: +userId,
          employeePeriodId: +month
        },
      });
      await refetch();
      setIsSubmitModal(false);
    } catch (e) {
      const message = getErrorBackendMessage(e);
      showNotification({
        [Date.now()]: {
          message: message || f(pan['app.notification.error.default']),
          type: NOTIFICATION.ERROR,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const currentUserIndex = users.findIndex(x => x.id === +userId);

  const previousUserId = users[currentUserIndex-1] && users[currentUserIndex-1].id;
  const nextUserId = users[currentUserIndex+1] && users[currentUserIndex+1].id;

  const cantTakeBack = (role === ROLES.EMPLOYEE && status === APPROVED) || [OPEN, REJECTED].includes(status);
  const cantReject = [OPEN, APPROVED, REJECTED].includes(status);
  const cantApprove = [OPEN, APPROVED, REJECTED].includes(status);
  const cantSubmit = [SUBMITTED, APPROVED].includes(status);

  return (
    <div style={{ margin: '32px 0', display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', gap: 10 }}>
        <Button
          variant="contained"
          disabled={cantTakeBack || isLoading}
          onClick={onPeriodTakeBack}
        >
          {f(ptr[`app.timeReport.periodTakeBackBtn`])}
        </Button>
        {isShowComponent && (
          <>
            <Button
              variant="contained"
              disabled={cantReject || isLoading}
              className={!cantReject && !isLoading ? 'ECIT-error' : ''}
              onClick={() => setIsRejectModal(true)}
            >
              {f(ptr[`app.timeReport.transactionStatusRejectBtn`])}
            </Button>
            <Button
              variant="contained"
              disabled={cantApprove || isLoading}
              className={!cantApprove && !isLoading ? 'ECIT-success' : ''}
              onClick={onPeriodApprove}
            >
              {f(ptr[`app.timeReport.transactionStatusApproveBtn`])}
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color={'secondary'}
          disabled={cantSubmit || isLoading}
          onClick={() => {
            setIsSubmitModal(true);
          }}
        >
          {cantSubmit
            ? f(ptr[`app.timeReport.periodIsClosedBtn`])
            : f(ptr[`app.timeReport.closePeriodBtn`])}
        </Button>
      </div>
      <div style={{ display: 'flex', gap: 10 }}>
        {role !== ROLES.EMPLOYEE && (
          <>
            <Button
              variant="contained"
              disabled={!previousUserId || isLoading}
              onClick={()=>{
                history.push(`/time-report-details/${month}/${previousUserId}`)
              }}
            >
              {f(ptr[`app.timeReport.prevEmployeeBtn`])}
            </Button>
            <Button
              variant="contained"
              disabled={!nextUserId || isLoading}
              onClick={()=>{
                history.push(`/time-report-details/${month}/${nextUserId}`)
              }}
            >
              {f(ptr[`app.timeReport.nextEmployeeBtn`])}
            </Button>
          </>
        )}
      </div>

      {isSubmitModal && (
        <SimpleDialog
          overflowReactSelect={false}
          closeOnCross={false}
          fullWidth
          maxWidth="sm"
          open
          onClose={() => setIsSubmitModal(false)}
        >
          <Typography
            variant="h5"
            style={{ padding: '10px 0' }}
          >
            Are you sure you want to close current period ?
            <br />
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ padding: '10px 0' }}
          >
            All the transactions logged to this period after
            closing will be considered as corrections
          </Typography>
          <DialogActions style={{ margin: '10px -4px' }}>
            <Button
              type="button"
              onClick={() => setIsSubmitModal(false)}
              variant="outlined"
              classes={{ outlined: classes.outlined }}
            >
              {f(pab[`app.base.btns.cancel`])}
            </Button>

            <Button
              variant="contained"
              type="submit"
              classes={{ contained: classes.contained }}
              style={{ marginLeft: '10px' }}
              disabled={isLoading}
              onClick={onPeriodSubmit}
            >
              {f(pab[`app.base.btns.confirm`])}
            </Button>
          </DialogActions>
        </SimpleDialog>
      )}

      {isRejectModal && (
        <SimpleDialog
          fullWidth
          maxWidth="sm"
          title="Reject transactions"
          open
          onClose={() => setIsRejectModal(false)}
        >
          <Typography variant="subtitle1">Comment:</Typography>
          <TextField
            value={rejectComment}
            fullWidth
            onChange={(e) => setRejectComment(e.target.value)}
            multiline
            rows={2}
            rowsMax={4}
            margin="normal"
            variant="outlined"
          />
          <DialogActions>
            <Button
              type="button"
              onClick={() => setIsRejectModal(false)}
              variant="outlined"
            >
              {f(pab[`app.base.btns.cancel`])}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={onPeriodReject}
              disabled={!rejectComment || isLoading}
              color="primary"
            >
              {f(pab[`app.base.btns.confirm`])}
            </Button>
          </DialogActions>
        </SimpleDialog>
      )}
    </div>
  );
};

export default compose(
  connect(null, { showNotification }),
)(PeriodActions);
