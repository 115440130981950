import employeeIcon from '../../styles/sidebar/employee.svg';
import employeeIconActive from '../../styles/sidebar/employee-active.svg';
import timeReportIcon from '../../styles/sidebar/time-report.svg';
import timeReportIconActive from '../../styles/sidebar/time-report-active.svg';
import departmentsIcon from '../../styles/sidebar/departments.svg';
import departmentsIconActive from '../../styles/sidebar/departments-active.svg';
import projectsIcon from '../../styles/sidebar/projects.svg';
import projectsIconActive from '../../styles/sidebar/projects-active.svg';

import { pageUrl } from '../urls';

export default function (user) {
  const { departmentId, departmentsToApprove, usersToApprove } = user;

  const {
    root,
    timeReportDetails,
    transactions,
    timeReport,
  } = pageUrl;

  const routes = [
    root,
    timeReportDetails,
    transactions,
    timeReport,
    '/employees',
    '/departments',
    '/departments/:departmentId',
  ];

  const manageRoles = ['employee', 'manager', 'customeradmin'];
  const createUsers = false;
  const editUsers = false;
  const deleteUsers = false;
  const createDepartments = false;
  const editDepartments = false;
  const deleteDepartments = false;

  const renderNavigation = [
    {
      name: 'Employees',
      activeIcon: employeeIconActive,
      icon: employeeIcon,
      path: '/employees',
      selector: 'app.navigation.employees',
    },
    {
      name: 'TimeReport',
      activeIcon: timeReportIconActive,
      icon: timeReportIcon,
      path: '/time-reports',
      selector: 'app.navigation.timeReport',
    },
    {
      name: 'Departments',
      activeIcon: departmentsIconActive,
      icon: departmentsIcon,
      path: '/departments',
      selector: 'app.navigation.departments',
    },
    {
      name: 'Projects',
      activeIcon: projectsIconActive,
      icon: projectsIcon,
      path: '/projects',
      selector: 'app.navigation.projects',
    }
  ];

  const indexToRedirectTo = '/time-reports';

  return {
    usersToApprove: usersToApprove.map(el => el.id),
    departmentsToApprove: departmentsToApprove.map(el => el.id),
    departmentId,
    indexToRedirectTo,
    routes,
    manageRoles,
    createUsers,
    editUsers,
    deleteUsers,
    editDepartments,
    deleteDepartments,
    createDepartments,
    renderNavigation,
  };
}
