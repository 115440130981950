import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

const Check = ({ value }) => {
  if (!value) return '';

  return <CheckIcon />;
};

export default Check;
