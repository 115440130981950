import React from 'react';
import { compose, map, pathOr } from 'ramda';
import { useIntl } from 'react-intl';
import {
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  withStyles,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo';
import Loader from '../../../../components/LoadingCircle';
import { GET_EMPLOYEES, ADD_USER_TO_DEPARTMENT } from '../../../../queries/users';
import pad from '../../../../config/intlMessageSelectors/payroll-admin-departments';
import pab from '../../../../config/intlMessageSelectors/payroll-admin-base';

export const DEFAULT_ORDER = ['firstName', 'asc'];

const AddExistingUserButton = ({ defaultCustomer, permissions, classes, refetch }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, changeSelected] = React.useState('');
  const { departmentId } = useParams();
  const [add] = useMutation(ADD_USER_TO_DEPARTMENT);

  const { data, loading } = useQuery(GET_EMPLOYEES, {
    variables: {
      customerId: defaultCustomer,
      roles: permissions.manageRoles,
      state: 'ACTIVE',
      order: DEFAULT_ORDER.join(' '),
      withApprovers: false,
    },
    fetchPolicy: 'no-cache',
  });
  const { formatMessage: f } = useIntl();

  const users = compose(
    map(({
      id,
      personalInfo: { firstName, lastName }
    }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    })),
    pathOr([], ['usersV2', 'users'])
  )(data)

  return (
    <React.Fragment>
      <Button
        disabled={!permissions.editDepartments}
        size="large"
        color="secondary"
        variant="contained"
        onClick={() => setIsOpen(true)}
      >
        {f(pad['app.departments.addExistingUserToDepartment'])}
      </Button>
      <Dialog fullWidth open={isOpen} onClose={() => setIsOpen(false)} classes={{ paper: classes.dialog }}>
        <DialogTitle>
          {f(pad['app.departments.addExistingUserToDepartment'])}
        </DialogTitle>
        <DialogContent>
          <Grid container justify="space-between" spacing={16}>
            <Grid item xs={12}>
              {loading && <Loader />}
              {!loading && data && (
                <Select
                  fullWidth
                  variant="outlined"
                  value={selected}
                  onChange={e => changeSelected(e.target.value)}
                  MenuProps={{ classes: { paper: classes.paper } }}
                >
                  {users.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              justify="flex-end"
            >
              <Grid item xs={3}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant="outlined"
                  onClick={() => setIsOpen(false)}
                >
                  {f(pab['app.base.btns.cancel'])}
                </Button>
              </Grid>

              <Grid item xs={4} style={{ marginLeft: '10px' }}>
                <Button
                  fullWidth
                  disabled={loading || !selected}
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    await add({
                      variables: {
                        id: selected,
                        departmentId: Number(departmentId),
                      },

                    });
                    await refetch();
                    changeSelected('');
                    setIsOpen(false);
                  }}
                >
                  {f(pab['app.base.btns.confirm'])}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(() => ({
  dialog: {
    minWidth: '320px',
    maxWidth: '560px',
  },
  paper: {
    maxHeight: '300px',
  }
}))(AddExistingUserButton);
