import React from 'react';
import { compose } from 'ramda';
import {
  Grid,
  withStyles
} from '@material-ui/core';

const SelectCustomer = ({ list = [], classes, selectedCustomerId, onChange  }) => {

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      classes={classes}
    >
      { list.map(item => (
        <Grid
          key={item.id}
          container
          direction="row"
          justify="space-between"
          style={{
            background: item.id === selectedCustomerId && '#e4eff9',
            cursor: 'pointer',
            padding: 15,
            overflow: 'auto'
          }}
          onClick={() => onChange(item.id)}
        >
          <div>{ item.name }</div>
          <div style={{ opacity: 0.6 }}>{ item.organizationNumber }</div>
        </Grid>
      )) }
    </Grid>
  );
};

export default compose(
  withStyles(() => ({
    container: {
      background: '#FFFFFF',
      border: '1px solid #D6D6D6',
      borderRadius: '3px',
      marginTop: 15,
      padding: '5px 0',
      maxHeight: '500px',
      overflow: 'auto'
    },
  })),
)(SelectCustomer);
