import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  pathOr,
  compose,
  head,
  keys,
  path,
  pathEq,
  allPass,
  equals,
  not,
  filter,
  propEq,
  isEmpty,
  includes,
  pluck,
  reject,
  find as findR,
} from 'ramda';
import moment from 'moment-timezone';
import { Typography, Grid, Button, Paper } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import Loader from '../../components/LoadingCircle';
import EcitTable from '../EcitTable';
import EmployeeCard from '../EmployeeCard';
import { GET_PERIOD_REPORTS_AND_TRANSACTIONS } from '../../queries/time-report';
import ptr from '../../config/intlMessageSelectors/payroll-admin-timeReport';
import CreateDeviationModal from './CreateDeviationModal';
import CreateProjectModal from './CreateProjectModal';
import PeriodActions from "./PeriodActions";
import ColumnActions from "./ColumnActions";
import PeriodSummary from "../PeriodSummary";
import EcitQuery from '../EcitQuery';
import { Warning } from '../Warning';
import { DEFAULT_DATE_FORMAT, getDateTimeData } from '../../utils/times';
import { truncate } from "../../utils/string";
import { hasTimeReports } from '../../utils/user';
import {
  PERIOD_STATUSES,
  REPORT_TYPES, TRANSACTION_STATUSES
} from "../../utils/timeReports";

const { OPEN, SUBMITTED, ARCHIVED, EXPORTED } = TRANSACTION_STATUSES;

const styles = () => ({
  label: {
    fontSize: '14px',
    color: '#000000',
  },
  outlined: {
    flex: 0.7,
    padding: '10px',
    borderRadius: '3px',
  },
  contained: {
    backgroundColor: '#62B081',
    color: '#FFFFFF',
    flex: 1.3,
    padding: '10px',
    borderRadius: '3px',
  },
  createButtons: {
    marginRight: 16,
  },
});

const modifyBehavior = () => {
  const isNotSubmittedRow = row => ({
    disabled: compose(not, equals(SUBMITTED), path(['status']))(row),
  });

  return {
    Checkbox: isNotSubmittedRow,
    TableRow: isNotSubmittedRow,
    // eslint-disable-next-line
    CheckAll: (checked, data) => {
      return checked ? filter(propEq('status', SUBMITTED), data) : [];
    },
  };
};

const getFilteredCompensationTypes = ({ compensationTypes = [], transactionsWithChildren = []}) => {
  const doesNotContainArchived = compose(
    not,
    isEmpty,
    includes(ARCHIVED),
    pluck('status'),
  )(transactionsWithChildren);

  const filtered = reject(
    propEq('reportingType', 'INTERVAL'),
    compensationTypes,
  );

  const hasMoreThanOneTransaction = !isEmpty(transactionsWithChildren);

  if (doesNotContainArchived && !hasMoreThanOneTransaction) return filtered;
  return reject(propEq('timeType', 'NO_DEVIATIONS'), filtered);
};

const Exported = ({status, updatedAt}) => {
  const formattedDate = updatedAt
    ? moment(+updatedAt).format(DEFAULT_DATE_FORMAT)
    : '';

  return status === EXPORTED && updatedAt ? <>{formattedDate}</> : <></>;
};

class TimeReportDetailsDeviations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 50,
      page: 0,
      order: 'asc',
      orderBy: 'dateRange',
      showDeviationModal: false,
      selectedDay: {},
      showDeleteDeviationModal: false,
      type: ''
    };
  }

  componentWillMount() {
    const {
      match,
      history,
      auth: { user },
      isManager,
      canManage,
    } = this.props;

    if (!isManager || canManage) return;

    if (+match.params.userId !== user.id) {
      history.push(`/time-report-details/${match.params.month}/${user.id}`)
    }
  }

  getHeaders() {
    const { currentUser } = this.props;
    const hasProjectReporting = currentUser?.employmentCategory?.modulesConfig.projectReporting;

    const actionHeaders = [
      {
        id: 'actionsColumn',
      },
    ];

    const headers = [
      {
        id: 'compensationType',
        label: this.translate('compensationType'),
      },
      {
        id: 'dateRange',
        label: this.translate('dateRange'),
      },
      {
        id: 'amount',
        label: this.translate('amount'),
      },
      {
        id: 'exported',
        label: this.translate('exported'),
      },
      {
        id: 'comment',
        label: this.translate('comment'),
      },
    ];

    const projectReportingHeaders = [
      {
        id: 'dimensionNumber',
        label: this.translate('dimensionNumber'),
      },
      {
        id: 'project',
        label: this.translate('project'),
      },
      {
        id: 'workType',
        label: this.translate('workType'),
      },
    ]

    return hasProjectReporting ? [...actionHeaders, ...projectReportingHeaders, ...headers] : [...actionHeaders, ...headers];
  }

  extract = refetch => el => {
    const {
      currentUser,
      currentPeriod,
      classes,
    } = this.props;
    const hasProjectReporting = currentUser?.employmentCategory?.modulesConfig.projectReporting;
    const { compensationType, comment } = el;
    const { dateRange, duration } = getDateTimeData(el, currentPeriod, DEFAULT_DATE_FORMAT);
    const data = el;

    const isEditDisabled = compose(
      not,
      includes(path(['compensationTypeId'], el)),
      pluck('id'),
      pathOr([],['currentUser', 'employmentCategory', 'compensationTypes']),
    )(this.props);

    const onEdit = () => {
      const isDeviation = pathEq(
        ['type'],
        REPORT_TYPES.DEVIATION,
        data,
      );
      if (isDeviation) {
        this.setState({
          type: compensationType.type,
        });

        return this.toggleDeviationModal({...this.formDevToEdit(data), isEdit: true });
      }

      return this.toggleProjectModal({ ...data, isEdit: true });
    }

    const onInfo = () => {
        const isDeviation = pathEq(
          ['type'],
          REPORT_TYPES.DEVIATION,
          data,
        );
        if (isDeviation) {
          return this.toggleDeviationModal({...this.formDevToEdit(data), isInfo: true });
        }

      return this.toggleProjectModal({ ...data, isInfo: true });
    }

    const columns = {
      actionsColumn: <ColumnActions
        compensationType={compensationType}
        isEditDisabled={isEditDisabled}
        el={el}
        refetch={refetch}
        classes={classes}
        onEdit={onEdit}
        onInfo={onInfo}
        periodStatus={currentPeriod.status}
      />,
      compensationType: compensationType.name,
      dateRange,
      amount: el.compensationType.timeType !== 'NO_DEVIATIONS' && duration,
      exported: <Exported status={el.status} updatedAt={el.updatedAt} />,
      comment: comment ? truncate(comment, 25) : '',
    };

    if(hasProjectReporting) {
      columns.dimensionNumber = pathOr(
        '',
        ['dimension', 'dimensionNumber'],
        data,
      );
      columns.workType = pathOr(
        '',
        ['workType', 'name'],
        data,
      );
      columns.project = pathOr(
        '',
        ['dimension', 'name'],
        data,
      );
    }

    return columns;
  };

  onPageChange = (e, page) => this.setState({ page });

  onRowsPerPageChange = ({ target: { value } }) =>
    this.setState({
      rowsPerPage: value,
    });

  toggleProjectModal = selectedDay => {
    this.setState({
      selectedDay,
      showProjectModal: !path(['state', 'showProjectModal'], this),
    });
  };

  isShowComponent = () => {
    const { auth: { user } } = this.props;
    let currentUser = this.props.currentUser;

    if (!currentUser) {
      currentUser = user
    }

    if (user.role === 'manager' && user.id === currentUser.id) {
      return currentUser.usersToApprove.some(
        userToApprove => userToApprove.id === user.id,
      );
    }
    return user.role !== 'employee';
  };

  formDevToEdit = transaction => {
    const data = {
      deviation: {
        transactions: [
          {
            date: transaction.date,
            duration: transaction.duration,
            normalWorkDay: transaction.normalWorkDay,
            status: transaction.parentStatus
          },
        ],
        parentId: transaction.id,
        comment: transaction.comment
      },
      isCreating: false,
      compensationTypeId: transaction.compensationTypeId,
      status: transaction.status,
      compensationType: transaction.compensationType,
      rejectMessage: transaction.rejectMessage,
    };

    transaction.children.forEach(tr =>
      data.deviation.transactions.push(tr),
    );

    return data;
  };

  toggleDeviationModal = selectedDay => {
    this.setState({
      selectedDay,
      showDeviationModal: !path(['state', 'showDeviationModal'], this),
    });
  };

  onSort = orderBy => {
    const isDesc = allPass([
      pathEq(['state', 'order'], 'desc'),
      pathEq(['state', 'orderBy'], orderBy),
    ])(this);

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy,
    });
  };

  findSuitablePeriod(p) {
    const { month } = this.props.match.params;

    return findR(({ id }) => +id === +month, p);
  }

  translate(key) {
    const { intl } = this.props;
    return intl.formatMessage(ptr[`app.timeReport.${key}`]);
  }

  // eslint-disable-next-line
  applyOrderToInput({ orderBy, order, variables }) {
    const paramsByColumn = {
      compensationType: {
        input: 'input2',
        order: `compensationType.name ${order} transactionsWithChildren`,
      },
      dateRange: {
        input: 'input2',
        order: `date ${order} transactionsWithChildren`,
      },
      status: {
        input: 'input2',
        order: `${orderBy} ${order} transactionsWithChildren`,
      },
      project: {
        input: 'input2',
        order: `dimension.name ${order} transactionsWithChildren`,
      },
      workType: {
        input: 'input2',
        order: `workType.name ${order} transactionsWithChildren`,
      },
      amount: {
        input: 'input2',
        order: `duration ${order} transactionsWithChildren`,
      }
    };

    const params = paramsByColumn[orderBy];

    // eslint-disable-next-line
    if (params) variables[params.input].order = params.order;
  }

  render() {
    const {
      match: {
        params: { userId },
      },
      auth,
      users,
      periods,
      classes,
      selectedCustomer,
      currentPeriod,
      fetchTimeReportDetails,
      periodSummary,
    } = this.props;

    let currentUser = this.props.currentUser

    if (!currentUser) {
      currentUser = auth.user
    }

    const isProjectReporting = compose(
      pathOr(
        false,
        ['employmentCategory', 'modulesConfig', 'projectReporting'],
      ),
      head,
      filter(propEq('id', +userId))
    )(users);

    const { selectedDay } = this.state;
    const suitablePeriod = this.findSuitablePeriod(periods);

    if (!hasTimeReports(auth.user)) {
      return (
        <Warning id="app.base.message.noModuleConfig"/>
      )
    }

    if (!suitablePeriod) {
      return (
        <Warning id="app.base.message.noPeriods"/>
      )
    }

    const isTheSameMonthAndYearAsSelectedPeriod =
      moment(suitablePeriod.startDate).isSame(new Date(), 'month') &&
      moment(suitablePeriod.startDate).isSame(new Date(), 'year');

    const start = moment(suitablePeriod.startDate);
    const end = moment(suitablePeriod.endDate);

    const {
      page,
      rowsPerPage,
      order,
      orderBy,
      showDeviationModal,
      showProjectModal,
    } = this.state;

    const variables = {
      input: {
        filters: {
          status: { not: 'ARCHIVED' },
          userId: [Number(userId)],
          periodId: suitablePeriod.id,
          filterSummary: true,
          date: {
            gte: start
              .clone()
              .startOf('day')
              .format(DEFAULT_DATE_FORMAT),
            lte: end
              .clone()
              .endOf('day')
              .format(DEFAULT_DATE_FORMAT),
          },
        },
      },
      input2: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        filters: {
          type: keys(REPORT_TYPES),
          userId: Number(userId),
          isParent: true,
          status: {
            not: ARCHIVED,
          },
          or: {
            date: {
              gte: start
                .clone()
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              lte: end
                .clone()
                .endOf('day')
                .format(DEFAULT_DATE_FORMAT),
            },
            start: {
              gte: start
                .clone()
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              lte: end
                .clone()
                .endOf('day')
                .format(DEFAULT_DATE_FORMAT),
            },
            end: {
              gte: start
                .clone()
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              lte: end
                .clone()
                .endOf('day')
                .format(DEFAULT_DATE_FORMAT),
            },
          },
        },
      },
      count: {
        filters: {
          userId: Number(userId),
          isParent: true,
          status: {
            not: ARCHIVED,
          },
          or: {
            date: {
              gte: start
                .clone()
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              lte: end
                .clone()
                .endOf('day')
                .format(DEFAULT_DATE_FORMAT),
            },
            start: {
              gte: start
                .clone()
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              lte: end
                .clone()
                .endOf('day')
                .format(DEFAULT_DATE_FORMAT),
            },
            end: {
              gte: start
                .clone()
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              lte: end
                .clone()
                .endOf('day')
                .format(DEFAULT_DATE_FORMAT),
            },
          },
        },
      }
    };

    this.applyOrderToInput({ order, orderBy, variables });

    return (
      <EcitQuery
        query={GET_PERIOD_REPORTS_AND_TRANSACTIONS}
        variables={variables}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data, refetch }) => {
          if (error) return `Error! ${error.message}`;
          if (loading) return <Loader />;
          const { employmentCategory } = currentUser;

          const filteredCompensationTypes = getFilteredCompensationTypes({
            ...employmentCategory,
            ...data,
          });

          const usersRawTransactions = data.transactionsWithChildren.map(el => ({
            ...el,
            user: currentUser,
          }));

          const refetchFun = async() => {
            await fetchTimeReportDetails();
            await refetch();
          };

          const canAddTransaction = currentPeriod.status === PERIOD_STATUSES.OPEN || currentPeriod.status === PERIOD_STATUSES.REJECTED;

          return (
            <div>
              <Paper className="day-efficiency-info-block">
                <Grid container spacing={16} justify={'space-between'}>
                  <Grid item>
                    <EmployeeCard
                      periods={periods}
                      users={users}
                      currentUser={currentUser}
                      start={start}
                      currentPeriod={currentPeriod}
                      end={end}
                      dates={`${start.format(DEFAULT_DATE_FORMAT)},${end.format(DEFAULT_DATE_FORMAT)}`}
                      agg={data.periodReports.general}
                      showRangeSelector
                      selectedPeriod={this.props.match.params.month}
                    />
                  </Grid>

                  <Grid item>
                    <PeriodSummary periodSummary={periodSummary} />
                  </Grid>

                  <Grid item style={{ textAlign: 'end'}}>
                    <Grid container spacing={16}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          disabled={!employmentCategory || !canAddTransaction}
                          onClick={() => {
                            this.setState({
                              type: 'ABSENCE',
                            });

                            this.toggleDeviationModal({
                              isCreating: true,
                              deviation: {
                                transactions: [
                                  {
                                    date: isTheSameMonthAndYearAsSelectedPeriod
                                      ? moment()
                                        .startOf('day')
                                        .format(DEFAULT_DATE_FORMAT)
                                      : start
                                        .startOf('day')
                                        .format(DEFAULT_DATE_FORMAT),
                                    duration:
                                    currentUser.employmentCategory
                                      .normalWorkDay,
                                    status: OPEN
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          {this.translate('createDeviation')}
                        </Button>
                      </Grid>
                      <Grid item>
                        {isProjectReporting ? (
                          <Button
                            variant="outlined"
                            disabled={!canAddTransaction}
                            onClick={() => this.toggleProjectModal()}
                          >
                            {this.translate('projectHours')}
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            disabled={!employmentCategory || !canAddTransaction}
                            onClick={() => {
                              this.setState({
                                type: 'PRESENCE',
                              });

                              this.toggleDeviationModal({
                                isCreating: true,
                                deviation: {
                                  transactions: [
                                    {
                                      date: isTheSameMonthAndYearAsSelectedPeriod
                                        ? moment()
                                          .startOf('day')
                                          .format(DEFAULT_DATE_FORMAT)
                                        : start
                                          .startOf('day')
                                          .format(DEFAULT_DATE_FORMAT),
                                      duration:
                                      currentUser.employmentCategory
                                        .normalWorkDay,
                                      status: OPEN
                                    },
                                  ],
                                },
                              });
                            }}
                          >
                            {this.translate('createPresence')}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Typography variant="h5" style={{ margin: '30px 0 10px 0' }}>
                {this.translate('details')}
              </Typography>

              <EcitTable
                onRowSelect={() => {}}
                modifiedBehavior={modifyBehavior()}
                data={usersRawTransactions}
                page={page}
                classNameCustom="day-efficiency-table-block"
                order={order}
                orderBy={orderBy}
                onSort={this.onSort}
                count={data.transactionsCount.count}
                paging
                rowIdentifier="id"
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={this.onRowsPerPageChange}
                handlePageChange={this.onPageChange}
                headers={this.getHeaders()}
                extractor={this.extract(refetchFun)}
              />
              <PeriodActions
                users={users}
                isShowComponent={this.isShowComponent()}
                classes={classes}
                period={currentPeriod}
                refetch={refetchFun}
              />

              {showDeviationModal && (
                <CreateDeviationModal
                  currentUser={currentUser}
                  onClose={() => this.toggleDeviationModal(null)}
                  data={selectedDay}
                  dates={{
                    start: start.clone().startOf('day'),
                    end: end.clone().endOf('day'),
                  }}
                  compensationTypes={filteredCompensationTypes.filter(
                    ({ type }) => type === this.state.type)}
                  refetchFun={refetchFun}
                  customer={selectedCustomer}
                  dimensions={[]}
                  reportingMethod={employmentCategory.reportingMethod}
                  isTheSameMonthAndYearAsSelectedPeriod={
                    isTheSameMonthAndYearAsSelectedPeriod
                  }
                />
              )}
              {showProjectModal && (
                <CreateProjectModal
                  currentUser={currentUser}
                  customer={selectedCustomer}
                  userId={userId}
                  data={selectedDay}
                  refetchFun={refetchFun}
                  normalWorkDay={currentUser.employmentCategory.normalWorkDay}
                  compensationTypes={filteredCompensationTypes
                  .filter(
                    ({ type, reportingType }) =>
                      type === 'PRESENCE' &&
                      reportingType !== 'FULLMONTH',
                  )
                  .map(({ id, name, comment, interval: { valueV2 }, multipleDays, reportingType }) => {
                    return {
                      label: name,
                      value: id,
                      comment,
                      interval: valueV2,
                      multipleDays,
                      reportingType
                    }
                  })}
                  onClose={() => this.toggleProjectModal(null)}
                  defaultDate={
                    isTheSameMonthAndYearAsSelectedPeriod
                      ? moment()
                      : moment(suitablePeriod.startDate)
                  }
                />
              )}
            </div>
          );
        }}
      </EcitQuery>
    );
  }
}

export default compose(
  connect(({ context, auth }) => {
    const selectedCustomer = path(['user', 'selectedCustomer'], auth);
    const isManager = pathEq(['user', 'role'], 'manager', auth);
    const canManage = compose(
      pathOr(false, ['isManager']),
      head,
      filter(pathEq(['id'], selectedCustomer)),
      path(['customers'])
    )(context);

    return { isManager, canManage, auth, ...context }
  }),
  withStyles(styles),
  injectIntl,
  withRouter,
)(TimeReportDetailsDeviations);
