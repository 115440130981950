export const pageUrl = {
  root: '/',
  employees: '/employees',
  customers: '/customers',
  timeReportDetails: '/time-report-details/:month/:userId',
  transactions: '/transactions/:userId/:dailyEfficiencyId',
  timeReport: '/time-reports',
  departments: '/departments',
  departmentDetails: '/department-details/:departmentId',
  forbidden: '/forbidden',
};
