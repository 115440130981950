import React from 'react';

const styles = {
  counter: {
    textAlign: 'right',
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.54)'
  }
};

const InputCounter = ({ limit, len, children }) => (
  <React.Fragment>
    { children }
    <div style={styles.counter}>
      { `${len} / ${limit}`}
    </div>
  </React.Fragment>
)

export default InputCounter;
