import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import {
  omit,
  gt,
  map,
  pick,
  compose,
  pathOr,
  isNil,
  not,
  allPass,
  either,
  isEmpty,
  mapObjIndexed,
  path,
  inc,
  ifElse,
  pluck,
  flatten,
} from 'ramda';
import { withApollo } from 'react-apollo';
import {
  Grid,
  Paper,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Loader from '../../components/LoadingCircle';
import {
  MainLayout,
  EmployeeTitle,
  EmployeeFooter,
  EmployeeGeneral,
  EmployeeLocation,
  EmployeeEmploymentDetails,
} from './components';
import {
  CREATE_EMPLOYEE,
  GET_EMPLOYEE_FORM_INFO,
  GET_EMPLOYEE_BY_ID,
  UPDATE_EMPLOYEE,
} from '../../queries/users';
import { ROLES } from '../../config/role-configs';
import { showNotification } from '../../actions/notifications';
import { NOTIFICATION } from '../../components/NotificationSnackbar';
import pae from '../../config/intlMessageSelectors/payroll-admin-employees';
import pan from '../../config/intlMessageSelectors/payroll-admin-notifications';
import pac from '../../config/intlMessageSelectors/payroll-admin-customers';
import { getErrorMessageCode } from '../../utils/error';

const ERROR_CODES = {
  EMAIL_ADDRESS_ALREADY_IN_USE: 3,
  EMPLOYMENT_NUMBER_ALREADY_IN_USE: 9,
  USER_IS_NOT_ACTIVE: 31,
  PORTAL_ADMIN_INTEGRATION: 16,
};

const MESSAGE_CODE = {
  PORTAL_ADMIN_TENANT_NOT_SYNCED: 32,
  PORTAL_ADMIN_INTEGRATION_ERROR: 33,
  PORTAL_ADMIN_CUSTOMER_NOT_SYNCED: 34,
}

const ERROR_MESSAGES = {
  [ERROR_CODES.EMAIL_ADDRESS_ALREADY_IN_USE]: 'app.employees.emailAddressInUse',
  [ERROR_CODES.EMPLOYMENT_NUMBER_ALREADY_IN_USE]: 'app.employees.errorEmploymentNumber',
  [ERROR_CODES.USER_IS_NOT_ACTIVE]: 'admin.error.notification.user.isNotActive',
  [MESSAGE_CODE.PORTAL_ADMIN_CUSTOMER_NOT_SYNCED]: 'admin.error.portal.admin.customer.not.synced',
  [MESSAGE_CODE.PORTAL_ADMIN_TENANT_NOT_SYNCED]: 'admin.error.portal.admin.tenant.not.synced',
  [MESSAGE_CODE.PORTAL_ADMIN_INTEGRATION_ERROR]: 'admin.error.portal.admin.integration.error',
};

const getErrorMessage = code => ERROR_MESSAGES[code];

const initialValues = {
  approveOwnTimeReport: false,
  employmentNumber: 1,
  employmentCategoryId: null,
  departmentId: null,
  role: ROLES.EMPLOYEE,
  accessRules: [],
  yearSalary: null,
  employeeStart: moment(),
  employeeEnd: null,
  suspended: false,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  personalId: '',
  bankAccountNumber: '',
  contactPerson: '',
  contactPersonPhone: '',
  country: '',
  postalCity: '',
  address: '',
  postalNumber: '',
};

const EmployeeSchema = Yup.object().shape({
  employmentNumber: Yup.string()
    .nullable()
    .when('role', {
      is: v => [ROLES.APPROVER].includes(v),
      then: Yup.string().nullable().notRequired(),
      otherwise: Yup.string()
        .nullable()
        .min(1, 'Too Short!')
        .max(9, 'Too Long!')
        .required('Required')
        .matches(/^\d+$/, 'Should contain positive numbers only'),
    }),
  employeeStart: Yup.string()
    .nullable()
    .required('Required'),
  employeeEnd: Yup.string()
    .nullable()
    .when('suspended', {
      is: v => !v,
      then: (y) => y.notRequired(),
      otherwise: (y) => y
        .required('Required')
    }),
  firstName: Yup.string()
    .min(1, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(1, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required'),
  role: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  phone: Yup.string()
    .nullable()
    .default('')
    .matches(/^$|^[+][0-9]{10,20}$/, 'Invalid phone number'),
  contactPersonPhone: Yup.string()
    .nullable()
    .default('')
    .matches(/^$|^[+][0-9]{10,20}$/, 'Invalid phone number'),
  employmentCategoryId: Yup.number()
    .nullable()
    .when('role', {
      is: v => [ROLES.APPROVER].includes(v),
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number().nullable().required('Required'),
    }),
  departmentId: Yup.number()
    .nullable()
    .when('role', {
      is: v => [ROLES.APPROVER].includes(v),
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number().nullable().required('Required'),
    }),
  accessRules: Yup.array()
    .when('role', {
      is: ROLES.EMPLOYEE,
      then: Yup.array().notRequired(),
      otherwise: Yup.array().of(Yup.object()
        .required()
        .shape({
          tenantId: Yup.number()
            .nullable()
            .required('Required'),
          customerId: Yup.number()
            .nullable()
            .required('Required'),
          departmentIds: Yup.array()
            .min(1, 'Required')
            .of(Yup.number())
            .required('Required'),
        })),
    }),
});

const initialState = {
  employee: initialValues,
  isLoading: false,
  tenants: [],
  customers: [],
  departments: [],
  options: {
    tenants: [],
    customers: [],
    departments: [],
  },
  employmentCategories: [],
};

const options = compose(
  // transform it into valid array
  mapObjIndexed(
    (_, key, obj) => compose(
      map(({ id, name }) => ({ value: id, label: name })),
      pathOr([], [key]),
    )(obj),
  ),
  // pick up array to filter selects
  pick(['tenants', 'customers', 'departments', 'employmentCategories']),
);

const reducer = (state, action) => {
  switch (action.type) {
    case 'EMPLOYEE_START_REQUESTS':
      return {
        ...state,
        isLoading: true,
      };
    case 'EMPLOYEE_FINISH_REQUESTS':
      return {
        ...state,
        isLoading: false,
      };
    case 'FETCH_EMPLOYEE_FORM_INFO_SUCCESS':
      const employmentNumber = pathOr(
        compose(
          inc,
          path(['payload', 'employmentNumber']),
        )(action),
        ['employmentNumber'],
        JSON.parse(localStorage.getItem('LINK_EMPLOYEE'))
      );

      localStorage.removeItem('LINK_EMPLOYEE')

      return {
        ...state,
        ...options(action.payload),
        employee: {
          ...state.employee,
          employmentNumber,
        },
      };
    case 'FETCH_EMPLOYEE_SUCCESS':
      // eslint-disable-next-line
      const suspended = allPass([
        // shoudn't be empty
        compose(
          not,
          either(isNil, isEmpty),
          path(['employeeEnd']),
        ),
        // should be greater than today
        compose(
          gt(Date.now()),
          path(['employeeEnd']),
        ),
      ])(action.payload);

      // eslint-disable-next-line
      const accessRules = compose(
        ifElse(
          isEmpty,
          () => [{
            tenantId: null,
            customerId: null,
            departmentIds: [],
          }],
          () => path(['payload', 'accessRules'], action),
        ),
        path(['payload', 'accessRules']),
      )(action);

      return {
        ...state,
        options: action.payload.options,
        employee: {
          ...action.payload,
          address: pathOr('', ['address'], action.payload),
          bankAccountNumber: pathOr('', ['bankAccountNumber'], action.payload),
          phone: pathOr('', ['phone'], action.payload),
          postalCity: pathOr('', ['postalCity'], action.payload),
          postalNumber: pathOr('', ['postalNumber'], action.payload),
          suspended,
          accessRules,
        },
      };
    default:
      return state;
  }
};

const useEmployee = ({ client, defaultCustomer }) => {
  const { id } = useParams()
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const isEdit = id !== 'new';

  // eslint-disable-next-line
  const fetchEmployeeFormInfo = React.useCallback(async () => {
    dispatch({ type: 'EMPLOYEE_START_REQUESTS' });

    const {
      data: {
        tenants = [],
        customers = [],
        departments = [],
        employmentCategories = [],
        usersCount,
      },
    } = await client.query({
      query: GET_EMPLOYEE_FORM_INFO,
      variables: {
        customerId: defaultCustomer,
        departments: { filters: { customerId: defaultCustomer } },
        employmentCategories: { filters: { customerId: defaultCustomer } },
        count: { filters: { customerId: defaultCustomer } },
      },
    });

    dispatch({
      type: 'FETCH_EMPLOYEE_FORM_INFO_SUCCESS',
      payload: {
        tenants,
        customers,
        departments,
        employmentCategories,
        employmentNumber: pathOr(0, ['maxEmploymentNumber'], usersCount),
      },
    });

    if (isEdit) {
      const { data: { userById } } = await client.query({
        query: GET_EMPLOYEE_BY_ID,
        variables: {
          id: +id,
          customerId: defaultCustomer,
        },
      });

      dispatch({
        type: 'FETCH_EMPLOYEE_SUCCESS',
        payload: {
          ...omit(['personalInfo', 'extendedAccessRules'], userById),
          country: pathOr(null, ['personalInfo', 'country'], userById),
          accessRules: compose(
            map(({ tenantId, customerId, departmentsToAccess }) => ({
              tenantId,
              customerId,
              departmentIds: pluck('id', departmentsToAccess)
            })),
            pathOr([], ['extendedAccessRules']),
          )(userById),
          options: {
            tenants: compose(
              map(({ tenantId, tenantName }) => ({
                id: tenantId,
                name: tenantName
              })),
              pathOr([], ['extendedAccessRules'])
            )(userById),
            customers: compose(
              map(({ customerId, customerName }) => ({
                id: customerId,
                name: customerName
              })),
              pathOr([], ['extendedAccessRules'])
            )(userById),
            departments: compose(
              flatten,
              map(({ departmentsToAccess }) => departmentsToAccess),
              pathOr([], ['extendedAccessRules'])
            )(userById)
          }
        },
      });
    }

    dispatch({ type: 'EMPLOYEE_FINISH_REQUESTS' });
  });

  React.useEffect(() => {
    fetchEmployeeFormInfo();
    // eslint-disable-next-line
  }, []);

  return {
    ...state,
    isEdit,
  };
};

const Employee = ({
  client,
  defaultTenant,
  defaultCustomer,
  showNotification,
}) => {
  const [futureReports, setFutureReports] = React.useState([]);
  const location = useLocation();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const {
    employee,
    isLoading,
    tenants,
    customers,
    departments,
    options,
    employmentCategories,
    isEdit,
  } = useEmployee({ client, defaultCustomer });
  const { id: employeeId } = useParams()

  const onSubmit = async values => {
    const {
      employmentNumber,
      suspended,
      employeeEnd,
      employeeStart,
      accessRules,
      role,
      ...rest
    } = values;

    const toSubmit = {
      customerId: defaultCustomer,
      tenantId: defaultTenant,
      connectionType: 'AUTH',
      employeeStart: employeeStart.valueOf(),
      role,
      ...omit([
        '__typename',
        'locale',
        'auth0Id',
        'options',
        ROLES.APPROVER === role && 'department',
        ROLES.APPROVER === role && 'employmentCategoryId',
      ], rest),
      employeeEnd: employeeEnd ? employeeEnd.valueOf() : null,
      ...![ROLES.APPROVER].includes(role) && { employmentNumber: +employmentNumber },
      ...![ROLES.EMPLOYEE].includes(role) && {
        departmentsToApprove:  map(omit(['__typename', 'id']), accessRules),
      }
    };

    try {
      if (!isEdit) {
        await client.mutate({
          mutation: CREATE_EMPLOYEE,
          variables: { input: toSubmit },
        });

        showNotification({
          [Date.now()]: {
            message: f(
              pan[`app.notification.success.${isEdit ? 'update' : 'create'}`],
            ),
            type: NOTIFICATION.SUCCESS,
          },
        });

        history.push(pathOr('/employees', ['state', 'redirect'], location));
      }

      if (isEdit) {
        const { data: { updateUser: { futureReports } } } = await client.mutate({
          mutation: UPDATE_EMPLOYEE,
          variables: {
            input: {
              id: +employeeId,
              ...toSubmit,
            },
          },
        });

        if (isEmpty(futureReports)) {
          showNotification({
            [Date.now()]: {
              message: f(
                pan[`app.notification.success.${isEdit ? 'update' : 'create'}`],
              ),
              type: NOTIFICATION.SUCCESS,
            },
          });

          history.push(pathOr('/employees', ['state', 'redirect'], location));
          return;
        }

        setFutureReports(futureReports)
      }
    } catch (e) {
      const errorCode = getErrorMessageCode(e);
      let message = pae[getErrorMessage(errorCode)] && f(pae[getErrorMessage(errorCode)]);

      if (ERROR_CODES.PORTAL_ADMIN_INTEGRATION === errorCode) {
        const messageCode = getErrorMessageCode(e);
        message = pac[getErrorMessage(messageCode)] && f(pac[getErrorMessage(messageCode)])
      }

      showNotification({
        [Date.now()]: {
          message: message || 'Ops something went wrong',
          type: NOTIFICATION.ERROR,
        },
      });
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...employee,
        ...!isEdit && {
          departmentId: (path(['state', 'departmentId'], location)
              && parseInt(path(['state', 'departmentId'], location)))
            || null
        }
      }}
      onSubmit={onSubmit}
      validateOnMount={isEdit}
      validationSchema={EmployeeSchema}
    >
      {({ isSubmitting, values, isValid }) => (
        <>
          <Form>
            <Grid container alignItems="center" justify="space-between" spacing={16}>
              <EmployeeTitle values={values} isEdit={isEdit} />
              <Paper style={{ padding: '20px 24px', width: '100%' }}>
                <Grid container justify="space-between" spacing={16}>
                  <EmployeeEmploymentDetails
                    roles={[
                      { value: 'employee', label: 'employee'},
                      { value: 'manager', label: 'manager'},
                      { value: 'approver', label: 'approver'},
                      { value: 'customeradmin', label: 'customer admin'},
                    ]}
                    tenants={tenants}
                    customers={customers}
                    departments={departments}
                    employmentCategories={employmentCategories}
                    values={values}
                    options={options}
                  />
                  <EmployeeGeneral isEdit={isEdit} />
                  <EmployeeLocation />
                  <EmployeeFooter isSubmitting={isSubmitting} isNotValid={!isValid} />
                </Grid>
              </Paper>
            </Grid>
          </Form>
          <Dialog open={!isEmpty(futureReports)}>
            <DialogTitle>
              {f(pae['app.employees.dialog.future.reports.title'])}
            </DialogTitle>
            <DialogContent>
              <i>{values.firstName} {values.lastName}</i> {f(pae['app.employees.dialog.future.reports.description'])}
              <br />
              <b>{f(pae['app.employees.dialog.future.reports.reported.date'])}:</b>&nbsp;&nbsp;
              {futureReports
                .map(d => moment(d).format('MMM Do YYYY'))
                .join(', ')}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => history.push(pathOr('/employees', ['state', 'redirect'], location))}
                >Ok</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Formik>
  );
};

const E = connect(null, { showNotification })(withApollo(Employee));

const EmployeePage = props => (
  <MainLayout {...props}>
    {rest => (
      <E {...rest} />
    )}
  </MainLayout>
);

export default EmployeePage;
