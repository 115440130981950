import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

const CustomersVerticalMenu = ({ row: { id }, passDown: { editUsers } }) => {
  const history = useHistory()

  if (!editUsers) return null

  return (
    <IconButton
      aria-label="Edit"
      onClick={() => history.push(`/employees/${id}`)}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  )
};

export default CustomersVerticalMenu;
