import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { showNotification } from '../actions/notifications';
import pan from '../config/intlMessageSelectors/payroll-admin-notifications';
import { NOTIFICATION } from './NotificationSnackbar';

const EcitQuery = ({
  query,
  variables,
  ignoreGlobalNotifyError,
  showNotification,
  children,
  intl,
  removeCustomerId,
  selectedCustomer,
  fetchPolicy,
}) => {
  function selectMessageError(errorObj) {
    switch (errorObj.messageCode) {
      case 2:
        return `Field: '${errorObj.field}' ${intl.formatMessage(pan['app.notification.error.notNull'])}`;
      case 3:
        return `Field: '${errorObj.field}' ${intl.formatMessage(pan['app.notification.error.invalid'])}`;
      default:
        return intl.formatMessage(pan['app.notification.error.default']);
    }
  }
  const adjustedData = {};
  const variablesKeys = Object.keys(variables);
  if (!removeCustomerId && query.definitions.length) {
    query.definitions[0].variableDefinitions.forEach(({ variable: { name: { value } = {} } = {} }) => {
      const index = variablesKeys.indexOf(value);
      if (index !== -1) {
        const key = variablesKeys[index];
        adjustedData[key] = variables[key];
        if (!adjustedData[key].filters) adjustedData[key].filters = {};
        adjustedData[key].filters.customerId = selectedCustomer;
      } else {
        adjustedData[value] = {};
        adjustedData[value].filters = {};
        adjustedData[value].filters.customerId = selectedCustomer;
      }
    });
  }
  return (
    <Query
      query={query}
      variables={adjustedData}
      fetchPolicy={fetchPolicy}
      notifyOnNetworkStatusChange
    >
      {({
        loading,
        error,
        data,
        refetch,
      }) => {
        if (error && !ignoreGlobalNotifyError) {
          error.graphQLErrors[0] && error.graphQLErrors[0].messageCodeObjs.forEach(element => {
            showNotification({
              [Date.now()]: {
                message: selectMessageError(element || {}),
                type: NOTIFICATION.ERROR,
              },
            });
          });
        }
        return children({
          loading,
          error,
          data,
          refetch,
        });
      }}
    </Query>
  );
};
const mapStateToProps = ({ auth: { user: { selectedCustomer } } }) => ({ selectedCustomer });

export default connect(
  mapStateToProps,
  { showNotification },
)(injectIntl(EcitQuery));
