import React from 'react';
import {Formik, Form, FastField} from 'formik';
import { Grid, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TextField } from '../../../../../../../components/FormikFields';
import { withStyles } from '@material-ui/core/styles';
import pab
  from '../../../../../../../config/intlMessageSelectors/payroll-admin-base';
import * as Yup from 'yup';
import pac
  from '../../../../../../../config/intlMessageSelectors/payroll-admin-customers';

const styles = () => ({
  root: {
    padding: '0 0 30px 0'
  },
  button: {
    padding: '12px 0 0 5px'
  }
});

const isUnique = (list, value) => {
  return list.reduce((acc, item) => {
    if(item.name === value) {
      acc = false;
    }
    return acc;
  }, true)
};

const AddDrawerListSchema = list => Yup.object().shape({
  name: Yup.string()
    .min(3, 'Too Short!')
    .max(32, 'Too Long!')
    .test('unique', 'Should be unique', value => isUnique(list, value))
    .required('Required'),
});

const AddDrawerListItem = ({
  onChange,
  onEnter,
  classes,
  list
}) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          name: '',
          enabled: true,
        }}
        onSubmit={async (values, { resetForm }) => {
          onChange(values)
          resetForm();
        }}
        validationSchema={AddDrawerListSchema(list)}
      >
        <Form>
          <Grid item container xs={12} spacing={0}>
            <Grid item xs={9}>
              <FastField
                fullWidth
                overrided
                label={f(pac['admin.page.step.employment.projects.add.activity'])}
                name="name"
                component={TextField}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onEnter(e.target.value)
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} className={classes.button}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
              >
                {f(pab['app.base.button.add'])}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  )
}

export default withStyles(styles)(AddDrawerListItem);
