import React from 'react';
import { useIntl } from 'react-intl';
import CsvDownloader from 'react-csv-downloader';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { Tooltip, Button } from '@material-ui/core';
import pac
  from '../../../../config/intlMessageSelectors/payroll-admin-customers';

const styles = {
  padding: 0,
  minWidth: 0
}

const DownloadButton = ({ columns, data }) => {
  const { formatMessage: f } = useIntl();

  return (
    <CsvDownloader
      filename="example"
      separator=";"
      columns={columns}
      datas={data}
    >
      <Button
        style={styles}
      >
        <Tooltip
          title={f(pac['admin.page.users.import.step.example.tooltip'])}
        >
          <DownloadIcon color="secondary" />
        </Tooltip>
      </Button>
    </CsvDownloader>
  );
}

export default DownloadButton;
