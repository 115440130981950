import React from 'react';
import LoadingCircle from '../LoadingCircle';

const styles = {
  position: 'absolute',
  zIndex: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  top: 0
};

const Loader = ({ open }) => {
  if(!open) {
    return null;
  }

  return (
    <div style={styles}>
      <LoadingCircle />
    </div>
  )
}

export default Loader;
