export default {
  "app.departments.createDepartment": "Opret afdeling",
  "app.departments.department": "Afdeling",
  "app.departments.approvers": "Godkendere",
  "app.departments.approver": "Godkender",
  "app.departments.noApproverAssigned": "Ingen godkender tildelt",
  "app.departments.employees": "Medarbejdere",
  "app.departments.firstName": "Fornavn",
  "app.departments.lastName": "Efternavn",
  "app.departments.phone": "Telefon",
  "app.departments.role": "Rolle",
  "app.departments.email": "E-mail",
  "app.departments.createUserInsideDepartment": "Opret medarbejder inden for afdelingen",
  "app.departments.addExistingUserDepartment": "Tilføj eksisterende medarbejder til afdelingen",
  "app.departments.removeUserFromDepartment": "Fjern bruger fra afdelingen",
  "app.departments.removeDepartment": "Er du sikker på du vil fjerne denne afdeling? Medarbejderne vil ikke kunne rapportere deres tid før de er blevet tilknyttet en afdeling",
  "app.departments.removeDepartmentTitle": "Fjern afdeling",
  "app.departments.remove.user": "Fjern bruger",
  "app.departments.edit.user": "Rediger bruger"
}
