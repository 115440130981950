import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { setAuth } from '../actions/auth';
import { setUserAdditional } from '../actions/context';
import getRoleConfigs from '../config/roles';
import { useLoggedUser } from '../providers/IdentityProvider';

const Wrapper = ({
  component: Component,
  auth,
  selectedPeriod,
  setAuth,
  setUserAdditional,
  ...rest
}) => {
  const user = useLoggedUser();

  const ProtectedComponent = withAuthenticationRequired(Component);
  const dummyProps = {
    auth,
    selectedPeriod,
    setAuth,
    setUserAdditional,
    roleConfigs: getRoleConfigs(user)
  }

  return (
    <Route
      {...rest}
      component={() => <ProtectedComponent {...dummyProps} />}
    />
  );
};

export const ProtectedRoute = connect(
  ({ auth, context: { selectedPeriod } }) => ({ auth, selectedPeriod }),
  { setAuth, setUserAdditional },
)(React.memo(Wrapper))

