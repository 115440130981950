import React from 'react';
import {
  withStyles,
  MenuItem,
  MenuList
} from '@material-ui/core';

const withLeadingZero = (number) => {
  const s = number.toString();
  return s.length === 1 ? `0${s}` : s
}

const styles = () => ({
  menu: {
    height: 104,
    minWidth: 48,
    padding: 8,
    overflowY: 'scroll',
  },
  items: {
    height: 8,
    width: 8,
    textAlign: 'center',
    paddingLeft: 8,
    paddingRight: 14,
  }
})

const Minutes = ({ minutes, selected, onMinutesClick, classes }) => (
  <MenuList className={classes.menu}>
    {minutes.map(minutes => (
      <MenuItem
        key={minutes}
        value={minutes}
        selected={selected === minutes}
        onClick={onMinutesClick}
        className={classes.items}
      >
        {withLeadingZero(minutes)}
      </MenuItem>
    ))}
  </MenuList>
)

export default withStyles(styles)(Minutes)
