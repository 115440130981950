import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { GET_EMPLOYEES_BY_DEPARTMENT } from '../../../../queries/departments';
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/table";

export const DEFAULT_ORDER = ['employmentNumber', 'asc'];

export default compose(
  withRouter,
  graphql(GET_EMPLOYEES_BY_DEPARTMENT, {
    options({ roleConfigs: permissions, defaultCustomer, match: { params: { departmentId } } }) {
      return {
        variables: {
          departmentId: +departmentId,
          customerId: defaultCustomer,
          order: DEFAULT_ORDER.join(' '),
          limit: TABLE_ROWS_PER_PAGE,
          offset: 0,
          roles: permissions.manageRoles
        },
        fetchPolicy: 'network-only'
      }
    },
    props({
      data: {
        refetch,
        fetchMore,
        variables: { customerId },
        variables,
        usersByDepartmentV2: {
          users: employees,
          count,
        } = { customers: [], count: 0 },
        loading,
      },
      ownProps: { roleConfigs: permissions, match: { params: { departmentId } } }
    }) {
      return {
        count,
        employees,
        variables,
        loading,
        refetch,
        onFiltersChange: ({ limit, offset, order, state }) => fetchMore({
          variables: {
            departmentId: +departmentId,
            customerId,
            state,
            limit,
            offset,
            order,
            roles: permissions.manageRoles
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return ({
              ...previousResult,
              usersByDepartmentV2: fetchMoreResult.usersByDepartmentV2,
            });
          },
        }),
      }
    },
  })
);
