export default {
  "app.timeReport.compensationType": "Type",
  "app.timeReport.dateRange": "Date/Range",
  "app.timeReport.transactionStatus": "Status",
  "app.timeReport.transactionStatusRejectBtn": "Reject",
  "app.timeReport.period": "Period",
  "app.timeReport.closed": "Closed",
  "app.timeReport.open": "Open",
  "app.timeReport.closePeriodBtn": "Finalize period",
  "app.timeReport.periodIsClosedBtn": "Period is already closed",
  "app.timeReport.periodIsClosedStatus": "Period is closed",
  "app.timeReport.transactionStatusApproveBtn": "Approve",
  "app.timeReport.transactionStatusApproved": "APPROVED",
  "app.timeReport.transactionStatusExported": "EXPORTED",
  "app.timeReport.transactionStatusRejected": "REJECTED",
  "app.timeReport.transactionStatusPendingApproval": "PENDING APPROVAL",
  "app.timeReport.transactionStatusArchived": "ARCHIVED",
  "app.timeReport.transactionStatusSubmitted": "SUBMITTED",
  "app.timeReport.createDeviation": "Create deviation",
  "app.timeReport.comment": "Comment",
  "app.timeReport.showLess": "Show less",
  "app.timeReport.showMore": "Show more",
  "app.timeReport.rejectMessage": "Rejection message",
  "app.timeReport.deviationsTable": "DEVIATIONS",
  "app.timeReport.details": "DETAILS",
  "app.timeReport.singleDayToggle": "Single day",
  "app.timeReport.periodToggle": "Period",
  "app.timeReport.formDuration": "Duration",
  "app.timeReport.formStart": "Start",
  "app.timeReport.formEnd": "End",
  "app.timeReport.formDate": "Date",
  "app.timeReport.user": "User",
  "app.timeReport.employees": "Employees",
  "app.timeReport.status": "Status",
  "app.timeReport.absence": "Absence",
  "app.timeReport.presence": "Presence",
  "app.timeReport.employee": "Employee",
  "app.timeReport.allDepartments": "All departments",
  "app.timeReport.department": "Department",
  "app.timeReport.all": "All",
  "app.timeReport.notReported": "Not reported",
  "app.timeReport.waitingForApproval": "Waiting for approval",
  "app.timeReport.approved": "Approved",
  "app.timeReport.exported": "Exported",
  "app.timeReport.timeReportsTitle": "Time reports",
  "app.timeReport.description": "Description",
  "app.timeReport.project": "Project",
  "app.timeReport.projectCompensationType": "Time type",
  "app.timeReport.workType": "Activity",
  "app.timeReport.projectHours": "Project Hours",
  "app.timeReport.active": "Active",
  "app.timeReport.archived": "Archived",
  "app.timeReport.reportingMoreThan24H": "Maximum number of hours is 24 hours on a day",
  "app.timeReport.overlappedAbsence": "You can only report absence which is less than or equal to the hours in a working day",
  "app.timeReport.reportingOnWeekendIsNotAllowed": "Not possible to report absence on these days. Please select another day",
  "app.time.report.transations.report.subtitle": "Department transactions report",
  "app.time.report.transation.report.download.button": "Download Report",
  "app.time.report.transations.error.no.transactions": "No transactions found for this period",
  "app.time.report.transations.error.no.departments": "No departments found for this customer",
  "app.time.report.transations.removeTransactionModal.title": "Are you sure you want to remove this deviation?",
  "app.time.report.transations.removeTransactionModal.body": "Submitted transactions will be removed. Any previously exported parts of the deviations will be kept.",
  "app.timeReport.userNotes": "User notes",
  "app.timeReport.transactionStatusPendingSubmit": "PENDING SUBMIT",
  "app.timeReport.createTransaction": "Create transaction",
  "app.timeReport.formTime": "Time",
  "app.timeReport.editTransaction": "Edit transaction",
  "app.timeReport.createTransactionModalTitle": "Create transaction",
  "app.timeReport.editTransactionModalTitle": "Edit transaction"
}
