import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  compose,
  path,
  type,
  isEmpty,
  ifElse,
  equals,
} from 'ramda';
import { TableCell, Tooltip, TableSortLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({ root: { '&:last-child': { paddingRight: '4px' } } });

const ORDER_VICE_VERSA = {
  asc: 'desc',
  desc: 'asc',
};

class TableHeadCell extends Component {
  isComponent() {
    return compose(
      equals('Function'),
      type,
      path(['header', 'component']),
    )(this.props);
  }

  isSpace() {
    return compose(isEmpty, path(['header', 'label']))(this.props);
  }

  withSorting() {
    const {
      sorting: { orderBy, order, onRequestSort },
      header: { id, label, translate = true },
      sortable,
      intl,
    } = this.props;

    if (!sortable) return translate ? intl.formatMessage(label) : label;

    const next = ifElse(
      equals(orderBy),
      () => ORDER_VICE_VERSA[order],
      () => order,
    )(id);

    return (
      <Tooltip title="Sort" placement="bottom" enterDelay={300}>
        <TableSortLabel
          active={orderBy === id}
          direction={order}
          onClick={() => onRequestSort([id, next])}
        >
          {translate
            ? intl.formatMessage(label)
            : label}
        </TableSortLabel>
      </Tooltip>
    );
  }

  render() {
    const {
      header,
      header: { component: ComponenT },
      tableCellProps,
      passDown,
      classes,
    } = this.props;

    return (
      <TableCell
        {...tableCellProps}
        variant="head"
        classes={{ root: classes.root }}
      >
        {/* eslint-disable-next-line */}
        {this.isComponent() ? (
          <ComponenT header={header} passDown={passDown} />
        ) : this.isSpace() ? (
          ' '
        ) : (
          this.withSorting()
        )}
      </TableCell>
    );
  }
}

export default injectIntl(withStyles(styles)(TableHeadCell));
