import React from 'react';
import { MultiMenu } from '../../MultiMenu';

const FormikMultiMenu = ({ field, form, onChange, ...props }) => {
  const { value, name } = field;

  const { BaseLineProps } = props;

  const onSelect = v => {
    form.setFieldValue(name, v);

    if (onChange) {
      onChange({
        key: name,
        value: v,
        form,
      });
    }
  };

  return (
    <MultiMenu
      selected={value}
      onSelect={onSelect}
      {...props}
      BaseLineProps={{
        ...BaseLineProps,
        form,
        field,
      }}
    />
  );
};

export default FormikMultiMenu;
