import React, { useState, useEffect, useContext } from 'react';
import { Stepper } from '../../../../components/Stepper';
import { WizzardContext } from '../../../../providers/WizzardProvider';
import { CUSTOMER_WIZARD_FLOW, MAIN_CUSTOMER_WIZARD_FLOW } from '../../Customer';
import { compose, map, pick, values } from 'ramda';

const styles = {
  maxWidth: '750px',
};
const getCustomerWizardFlow = showProjectsStep => {
  return showProjectsStep ? CUSTOMER_WIZARD_FLOW : MAIN_CUSTOMER_WIZARD_FLOW;
}

const CustomerStepper = ({ id, onChange }) => {
  const { showProjectsStep } = useContext(WizzardContext);
  const [steps, setSteps] = useState({});

  const getOptions = () => {
    const options = compose(
      map(pick(['label', 'id'])),
      values,
    )(steps);

    return options.sort((a, b) => a.id - b.id);
  }

  useEffect(() => {
    setSteps(getCustomerWizardFlow(showProjectsStep));
  }, [showProjectsStep]);

  return (
    <Stepper
      active={id}
      steps={getOptions()}
      style={styles}
      onChange={onChange}
    />
  );
}

export default CustomerStepper;
