import React from 'react';
import { useIntl } from 'react-intl';
import { withStyles, Grid, Button } from '@material-ui/core';


import pab from '../../../../../../config/intlMessageSelectors/payroll-admin-base';

const MenuActions = ({ onSelect, onCancel, classes }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Grid
      item
      container
      alignItems="center"
      classes={{ container: classes.container }}
    >
      <Grid item xs={3}>
        <Button
          fullWidth
          variant="outlined"
          classes={{ root: classes.cancel }}
          onClick={onCancel}
        >
          <span className={classes.cancelText}>
            {f(pab['app.base.button.cancel'])}
          </span>
        </Button>
      </Grid>
      <Grid item xs={5}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          classes={{ root: classes.apply }}
          onClick={onSelect}
        >
          <span className={classes.applyText}>
            {f(pab['app.base.button.apply'])}
          </span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(() => ({
  container: { height: '65px', paddingLeft: '26px' },
  apply: {
    height: 36,
    marginLeft: '20px',
    background: '#73B783',
    textTransform: 'none',
    boxShadow: 'none',
  },
  cancel: {
    border: '1px solid #A7A7A7',
    height: 36,
    textTransform: 'none',
  },
  cancelText: {
    color: '#515151',
    fontSize: 13,
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  applyText: {
    color: '#FFFFFF',
    fontSize: 13,
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
}))(MenuActions);
