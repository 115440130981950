import React from 'react';
import { compose, path, head, filter, propEq, pathOr, map, length } from 'ramda';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-apollo';
import {
  Paper,
  Grid,
  Button,
  Typography,
  Tooltip,
} from '@material-ui/core';
import Loader from '../../components/LoadingCircle';

import {
  MainLayout,
  EmployeesTable,
  AddExistingUserButton,
  withEmployees,
  DEFAULT_ORDER,
} from './components'

import { GET_DEPARTMENT_BY_ID } from '../../queries/departments'
import pad from '../../config/intlMessageSelectors/payroll-admin-departments';
import { TABLE_ROWS_PER_PAGE } from "../../utils/table";

const initialState = {
  page: 0,
  sorting: DEFAULT_ORDER,
  rowsPerPage: TABLE_ROWS_PER_PAGE,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_PAGE_REQUEST':
      return {
        ...state,
        page: action.payload,
      };
    case 'CHANGE_SORTING_REQUEST':
      return {
        ...state,
        sorting: action.payload,
      };
    case 'CHANGE_ROW_PER_PAGE_REQUEST':
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    default:
      return state;
  }
};

const useTable = onFiltersChange => {
  const [{ sorting, rowsPerPage, page }, dispatch] = React.useReducer(
    reducer,
    initialState,
  );

  const [orderBy, order] = sorting;

  React.useEffect(() => {
    onFiltersChange({
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      order: sorting.join(' '),
    });
    // eslint-disable-next-line
  }, [order, orderBy, page, rowsPerPage]);

  const onChangePage = React.useCallback(
    page => dispatch({
      type: 'CHANGE_PAGE_REQUEST',
      payload: page,
    }),
    [],
  );

  const onChangeRowsPerPage = React.useCallback(
    rowsPerPage => dispatch({
      type: 'CHANGE_ROW_PER_PAGE_REQUEST',
      payload: rowsPerPage,
    }),
    [],
  );

  const onRequestSort = React.useCallback(
    sorting => dispatch({
      type: 'CHANGE_SORTING_REQUEST',
      payload: sorting,
    }),
    [],
  );

  return {
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
  };
};

const DepartmentsDetail = withEmployees(({
  defaultCustomer,
  count,
  loading: isEmployeesLoading,
  employees,
  onFiltersChange,
  roleConfigs: permissions,
  refetch: refetchEmployees,
}) => {
  const { departmentId } = useParams();
  const { formatMessage: f } = useIntl();

  const {
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
  } = useTable(onFiltersChange);

  const { data, loading, refetch: refetchStats } = useQuery(GET_DEPARTMENT_BY_ID, {
    variables: {
      input: {
        filters: {
          customerId: defaultCustomer,
          id: +departmentId,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return (
      <Grid container alignItems="center">
        <Loader />
      </Grid>
    )
  }

  const department = compose(
    head,
    filter(propEq('id', +departmentId)),
    path(['departments']),
  )(data);

  const approvers = compose(
    map(({ firstName, lastName }) => `${firstName} ${lastName}`),
    pathOr([], ['approvers']),
  )(department)

  return (
    <Grid container alignItems="center">
      <Paper style={{ marginBottom: '20px', width: '100%', padding: '20px 24px' }}>
        <Grid container>
          <Grid item sm={12} md={4} container justify="center" alignItems="center">
            {`${f(pad['app.departments.department'])}:`}
            &nbsp;&nbsp;
            <Typography variant="subtitle1">{department.name}</Typography>
          </Grid>
          <Grid item sm={12} md={4} container justify="center" alignItems="center">
            {`${f(pad['app.departments.employees'])}:`}
            &nbsp;&nbsp;
            <Typography variant="subtitle1">
              {pathOr(0, ['usersCount','count'], department)}
            </Typography>
          </Grid>
          <Grid item sm={12} md={4} container justify="center" alignItems="center">
            {`${f(pad['app.departments.approvers'])}:`}
            &nbsp;&nbsp;
            <Tooltip title={approvers.join(', ')}>
              <Typography variant="subtitle1" style={{ textDecoration: 'underline'}}>
                {length(approvers)}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ width: '100%', padding: '20px 24px' }}>
        <Grid item container spacing={16} alignItems="center">
          <Grid item>
            <Button
              disabled={!permissions.createUsers}
              size="large"
              color="primary"
              variant="contained"
              component={Link}
              to={{
                pathname: '/employees/new',
                state: { redirect: `/departments/${departmentId}`, departmentId }
              }}
            >
              {f(pad['app.departments.createUserInsideDepartment'])}
            </Button>
          </Grid>

          <Grid item>
            <AddExistingUserButton
              defaultCustomer={defaultCustomer}
              permissions={permissions}
              refetch={async () => {
                await refetchEmployees();
                await refetchStats()
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid item xs={12} style={{ marginTop: '20px' }}>
        {isEmployeesLoading && <Loader />}
        {!isEmployeesLoading && employees && (
          <EmployeesTable
            passDown={{
              permissions,
              refetch: async () => {
                await refetchEmployees();
                await refetchStats()
              }
            }}
            employees={employees}
            count={count}
            loading={loading}
            page={page}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onRequestSort={onRequestSort}
          />
        )}
      </Grid>
    </Grid>
  )
})

export default (props) => (
  <MainLayout {...props}>
    {(r) => <DepartmentsDetail {...r} />}
  </MainLayout>
);
