export default {
  "app.notification.success.create": "Skapad",
  "app.notification.success.delete": "Borttagen",
  "app.notification.success.update": "Uppdaterad",
  "app.notification.success.default": "Genomfört",
  "app.notification.error.default": "Något gick fel",
  "app.notification.error.notNull": "Kan inte vara tomt",
  "app.notification.error.invalid": "Är ogiltig",
  "app.notification.error.field": "Fält",
  "app.notification.default": "Något gick fel",
  "notification.error.notNull": "Kan inte vara tomt",
  "notification.error.invalid": "är ogiltig"
}
