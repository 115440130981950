import React from 'react';
import { Popover, Grid } from '@material-ui/core'

const TimePopup = ({ anchor, isOpen, onClose, hours, minutes }) => (
  <Popover
    open={isOpen}
    anchorEl={anchor}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    onClose={onClose}
  >
    <Grid container alignItems="center" justify="center">
      <Grid item>
        {hours}
      </Grid>
      :
      <Grid item>
        {minutes}
      </Grid>
    </Grid>
  </Popover>
);

export default TimePopup
