export default {
  "app.employees": "Medarbejdere",
  "app.departments": "Afdelinger",
  "app.efficiency.timeReport": "Tidsrapport",
  "app.efficiency.admin": "Hovedmenu",
  "app.base.importInfo": "Import fra fil",
  "app.base.month.january": "Januar",
  "app.base.month.february": "Februar",
  "app.base.month.march": "Marts",
  "app.base.month.april": "April",
  "app.base.month.may": "Maj",
  "app.base.month.june": "Juni",
  "app.base.month.july": "Juli",
  "app.base.month.august": "August",
  "app.base.month.september": "September",
  "app.base.month.october": "Oktober",
  "app.base.month.november": "November",
  "app.base.month.december": "December",
  "app.base.btns.confirm": "Bekræft",
  "app.base.btns.cancel": "Annullere",
  "app.base.btns.ok": "OK",
  "app.base.btns.remove": "Fjerne",
  "app.base.select.placeholder": "Vælg...",
  "app.base.tablePaginationRowsPerPage": "Rækker pr. side",
  "app.base.tablePaginationDisplayedRows": "af",
  "app.base.tableNoData": "Ingen data",
  "app.base.tenantSelect": "VÆLG FIRMA",
  "app.base.customerSelect": "VÆLG KUNDE",
  "app.base.logout": "LOG UD",
  "app.base.button.apply": "Anvend",
  "app.base.button.cancel": "Afbryd",
  "app.base.button.import": "Import",
  "app.base.button.choseFile": "Vælg fil",
  "app.base.button.mark.all": "Vælg alle",
  "app.base.message.noModuleConfig": "Du har ikke konfigureret nogen moduler. Kontakt din manager eller ECIT for at aktivere et modul.",
  "app.base.message.noPeriods": "Der er ingen tilgængelige perioder. Kontakt din administrator.",
  "admin.page.modules.config.timeReport": "Tidsrapport",
  "admin.page.modules.config.projectReporting": "Projektrapportering",
  "admin.page.modules.config.payslips": "Lønsedler",
  "admin.page.title": "Admin. Kundernes ledelse",
  "admin.page.add.button": "Tilføj ny kunde",
  "admin.page.back.button": "Kundeliste ",
  "admin.page.edit.button": "rediger",
  "admin.page.table.column.org.number": "Org nummer",
  "admin.page.table.column.name": "Navn",
  "admin.page.table.column.payroll.system": "lønsystem",
  "admin.page.table.column.tenant": "Lejer",
  "admin.page.table.column.employees": "Medarbejdere",
  "admin.page.table.column.status": "Status",
  "admin.page.table.column.empty": "Ikke deffineret ",
  "admin.page.table.customer.status.active": "Aktiv",
  "admin.page.table.customer.status.inactive": "Inaktiv ",
  "admin.page.table.customer.status.incomplete": "ufuldstændig",
  "admin.page.general.step.title": "Generelt ",
  "admin.page.compensation.types.step.title": "Kompensationstyper",
  "admin.page.employment.categories.step.title": "Beskæftigelseskategorier",
  "admin.page.users.import.success": "Importen blev gennemført",
  "admin.page.users.import.reminder": "Glem ikke at tildele hvilke afdelingsledere og godkendere, der skal godkende. Dette gøres i menuen Medarbejder.",
  "admin.page.users.import.step.title": "Brugere import",
  "admin.page.users.import.step.tooltip": "Når du importerer brugere med roll Godkendere, skal du lade EmploymentNumber, Beskæftigelseskategori og Department være tomme.",
  "admin.page.users.import.step.example.tooltip": "Hent eksempelfil",
  "admin.page.dimensions.worktypes.step.title": "Dimensioner og arbejdstyper",
  "admin.page.step.next.button": "Næste",
  "admin.page.step.back.button": "Tilbage",
  "admin.page.step.finish.button": "Færdig",
  "admin.page.step.save.button": "Gem",
  "admin.page.step.general.copy.button": "Kopier indstillinger fra eksisterende kunde",
  "admin.page.step.general.customer.name": "Kundenavn ",
  "admin.page.step.general.org.number": "Org. nummer",
  "admin.page.step.general.payroll.system": "Lønsystem",
  "admin.page.step.general.payroll.tenant": "Lejer",
  "admin.page.step.general.payroll.is.active": "Er aktiv",
  "admin.page.step.general.payroll.is.billable": "Fakturerbar",
  "admin.page.step.general.inactive.date": "Inaktiv dato",
  "admin.page.step.general.period.reports": "Perioder",
  "admin.page.step.general.label.monthly": "Månedlige",
  "admin.page.step.general.label.bi-weekly": "BI-ugentligt ",
  "admin.page.step.general.label.weekly": "Ugentligt",
  "admin.page.step.general.label.start.from": "Start fra",
  "admin.page.step.general.generated.periods": "Genererede perioder ",
  "admin.page.step.general.notifications": "Notifikationer ",
  "admin.page.step.general.add.notification.button": "Tilføj ny notifikation",
  "admin.page.step.general.notification.type.employee": "Medarbejder",
  "admin.page.step.general.notification.type.manager": "Leder",
  "admin.page.step.general.notification.label.type": "Type",
  "admin.page.step.general.label.notification.deadline": "Deadline",
  "admin.page.step.general.label.notification.time": "Tid",
  "admin.page.step.general.label.notification.repeatable": "Gentagelse",
  "admin.page.step.general.label.notification.repeatable.tooltip": "Gentagelse ON angiver, at anmeldelse/notifikationer gentages hver måned på samme dato og tidspunkt. \n\nGentagelse FRA angiver, at anmeldelse/notifikation vil ske\nsendes kun én gang på specificeret dato.",
  "admin.page.step.general.holidays": "helligdage ",
  "admin.page.step.general.add.holiday.button": "Tilføj ny helligdag",
  "admin.page.step.general.label.holidays.day": "Dag",
  "admin.page.step.compensation.types.column.code": "Løn kode",
  "admin.page.step.compensation.types.column.name": "Navn",
  "admin.page.step.compensation.types.column.type": "Type",
  "admin.page.step.compensation.types.column.comment": "Kommentar",
  "admin.page.step.compensation.types.column.reporting.type": "Rapporterings type",
  "admin.page.step.compensation.types.column.intervals": "interval ",
  "admin.page.step.compensation.types.column.periodic": "Periodiske ",
  "admin.page.step.compensation.types.column.in.summary": "Vis opsummeret",
  "admin.page.step.compensation.types.column.global.type": "Global type",
  "admin.page.step.compensation.types.column.description": "Beskrivelse",
  "admin.page.step.compensation.types.add.compensation.type": "Tilføj ny kompensations type",
  "admin.page.step.compensation.types.type.absence": "Fravær",
  "admin.page.step.compensation.types.type.presence": "tilstedeværelse",
  "admin.page.step.compensation.types.comment.required": "Kræver ",
  "admin.page.step.compensation.types.comment.no": "Nej",
  "admin.page.step.compensation.types.comment.optional": "Valgfri",
  "admin.page.step.compensation.types.reporting.type.overlapping": "Overlappende",
  "admin.page.step.compensation.types.reporting.type.full.day": "Fuld dag",
  "admin.page.step.compensation.types.reporting.type.interval": "Interval",
  "admin.page.step.compensation.types.reporting.type.duration": "Normal tid ",
  "admin.page.step.compensation.types.reporting.type.full.month": "Fuld måned",
  "admin.page.step.compensation.types.time.type.vacation": "Ferie",
  "admin.page.step.compensation.types.time.type.overtime": "Overtid",
  "admin.page.step.compensation.types.time.type.off.duty": "Har fri",
  "admin.page.step.compensation.types.time.type.more.time": "Mere tid",
  "admin.page.step.compensation.types.time.type.sickleave": "Sygeorlov",
  "admin.page.step.compensation.types.time.type.workhours": "arbejdstider ",
  "admin.page.step.compensation.types.time.type.no.deviation": "Ingen afvigelser",
  "admin.page.step.compensation.types.time.type.care.of.child": "Pleje af barn ",
  "admin.page.step.compensation.types.time.type.parental.leave": "Forældreorlov",
  "admin.page.step.compensation.types.delete.button": "Slet",
  "admin.page.step.employment.categories.add.employment.category": "Tilføj ny ansættelses kategori ",
  "admin.page.step.employment.categories.column.category": "Kategori ",
  "admin.page.step.employment.categories.column.modules.config": "Moduler",
  "admin.page.step.employment.categories.column.norm.time": "Normal  tid",
  "admin.page.step.employment.categories.column.exclude.weekends": "Ekskluderet weekender ",
  "admin.page.step.employment.categories.column.reporting.method": "Rapporterings metode",
  "admin.page.step.employment.categories.column.compensation.types": "Kompensations typer",
  "admin.page.step.employment.categories.delete.button": "Slet",
  "admin.page.step.employment.users.employmentNumber": "Beskæftigelsesnummer",
  "admin.page.step.employment.users.column.employmentCategoryName": "Beskæftigelseskategori",
  "admin.page.step.employment.users.column.firstName": "Fornavn",
  "admin.page.step.employment.users.column.lastName": "Efternavn",
  "admin.page.step.employment.users.column.email": "E-mail",
  "admin.page.step.employment.users.column.role": "Rolle",
  "admin.page.step.employment.users.column.departmentName": "Afdeling",
  "admin.page.step.employment.users.column.employeeStart": "Start dato",
  "admin.error.remove.compensation.type": "Kan ikke fjerne kompensationstype, der allerede er i brug",
  "admin.error.remove.used.employment.category": "Kan ikke fjerne beskæftigelseskategori, som allerede er i brug",
  "admin.success.notification.saved": "Gemt",
  "admin.error.notification.ops.something.went.wrong": "Obs, noget er galt",
  "admin.stepper.page.customer.name": "Kundenavn",
  "admin.page.stepper.customer.org.number": "Org nummer",
  "admin.page.stepper.custom.error.duplicated.notifications": "Ups, det ser ud til, at du har duplikate underretninger",
  "admin.page.stepper.custom.error.duplicated.holidays": "Ups, det ser ud til, at du har duplikerede helligdage",
  "app.departments.createDepartment": "Opret afdeling",
  "app.departments.department": "Afdeling",
  "app.departments.approvers": "Godkendere",
  "app.departments.approver": "Godkender",
  "app.departments.noApproverAssigned": "Ingen godkender tildelt",
  "app.departments.employees": "Medarbejdere",
  "app.departments.firstName": "Fornavn",
  "app.departments.lastName": "Efternavn",
  "app.departments.phone": "Telefon",
  "app.departments.role": "Rolle",
  "app.departments.email": "E-mail",
  "app.departments.createUserInsideDepartment": "Opret medarbejder inden for afdelingen",
  "app.departments.addExistingUserDepartment": "Tilføj eksisterende medarbejder til afdelingen",
  "app.departments.removeUserFromDepartment": "Fjern bruger fra afdelingen",
  "app.departments.removeDepartment": "Er du sikker på du vil fjerne denne afdeling? Medarbejderne vil ikke kunne rapportere deres tid før de er blevet tilknyttet en afdeling",
  "app.departments.removeDepartmentTitle": "Fjern afdeling",
  "app.departments.remove.user": "Fjern bruger",
  "app.departments.edit.user": "Rediger bruger",
  "app.employees.userModalCreate": "Opret medarbejder",
  "app.employees.userModalEdit": "Rediger medarbejder detaljer",
  "app.employees.userModalConfirm": "Godkend",
  "app.employees.userModalCancel": "Annuller",
  "app.employees.timeReportingMethod": "Tidsrapporteringmetode",
  "app.employees.userModalGeneral": "Generelt",
  "app.employees.employmentDetails": "Medarbejder detaljer",
  "app.employees.employmentCategory": "Medarbejder kategori",
  "app.employees.employmentNumber": "Medarbejder nummer",
  "app.employees.firstName": "Fornavn",
  "app.employees.lastName": "Efternavn",
  "app.employees.phone": "Telefon",
  "app.employees.department": "Afdeling",
  "app.employees.role": "Rolle",
  "app.employees.createUserButton": "Opret medarbejder",
  "app.employees.email": "E-mail",
  "app.employees.personalId": "Personligt Id",
  "app.employees.bankAccount": "Bankkontonummer",
  "app.employees.contactPerson": "Kontaktperson",
  "app.employees.contactPersonPhone": "Kontaktperson telefon",
  "app.employees.authLogin": "Autoriseret log ind",
  "app.employees.smsLogin": "Sms log ind",
  "app.employees.address": "Adresse",
  "app.employees.postalCity": "By",
  "app.employees.postalNumber": "Postnr.",
  "app.employees.userTimezone": "Tidszone",
  "app.employees.userNationality": "Nationalitet",
  "app.employees.userLanguage": "Sprog",
  "app.employees.admin": "Administrator",
  "app.employees.manager": "Leder",
  "app.employees.approver": "Godkender",
  "app.employees.employee": "Medarbejder",
  "app.employees.superadmin": "Super administrator",
  "app.employees.employmentType": "Medarbejdertype",
  "app.employees.fullTime": "Fuldtid",
  "app.employees.partTime": "Deltid",
  "app.employees.yearSalary": "Årsløn",
  "app.employees.employeeStartDate": "Medarbejder startdato",
  "app.employees.suspended": "Suspenderet",
  "app.employees.all": "Alle",
  "app.employees.noDepartmentAssigned": "Afdeling mangler",
  "app.employees.employeeEndDate": "Medarbejder slutdato",
  "app.employees.country": "Land",
  "app.employees.city": "By",
  "app.employees.home.address": "Hjemmeadresse",
  "app.employees.removeEmployeeTitle": "Fjern medarbejder",
  "app.employees.removeEmployeeConfirmationText": "Er du sikker på at du vil fjerne denne medarbejder?",
  "app.employees.cannotRemoveHasTransaction": "Kan ikke fjerne en medarbejder/bruger der er transaktioner på",
  "app.employees.errorEmploymentNumber": "Medarbejdernummer er allerede brugt, vælg et andet nummer",
  "app.employees.emailAddressInUse": "Email-adresse bruges allerede",
  "app.employees.create.employee": "Opret medarbejder",
  "app.employees.edit.employee": "Rediger medarbejder",
  "app.employees.approve.own.timereport": "Godkend egen tidsrapport",
  "app.employees.access.rights": "Adgangsrettigheder",
  "app.employees.button.add.new.department.to.approve": "Tilføj ny afdeling til godkendelse",
  "app.employees.button.remove.department.to.approve": "Fjern afdeling der skal til godkendelse",
  "app.employees.tenant": "Lejer",
  "app.employees.customer": "Kunde",
  "app.employees.departments": "Afdelinger",
  "app.employees.subtitle.location": "Sted",
  "app.employees.button.save": "Gemme",
  "app.employees.notitication.success.save": "Gemt",
  "app.employees.error.no.deviation": "Kan ikke rapportere - Ingen afvigelser -, der findes allerede transaktioner i den givne periode\n",
  "app.employees.error.national.holiday": "Fravær kan ikke rapporteres på helligdage",
  "app.notification.success.create": "Oprettet",
  "app.notification.success.delete": "Slettet",
  "app.notification.success.update": "Opdateret",
  "app.notification.success.default": "Succes",
  "app.notification.error.default": "Noget gik galt",
  "app.notification.error.notNull": "Må ikke være tom",
  "app.notification.error.invalid": "Er ugyldig",
  "app.notification.error.field": "Data ikke gyldig",
  "app.timeReport.compensationType": "Type",
  "app.timeReport.dateRange": "Datointerval",
  "app.timeReport.amount": "Beløb",
  "app.timeReport.transactionStatus": "Status",
  "app.timeReport.transactionStatusRejectBtn": "Afvise",
  "app.timeReport.period": "Periode",
  "app.timeReport.closed": "Lukket",
  "app.timeReport.open": "Åben",
  "app.timeReport.closePeriodBtn": "Luk periode",
  "app.timeReport.periodIsClosedBtn": "Perioden er allerede lukket",
  "app.timeReport.periodIsClosedStatus": "Perioden er lukket\n",
  "app.timeReport.transactionStatusApproveBtn": "Godkende",
  "app.timeReport.transactionStatusApproved": "GODKENDT",
  "app.timeReport.transactionStatusExported": "EKSPORTERET",
  "app.timeReport.transactionStatusRejected": "AFVIST",
  "app.timeReport.transactionStatusPendingApproval": "AFVENTER GODKENDELSE",
  "app.timeReport.transactionStatusArchived": "ARKIVERET",
  "app.timeReport.transactionStatusSubmitted": "TILFØJET",
  "app.timeReport.createDeviation": "Opret afvigelse",
  "app.timeReport.comment": "Kommentar",
  "app.timeReport.showLess": "Vis mindre",
  "app.timeReport.showMore": "Vis mere",
  "app.timeReport.rejectMessage": "Årsag til afvisning",
  "app.timeReport.deviationsTable": "AFVIGELSER",
  "app.timeReport.details": "DETALJER",
  "app.timeReport.singleDayToggle": "En dag",
  "app.timeReport.periodToggle": "Periode",
  "app.timeReport.formDuration": "Varighed",
  "app.timeReport.formStart": "Start",
  "app.timeReport.formEnd": "Slut",
  "app.timeReport.formDate": "Dato",
  "app.timeReport.user": "Bruger",
  "app.timeReport.employees": "Medarbejdere",
  "app.timeReport.status": "Status",
  "app.timeReport.absence": "Fravær",
  "app.timeReport.presence": "Tilstedeværelse",
  "app.timeReport.employee": "Medarbejder",
  "app.timeReport.allDepartments": "Alle afdelinger",
  "app.timeReport.department": "Afdeling",
  "app.timeReport.all": "Alle",
  "app.timeReport.notReported": "Ikke rapporteret",
  "app.timeReport.waitingForApproval": "Venter på godkendelse",
  "app.timeReport.approved": "Godkendt",
  "app.timeReport.exported": "Eksporteret",
  "app.timeReport.timeReportsTitle": "Tidsrapporter",
  "app.timeReport.description": "Beskrivelse",
  "app.timeReport.project": "Projekt",
  "app.timeReport.projectCompensationType": "Tidstype",
  "app.timeReport.workType": "Aktivitet",
  "app.timeReport.projectHours": "Projekt timer",
  "app.timeReport.active": "Aktiv",
  "app.timeReport.archived": "Arkiveret",
  "app.timeReport.reportingMoreThan24H": "Der kan maks registreres 24 timer på en dag",
  "app.timeReport.overlappedAbsence": "Du kan kun registrere fravær der har samme eller færre antal timer svarende til en arbejdsdag",
  "app.timeReport.reportingOnWeekendIsNotAllowed": "Ikke muligt at rapportere fravær i disse dage. Vælg en anden dag",
  "app.time.report.transactions.reports.subtitle": "Tidsrapporter - Excel",
  "admin.page.step.general.label.notification.when": "Hvornår",
  "admin.page.step.general.notification.when.before.deadline": "Før deadline",
  "admin.page.step.general.notification.when.after.deadline": "Efter deadline",
  "admin.page.step.general.notification.when.on.deadline": "På deadline",
  "admin.page.step.general.label.notification.period": "Periode",
  "admin.page.step.general.notification.period.day": "dag",
  "admin.page.step.general.notification.period.days": "dage",
  "admin.page.step.employment.categories.column.project.reporting": "Projekt Rapportering",
  "admin.page.step.employment.categories.column.work.day": "Arbejdsdag",
  "app.time.report.transations.report.subtitle": "Afdelingens transaktionsrapport",
  "app.time.report.transation.report.download.button": "Download Rapport",
  "app.time.report.transations.error.no.transactions": "Ingen transaktioner fundet i den givne periode ",
  "app.time.report.transations.error.no.departments": "Ingen afdelinger fundet for denne kunde",
  "app.time.report.transations.removeTransactionModal.title": "Er du sikker på, at du vil fjerne denne afvigelse?",
  "app.time.report.transations.removeTransactionModal.body": "Indsendte transaktioner fjernes. Eventuelt tidligere eksporterede dele af afvigelserne opbevares."
}
