import React from 'react';
import { useIntl } from 'react-intl';
import { FastField } from 'formik';

import { TableCell, TableRow } from '../components/Table';
import { StyledTextField, StyledTimePicker, StyledMuiTextField, } from '../components/StyledFields';
import MoreVerticalMenu from '../../../components/MoreVerticalMenu';
import pac from '../../../config/intlMessageSelectors/payroll-admin-customers';
import EmploymentCategoryDrawer from './EmploymentCategoryDrawer/EmploymentCategoryDrawer';
import MultiMenu from '../../../components/FormikFields/MultiMenu/MultiMenu';

const EmploymentCategoryTableRow = ({ types, onUpdate, onRemove }) => {
  const { formatMessage: f } = useIntl();
  return (
    <TableRow>
      <TableCell>
        <FastField
          name="name"
          component={StyledTextField}
          onChange={onUpdate}
        />
      </TableCell>
      <TableCell>
        <FastField
          fullWidth
          name="modulesConfig"
          component={MultiMenu}
          BaseLine={StyledMuiTextField}
          options={[
            {
              value: 'projectReporting',
              label: f(pac['admin.page.modules.config.projectReporting'])
            },
            {
              value: 'timeReports',
              label: f(pac['admin.page.modules.config.timeReport'])
            },
            {
              value: 'payslips',
              label: f(pac['admin.page.modules.config.payslips'])
            }
          ]}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          ampm={false}
          name="normalWorkDay"
          component={StyledTimePicker}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField name="reportingMethod">
          {({ field: { value } }) => <span>{value}</span>}
        </FastField>
      </TableCell>

      <TableCell>
        <FastField
          fullWidth
          name="compensationTypes"
          component={EmploymentCategoryDrawer}
          options={types}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField name="id">
          {({ form: { values: { id } } }) => (
            <MoreVerticalMenu
              moreVertIconProps={{ fontSize: 'small' }}
              options={[
                {
                  label: f(
                    pac['admin.page.step.employment.categories.delete.button'],
                  ),
                  action: () => onRemove(id),
                },
              ]}
            />
          )}
        </FastField>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(EmploymentCategoryTableRow, () => true);
