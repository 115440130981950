import gql from 'graphql-tag';

export const GET_ALL_DEPARTMENTS_WITH_INFO = gql`
  query GET_ALL_DEPARTMENTS_WITH_INFO($input: ListInputType){
    departments(input: $input){
      id
      name
      customerId
      approvers{
        id
        firstName
        lastName
        customerId
        customerName
      }
      usersCount(input: { filters: { userState: "ACTIVE" } }) {
        count
      }
    }
  }
`;

export const GET_DEPARTMENT_BY_ID = gql`
  query GET_DEPARTMENT_BY_ID($input: ListInputType){
    departments(input: $input){
      id
      name
      approvers{
        id
        firstName
        lastName
      }
      usersCount(input: { filters: { userState: "ACTIVE" } }) {
        count
      }
    }
  }
`;

export const GET_EMPLOYEES_BY_DEPARTMENT = gql`
  query GET_EMPLOYEES_BY_DEPARTMENT (
    $customerId: Int!, $departmentId: Int!, $roles: [String], $limit: Int, $offset: Int, $order: String
  ) {
    usersByDepartmentV2(
      customerId: $customerId,
      departmentId: $departmentId,
      roles: $roles,
      limit: $limit,
      offset: $offset,
      order: $order
    ) {
      users {
        id
        employmentNumber
        employmentCategory {
          id
          name
        }
        personalInfo {
          id
          firstName
          lastName
        }
        department {
          id
          name
        }
        role
      }
      count
    }
  }
`

export const CREATE_DEPARTMENT = gql`
  mutation CREATE_DEPARTMENT($input: CreateDepartmentInputType!) {
    createDepartment(input: $input) {
      id
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation DELETE_DEPARTMENT($input: DeleteInputType!) {
    deleteDepartment(input: $input) {
      id
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UPDATE_DEPARTMENT($input: UpdateDepartmentInputType!) {
    updateDepartment(input: $input) {
      id
    }
  }
`;
