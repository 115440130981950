import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';

import Routes from './routes';
import {store} from './store';

import './styles/styles.css';

import theme from './theme';
import Notification from './components/Notification';
import {CookieChecker} from './components/CookieChecker';
import {AuthorizedApolloProvider} from './providers/AuthorizedApolloProvider'
import {IdentityProvider} from './providers/IdentityProvider'
import {EnvironmentConfigProvider} from './providers/EnvironmentConfigProvider'
import {AuthorizationProvider} from './providers/AuthorizationProvider'
import {WizzardProvider} from './providers/WizzardProvider'
import {MultiTenancyProvider} from './providers/MultiTenancyProvider'

const App = () => (
  <EnvironmentConfigProvider>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
        <CookieChecker>
          <Provider store={store}>
            <AuthorizationProvider>
              <Router>
                <AuthorizedApolloProvider>
                  <IdentityProvider>
                    <MultiTenancyProvider>
                      <WizzardProvider>
                        <Route component={Routes}/>
                      </WizzardProvider>
                      <Notification/>
                    </MultiTenancyProvider>
                  </IdentityProvider>
                </AuthorizedApolloProvider>
              </Router>
            </AuthorizationProvider>
          </Provider>
        </CookieChecker>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </EnvironmentConfigProvider>
)

export default App
