import React from 'react';
import { withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl'
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import {
  compose,
  filter,
  fromPairs,
  isEmpty,
  last,
  map,
  path,
  pathOr,
  propEq,
  reject,
  split,
} from 'ramda';

import LoadingCircle from '../components/LoadingCircle';
import {
  CHANGE_SELECTED_TENANT,
  GET_TENANTS,
} from '../queries/tenants';
import { CHANGE_SELECTED_CUSTOMER, GET_CUSTOMERS } from '../queries/customers';
import { setAuth } from '../actions/auth';
import { setUserAdditional } from '../actions/context';
import { messages } from '../config';

class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, user: null }
  }

  async componentDidMount() {
    const user = this.props.user;

    const link = compose(
      fromPairs,
      map(split('=')),
      reject(isEmpty),
      split('&'),
      last,
      split('?'),
    )(this.props.location.search)

    if (!isEmpty(link) && link.open) {
      localStorage.setItem('LINK_EMPLOYEE', JSON.stringify(link));

      await this.props.client.mutate({
        mutation: CHANGE_SELECTED_TENANT,
        variables: { tenantId: parseInt(link.tenantId, 10) }
      })

      await this.props.client.mutate({
        mutation: CHANGE_SELECTED_CUSTOMER,
        variables: { customerId: parseInt(link.customerId, 10) }
      })
    }

    // eslint-disable-next-line
    if (['superadmin', 'admin', 'approver', 'manager', 'customeradmin'].includes(user.role)) {
      const tenantData = await this.props.client.query({ query: GET_TENANTS });

      const customersData = await this.props.client
        .query({
          query: GET_CUSTOMERS,
          variables: { input: { filters: { tenantId: tenantData.data.tenants.map(({ id }) => id) } } },
        });

      this.props.setUserAdditional({
        ...tenantData.data,
        ...customersData.data,
        selectedPeriod: compose(
          path([0, 'periodId']),
          filter(propEq('id', user.selectedCustomer)),
          pathOr([], ['data', 'customers']),
        )(customersData),
      });

      this.props.setAuth({
        user,
        ...!isEmpty(link) && link.open && {
          user: {
            ...user,
            selectedCustomer: parseInt(link.customerId, 10),
            selectedTenant: parseInt(link.tenantId, 10),
          }
        }
      });

      if (!isEmpty(link) && link.open) {
        window.location.href = [
          window.location.origin,
          '/',
          link.open,
        ].join('')
      }
    }

    // eslint-disable-next-line
    if ((['employee'].includes(user.role)) && user.customerId) {
      const customersData = await this.props.client
        .query({
          query: GET_CUSTOMERS,
          variables: { input: { filters: { id: user.customerId } } },
        });

      user.selectedCustomer = customersData.data.customers.length
        ? customersData.data.customers[0].id
        : null;

      this.props.setUserAdditional({
        ...customersData.data,
        selectedPeriod: compose(
          path([0, 'periodId']),
          filter(propEq('id', user.selectedCustomer)),
          pathOr([], ['data', 'customers']),
        )(customersData),
      });

      this.props.setAuth({ user });
    }

    this.setState({ loading: false })
  }

  render() {
    if (this.state.loading) {
      return (
        <Grid container justify="center" alignItems="center" style={{ height: '100%'}}>
          <LoadingCircle />
        </Grid>
      )
    }

    return (
      <IntlProvider locale={this.props.user.locale} messages={messages[this.props.user.locale]}>
        {this.props.children}
      </IntlProvider>
    )
  }
}

export const MultiTenancyProvider = compose(
  connect(({ auth }) => auth, { setAuth, setUserAdditional }),
  withApollo,
  withRouter,
)(Provider)
