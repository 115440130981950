import React from 'react';
import { pathOr } from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import pae from '../../../../config/intlMessageSelectors/payroll-admin-employees';

const EmployeeFooter = ({ isSubmitting, isLoading, isNotValid }) => {
  const history = useHistory();
  const location = useLocation();
  const { formatMessage: f } = useIntl();

  return (
    <Grid
      item
      xs={12}
      container
      justify="center"
      alignItems="center"
      spacing={16}
      style={{ marginTop: '16px' }}
    >
      <Grid item xs={3} sm={2} md={1}>
        <Button
          disabled={isLoading || isSubmitting}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => history.push(pathOr('/employees', ['state', 'redirect'], location))}
        >
          {f(pae['app.employees.userModalCancel'])}
        </Button>
      </Grid>
      <Grid item xs={3} sm={2} md={1}>
        <Button
          disabled={isLoading || isSubmitting}
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
        >
          {f(pae['app.employees.button.save'])}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmployeeFooter;
