import gql from 'graphql-tag';

export const AUTH_USER = gql`
  {
   userByToken {
     id
     auth0Id
     phone
     departmentId
     employmentType
     email
     role
     locale
     employeeStart
     employeeEnd
     selectedTenant
     selectedCustomer
     customerId
     tenantId
     firstName
     lastName
     departmentsToApprove{
      id
      name
    }
    usersToApprove{
      id
    }
    employmentCategory{
      reportingMethod
      normalWorkDay
      id
      modulesConfig{
        projectReporting
        timeReports
      }
      compensationTypes{
        name
        id
        reportingType
        multipleDays
        comment
        timeType
        type
        interval{
          id
          type
          value
          valueV2
        }
      }
    },
   }
 }
`;

export const GET_USERS = gql`
  query GET_USERS(
    $input: ListInputType!,
    $input2:CountInputType!,
    $input4:ListInputType
  ){
    users(input:$input){
      id
      connectionType
      firstName
      lastName
      email
      phone
      contactPerson
      contactPersonPhone
      postalNumber
      address
      timezone
      personalId
      postalCity
      bankAccountNumber
      yearSalary
      employmentNumber
      usersToApprove{
        id
      },
      departmentsToApprove{
        id
      },
      customerId,
      employmentCategory{
        reportingMethod
        normalWorkDay
        id
        name
        modulesConfig{
          id
          projectReporting
        }
        compensationTypes{
          name
          id
          reportingType
          multipleDays
          comment
          timeType
          type
          enabled
          interval{
            id
            type
            value
            valueV2
          }
        }
      },
      departmentId
      department { name, id }
      role
      employmentType
      employeeStart
      employeeEnd
      language
    }

    departments(input:$input4){
      id
      name
    }

    usersCount(input:$input2){
      count
      maxEmploymentNumber
    }
  }
`;

export const GET_PERIODS = gql`
  query GET_PERIODS($input: ListInputType) {
    periods(input:$input){
      startDate
      endDate
      cutOffDate
      id
    }
  }
`;

export const GET_EMPLOYEE_PERIODS = gql`
  query GET_EMPLOYEE_PERIODS($employeeId: Int!) {
    employeePeriods(employeeId:$employeeId){
      id
      startDate
      endDate
      cutOffDate
      status
      approvedBy
      approvedAt
      comment
      reminded
    }
  }
`;

export const GET_EMPLOYEE_PERIOD_SUMMARY = gql`
  query GET_EMPLOYEE_PERIOD_SUMMARY($employeeId: Int!, $employeePeriodId: Int!) {
    employeePeriodSummary(employeeId:$employeeId, employeePeriodId:$employeePeriodId){
      type
      total
    }
  }
`;

export const GET_EMPLOYEE_CURRENT_PERIOD_EXPECTATION = gql`
  query GET_EMPLOYEE_CURRENT_PERIOD_EXPECTATION($employeeId: Int!) {
    employeeCurrentPeriodExpectations(employeeId:$employeeId) {
      expected {
        hours
        minutes
      }
    }
  }
`;

export const GET_EMPLOYEE_PERIOD_EXPECTATION = gql`
  query GET_EMPLOYEE_PERIOD_EXPECTATION($employeeId: Int!, $employeePeriodId: Int!) {
    employeePeriodExpectations(employeeId:$employeeId, employeePeriodId:$employeePeriodId) {
      expected {
        hours
        minutes
      }
    }
  }
`;

export const GET_DATA_AS_EMPLOYEE = gql`
  query GET_DATA_AS_EMPLOYEE(
    $input3:ListInputType,
    $input4:ListInputType,
  ){
    departments(input:$input4){
      id
      name
    }
    employmentCategories(input:$input3){
      name
      id
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation createUser($input: CreateUserInputType!){
   createUser(input: $input){
     id
   }
 }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation updateUser($input: UpdateUserInputType!) {
    updateUser(input:$input){
      id
      futureReports
    }
  }
`;

export const DELETE_EMPLOYEE = gql`
  mutation($input: DeleteInputType!) {
    deleteUser(input: $input) {
      id
    }
  }
`;

export const GET_EMPLOYEE_FORM_INFO = gql`
  query GET_FORM_INFORMATION(
    $count: CountInputType,
    $departments: ListInputType,
    $employmentCategories: ListInputType
  ){
    tenants{
      id
      name
    }

    customers{
      id
      name
      tenantId
    }

    departments(input: $departments){
      id
      name
      customerId
    }

    employmentCategories(input: $employmentCategories) {
      id
      name
    }

    usersCount(input: $count) {
      maxEmploymentNumber
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query GET_EMPLOYEE_BY_ID($id: Int!, $customerId: Int!) {
    userById(id: $id, customerId: $customerId) {
      id
      departmentId
      customerId
      tenantId
      employmentCategoryId
      email
      phone
      role
      lastName
      firstName
      employeeEnd
      employeeStart
      yearSalary
      address
      postalCity
      postalNumber
      contactPerson
      personalInfo {
        id
        country
      }
      approveOwnTimeReport
      contactPersonPhone
      bankAccountNumber
      employmentNumber
      extendedAccessRules {
        tenantId
        tenantName
        customerId
        customerName
        departmentsToAccess {
          id
          name
          canUpdate
        }
      }
    }
  }
`;

export const DELETE_EMPLOYEE_FROM_DEPARTMENT = gql`
  mutation DELETE_EMPLOYEE_FROM_DEPARTMENT($id: Int!, $customerId: Int!) {
    deleteUserFromDepartment(id: $id, customerId: $customerId) {
      id
    }
  }
`;

export const ADD_USER_TO_DEPARTMENT = gql`
  mutation ADD_USER_TO_DEPARTMENT($id: Int!, $departmentId: Int!) {
    updateUserDepartment(id: $id, departmentId: $departmentId) {
      id
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query GET_EMPLOYEES_V2(
    $customerId: Int!,
    $roles: [String],
    $limit: Int,
    $offset: Int,
    $order: String,
    $state: String,
    $withApprovers: Boolean
    $search: String,
  ){
    usersV2(
      customerId: $customerId,
      roles: $roles,
      limit: $limit,
      offset: $offset,
      order: $order,
      state: $state,
      withApprovers: $withApprovers,
      search: $search
    ){
      users {
        id
        employmentNumber
        email
        employmentCategory {
          id
          name
        }
        personalInfo {
          id
          firstName
          lastName
        }
        department {
          id
          name
        }
        role
      }
      count
    }
  }
`

export const CHANGE_LANGUAGE = gql`
  mutation CHANGE_LANGUAGE($lang: LanguageEnum) {
    setLang(lang: $lang)
  }
`

export const VALIDATE_EMPLOYEES_FROM_FILE = gql`
  mutation VALIDATE_EMPLOYEES_FROM_FILE($input: [CreateWizardUserInputType]) {
    validateUsersFromFileImport(input: $input) {
      validUsers {
        role
        email
        lastName
        firstName
        employeeStart
        employeeEnd
        employmentCategoryName
        employmentNumber
        departmentName
      }
      invalidUsers {
        role
        email
        lastName
        firstName
        employeeStart
        employeeEnd
        employmentCategoryName
        employmentNumber
        departmentName
        errors
      }
    }
  }
`
