import React from 'react';
import { SvgIcon } from '@material-ui/core';

const NotReported = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <svg fill="none">
      <path
        d="M10.0013 18.3337C14.6037 18.3337 18.3346 14.6027 18.3346 10.0003C18.3346 5.39795 14.6037 1.66699 10.0013 1.66699C5.39893 1.66699 1.66797 5.39795 1.66797 10.0003C1.66797 14.6027 5.39893 18.3337 10.0013 18.3337Z"
        stroke="#2888D4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.58688C8.18612 7.05778 8.5535 6.61163 9.03705 6.32744C9.5206 6.04325 10.0891 5.93937 10.6419 6.03419C11.1947 6.12901 11.6961 6.41642 12.0574 6.8455C12.4186 7.27459 12.6163 7.81767 12.6154 8.37854C12.6154 9.96188 10.2404 10.7535 10.2404 10.7535"
        stroke="#2888D4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3047 13.9204H10.3126"
        stroke="#2888D4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  </SvgIcon>
);


export default NotReported;
