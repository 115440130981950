import React from 'react';
import { useIntl } from 'react-intl';
import { Field, FastField } from 'formik';
import { compose, map, pathEq, path, filter, head } from 'ramda';

import { TableCell, TableRow } from '../components/Table';
import {
  StyledSelect,
  StyledTextField,
  StyledCheckbox,
} from '../components/StyledFields';
import MoreVerticalMenu from '../../../components/MoreVerticalMenu';
import pac from '../../../config/intlMessageSelectors/payroll-admin-customers';

const reporting = [{
  value: 'FULLDAY',
  label: 'admin.page.step.compensation.types.reporting.type.full.day',
  disabled: false,
}, {
  value: 'DURATION',
  label: 'admin.page.step.compensation.types.reporting.type.duration',
  disabled: false,
}, {
  value: 'FULLMONTH',
  label: 'admin.page.step.compensation.types.reporting.type.full.month',
  disabled: false,
}, {
  value: 'OVERLAPPING',
  label: 'admin.page.step.compensation.types.reporting.type.overlapping',
  disabled: false,
}];

const global = [{
  value: 'VACATION',
  label: 'admin.page.step.compensation.types.time.type.vacation',
  disabled: false,
}, {
  value: 'OVERTIME',
  label: 'admin.page.step.compensation.types.time.type.overtime',
  disabled: false,
}, {
  value: 'OFF_DUTY',
  label: 'admin.page.step.compensation.types.time.type.off.duty',
  disabled: false,
}, {
  value: 'MORE_TIME',
  label: 'admin.page.step.compensation.types.time.type.more.time',
  disabled: false,
}, {
  value: 'SICKLEAVE',
  label: 'admin.page.step.compensation.types.time.type.sickleave',
  disabled: false,
}, {
  value: 'WORK_HOURS',
  label: 'admin.page.step.compensation.types.time.type.workhours',
  disabled: false,
}, {
  value: 'NO_DEVIATIONS',
  label: 'admin.page.step.compensation.types.time.type.no.deviation',
  disabled: true,
}, {
  value: 'CARE_OF_CHILD',
  label: 'admin.page.step.compensation.types.time.type.care.of.child',
  disabled: false,
}, {
  value: 'PARENTAL_LEAVE',
  label: 'admin.page.step.compensation.types.time.type.parental.leave',
  disabled: false,
}];

const types = [{
  value: 'ABSENCE',
  label: 'admin.page.step.compensation.types.type.absence',
  disabled: false,
}, {
  value: 'PRESENCE',
  label: 'admin.page.step.compensation.types.type.presence',
  disabled: false,
}];

const comments = [{
  value: 'REQUIRED',
  label: 'admin.page.step.compensation.types.comment.required',
}, {
  value: 'NO',
  label: 'admin.page.step.compensation.types.comment.no',
}, {
  value: 'OPTIONAL',
  label: 'admin.page.step.compensation.types.comment.optional',
}];

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE_ROW':
      return {
        types: types.map(type => ({
          ...type,
          disabled: type.value === 'ABSENCE' && action.payload.timeType === 'NO_DEVIATIONS' && action.payload.reportingType === 'FULLMONTH',
        })),
        reporting: reporting.map(type => ({
          ...type,
          disabled: action.payload.timeType === 'NO_DEVIATIONS' && type.value !== 'FULLMONTH',
        })),
        global: global.map(type => ({
          ...type,
          disabled: type.value === 'NO_DEVIATIONS' && (action.payload.type === 'ABSENCE' || action.payload.reportingType !== 'FULLMONTH'),
        })),
        intervals: state.intervals.map(({ value, label, code, }) => ({
          value,
          label,
          code,
          disabled: action.payload.reportingType === 'FULLDAY' && code !== 'FULL_DAY'
        }))
      };
    case 'REQUEST_REPORTING_TYPE_CHANGE':
      return {
        ...state,
        global: compose(
          map(type => ({
            ...type,
            disabled: type.value === 'NO_DEVIATIONS' && (action.payload.type === 'ABSENCE' || action.payload.reportingType !== 'FULLMONTH'),
          })),
          path(['global']),
        )(state),
        intervals: compose(
          map(interval => ({
            ...interval,
            disabled: action.payload === 'FULLDAY' && interval.code !== 'FULL_DAY'
          })),
          path(['intervals'])
        )(state)
      };
    case 'REQUEST_GLOBAL_TYPE_CHANGE':
      return {
        ...state,
        reporting: compose(
          map(type => ({
            ...type,
            disabled: action.payload === 'NO_DEVIATIONS' && type.value !== 'FULLMONTH',
          })),
          path(['reporting']),
        )(state),
      };
    case 'REQUEST_TYPE_CHANGE':
      return {
        ...state,
        global: compose(
          map(type => ({
            ...type,
            disabled: type.value === 'NO_DEVIATIONS' && (action.payload.type === 'ABSENCE' || action.payload.reportingType !== 'FULLMONTH'),
          })),
          path(['global']),
        )(state),
      };
    default:
      return state;
  }
};

const CompensationTypeTableRow = ({ setFieldTouched, setFieldValue, values, intervals, onUpdate, onRemove }) => {
  const { formatMessage: f } = useIntl();

  const [state, dispatch] = React.useReducer(reducer, {
    types,
    reporting,
    global,
    intervals,
  });

  React.useEffect(() => {
    dispatch({
      type: 'INITIALIZE_ROW',
      payload: {
        types: values.types,
        type: values.type,
        timeType: values.timeType,
        reportingType: values.reportingType,
      },
    });
  }, [values.reportingType, values.timeType, values.type]);

  return (
    <TableRow>
      <TableCell>
        <FastField
          name="code"
          component={StyledTextField}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          name="name"
          component={StyledTextField}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <Field
          fullWidth
          name="type"
          component={StyledSelect}
          SelectProps={{ IconComponent: () => null }}
          options={state.types.map(t => ({ ...t, label: f(pac[t.label]) }))}
          onChange={(e) => {
            dispatch({
              type: 'REQUEST_TYPE_CHANGE', payload: {
                type: e.value,
                timeType: values.timeType,
                reportingType: values.reportingType,
              }
            });
            onUpdate(e);
          }}
        />
      </TableCell>

      <TableCell>
        <Field
          fullWidth
          name="comment"
          SelectProps={{ IconComponent: () => null }}
          component={StyledSelect}
          options={comments.map(t => ({ ...t, label: f(pac[t.label]) }))}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <Field
          fullWidth
          name="reportingType"
          SelectProps={{ IconComponent: () => null }}
          component={StyledSelect}
          options={state.reporting.map(t => ({ ...t, label: f(pac[t.label]) }))}
          onChange={(e) => {
            dispatch({ type: 'REQUEST_REPORTING_TYPE_CHANGE', payload: {
                type: values.type,
                timeType: values.timeType,
                reportingType: e.value,
              } });
            onUpdate(e);

            if (e.value !== 'FULLDAY') return

            const fullDayInterval = compose(
              path(['value']),
              head,
              filter(pathEq(['code'], 'FULL_DAY')),
              path(['intervals']),
            )(state)

            setFieldValue('intervalId', fullDayInterval, true);
            setFieldTouched('intervalId', true, false);

            setTimeout(() => {
              onUpdate({
                ...e,
                value: fullDayInterval,
                key: 'intervalId',
              })
            }, 350)
          }}
        />
      </TableCell>

      <TableCell>
        <Field
          fullWidth
          name="intervalId"
          SelectProps={{ IconComponent: () => null }}
          component={StyledSelect}
          options={state.intervals}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          name="multipleDays"
          component={StyledCheckbox}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          name="displayInSummary"
          component={StyledCheckbox}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          name="isExcludeWeekend"
          component={StyledCheckbox}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <Field
          key={Date.now()}
          fullWidth
          name="timeType"
          SelectProps={{ IconComponent: () => null }}
          component={StyledSelect}
          options={state.global.map(t => ({ ...t, label: f(pac[t.label]) }))}
          onChange={e => {
            dispatch({ type: 'REQUEST_GLOBAL_TYPE_CHANGE', payload: e.value });
            onUpdate(e);
          }}
        />
      </TableCell>

      <TableCell>
        <FastField
          name="description"
          component={StyledTextField}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField name="id">
          {({ form: { values: { id } } }) => (
            <MoreVerticalMenu
              moreVertIconProps={{ fontSize: 'small' }}
              options={[{
                label: f(pac['admin.page.step.compensation.types.delete.button']),
                action: () => onRemove(id),
              }]}
            />
          )}
        </FastField>
      </TableCell>
    </TableRow>
  );
};

export default CompensationTypeTableRow;
