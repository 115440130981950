import React from 'react';
import { useIntl } from 'react-intl';
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Stepper = ({ active, steps, classes, onChange, ...props }) => {
  const { formatMessage: f } = useIntl();
  return (
    <MuiStepper
      classes={{ root: classes.stepper }}
      {...props}
    >
      {steps.map(({ id, label }, i) => (
        <Step
          key={id}
          completed={id < active}
          active={id === active}
          onClick={() => onChange(id)}
        >
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.iconRoot,
                text: classes.iconText,
                active: classes.iconActive,
                completed: classes.iconActive,
              },
            }}
          >
            {f(label)}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default withStyles(() => ({
  stepper: { background: 'none', padding: '0 0 15px 0' },
  iconRoot: {
    color: 'white',
    fontSize: '35px',
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    border: '1px solid #797979',
    cursor: 'pointer',
  },
  iconText: { fill: '#797979' },
  iconActive: {
    border: 'none',
    color: '#58A959 !important',
    '& text': { fill: 'white' },
  },
}))(Stepper);
