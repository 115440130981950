import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { TableBody } from '../../../components/Table';

import ProjectsEditableTrow from './ProjectsEditableTrow';

const ProjectsEditableSchema = Yup.object().shape({
  projectNumber: Yup.number()
    .typeError('Should contains only numbers')
    .min(1, 'Too Small!')
    .max(999999999, 'Too Big!')
    .required('Required'),
  projectName: Yup.string()
    .min(1, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required'),
  billable: Yup.number()
    .nullable()
    .required('Required'),
  enabled: Yup.string()
    .nullable()
    .required('Required'),
});

const PreFilledRow = React.memo(
  withFormik({
    mapPropsToValues: ({ data }) => ({
      ...data,
      billable: data.billable,
      enabled: data.enabled,
      activities: data.activities,
    }),
    validateOnMount: true,
    displayName: 'PreFilledProjectRow',
    validationSchema: ProjectsEditableSchema,
  })(ProjectsEditableTrow),
  () => true,
);

const ProjectEditableTBody = ({
   data = [],
   activities = [],
   AddNewRowComponent,
   onRowUpdate,
   onRowRemove,
}) => (
  <TableBody
    rows={data}
    onRowUpdate={onRowUpdate}
    onRowRemove={onRowRemove}
    AddNewRowComponent={AddNewRowComponent}
    PreFilledRow={PreFilledRow}
    preFilledRowProps={{ activities }}
  />
);

export default ProjectEditableTBody;
