import { defineMessages } from 'react-intl';

export default defineMessages({

  // ----Success---
  'app.notification.success.create': {
    id: 'app.notification.success.create',
    defaultMessage: 'Created',
    description: 'Success message for creating something (User, Department etc)',
  },
  'app.notification.success.delete': {
    id: 'app.notification.success.delete',
    defaultMessage: 'Deleted',
    description: 'Success message for deleting something (Department etc)',
  },

  'app.notification.success.update': {
    id: 'app.notification.success.update',
    defaultMessage: 'Updated',
    description: 'Success message for updating something',
  },
  'app.notification.success.default': {
    id: 'app.notification.success.default',
    defaultMessage: 'Success',
    description: 'Default success message - Currently not used',
  },
  // ------------

  'app.notification.error.default': {
    id: 'app.notification.error.default',
    defaultMessage: 'Something went wrong',
    description: "Default error message which can't be isolated",
  },
  'app.notification.error.notNull': {
    id: 'app.notification.error.notNull',
    defaultMessage: 'can not be empty',
    description: 'Error message for an empty field that is not allowed to be empty',
  },
  'app.notification.error.invalid': {
    id: 'app.notification.error.invalid',
    defaultMessage: 'is not valid',
    description: 'Error message when invalid data in a field',
  },
  'app.notification.error.field': {
    id: 'app.notification.error.field',
    defaultMessage: 'Field',
    description: "translation for 'Field' word when invalid data in a field",
  },

  'app.notification.period.reminded': {
    id: 'app.notification.period.reminded',
    defaultMessage: 'Period reminded',
    description: "Notification - Period reminded",
  },
  'app.notification.periods.reminded': {
    id: 'app.notification.periods.reminded',
    defaultMessage: 'All periods reminded',
    description: "Notification - All periods reminded",
  },
});
