import { fromPairs } from 'ramda';

export default fromPairs([
  'SET_AUTH',
  'SET_USER_ADDITIONAL',
  'SHOW_NOTIFICATION',
  'HIDE_NOTIFICATION',
  'SET_SELECTED_PERIOD',
  'CHANGE_USER_LOCALE',
  'CLEAR_AUTH',
  'SET_SELECTED_DEPARTMENT',
].map(k => [k, k]));
