import React,  { useState } from 'react';
import { useIntl } from 'react-intl';
import { compose, isEmpty } from 'ramda';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { ROLES } from '../../../../../../../config/role-configs';
import Loader from '../../../../../../../components/Loader';
import { UsersImportTable } from '../../../UsersImportTable';
import pab from '../../../../../../../config/intlMessageSelectors/payroll-admin-base';
import pac from '../../../../../../../config/intlMessageSelectors/payroll-admin-customers';
import { graphql } from 'react-apollo';
import { CREATE_WIZARD_USERS } from '../../../../../../../queries/customers';
import { useParams } from 'react-router-dom';
import { getErrorObjs, getErrorMessage } from '../../../../../../../utils/error';
import { withStyles } from "@material-ui/core/styles";

const APPROVER_EMPTY_COLUMNS = [
  'employmentNumber',
  'employmentCategoryName',
  'departmentName',
];

const styles = {
  contentWrapper: {
    marginTop: 1
  },
  noContentWrapper: {
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  actions: { padding: 12 },
  dialogContent: { paddingBottom: 0 }
};

const filterByRole = (data = {}) => {
  return Object.keys(data).reduce((acc, item) => {
    const value = data[item];
    const { role } = data;
    if (role === ROLES.APPROVER) {
      if(value || !APPROVER_EMPTY_COLUMNS.includes(item)) {
        acc[item] = value;
      }
    } else {
      acc[item] = value;
    }

    return acc;
  }, {});
};

const isValidUserData = (data = {}) => Object.values(data).reduce((acc, item) => {
  if(item) {
    acc++;
  }
  return acc;
}, 0);

const ImportDialog = ({
  isOpen,
  onClose,
  data,
  onError,
  onChange,
  createWizardUsers,
  tenantId,
  classes
}) => {
  const { formatMessage: f } = useIntl();
  const { id: customerId } = useParams();
  const [invalidRows, setInvalidRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const filteredData = data.valid.filter(item => !isEmpty(item) && isValidUserData(item));

      await createWizardUsers({
        variables: {
          input: [
            ...filteredData.map(item => {
              const employeeStartMoment = moment(item.employeeStart);

              return {
                ...filterByRole(item),
                customerId: +customerId,
                employeeStart: employeeStartMoment && employeeStartMoment.isValid() ? employeeStartMoment : item.employeeStart,
                tenantId
              }
            })
          ]
        }
      });
      onClose();
      onChange();
    } catch(e) {
      const errorObj = getErrorObjs(e)[0];

      if(errorObj && errorObj.messageCode) {
        onError(getErrorMessage(errorObj, f));
        if(errorObj.payload) {
          setInvalidRows([errorObj.payload.index]);
        }
      } else {
        onError();
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>
          {f(pac['admin.page.users.import.step.title'])}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {
            (!!data.valid.length || !!data.invalid.length) ? (
              <div className={classes.contentWrapper}>
                <UsersImportTable
                  data={data}
                  invalidRows={invalidRows}
                  onTabChange={(tab) => setIsDisabledConfirm(tab !== 'valid')}
                />
              </div>
            ) : (
              <div className={classes.noContentWrapper}>
                {f(pac['admin.page.users.import.noData'])}
              </div>
            )
          }
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            {f(pab['app.base.btns.cancel'])}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!data.valid.length || !!invalidRows.length || loading || isDisabledConfirm}
          >
            {f(pab['app.base.btns.confirm'])}
          </Button>
        </DialogActions>

        <Loader open={loading} />
      </Dialog>
    </>
  );
}

export default compose(
  graphql(CREATE_WIZARD_USERS, { name: 'createWizardUsers' }),
  withStyles(styles)
)(ImportDialog);
