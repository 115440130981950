import React from 'react';
import moment from 'moment';
import { compose, filter, propEq, pathOr } from 'ramda';
import { Grid } from '@material-ui/core';
import {
  MainLayout,
  TransactionsReports,
  Periods,
  Departments,
  OverviewTable,
} from './components';
import { DatePicker } from '../../components/DatePicker';
import { Warning } from '../../components/Warning';
import { hasTimeReports } from '../../utils/user';
import { DEFAULT_DATE_FORMAT } from "../../utils/times";

const TimeReportsOverview = ({
  selectedCustomer,
  additional,
  setSelectedPeriod,
  setSelectedDepartment,
  auth = {}
}) => {
  const [periods, setPeriods] = React.useState([]);

  const hasSelectedPeriod = compose(
    pathOr(false, [0, 'periodId']),
    filter(propEq('id', selectedCustomer)),
  )(additional.customers);

  if (!hasTimeReports(auth.user)) {
    return (
      <Warning id="app.base.message.noModuleConfig"/>
    )
  }

  if (!additional.selectedPeriod && !hasSelectedPeriod) {
    return (
      <Warning id="app.base.message.noPeriods" />
    );
  }

  const period = compose(
    pathOr(moment().format(DEFAULT_DATE_FORMAT), [0, 'startDate']),
    filter(propEq('id', additional.selectedPeriod)),
  )(periods);

  return (
    <Grid container direction="column">
      <Grid item xs={12} container justify="space-between" alignContent="center">
        <Grid item sm={12} md={3}>
          <Periods
            selectedCustomer={selectedCustomer}
            setSelectedPeriod={setSelectedPeriod}
            setPeriods={setPeriods}
            {...additional}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Departments
            selectedCustomer={selectedCustomer}
            setSelectedDepartment={setSelectedDepartment}
            selectedDepartment={additional.selectedDepartment}
          />
        </Grid>

        <Grid item sm={12} md={9} container alignContent="center" justify="flex-end">
          <TransactionsReports
            components={{
              StartDateComponent: DatePicker,
              EndDateComponent: DatePicker,
            }}
            start={moment(period).startOf('month')}
            end={moment(period).endOf('month')}
            customerId={selectedCustomer}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 52 }}>
        <OverviewTable
          {...additional}
          selectedCustomer={selectedCustomer}
        />
      </Grid>
    </Grid>
  );
};

const TimeReportsPage = props => (
  <MainLayout {...props}>
    {rest => (
      <TimeReportsOverview {...rest} />
    )}
  </MainLayout>
);

export default TimeReportsPage;
