import React, {useEffect} from 'react';
import { useLazyQuery } from 'react-apollo';
import { GET_NETTLONN_USERS } from '../../../../../../../queries/customers';
import nettlonnLogo from '../../../../../../../styles/nettlonnLogo.png';
import { getErrorObjs, getErrorMessage } from '../../../../../../../utils/error';
import { Button, CircularProgress } from '@material-ui/core';
import { useIntl } from "react-intl";

const styles = {
  color: '#3B77B5',
  textTransform: 'inherit',
  border: '1px solid #3B77B5',
  height: 33
};

const NettlonButton = ({
  onChange,
  onError,
  organizationNumber,
}) => {
  const { formatMessage: f } = useIntl()
  const [getNettlonUsers, { data, loading }] = useLazyQuery(GET_NETTLONN_USERS, {
    onError(e){
      onError(getErrorMessage(getErrorObjs(e)[0], f));
    }
  });

  const onClick = async () => {
    try {
      await getNettlonUsers({
        variables: {
          organizationNumber
        }
      });
    } catch (e) {
      onError();
    }
  };

  useEffect(() => {
    if (data?.nettlonnUsers) {
      onChange(data?.nettlonnUsers);
    }
  }, [data?.nettlonnUsers]);

  return (
    <Button
      variant="outlined"
      style={styles}
      onClick={onClick}
      disabled={loading}
    >
      <img width={25} height={16} src={nettlonnLogo} alt="" />
      &nbsp;&nbsp;
      {
        loading && (
          <CircularProgress size={18} color="primary" />
        )
      }
    </Button>
  );
}

export default NettlonButton;
