import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CheckBoxOutlined = props => (
  <SvgIcon viewBox="0 0 13 14" {...props}>
    <path
      d="M11.7974 0.751709H1.20264C0.53844 0.751709 0 1.30043 0 1.97732V12.7744C0 13.4513 0.53844 14 1.20264 14H11.7974C12.4616 14 13 13.4513 13 12.7744V1.97732C13 1.30043 12.4616 0.751709 11.7974 0.751709ZM12.078 12.0093C12.078 12.5899 11.6162 13.0605 11.0465 13.0605H1.95341C1.38372 13.0604 0.921986 12.5898 0.921986 12.0093V2.74243C0.921986 2.16186 1.38372 1.69131 1.95341 1.69131H11.0467C11.6162 1.69131 12.078 2.16186 12.078 2.74243V12.0093Z"
    />
    <path
      d="M10.0439 5.26893L10.0048 5.22899C9.72289 4.94195 9.26632 4.94185 8.98484 5.22899L7.94862 6.28491C7.94834 6.28519 7.94797 6.28538 7.9477 6.28576L5.99373 8.27714L4.05397 6.30042C3.7723 6.01327 3.31555 6.01327 3.03397 6.30042L2.99488 6.34007C2.71321 6.62721 2.71321 7.09259 2.99488 7.37954L5.47797 9.91016C5.71216 10.1489 6.06684 10.1884 6.34178 10.0302C6.40733 9.99623 6.46902 9.95141 6.52387 9.89541L9.00706 7.36498C9.00743 7.36451 9.00798 7.36395 9.00844 7.36348L10.0439 6.30821C10.3255 6.02126 10.3253 5.55588 10.0439 5.26893Z"
    />
    <path
      d="M3.59574 1.65604C3.59574 2.04118 3.31444 2.34899 2.96741 2.34899H2.9333C2.58626 2.34899 2.30496 2.04118 2.30496 1.65604V0.697275C2.30496 0.312228 2.58626 0 2.9333 0H2.96741C3.31444 0 3.59574 0.312228 3.59574 0.697275V1.65604Z"
    />
    <path
      d="M10.695 1.65604C10.695 2.04118 10.4136 2.34899 10.0668 2.34899H10.0328C9.68562 2.34899 9.40442 2.04118 9.40442 1.65604V0.697275C9.40423 0.312228 9.68562 0 10.0325 0H10.0665C10.4136 0 10.695 0.312228 10.695 0.697275V1.65604Z"
    />
    <path
      d="M7.09931 1.65604C7.09931 2.04118 6.81801 2.34899 6.47107 2.34899H6.43687C6.08983 2.34899 5.80853 2.04118 5.80853 1.65604V0.697275C5.80853 0.312228 6.08983 0 6.43677 0H6.47098C6.81802 0 7.09931 0.312228 7.09931 0.697275V1.65604Z"
    />
  </SvgIcon>
);

export default CheckBoxOutlined
