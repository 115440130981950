import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Typography,
} from '@material-ui/core';
import CopyCustomerDialog from '../CopyCustomerDialog';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import pac from '../../../../config/intlMessageSelectors/payroll-admin-customers';

const CopyCustomerButton = ({ onChange, dataToCopy }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage: f } = useIntl();

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Button color="secondary" onClick={open}>
        <CopyIcon fontSize="small" />
        <Typography variant="subtitle1" color="secondary">
          &nbsp;{f(pac['admin.page.step.general.copy.button'])}
        </Typography>
      </Button>
      <CopyCustomerDialog
        open={isOpen}
        onClose={close}
        onChange={onChange}
        dataToCopy={dataToCopy}
      />
    </>
  );
};

export default CopyCustomerButton;
