import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from "react-intl";
import pac from "../../../config/intlMessageSelectors/payroll-admin-customers";

const styles = () => ({
  dialogActions: {
    display: "flex",
    justifyContent: 'space-between',
    margin: '0 20px 20px'
  },
});

const ResetHolidaysDialog = ({
  classes,
  isOpen,
  setDialog,
  resetHolidays,
  isLoading
}) => {
  const { formatMessage: f } = useIntl();

  return (
      <Dialog
        maxWidth={"xs"}
        fullWidth
        open={isOpen}
        onClose={() => setDialog(false)}
      >
        <DialogTitle>
          {f(pac['admin.page.step.general.popup.reset.holidays.title'])}
        </DialogTitle>
        <DialogContent>
          <Typography variant={"subtitle1"}>
            {f(pac['admin.page.step.general.popup.change.country.text'])}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="outlined" onClick={() => setDialog(false)}>
            {f(pac['admin.page.step.general.popup.reset.holidays.cancel.button'])}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => resetHolidays()}
            disabled={isLoading}
          >
            {f(pac['admin.page.step.general.popup.reset.holidays.reset.button'])}
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ResetHolidaysDialog);
