import { defineMessages } from 'react-intl';

export default defineMessages({
  'app.navigation.employees': {
    id: 'app.employees',
    defaultMessage: 'Employees',
    description:
      'This is the tooltip when hovering over the employee page menu icon',
  },
  'app.navigation.departments': {
    id: 'app.departments',
    defaultMessage: 'Departments',
    description:
      'This is the tooltip when hovering over the departments page menu icon',
  },
  'app.navigation.projects': {
    id: 'app.projects',
    defaultMessage: 'Projects',
    description:
      'This is the tooltip when hovering over the projects page menu icon',
  },
  'app.navigation.timeReport': {
    id: 'app.efficiency.timeReport',
    defaultMessage: 'Time Report',
    description:
      'This is the tooltip when hovering over the timereport page menu icon',
  },
  'app.navigation.admin': {
    id: 'app.efficiency.admin',
    defaultMessage: 'Admin',
    description: 'Title for admin tab',
  },
  'app.base.importInfo': {
    id: 'app.base.importInfo',
    defaultMessage: 'Import from file',
    description: 'Import info text',
  },
  'app.base.month.january': {
    id: 'app.base.month.january',
    defaultMessage: 'January',
  },
  'app.base.month.february': {
    id: 'app.base.month.february',
    defaultMessage: 'February',
  },
  'app.base.month.march': {
    id: 'app.base.month.march',
    defaultMessage: 'March',
  },
  'app.base.month.april': {
    id: 'app.base.month.april',
    defaultMessage: 'April',
  },
  'app.base.month.may': {
    id: 'app.base.month.may',
    defaultMessage: 'May',
  },
  'app.base.month.june': {
    id: 'app.base.month.june',
    defaultMessage: 'June',
  },
  'app.base.month.july': {
    id: 'app.base.month.july',
    defaultMessage: 'July',
  },
  'app.base.month.august': {
    id: 'app.base.month.august',
    defaultMessage: 'August',
  },
  'app.base.month.september': {
    id: 'app.base.month.september',
    defaultMessage: 'September',
  },
  'app.base.month.october': {
    id: 'app.base.month.october',
    defaultMessage: 'October',
  },
  'app.base.month.november': {
    id: 'app.base.month.november',
    defaultMessage: 'November',
  },
  'app.base.month.december': {
    id: 'app.base.month.december',
    defaultMessage: 'December',
  },

  //------

  'app.base.btns.confirm': {
    id: 'app.base.btns.confirm',
    defaultMessage: 'Confirm',
    description: 'This is the default button used for submission',
  },
  'app.base.btns.cancel': {
    id: 'app.base.btns.cancel',
    defaultMessage: 'Cancel',
    description: 'This is the default button used for cancelling an action',
  },
  'app.base.btns.ok': {
    id: 'app.base.btns.ok',
    defaultMessage: 'OK',
    description: 'This is the default button used for closing dialog after informing',
  },
  'app.base.btns.remove': {
    id: 'app.base.btns.remove',
    defaultMessage: 'Remove',
    description: 'This is the name of the button used to delete something',
  },
  'app.base.select.placeholder': {
    id: 'app.base.select.placeholder',
    defaultMessage: 'Select...',
    description: 'This is a placeholder for an empty selection control',
  },
  'app.base.tablePaginationRowsPerPage': {
    id: 'app.base.tablePaginationRowsPerPage',
    defaultMessage: 'Rows per page',
    description: 'This is a pagination element at the bottom for lists',
  },
  'app.base.tablePaginationDisplayedRows': {
    id: 'app.base.tablePaginationDisplayedRows',
    defaultMessage: 'of',
    description: 'This is a pagination element at the bottom for lists',
  },
  'app.base.tableNoData': {
    id: 'app.base.tableNoData',
    defaultMessage: 'No data',
    description: 'This is placeholder for an empty table',
  },
  'app.base.tenantSelect': {
    id: 'app.base.tenantSelect',
    defaultMessage: 'SELECT TENANT',
    description: 'This is placeholder for the tenant selector',
  },
  'app.base.customerSelect': {
    id: 'app.base.customerSelect',
    defaultMessage: 'SELECT CUSTOMER',
    description: 'This is placeholder for the customer selector',
  },
  'app.base.logout': {
    id: 'app.base.logout',
    defaultMessage: 'LOG OUT',
    description: 'This is text for logout button',
  },
  'app.base.button.apply': {
    id: 'app.base.button.apply',
    defaultMessage: 'Apply',
    description: 'Apply button text for multi checkbox menu',
  },
  'app.base.button.add': {
    id: 'app.base.button.add',
    defaultMessage: 'Add',
    description: 'Add button text',
  },
  'app.base.button.cancel': {
    id: 'app.base.button.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button text for multi checkbox menu',
  },
  'app.base.button.import': {
    id: 'app.base.button.import',
    defaultMessage: 'Import',
    description: 'Import button title',
  },
  'app.base.button.choseFile': {
    id: 'app.base.button.choseFile',
    defaultMessage: 'Chose file',
    description: 'Chose file button title',
  },
  'app.base.button.mark.all': {
    id: 'app.base.button.mark.all',
    defaultMessage: 'Mark all',
    description: 'Text for mark all action (multi checkbox menu)',
  },
  'app.base.message.noModuleConfig': {
    id: 'app.base.message.noModuleConfig',
    defaultMessage: 'You have no modules configured. Please contact your manager or ECIT to activate a module.',
    description: 'No module config message',
  },
  'app.base.message.noProjectReporting': {
    id: 'app.base.message.noProjectReporting',
    defaultMessage: 'You have no project reporting enabled. Please contact your administrator.',
    description: 'No project reporting message',
  },
  'app.base.message.noPeriods': {
    id: 'app.base.message.noPeriods',
    defaultMessage: 'There are no periods available. Please contact your administrator.',
    description: 'No periods message',
  },
  'app.base.label.search': {
    id: 'app.base.label.search',
    defaultMessage: 'Search',
    description: 'Label for search input field',
  },
  'app.base.message.noEmployees': {
    id: 'app.base.message.noEmployees',
    defaultMessage: 'There are no employees available. Please contact your administrator.',
    description: 'No employees message',
  },
});
