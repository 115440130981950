export default {
  "app.timeReport.compensationType": "Typ",
  "app.timeReport.dateRange": "Datumintervall",
  "app.timeReport.transactionStatus": "Status",
  "app.timeReport.transactionStatusRejectBtn": "Neka",
  "app.timeReport.period": "Period",
  "app.timeReport.closed": "Stängd",
  "app.timeReport.open": "Öppen",
  "app.timeReport.closePeriodBtn": "Klarmarkera period",
  "app.timeReport.periodIsClosedBtn": "Perioden är redan stängd",
  "app.timeReport.periodIsClosedStatus": "Stängd period",
  "app.timeReport.transactionStatusApproveBtn": "Godkänn",
  "app.timeReport.transactionStatusApproved": "GODKÄND",
  "app.timeReport.transactionStatusExported": "EXPORTERAD",
  "app.timeReport.transactionStatusRejected": "NEKAD",
  "app.timeReport.transactionStatusPendingApproval": "Inväntar godkännande",
  "app.timeReport.transactionStatusArchived": "Arkiverat",
  "app.timeReport.transactionStatusSubmitted": "Skickat",
  "app.timeReport.createDeviation": "Skapa avvikelse",
  "app.timeReport.comment": "Kommentar",
  "app.timeReport.showLess": "Visa mindre",
  "app.timeReport.showMore": "Visa mer",
  "app.timeReport.rejectMessage": "Anledning",
  "app.timeReport.deviationsTable": "AVVIKELSER",
  "app.timeReport.details": "DETALJER",
  "app.timeReport.singleDayToggle": "En dag",
  "app.timeReport.periodToggle": "Period",
  "app.timeReport.formDuration": "Varaktighet",
  "app.timeReport.formStart": "Start",
  "app.timeReport.formEnd": "Slut",
  "app.timeReport.formDate": "Datum",
  "app.timeReport.user": "Användare",
  "app.timeReport.employees": "Anställda",
  "app.timeReport.status": "Status",
  "app.timeReport.absence": "Frånvaro",
  "app.timeReport.presence": "Närvaro",
  "app.timeReport.employee": "Anställd",
  "app.timeReport.allDepartments": "Alla avdelningar",
  "app.timeReport.department": "Avdelning",
  "app.timeReport.all": "Alla",
  "app.timeReport.notReported": "Ej rapporterade",
  "app.timeReport.waitingForApproval": "Väntar på godkännande",
  "app.timeReport.approved": "Godkänt",
  "app.timeReport.exported": "Exporterat",
  "app.timeReport.timeReportsTitle": "Tidrapporter",
  "app.timeReport.description": "Beskrivning",
  "app.timeReport.project": "Projekt",
  "app.timeReport.projectCompensationType": "Tidstyp",
  "app.timeReport.workType": "Aktivitet",
  "app.timeReport.projectHours": "Projektrapportering",
  "app.timeReport.active": "Aktiv",
  "app.timeReport.archived": "Arkiverat",
  "app.timeReport.reportingMoreThan24H": "Det är endast möjligt att registrera 24 timmar på ett dygn.",
  "app.timeReport.overlappedAbsence": "Antalet frånvarotimmar kan maximalt vara lika mycket timmarna på en arbetsdag.",
  "app.timeReport.reportingOnWeekendIsNotAllowed": "Det är inte möjligt att rapportera frånvaro på dessa dagar. Vänligen välj en annan dag.",
  "app.time.report.transations.report.subtitle": "Rapport Transaktioner per Avdelning/Attesgrupp",
  "app.time.report.transation.report.download.button": "Ladda ner rapport",
  "app.time.report.transations.error.no.transactions": "Inga transaktioner hittade för denna period",
  "app.time.report.transations.error.no.departments": "Inga avdelningar/attestgrupper hittades för denna kund",
  "app.time.report.transations.removeTransactionModal.title": "Är du säker på att ta bort denna avvikelse?",
  "app.time.report.transations.removeTransactionModal.body": "Rapporterade tider kommer att tas bort. Eventuella exporterade delar av registreringen kommer att bevaras.",
  "app.timeReport.userNotes": "Notering",
  "app.timeReport.transactionStatusPendingSubmit": "Väntar på att skickas in",
  "app.timeReport.createTransaction": "Skapa transaktion",
  "app.timeReport.formTime": "Tid",
  "app.timeReport.editTransaction": "Ändra transaktion",
  "app.timeReport.createTransactionModalTitle": "Skapa transaktion",
  "app.timeReport.editTransactionModalTitle": "Ändra transaktion"
}
