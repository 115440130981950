import * as moment from 'moment';
import {
  compose,
  difference,
  filter,
} from 'ramda';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

/* eslint-disable */
export const getEuropeStockholmWithFormat = (date, format) => moment(date).format(format || 'LLL');

export const msToHMS = (msInput) => {
  const ms = Number(msInput);
  if (ms === 0) {
    return '0';
  }
  // const sign = Math.sign(ms) < 0 ? '-' : '';
  // 1- Convert to seconds:
  let seconds = Math.abs(ms) / 1000;
  // 2- Extract hours:
  const hours = parseInt(seconds / 3600, 10); // 3,600 seconds in 1 hour
  seconds %= 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = _convertMinsToHrsMins(parseInt(seconds / 60, 10)); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds %= 60;

  let output = '';
  if (hours >= 1) {
    output += `${hours}h`;
  }
  if (minutes) {
    output += ` ${minutes}m`;
  }
  return output;
};

function _convertMinsToHrsMins(mins) {
  const m = mins % 60;
  if (m !== 0) {
    return m < 10 ? `0${m}` : m;
  }

  return null;
}

export function getDisabledHoursForDay(workTimeInMS = 0, isOverlapping = false) {
  if (!workTimeInMS || isOverlapping) return [];

  const workDayInHrs = Math.floor(workTimeInMS / (1000 * 3600));
  return [...Array(24 - workDayInHrs).keys()].map((index) => 24 - index);
}

const INTERVALS = {
  '1_MIN': 1,
  '5_MINS': 5,
  '10_MINS': 10,
  '15_MINS': 15,
  '30_MINS': 30,
  '1_HOUR': 60,
  'HALF_DAY': 'HALF_DAY',
  'FULL_DAY': 'FULL_DAY',
}

const mins = Array.from({ length: 60 }, (_, k) => k);

export function getDisabledMinutes({ h, normalWorkDay, interval = '1_MIN' }) {
  if ([
    INTERVALS['HALF_DAY'],
    INTERVALS['FULL_DAY']].includes(interval)
  ) return [];

  const hours = parseInt((normalWorkDay / (1000 * 60 * 60)) % 24, 10);
  const minutes = parseInt((normalWorkDay / (1000 * 60)) % 60, 10);
  const isMax = h === hours && h !== 0;
  const isHour = INTERVALS[interval] === INTERVALS['1_HOUR'];

  return compose(
    difference(mins),
    filter(m => {
      if (isMax) {
        return (m % INTERVALS[interval] === 0 && m <= minutes)
          || (isHour && m === minutes || m === 0);
      }
      return m % INTERVALS[interval] === 0;
    }),
  )(mins)
}

export const getDateTimeData = ({
  start, end, date, duration, children, format, compensationType
}, currentPeriod, customFormat) => {
  const timeFormat = customFormat || format;
  const startCopy = getEuropeStockholmWithFormat(start, timeFormat);
  const endCopy = getEuropeStockholmWithFormat(end, timeFormat);
  const dateCopy = getEuropeStockholmWithFormat(date, timeFormat);
  const durationCopy = msToHMS(duration);

  const result = {
    dateRange: '',
    duration: ''
  };
  if (start) {
    result.dateRange = startCopy;

    if (end) {
      result.dateRange += ` → ${endCopy}`;
    }

    if (duration) {
      date.duration = durationCopy;
    }
  } else {
    result.dateRange = dateCopy;
    result.duration = durationCopy;

    if (compensationType.timeType === "NO_DEVIATIONS" && compensationType.reportingType === "FULLMONTH") {
      result.dateRange = `${currentPeriod.startDate} → ${currentPeriod.endDate}`;
    }

    if (children.length) {
      let durationSum = duration;
      let durationPeriod = currentPeriod && dateCopy >= currentPeriod.startDate && dateCopy <= currentPeriod.endDate ? duration : 0;

      const rangeEnd = children.reduce((memo, el) => {
        const date =  getEuropeStockholmWithFormat(el.date, timeFormat);
        if (currentPeriod && date >= currentPeriod.startDate && date <= currentPeriod.endDate) {
          durationPeriod += el.duration;
        }

        if (!memo || el.date >= memo.date) {
          durationSum += el.duration;
          return el;
        }
        return memo;
      }, null);

      const rangeEndDateString = getEuropeStockholmWithFormat(rangeEnd.date, timeFormat);
      const rangeEndDurationHours = msToHMS(durationSum);
      const durationPeriodHours = msToHMS(durationPeriod);

      result.dateRange += ` → ${rangeEndDateString}`;
      result.duration = (!!durationPeriod && rangeEndDurationHours !== durationPeriodHours) ? `${durationPeriodHours} (${rangeEndDurationHours})` : rangeEndDurationHours;
    }
  }

  return result;
};

export function getDateTimeString(data) {
  const { dateRange, duration } = getDateTimeData(data);

  return `${dateRange} / ${duration}`;
}
