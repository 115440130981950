import React from 'react';
import { connect } from 'react-redux';
import {
  compose,
  head,
  filter,
  path,
  pathEq,
  pathOr,
  allPass,
  propEq,
} from 'ramda';
import { useHistory } from 'react-router-dom'
import PageTemplate from '../../../../components/PageTemplate';
import { setAuth } from '../../../../actions/auth';
import {
  setUserAdditional,
  setSelectedPeriod,
  setSelectedDepartment,
} from '../../../../actions/context';

const Layout = ({ children, ...rest }) => {
  const history = useHistory()

  React.useEffect(() => {
    if (!rest.isManager || rest.canManage) return;

    const periodId = compose(
      path([0, 'periodId']),
      filter(allPass([
        propEq('id', rest.auth.user.selectedCustomer),
        propEq('tenantId', rest.auth.user.selectedTenant),
      ])),
    )(rest.additional.customers);

    history.push(`/time-report-details/${periodId}/${rest.userId}`);
    // eslint-disable-next-line
  }, []);

  return (
    <PageTemplate
      editable
      {...rest}
      activeRoute="TimeReport"
    >
      {children(rest)}
    </PageTemplate>
  );
};

const mapStateToProps = ({
  auth,
  context: additional,
}) => {
  const selectedCustomer = path(['user', 'selectedCustomer'], auth);
  const isManager = pathEq(['user', 'role'], 'manager', auth);

  const canManage = compose(
    pathOr(false, ['isManager']),
    head,
    filter(pathEq(['id'], selectedCustomer)),
    path(['customers']),
  )(additional);

  return {
    additional,
    selectedCustomer,
    isManager,
    canManage,
    userId: auth.user.id,
  };
};

const MainLayout = connect(
  mapStateToProps,
  { setUserAdditional, setAuth, setSelectedPeriod, setSelectedDepartment },
)(Layout);

export default MainLayout;
