/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'core-js/stable';

import 'raf/polyfill';
import 'react-dates/initialize';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'

function bootstrap() {
  ReactDOM.render(<App />, document.querySelector('#root'));
}

bootstrap();
