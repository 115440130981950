import React from 'react';
import { Grid } from '@material-ui/core';
import { MenuHeader, Options, MenuActions } from './components';

const Menu = ({
  onCheckOne,
  onCheckAll,
  onSelect,
  onCancel,
  options,
  selected,
}) => (
  <Grid
    container
    justify="center"
    alignItems="center"
    style={{ width: (options.length <= 2 && '344px') || '500px' }}
  >
    <MenuHeader onCheckAll={onCheckAll} options={options} selected={selected} />
    <Options onCheckOne={onCheckOne} options={options} selected={selected} />
    <MenuActions onSelect={onSelect} onCancel={onCancel} />
  </Grid>
);

export default Menu;
