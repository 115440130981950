import React from 'react';
import { DownloadButton } from '../../../../../components/DownloadButton';

const columns = [
  {
    id: 'projectNumber',
    displayName: 'projectNumber'
  },
  {
    id: 'projectName',
    displayName: 'projectName'
  },
  {
    id: 'billable',
    displayName: 'billable'
  },
  // {
  //   id: 'enabled',
  //   displayName: 'enabled'
  // },
];

const data = [
  {
    projectNumber: 1,
    projectName: 'Project 1',
    billable: 0,
    // enabled: 1,
  },
  {
    projectNumber: 2,
    projectName: 'Project 2',
    billable: 1,
    // enabled: 1,
  }
];

const DownloadExampleButton = () => (
  <DownloadButton
    columns={columns}
    data={data}
  />
);

export default DownloadExampleButton;
