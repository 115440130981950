import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useEnvironmentConfig } from '../hooks/useEnvironmentConfig'

export const AuthorizationProvider = ({ children }) => {
  const {
    AUTH0_AUDIENCE,
    AUTH0_CLIENT_ID,
    AUTH0_DOMAIN,
  } = useEnvironmentConfig();

  return (
    <Auth0Provider
      audience={AUTH0_AUDIENCE}
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};
