import React from 'react';
import { Table, Paper } from '@material-ui/core';
import EmploymentCategoryTbody from './EmploymentCategoryTbody';
import { TableHead } from '../components/Table';

const EmploymentCategoryTable = ({
  headers,
  types,
  categories,
  AddNewRowComponent,
  onRowUpdate,
  onRowRemove,
}) => (
  <Paper
    style={{
      height: '100%',
      width: '100%',
      overflow: 'auto',
      marginBottom: '72px',
    }}
  >
    <Table>
      <TableHead headers={headers} />
      <EmploymentCategoryTbody
        types={types}
        categories={categories}
        AddNewRowComponent={AddNewRowComponent}
        onRowUpdate={onRowUpdate}
        onRowRemove={onRowRemove}
      />
    </Table>
  </Paper>
);

export default EmploymentCategoryTable;
