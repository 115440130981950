import { fromPairs } from 'ramda';

export const envs = fromPairs([
  'production',
  'development',
  'staging',
  'ci',
].map(k => [k.toUpperCase(), k]));

export const env = envs[process.env.NODE_ENV.toUpperCase() || envs.DEVELOPMENT];

export const isProduction = process.env.NODE_ENV.toUpperCase() === envs.PRODUCTION;
