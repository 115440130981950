import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { compose } from 'ramda';
import {
  EditOutlined as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  MoreVert as MoreVertIcon
} from '@material-ui/icons';
import {
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  Typography,
  withWidth,
} from '@material-ui/core';
import { isWidthUp } from "@material-ui/core/withWidth";
import { withApollo } from "react-apollo";
import { withStyles } from '@material-ui/core/styles';

import ResetHolidaysDialog from "./ResetHolidaysDialog";
import ChangeCountryDialog from "./ChangeCountryDialog";
import HolidayDialog from "./HolidayDialog";
import pac from '../../../config/intlMessageSelectors/payroll-admin-customers';
import { Select as StandardSelect } from '../../../components/Select';
import { GET_COUNTRY_HOLIDAYS } from "../../../queries/customers";
import fullDayIcon from '../../../styles/full-day.svg';
import halfDayIcon from '../../../styles/half-day.svg';
import { DEFAULT_DATE_FORMAT } from "../../../utils/times";

const styles = (theme) => ({
  wrapper: { margin: '15px 0' },
  resetBtn: { marginLeft: 8 },
  secondaryButton: { color: '#3B77B5', fontWeight: 400 },
  holidaysBlock: { marginTop: 16, rowGap: `${theme.spacing.unit}px` },
  holidayWrapper: {
    border: '1px solid #D6D6D6',
    padding: 10,
    height: 62,
  },
  holidayItem: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between'
  },
  holidayItemInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  holidayName: {
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: 1,
  },
  holidayDate: {
    fontSize: 14,
    lineHeight: 1,
  },
  iconMore: { padding: 0 },
  iconDuration: { opacity: 0.5, height: 16 },
  menuMore: { padding: 4 },
  menuMoreIcon: { margin: '0 0 0 8px' },
});

export const HOLIDAY_DURATION = {
  FULL_DAY: 'FULL_DAY',
  HALF_DAY: 'HALF_DAY',
}

const HolidaysSection = ({
  client,
  values,
  classes,
  width,
  setFieldValue,
  isHolidayLoading,
  setIsHolidayLoading,
}) => {
  const { formatMessage: f } = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [resetDialog, setResetDialog] = useState(false);
  const [changeCountryDialog, setChangeCountryDialog] = useState(false);

  const [prevCountry, setPrevCountry] = useState(null);
  const [holidayYears, setHolidayYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const widthLg = isWidthUp('lg', width);
  const widthMd = isWidthUp('md', width);
  const widthSm = isWidthUp('xs', width);
  useEffect(() => {
    if ((!!values.holidays.length && prevCountry && prevCountry !== values.customer.countryId )) {
      setChangeCountryDialog(true);
    } else {
      setPrevCountry(values.customer.countryId);
    }

    if (values.customer.countryId && !values.holidays.length) {
      fetchCountryHolidays(values.customer.countryId);
    }
  },[values.customer.countryId])

  useEffect(() => {
    if (!!values.holidays.length) {
      let initYear;
      const currentYear = new Date().getFullYear();
      const uniqueYears = [...new Set(values.holidays.map(({ date }) => parseInt(date)))];
      setHolidayYears(uniqueYears);
      if (uniqueYears.includes(currentYear)) {
        initYear = currentYear;
      } else {
        initYear = uniqueYears[0];
      }

      setSelectedYear(selectedYear || initYear);
    }
  },[values.holidays]);

  const fetchCountryHolidays = async countryId => {
    setIsHolidayLoading(true);
    const { data: { countryHolidays } } = await client.query({
      query: GET_COUNTRY_HOLIDAYS,
      variables: { filter: { countryId, isOfficial: true } },
    });
    setHolidayYears([]);
    setIsHolidayLoading(false);
    setResetDialog(false);
    setChangeCountryDialog(false);
    const mappedCountryHolidays = countryHolidays.map(holiday => ({
      ...holiday,
      duration: HOLIDAY_DURATION.FULL_DAY
    }));

    setFieldValue('holidays', mappedCountryHolidays);
  };

  const handlePopoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedHoliday(id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const deleteHoliday = () => {
    const filteredHolidays = values.holidays.filter((holiday => holiday.id !== selectedHoliday));
    setFieldValue('holidays', filteredHolidays);
    setSelectedHoliday(null);
    handlePopoverClose();
  };

  const changeCountry = () => {
    setSelectedYear(null);
    setPrevCountry(values.customer.countryId);
    fetchCountryHolidays(values.customer.countryId);
  };

  const closeCountryDialog = () => {
    setPrevCountry(values.customer.countryId);
    setChangeCountryDialog(false);
  };

  const yearsOptions = holidayYears.map(el => ({ label: el, value: el })) || [];
  const holidaysByYear = values.holidays.filter(({ date }) => parseInt(date) === selectedYear);
  const holidaysRowCnt = widthLg ? 6 : widthMd ? 4 : widthSm && 2;

  return (
    <React.Fragment>
      <Grid
        item
        container
        className={classes.wrapper}
        alignItems="center"
      >
        <Grid container justify="space-between">
          <Grid item container alignItems={'center'} xs={6} spacing={16}>
            <Grid item>
              <Typography variant="h5">
                {f(pac['admin.page.step.general.holidays'])} (
                {holidaysByYear.length})
              </Typography>
            </Grid>
            <Grid item>
              <StandardSelect
                value={selectedYear || ''}
                options={yearsOptions}
                onChange={(_, value) => setSelectedYear(+value.key)}
              />
              <Button
                size={'small'}
                className={classes.resetBtn}
                classes={{ root: classes.secondaryButton }}
                onClick={() => setResetDialog(true)}
                disabled={!values.holidays.length || !values.customer.countryId || isHolidayLoading}
              >
                {f(pac['admin.page.step.general.reset.button'])}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              classes={{ root: classes.secondaryButton }}
              onClick={() => {
                setSelectedHoliday(null);
                setEditDialog(true);
              }}
              disabled={!values.customer.countryId}
            >
              + {f(pac['admin.page.step.general.add.holiday.button'])}
            </Button>
          </Grid>
        </Grid>

        <Grid container className={classes.holidaysBlock}>
          {holidaysByYear.map((holiday, i) => {
            const isPast = holiday.date < moment().format(DEFAULT_DATE_FORMAT);
            const isEditable = values.customer.status >= 3 ? !Number(holiday.id) || !isPast : true;

            return (
              <Grid
                key={i}
                item
                xs={6}
                sm={6}
                md={3}
                lg={2}
                container
                className={classes.holidayWrapper}
                style={{
                  borderLeft: ( i % holidaysRowCnt === 0 ) ? '1px solid #D6D6D6' : 0,
                  backgroundColor: isPast ? '#F5F5F5' : 'transparent',
                }}
              >
                <div className={classes.holidayItem}>
                  <div className={classes.holidayItemInfo}>
                    <Typography className={classes.holidayName}>
                      {holiday.name}
                    </Typography>
                    <Typography className={classes.holidayDate}>
                      {moment(holiday.date).format('DD MMM YYYY')}
                    </Typography>
                  </div>
                  <div className={classes.holidayItemInfo}>
                    <IconButton
                      className={classes.iconMore}
                      style={{ visibility: (!isEditable) ? 'hidden' : '' }}
                      onClick={(e) => handlePopoverOpen(e, holiday.id)}
                    >
                      <MoreVertIcon fontSize={'small'} />
                    </IconButton>
                    {holiday.duration === HOLIDAY_DURATION.FULL_DAY
                      ? (
                        <Tooltip
                          title={f(pac['admin.page.step.general.holiday.full.day'])}
                          placement="bottom"
                        >
                          <img className={classes.iconDuration} src={fullDayIcon} alt='full-day' />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={f(pac['admin.page.step.general.holiday.half.day'])}
                          placement="bottom"
                        >
                          <img className={classes.iconDuration} src={halfDayIcon} alt='half-day' />
                        </Tooltip>
                      )
                    }
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <Popover
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          <MenuItem
            className={classes.menuMore}
            onClick={() => {
              handlePopoverClose();
              setEditDialog(true);
            }}
          >
            <ListItemIcon className={classes.menuMoreIcon}>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={f(pac['admin.page.step.general.holiday.menu.edit'])} />
          </MenuItem>
          <MenuItem className={classes.menuMore} onClick={() => deleteHoliday()}>
            <ListItemIcon className={classes.menuMoreIcon}>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={f(pac['admin.page.step.general.holiday.menu.remove'])} />
          </MenuItem>
        </MenuList>
      </Popover>

      {editDialog && (
        <HolidayDialog
          holidays={values.holidays}
          selectedHoliday={selectedHoliday}
          isOpen={editDialog}
          setDialog={setEditDialog}
          setFieldValue={setFieldValue}
          setSelectedHoliday={setSelectedHoliday}
          selectedYear={selectedYear}
        />
      )}

      {resetDialog && (
        <ResetHolidaysDialog
          isOpen={resetDialog}
          setDialog={setResetDialog}
          resetHolidays={() => {
            setSelectedYear(null);
            fetchCountryHolidays(values.customer.countryId);
          }}
          isLoading={isHolidayLoading}
        />
      )}

      {changeCountryDialog && (
        <ChangeCountryDialog
          isOpen={changeCountryDialog}
          closeDialog={closeCountryDialog}
          changeCountry={changeCountry}
          isLoading={isHolidayLoading}
        />
      )}

    </React.Fragment>
  );
};

export default compose(
  withStyles(styles),
  withApollo,
  withWidth(),
)(HolidaysSection);
