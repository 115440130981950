import { defineMessages } from 'react-intl';

export default defineMessages({
  'app.departments.createDepartment': {
    id: 'app.departments.createDepartment',
    defaultMessage: 'Create department',
    description: 'Text for the button to create a department',
  },
  'app.departments.department': {
    id: 'app.departments.department',
    defaultMessage: 'Department',
  },
  'app.departments.approvers': {
    id: 'app.departments.approvers',
    defaultMessage: 'Approvers',
    description: 'People who can approve departments',
  },
  'app.departments.approver': {
    id: 'app.departments.approver',
    defaultMessage: 'Approver',
    description: 'A person who can approve certain departments (single)',
  },
  'app.departments.noApproverAssigned': {
    id: 'app.departments.noApproverAssigned',
    defaultMessage: 'No approver assigned',
    description: 'In the departments view, to notify there is no approver in a department',
  },
  'app.departments.employees': {
    id: 'app.departments.employees',
    defaultMessage: 'Employees',
  },
  'app.departments.firstName': {
    id: 'app.departments.firstName',
    defaultMessage: 'First Name',
  },
  'app.departments.lastName': {
    id: 'app.departments.lastName',
    defaultMessage: 'Last Name',
  },
  'app.departments.phone': {
    id: 'app.departments.phone',
    defaultMessage: 'Phone',
  },

  'app.departments.role': {
    id: 'app.departments.role',
    defaultMessage: 'Role',
  },
  'app.departments.email': {
    id: 'app.departments.email',
    defaultMessage: 'Email',
  },
  'app.departments.createUserInsideDepartment': {
    id: 'app.departments.createUserInsideDepartment',
    defaultMessage: 'Create employee inside department',
    description: 'Departments - In a selected department, a buttontext',
  },
  'app.departments.addExistingUserToDepartment': {
    id: 'app.departments.addExistingUserDepartment',
    defaultMessage: 'Add existing employee to the department',
    description: 'Departments - In a selected department, a buttontext',
  },
  'app.departments.removeUserFromDepartment': {
    id: 'app.departments.removeUserFromDepartment',
    defaultMessage: 'Remove user from department',
    description: 'Departments - In a selected department, a buttontext',
  },
  'app.departments.removeDepartment': {
    id: 'app.departments.removeDepartment',
    defaultMessage: 'Are you sure you want to remove this department?'
          + ' Employees won\'t be able to time report until they have been'
          + ' reassinged to another department.',
    description: 'Confirmation text for department removal',
  },
  'app.departments.removeDepartmentTitle': {
    id: 'app.departments.removeDepartmentTitle',
    defaultMessage: 'Remove Department',
    description: 'Title for Department removal confirmation popup',
  },
  'app.departments.remove.user': {
    id: 'app.departments.remove.user',
    defaultMessage: 'Remove user',
    description: 'Button text for removing user (department details page, submenu)',
  },
  'app.departments.edit.user': {
    id: 'app.departments.edit.user',
    defaultMessage: 'Edit user',
    description: 'Button text for editing user (department details page, submenu)',
  },
});
