import React from 'react';
import { useIntl } from 'react-intl';
import { FastField } from 'formik';

import { TableCell, TableRow } from '../../../components/Table';
import {
  StyledTextField,
  StyledCheckbox,
} from '../../../components/StyledFields';
import ProjectsDrawer from '../ProjectsDrawer/ProjectsDrawer';
import MoreVerticalMenu from '../../../../../components/MoreVerticalMenu';
import pac from '../../../../../config/intlMessageSelectors/payroll-admin-customers';


const ProjectsEditableTableRow = ({ onUpdate, onRemove }) => {
  const { formatMessage: f } = useIntl();

  return (
    <TableRow>
      <TableCell>
        <FastField
          name="projectNumber"
          component={StyledTextField}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          name="projectName"
          component={StyledTextField}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          name="billable"
          component={StyledCheckbox}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          name="enabled"
          component={StyledCheckbox}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField
          fullWidth
          name="activities"
          component={ProjectsDrawer}
          onChange={onUpdate}
        />
      </TableCell>

      <TableCell>
        <FastField name="id">
          {({ form: { values: { id } } }) => (
            <MoreVerticalMenu
              moreVertIconProps={{ fontSize: 'small' }}
              options={[{
                label: f(pac['admin.page.step.compensation.types.delete.button']),
                action: () => onRemove(id),
              }]}
            />
          )}
        </FastField>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ProjectsEditableTableRow, () => true);
