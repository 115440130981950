import React from 'react';
import ReactSelect from 'react-select';
import TimePicker from '../TimePicker';

const INTERVALS = {
  '1_MIN': 1,
  '5_MINS': 5,
  '10_MINS': 10,
  '15_MINS': 15,
  '30_MINS': 30,
  '1_HOUR': 60,
  'HALF_DAY': 'HALF_DAY',
  'FULL_DAY': 'FULL_DAY',
};

const HalfDayInterval = ({ value, normalWorkDay, onChange, disabled }) => {
  const DAYS_OPTIONS = [
    { value: normalWorkDay / 2, label: 'Half day' },
    { value: normalWorkDay, label: 'Full day' },
  ];

  return (
    <ReactSelect
      isDisabled={disabled}
      isSearchable={false}
      defaultValue={DAYS_OPTIONS[1]}
      value={DAYS_OPTIONS.find(({ value: v }) => value === v)}
      options={DAYS_OPTIONS}
      styles={{
        dropdownIndicator: prov => ({ ...prov, display: 'none' }),
        indicatorSeparator: prov => ({ ...prov, display: 'none' }),
        container: prov => ({ ...prov, width: '80px' }),
        valueContainer: prov => ({
          ...prov,
          justifyContent: 'center',
          padding: '18px 14px',
        }),
        control: prov => ({
          ...prov,
          border: 'none',
          boxShadow: 'none',
          background: 'none',
        }),
        input: prov => ({
          ...prov,
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
          height: '17px',
        }),
      }}
      onChange={({ value }) => onChange(value)}
    />
  );
};

const FullDayInterval = ({disabled}) => (
  <div style={{ width: '100%', height: '53px', padding: '18px 14px', marginRight: '8px', color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)' }}>
    Full day
  </div>
);

const IntervalMapper = interval => {
  if (INTERVALS.HALF_DAY === interval) {
    return (props) => <HalfDayInterval {...props} />;
  }

  if (INTERVALS.FULL_DAY === interval) {
    return (props) => <FullDayInterval {...props} />;
  }

  return (props) => <TimePicker {...props} step={INTERVALS[interval]} />
};

const IntervalTimePicker = ({ interval = INTERVALS['1_MIN'], ...props }) => {
  const Picker = IntervalMapper(interval);

  if (!Picker) return null;

  return (
    <Picker {...props} step={INTERVALS[interval]} />
  );
};

export default IntervalTimePicker;
