import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { compose, ifElse, equals } from 'ramda';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import PageTemplate from '../components/PageTemplate';
import Loader from '../components/LoadingCircle';
import TimeReportDetailsDeviations from '../components/TimeReportDetails/TimeReportDetailsDeviations';
import {
  GET_USERS,
  GET_DATA_AS_EMPLOYEE,
  GET_EMPLOYEE_PERIODS,
  GET_EMPLOYEE_PERIOD_SUMMARY
} from '../queries/users';
import { setAuth } from '../actions/auth';
import { setUserAdditional } from '../actions/context';
import moment from "moment";
import { ROLES } from "../config/role-configs";
import { DEFAULT_DATE_FORMAT } from "../utils/times";

const TimeReportDetails = props => {
  const { userId, month } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState({
    users: [],
    periods: [],
    currentUser: {},
    currentPeriod: {},
    periodSummary: []
  });

  const fetchTimeReportDetails = async () => {
    const {
      auth: {
        user,
        user: { role },
      },
      selectedCustomer,
      roleConfigs: { manageRoles, usersToApprove },
      client,
    } = props;

    const { data: { employeePeriods } } = await client.query({
      query: GET_EMPLOYEE_PERIODS,
      variables: {
        employeeId: +userId
      }
    });

    const variables = {
      input: {
        order: 'firstName asc',
        filters: {
          role: manageRoles,
          or: {
            id: usersToApprove || ['customeradmin', 'manager'].includes(user.role) ? [user.id] : undefined,
          },
          customerId: selectedCustomer,
        },
      },
      input2: {
        filters: {
          role: manageRoles,
          or: {
            id: usersToApprove || ['customeradmin', 'manager'].includes(user.role)  ? [user.id] : undefined,
          },
          customerId: selectedCustomer,
        },
      },
      input3: { filters: { customerId: selectedCustomer } },
      input4: { filters: { customerId: selectedCustomer } },
    };

    const query = ifElse(
      equals('employee'),
      () => GET_DATA_AS_EMPLOYEE,
      () => GET_USERS,
    )(role);

    const { data } = await client.query({
      query,
      variables,
      fetchPolicy: 'network-only',
    });

    const { data: { employeePeriodSummary: periodSummary } } = await client.query({
      query: GET_EMPLOYEE_PERIOD_SUMMARY,
      variables: {
        employeeId: +userId,
        employeePeriodId: +month
      }
    });

    const isManager = props.auth.user.role !== ROLES.EMPLOYEE
    const currentPeriod = employeePeriods.find(el => el.id === month)

    const filteredUsers = data.users && data.users.filter(
      user => user.role !== ROLES.APPROVER &&
      moment(user.employeeEnd).format(DEFAULT_DATE_FORMAT) >= currentPeriod.startDate);
    const users = filteredUsers ? filteredUsers: [user];
    const currentUser = users.find(el => el.id === Number(userId));

    const userPeriods = (employeePeriods || []).filter(({ startDate, endDate, id }) => {
      const employeeStart = moment(currentUser?.employeeStart)
      const employeeEnd = moment(currentUser?.employeeEnd || endDate)

      return moment(startDate).isBetween(employeeStart, employeeEnd)
        || moment(startDate).format('YYYY-MM') === employeeStart.format('YYYY-MM')
    })

    setDetails({
      users,
      periods: isManager ? employeePeriods : userPeriods,
      currentUser,
      currentPeriod,
      periodSummary
    });

    setIsLoading(false);
  };

  useEffect(() => {
    fetchTimeReportDetails();
    // eslint-disable-next-line
  }, []);

  const {
    setAuth,
    additional,
    setUserAdditional,
    auth,
    roleConfigs,
    selectedCustomer,
  } = props;

  return (
    <PageTemplate
      editable
      setAuth={setAuth}
      additional={additional}
      setUserAdditional={setUserAdditional}
      auth={auth}
      roleConfigs={roleConfigs}
      activeRoute="TimeReport"
    >
      {isLoading
        ? <Loader />
        : (<TimeReportDetailsDeviations
            selectedCustomer={selectedCustomer}
            fetchTimeReportDetails={fetchTimeReportDetails}
            {...details}
            {...props}
          />)}
    </PageTemplate>
  );
};

const mapStateToProps = ({
  auth: { user: { selectedCustomer } },
  context: additional,
}) => ({ additional, selectedCustomer });

export default compose(
  connect(mapStateToProps, { setAuth, setUserAdditional }),
  withApollo,
)(TimeReportDetails);
