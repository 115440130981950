import { fromPairs } from "ramda";

export const TRANSACTION_STATUSES = fromPairs(
  ['STARTED', 'OPEN', 'APPROVED', 'SUBMITTED', 'REJECTED', 'ARCHIVED', 'EXPORTED'].map(k => [
    k,
    k,
  ]),
);

export const REPORT_TYPES = fromPairs(['DEVIATION', 'DIMENSION'].map(k => [k, k]));

export const PERIOD_STATUSES = fromPairs(
  ['OPEN', 'APPROVED', 'REJECTED', 'SUBMITTED'].map(k => [k, k]),
);

export const PERIOD_TYPES = fromPairs(['ABSENCE', 'PRESENCE'].map(k => [k, k]));

export const PERIOD_TYPES_MESSAGES = {
  [PERIOD_TYPES.DIMENSION]: 'app.timeReport.periodTypeDimension',
  [PERIOD_TYPES.DEVIATION]: 'app.timeReport.periodTypeDeviation',
};

export const getPeriodTypeMsg = type => PERIOD_TYPES_MESSAGES[type];

export const getPeriodTypeColor = type => {
  switch (type) {
    case PERIOD_TYPES.DIMENSION:
      return '#4AAF62';
    case PERIOD_TYPES.DEVIATION:
      return '#F55448';
    default:
      return '#000';
  }
};

export const PERIOD_STATUS_MESSAGES = {
  [PERIOD_STATUSES.OPEN]: 'app.timeReport.periodStatusOpen',
  [PERIOD_STATUSES.APPROVED]: 'app.timeReport.periodStatusApproved',
  [PERIOD_STATUSES.REJECTED]: 'app.timeReport.periodStatusRejected',
  [PERIOD_STATUSES.SUBMITTED]: 'app.timeReport.periodStatusSubmitted',
};

export const getPeriodStatusMsg = status => PERIOD_STATUS_MESSAGES[status];

export const getPeriodStatusColor = status => {
  switch (status) {
    case PERIOD_STATUSES.OPEN:
      return '#3B77B5';
    case PERIOD_STATUSES.SUBMITTED:
      return '#F5C944';
    case PERIOD_STATUSES.REJECTED:
      return '#F55448';
    case PERIOD_STATUSES.APPROVED:
      return '#4AAF62';
    default:
      return '#000';
  }
};

export const formatTimeToHours = (time) => {
  const totalSeconds = parseInt(time) / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);

  return hours.toString() + ':' + minutes.toString().padStart(2, '0');
};
