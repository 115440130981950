import React from 'react';
import { useIntl } from 'react-intl';
import { FastField } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { TextField, Select } from '../../../../components/FormikFields';
import countries from '../../../../jsonData/countries';
import pae from '../../../../config/intlMessageSelectors/payroll-admin-employees';

const EmployeeLocation = () => {
  const { formatMessage: f } = useIntl();
  return (
    <Grid item container alignItems="center" xs={12} spacing={32}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          color="default"
          style={{ marginTop: '16px' }}
        >
          {f(pae['app.employees.subtitle.location'])}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.country'])}
          name="country"
          component={Select}
          options={countries}
        />
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.city'])}
          name="postalCity"
          component={TextField}
        />
      </Grid>
      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.home.address'])}
          name="address"
          component={TextField}
        />
      </Grid>
      <Grid item xs={3}>
        <FastField
          fullWidth
          overrided
          label={f(pae['app.employees.postalNumber'])}
          name="postalNumber"
          component={TextField}
        />
      </Grid>
    </Grid>
  );
};

export default EmployeeLocation;
