import React, { useRef, useState } from 'react';
import { compose, equals } from 'ramda';
import { useIntl } from 'react-intl';
import { ChoseFileButton } from '../../../components/ChoseFileButton';
import { DownloadExampleButton } from './components/DownloadExampleButton';
import { ProjectDialog } from './components/ProjectDialog';
import { ProjectDialogReminder } from './components/ProjectDialogReminder';
import { CSVReader } from 'react-papaparse';
import { NOTIFICATION } from '../../../../../components/NotificationSnackbar';
import { connect } from 'react-redux';
import { showNotification } from "../../../../../actions/notifications";
import pan from '../../../../../config/intlMessageSelectors/payroll-admin-notifications';

const COLUMNS = [
  'projectNumber',
  'projectName',
  'billable',
];
const blueColor = '#3B77B5';
const styles = {
  container: {
    display: 'flex',
    height: 36
  },
  importButtonContainer: {
    marginLeft: 10,
  },
  downloadButtonContainer: {
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
  },
};

const ProjectsActions = ({ showNotification, onChange, tenantId, customerId }) => {
  const {
    container,
    importButtonContainer,
    downloadButtonContainer,
  } = styles;
  const { formatMessage: f } = useIntl();
  const buttonRef = useRef(null);
  const [projects, setPtojects] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isReminderOpen, setIsReminderOpen] = useState(false);

  const handleOpenDialog = (e) => {
    if (buttonRef && buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (result) => {
    const columns = result[0].data;

    if (equals(columns, COLUMNS)) {
      setPtojects(result.slice(1, result.length).map((item) => {
        return columns.reduce((acc, col, rowIndex) => {

          if (col === 'billable') {
            acc[col] = ['true', '1'].includes((item.data[rowIndex] || '').toString().toLowerCase())
            return acc
          }

          if (col === 'projectNumber') {
            acc[col] = (item.data[rowIndex] || '' ).replace(/\D/g, '')
            return acc
          }

          acc[col] = item.data[rowIndex]
          return acc;
        }, {});
      }))
    } else {
      setPtojects([])
    }

    setIsOpen(!isOpen);
  };

  const handleOnError = (msg = '') => {
    showNotification({
      [Date.now()]: {
        message: msg || f(pan['app.notification.error.default']),
        type: NOTIFICATION.ERROR,
      },
    });
  };

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  const onImport = data => {
    setIsReminderOpen(true);
    onChange(data);
  }

  const onReminderDialogClose = () => {
    setIsReminderOpen(false);
  }

  return (
    <>
      <CSVReader
        style={container}
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        progressBarColor={blueColor}
        config={{ skipEmptyLines: true }}
        noClick
        noDrag
      >
        {() => (
          <aside style={container}>
            <div style={importButtonContainer}>
              <ChoseFileButton
                onClick={handleOpenDialog}
              />
            </div>
            <div style={downloadButtonContainer}>
              <DownloadExampleButton />
            </div>
          </aside>
        )}
      </CSVReader>
      {
        !!isOpen && (
          <ProjectDialog
            isOpen={isOpen}
            onClose={toggleDialog}
            onError={handleOnError}
            data={projects}
            onChange={onImport}
            tenantId={tenantId}
            customerId={customerId}
          />
        )
      }
      {
        !!isReminderOpen && (
          <ProjectDialogReminder
            isOpen={isReminderOpen}
            onClose={onReminderDialogClose}
          />
        )
      }
    </>
  );
}

export default compose(
  connect(null, { showNotification })
)(ProjectsActions);
