import React, { Component } from 'react';
import {
  compose,
  not,
  pick,
  whereEq,
  __,
} from 'ramda';
import {
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { TableBodyRow } from './components';

class Body extends Component {
  shouldComponentUpdate(nextProps) {
    return compose(
      not,
      whereEq(__, nextProps),
      pick(['rows']),
    )(this.props);
  }

  render() {
    const {
      schema,
      rows,
      passDown,
      onRowClick,
    } = this.props;

    return (
      <TableBody>
        {rows.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={schema.length}>
              <i>No Data</i>
            </TableCell>
          </TableRow>
        )}

        {rows.length > 0 && rows.map(
          (row, i) => (
            <TableBodyRow
              key={i}
              row={row}
              i={i}
              passDown={passDown}
              schema={schema}
              onRowClick={onRowClick}
            />
          ),
        )}
      </TableBody>
    );
  }
}

export default Body;
