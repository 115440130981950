import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  TablePagination,
  Typography,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core';
import { injectIntl } from 'react-intl';
import payrollAdminBase from '../config/intlMessageSelectors/payroll-admin-base';

const styles = () => ({
  firstPadded: {
    padding: '0 0 0 20px',
  },
  lastPadded: {
    padding: 0,
  },
  tableRow: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  controlPanel: { padding: 20 },
  root: {
    overflowX: 'auto',
    width: '100%',
    padding: '20px',
  },
});

class EcitTable extends React.Component {
  _behaviorDefaults() {
    return {
      Checkbox: (row) => ({}),
      TableRow: (row) => ({}),
      CheckAll: this._onCheckAllClick,
    };
  }

  __uuid() {
    return Math.floor(Math.random() * 900000) + 100000;
  }

    _checkSelected = (row) => {
      const {
        selectedRow, rowIdentifier, selectable, selected,
      } = this.props;


      if (selectable) {
        return selected.map((el) => el.id).indexOf(row.id) !== -1;
      }
      if (selectedRow) {
        return (selectedRow[rowIdentifier] === row[rowIdentifier]);
      }
    }

    _generateRow(row) {
      const {
        onCheck, onRowSelect, extractor, selectable, modifiedBehavior = this._behaviorDefaults(), headers,
      } = this.props;
      const rowDataToRender = extractor(row);

      const columns = headers.map((el, index) => this._generateColumn(rowDataToRender[el.id], index === 0, index === headers.length - 1));
      selectable && columns.unshift(<TableCell padding="checkbox" key={this.__uuid()}>
        <Checkbox {...modifiedBehavior.Checkbox(row)} checked={this._checkSelected(row)} />
                                    </TableCell>);

      return (
        <TableRow
          hover
          selected={this._checkSelected(row)}
          onClick={(e) => (!modifiedBehavior.TableRow(row).disabled ? (selectable ? this._onCheck(row)(onCheck) : onRowSelect(row)) : e.stopPropagation())}
          key={this.__uuid()}
        >
          {columns}
        </TableRow>
      );
    }

    _generateColumn(column, first, last) {
      const { classes } = this.props;
      if (first) {
        return <TableCell key={this.__uuid()} className={classes.firstPadded}>{column || '―'}</TableCell>;
      } if (last) {
        return <TableCell key={this.__uuid()} className={classes.lastPadded}>{column || '―'}</TableCell>;
      }
      return <TableCell key={this.__uuid()}>{column || '―'}</TableCell>;
    }

    _generateHeader(header, first, last) {
      const {
        classes, order, orderBy, onSort,
      } = this.props;
      return (
        <TableCell
          className={(first && classes.firstPadded) || (last && classes.lastPadded) || ''}
          key={this.__uuid()}
        >
          {
            header && header.id && (
            <TableSortLabel
              active={orderBy === header.id}
              direction={order}
              onClick={() => onSort(header.id)}
            >
              {header.label}
            </TableSortLabel>
            )
          }
        </TableCell>
      );
    }

    _onCheck(row) {
      const { id } = row;
      const { selected } = this.props;
      const selectedIndex = selected.map((el) => el.id).indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      return function (selectHandler) {
        selectHandler(newSelected);
      };
    }

    _onCheckAllClick = (checked, data) => {
      let newSelected = [];
      if (checked) {
        newSelected = data;
      } else {
        newSelected = [];
      }
      return newSelected;
    };

    render() {
      const {
        intl, data, headers, selectable, classes, modifiedBehavior = this._behaviorDefaults(), selected, classNameCustom, onCheck, tableName, controlPanel, count, paging, handlePageChange, handleRowsPerPageChange, rowsPerPage, page,
      } = this.props;

      return (
        <Paper className={classNameCustom || classes.root}>
          {tableName && <Typography className={classes.controlPanel} variant="display1">{tableName}</Typography>}
          {controlPanel && (
          <Toolbar className={classes.controlPanel}>
            {controlPanel}
          </Toolbar>
          )}
          {data.length === 0 && (
          <div style={{ margin: '20px', textAlign: 'center' }}>
            <Typography gutterBottom variant="headline">
              {intl.formatMessage(payrollAdminBase['app.base.tableNoData'])}
            </Typography>
          </div>
          )}

          {data.length !== 0 && (
            <div style={{ overflow: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    {selectable && (
                    <TableCell padding="checkbox" key={this.__uuid()}>
                      <Checkbox
                        disabled={modifiedBehavior.CheckAll(true, data).length === selected.length && selected.length === 0}
                        checked={modifiedBehavior.CheckAll(true, data).length === selected.length && !!selected.length}
                        onChange={(e) => onCheck(modifiedBehavior.CheckAll(e.target.checked, data))}
                      />
                    </TableCell>
                    )}
                    {headers.map((el, index) => this._generateHeader(el, index === 0, index === headers.length - 1))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((el) => !!el && (this._generateRow(el)))}
                </TableBody>
              </Table>
            </div>
          )}
          <div>
            {paging && (
              <TablePagination
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage(payrollAdminBase['app.base.tablePaginationDisplayedRows'])} ${count}`}
                labelRowsPerPage={intl.formatMessage(payrollAdminBase['app.base.tablePaginationRowsPerPage'])}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
              />
            )}
          </div>

        </Paper>
      );
    }
}

export default injectIntl(withStyles(styles)(EcitTable));
