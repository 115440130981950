import React, { Component } from 'react';
import {
  compose,
  __,
  pick,
  whereEq,
  not,
} from 'ramda';
import {
  TablePagination,
  TableRow,
  TableFooter,
} from '@material-ui/core/';
import { withStyles } from "@material-ui/core/styles";

class Pagination extends Component {
  shouldComponentUpdate(nextProps) {
    return compose(
      not,
      whereEq(__, nextProps),
      pick([
        'page',
        'count',
        'rowsPerPage',
        'rowsPerPageOptions',
      ]),
    )(this.props);
  }

  render() {
    const {
      rowsPerPageOptions,
      count,
      rowsPerPage,
      page,
      onChangePage,
      onChangeRowsPerPage,
      PaginationProps,
      classes,
    } = this.props;

    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            classes={{ toolbar: classes.toolbar }}
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onChangePage={(e, page) => onChangePage(page)}
            onChangeRowsPerPage={({ target: { value } }) => onChangeRowsPerPage(value)}
            {...PaginationProps}
          />
        </TableRow>
      </TableFooter>
    );
  }
}

const StyledPagination = withStyles(() => ({
  toolbar: {
    float: 'left',
  },
}))(Pagination);

export default StyledPagination;
