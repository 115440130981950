import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, Paper, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = () => ({
  root: {
    listStyle: 'none',
    width: '100%',
    marginBottom: 5,
  },
  paper: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: 48,
    border: '1px solid #F0F0F0',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    padding: '0 5px 0 10px'
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  iconWrapper: {
    height: 20,
    color: '#575757',
    cursor: 'pointer',
    padding: '0 7px'
  }
});

const DrawerListItem = ({
  name,
  classes,
  onRemove,
  checked,
  onChange,
}) => (
  <li className={classes.root}>
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Checkbox checked={checked} onChange={onChange} />
        <Typography component="p" noWrap>
          { name }
        </Typography>
      </div>
      <div className={classes.iconWrapper}>
        <DeleteIcon fontSize='small' onClick={onRemove} />
      </div>
    </Paper>
  </li>
);

export default withStyles(styles)(DrawerListItem);
