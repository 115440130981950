import React, { Component } from 'react';
import { __, includes, filter, last, any, propEq, pathOr, pathEq, compose, pick, path, map } from 'ramda';
import * as moment from 'moment';
import ReactSelect from 'react-select';
import { injectIntl } from 'react-intl';
import InputCounter from '../InputCounter';
import IntervalBasedTimePicker from './IntervalBasedTimePicker'
import 'rc-time-picker/assets/index.css';
import './timepicker.css';
import {
  Button,
  DialogActions,
  TextField,
  Radio,
  FormControlLabel,
  Checkbox,
  Collapse,
  Typography,
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';
import EcitMutation from '../EcitMutation';
import SimpleDialog from '../Dialog';
import {
  CHANGE_TRANSACTIONS_STATUS,
  CREATE_EMPLOYEE_DEVIATION_REPORT,
  UPDATE_EMPLOYEE_DEVIATION_REPORT,
} from '../../queries/transactions';
import payrollAdminBase from '../../config/intlMessageSelectors/payroll-admin-base';
import payrollTimeReport from '../../config/intlMessageSelectors/payroll-admin-timeReport';
import { DEFAULT_DATE_FORMAT, getDateTimeString } from '../../utils/times';
import { TRANSACTION_STATUSES } from "../../utils/timeReports";
import { findLastIndex } from "lodash";

const { OPEN, SUBMITTED, APPROVED, REJECTED, ARCHIVED, EXPORTED } = TRANSACTION_STATUSES;
const COMMENT_MAX_LENGTH = 255;

class CreateDeviationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviation: {
        transactions: [
          {
            date: moment()
              .startOf('day')
              .format(DEFAULT_DATE_FORMAT),
            duration: props.currentUser.employmentCategory.normalWorkDay,
            status: OPEN,
          },
        ],
        comment: '',
      },
      applyToAll: true,
      rejectMessage: '',
      status: undefined,
      period: false,
      selectedCompensation: null,
      collapsed: false,
      error: null,
    };
  }

  componentDidMount() {
    const { currentUser, data } = this.props;
    const { deviation, selectedCompensation } = this.state;

    if (data.isCreating) {
      this.setState({
        deviation: {
          ...(data.deviation || deviation),
          compensationTypeId: selectedCompensation && selectedCompensation.id,
          userId: currentUser.id,
        },
        period: !!(data.deviation && data.deviation.transactions.length > 1),
      });
    } else {
      this.setState({
        deviation: {
          ...data.deviation,
          comment: data.deviation.comment || '',
        },
        period: data.deviation.transactions.length > 1,
        selectedCompensation: data.compensationType,
        status: data.status,
        rejectMessage: data.rejectMessage,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentUser,
      data: { isCreating },
      isTheSameMonthAndYearAsSelectedPeriod,
    } = this.props;

    const { deviation, period, selectedCompensation } = this.state;
    const isPresence = pathEq(['type'], 'PRESENCE', selectedCompensation);
    const isDayInterval = compose(
      includes(__, ['FULL_DAY', 'HALF_DAY']),
      pathOr('default', ['interval', 'valueV2'])
    )(selectedCompensation)

    let newCompTypeDev = {};
    let newTimeTypeDev = {};
    const periodChanged = prevState.period !== period;
    const compTypeChanged =
      (!prevState.selectedCompensation && selectedCompensation) ||
      (prevState.selectedCompensation && prevState.selectedCompensation.id) !==
      (selectedCompensation && selectedCompensation.id);

    if ((isCreating && compTypeChanged) || periodChanged) {
      if (isCreating && compTypeChanged) {
        const isNoDeviationTimeType = pathEq(
          ['timeType'],
          'NO_DEVIATIONS',
          selectedCompensation,
        );

        const transactions = deviation.transactions.map(tr => ({
          duration: (isPresence && !isDayInterval && !isNoDeviationTimeType)
            ? 0
            : currentUser.employmentCategory.normalWorkDay,
          date: tr.date,
          status: tr.status
        }));

        if (isNoDeviationTimeType && isTheSameMonthAndYearAsSelectedPeriod) {
          transactions[0].date = moment()
            .startOf('month')
            .format(DEFAULT_DATE_FORMAT);
        }

        if (!isNoDeviationTimeType && isTheSameMonthAndYearAsSelectedPeriod) {
          transactions[0].date = moment()
            .startOf('day')
            .format(DEFAULT_DATE_FORMAT);
        }

        newCompTypeDev = {
          compensationTypeId: selectedCompensation.id,
          transactions,
        };
      }
      if (periodChanged && period && deviation.transactions.length === 1) {
        newTimeTypeDev = {
          transactions: [
            {
              date: deviation.transactions[0].date,
              duration: currentUser.employmentCategory.normalWorkDay,
              status: deviation.transactions[0].status,
            },
            {
              date: moment(deviation.transactions[0].date)
                .add(1, 'day')
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              duration: currentUser.employmentCategory.normalWorkDay,
              status: deviation.transactions[0].status,
            },
          ],
        };
      } else if (periodChanged && !period) {
        newTimeTypeDev = {
          transactions: [
            {
              date: deviation.transactions[0].date,
              duration: currentUser.employmentCategory.normalWorkDay,
              status: deviation.transactions[0].status,
            },
          ],
        };
      }
      this.setState({
        deviation: {
          ...deviation,
          ...newCompTypeDev,
          ...newTimeTypeDev,
        },
      });
    }
  }

  changeCompensation = (key, data) => {
    const { compensationTypes } = this.props;
    const { period } = this.state;
    const selectedCompensation = compensationTypes.find(
      el => el.id === data.value,
    );
    this.setState({
      selectedCompensation,
      period: selectedCompensation.multipleDays ? period : false,
    });
  };

  onDayPeriodChange = (e, val) => {
    if (e.target.name === 'single' && val) {
      this.setState({ period: false });
    } else {
      this.setState({ period: val });
    }
  };

  setTimeWithHours = (duration, index) => {
    const { applyToAll, deviation } = this.state;
    const transactions = [...deviation.transactions];

    if (applyToAll) {
      transactions
        .filter(({ status }) => status === OPEN || status === REJECTED)
        .forEach(transaction => (transaction.duration = duration))
    } else {
      transactions[index].duration = duration;
    }

    this.setState({
      deviation: {
        ...deviation,
        transactions,
      },
    });
  };

  generateDeviations = (date, index) => {
    const { currentUser, data } = this.props;
    const { deviation, period } = this.state;
    const isEdit = pathOr(false, ['isEdit'], data);
    let transactions = [];

    if (period) {
      if (index) {
        let i = 0;
        let current = deviation.transactions[0].date;
        let lastTransaction = deviation.transactions[deviation.transactions.length-1];

        while (
          +moment(current) <=
          +moment(date).startOf('day')
          ) {
          // eslint-disable-next-line
          const copyExistingDev = deviation.transactions.find(dev => dev.date === current);

          let duration = (copyExistingDev && copyExistingDev.duration) ||
            currentUser.employmentCategory.normalWorkDay;
          if (isEdit) {
            duration = deviation.transactions[i]?.duration || duration || lastTransaction.duration
          }

          const status = deviation.transactions[i]?.status || lastTransaction.status;

          transactions.push({
            date: current,
            duration,
            status,
          });
          current = moment(current)
            .add(1, 'day')
            .startOf('day')
            .format(DEFAULT_DATE_FORMAT);
          i++;
        }
      } else {
        let current = moment(date)
          .startOf('day')
          .format(DEFAULT_DATE_FORMAT);
        if (
          +moment(current) <
          +moment(
            deviation.transactions[deviation.transactions.length - 1].date,
          )
        ) {
          while (
            current <=
            deviation.transactions[deviation.transactions.length - 1].date
            ) {
            // eslint-disable-next-line
            const copyExistingDev = deviation.transactions.find(dev => dev.date === current);
            transactions.push({
              date: current,
              duration:
                (copyExistingDev && copyExistingDev.duration) ||
                currentUser.employmentCategory.normalWorkDay,
              status: OPEN,
            });
            current = moment(current)
              .add(1, 'day')
              .startOf('day')
              .format(DEFAULT_DATE_FORMAT);
          }
        } else {
          transactions = [
            {
              date: current,
              duration: currentUser.employmentCategory.normalWorkDay,
              status: OPEN,
            },
            {
              date: moment(current)
                .add(1, 'day')
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              duration: currentUser.employmentCategory.normalWorkDay,
              status: OPEN,
            },
          ];
        }
      }
    } else if (!period) {
      transactions.push({
        date: moment(date)
          .startOf('day')
          .format(DEFAULT_DATE_FORMAT),
        duration:
          deviation.transactions[0].duration ||
          currentUser.employmentCategory.normalWorkDay,
        status:  deviation.transactions[0].status || OPEN,
      });
    }
    this.setState(
      {
        deviation: {
          ...deviation,
          transactions,
        },
      },
      () => transactions.length === 1 && this.setState({ period: false }),
    );
  };

  translate(key) {
    const { intl } = this.props;
    return intl.formatMessage(payrollTimeReport[`app.timeReport.${key}`]);
  }

  getHoursPercentage = transaction => {
    const {
      currentUser: {
        employmentCategory: { normalWorkDay },
      },
    } = this.props;
    const nwd = transaction.normalWorkDay || normalWorkDay;
    return Math.round((transaction.duration * 100) / nwd);
  };

  getMsFromTimestring = timeString =>
    timeString.split(':')[0] * 60 * 60 * 1000 +
    timeString.split(':')[1] * 60 * 1000;

  getQueryVars = () => {
    const { selectedCompensation, deviation } = this.state;
    const { comment, ...dvn } = deviation

    const variables = {
      ...dvn,
      ...selectedCompensation.comment !== 'NO' && { comment },
      transactions: compose(
        map(pick(['date', 'duration'])),
        path(['transactions']),
      )(deviation),
      compensationTypeId: selectedCompensation.id,
    }

    return {
      variables: { input: variables }
    };
  };

  percentageRange = (start, end) => {
    const {
      selectedCompensation: { interval },
    } = this.state;

    if (start === end) return [{ label: `${start}%`, value: start }];
    if (start > end) return [];
    return [
      { label: `${start}%`, value: start },
      ...this.percentageRange(start + interval.value, end),
    ];
  };

  renderDayRow = (transaction, index) => {
    const { currentUser, classes, data } = this.props;
    const { selectedCompensation, deviation } = this.state;
    const isRange = index !== 0 && index !== deviation.transactions.length - 1;
    const isLastPeriodTransaction = deviation.transactions.length > 1 && index === deviation.transactions.length - 1;

    const isPresence = pathEq(['type'], 'PRESENCE', selectedCompensation);
    const isFullDay = pathEq(['interval', 'valueV2'], 'FULL_DAY', selectedCompensation);

    const hours = isPresence && !isFullDay
      ? 86400000
      : currentUser.employmentCategory.normalWorkDay

    const status = pathOr('DEFAULT', ['status'], transaction).toUpperCase();
    const lastExported = compose(
      pathOr(false, ['date']),
      last,
      filter(propEq('status', EXPORTED)),
      pathOr([], ['deviation', 'transactions']),
    )(this.state)

    const isOverlapping = pathEq([
      'selectedCompensation',
      'reportingType',
    ], 'OVERLAPPING', this.state)

    const isInfo = pathOr(false, ['isInfo'], data);
    const canChange = transaction?.status === OPEN || transaction?.status === REJECTED;

    let maxDate;
    let minDate = lastExported && status.toUpperCase() !== EXPORTED
      ? new Date(moment(lastExported).add(1, 'days').format(DEFAULT_DATE_FORMAT))
      : index !== 0
        ? deviation.transactions[0].date
        : new Date('2018-01-01');

    if(index === deviation.transactions.length - 1) {
      const lastNotOpenTransactionIdx = findLastIndex(deviation.transactions, n => n.status !== OPEN);
      const lastNotOpenTransaction = deviation.transactions[lastNotOpenTransactionIdx];
      minDate = lastNotOpenTransaction && moment(lastNotOpenTransaction.date).add(1, 'days').format(DEFAULT_DATE_FORMAT)
    }

    if (!minDate && isLastPeriodTransaction) {
      minDate = new Date(moment(deviation.transactions[0].date).add(1, 'days').format(DEFAULT_DATE_FORMAT));
    }

    if (currentUser.employeeEnd) {
      maxDate = new Date(currentUser.employeeEnd);
    }

    return (
      <div
        key={+moment(transaction.date)}
        style={{ margin: '10px 0', display: 'flex' }}
      >
        <DatePicker
          error={null}
          helperText={null}
          disabled={isInfo || isRange || !canChange}
          variant="outlined"
          label={
            isRange
              ? ''
              : index === 0
                ? this.translate('formStart')
                : this.translate('formEnd')
          }
          value={transaction.date}
          onChange={date => this.generateDeviations(date, index)}
          minDate={minDate}
          maxDate={maxDate}
          style={{ zIndex: 0 }}
          InputProps={{ classes: { root: classes.datePicker } }}
        />
        <div className="materialish-wrapper">
          <label
            className="materialish-input"
            style={{
              color: isInfo
                ? 'rgba(0, 0, 0, 0.38)'
                : 'rgba(0, 0, 0, 0.54)',
            }}
          >
            {this.translate('formDuration')}
          </label>
          <div className="materialish-container">
            <fieldset className="materialish-fieldset">
              <legend className="materialish-legend">
                <span>&#8203;</span>
              </legend>
            </fieldset>
            <IntervalBasedTimePicker
              interval={selectedCompensation.interval.valueV2}
              normalWorkDay={currentUser.employmentCategory.normalWorkDay}
              maxTime={{
                hour: isOverlapping
                  ? 24
                  : parseInt((hours / (1000 * 60 * 60)) % 24, 10) || 24,
                minutes: isOverlapping
                  ? 0
                  : parseInt((hours / (1000 * 60)) % 60, 10),
              }}
              value={transaction.duration}
              onChange={value => this.setTimeWithHours(value, index)}
              disabled={isInfo || !canChange}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      intl,
      data,
      classes,
      onClose,
      refetchFun,
      compensationTypes,
    } = this.props;

    const isInfo = pathOr(false, ['isInfo'], data);

    const {
      period,
      status,
      deviation,
      applyToAll,
      rejectMessage,
      selectedCompensation,
      collapsed,
    } = this.state;

    const transactionMutation = data.isCreating
      ? CREATE_EMPLOYEE_DEVIATION_REPORT
      : UPDATE_EMPLOYEE_DEVIATION_REPORT;
    const compensationTypeOptions =
      compensationTypes.map(el => ({ label: el.name, value: el.id })) || [];

    const isConfirmDisabled = !selectedCompensation
      || (selectedCompensation.comment === 'REQUIRED' && !deviation.comment)
      || (deviation.transactions[0].duration === 0 && selectedCompensation.timeType !== 'NO_DEVIATIONS')

    const isExported = any(propEq('status', EXPORTED), deviation.transactions)

    return (
      <EcitMutation
        ignoreGlobalNotifyError
        ignoreGlobalNotifySuccess={
          status === REJECTED && this.props.currentUser.role === 'employee'
        }
        action={!data.isCreating ? 'update' : 'create'}
        mutation={transactionMutation}
        shouldSkipFilter
      >
        {runQuery => (
          <SimpleDialog
            overflowReactSelect={!selectedCompensation}
            fullWidth
            maxWidth="sm"
            open
            onClose={onClose}
            showError={!!this.state.error}
            errorMessage={this.state.error}
          >
            <ReactSelect
              styles={{ container: prov => ({ ...prov, zIndex: 2 }) }}
              isDisabled={isInfo}
              placeholder={intl.formatMessage(
                payrollAdminBase['app.base.select.placeholder'],
              )}
              value={
                selectedCompensation && {
                  label: selectedCompensation.name,
                  value: selectedCompensation.id,
                }
              }
              onChange={cat =>
                this.changeCompensation('selectedCompensation', cat)
              }
              options={compensationTypeOptions}
            />

            {selectedCompensation &&
            selectedCompensation.reportingType !== 'FULLMONTH' && (
              <div style={{ paddingTop: 8 }}>
                {selectedCompensation.multipleDays && (!status || status === OPEN) &&
                  (
                  <div>
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      control={
                        <Radio
                          disabled={isInfo || isExported}
                          checked={!this.state.period}
                          onChange={this.onDayPeriodChange}
                          name="single"
                        />
                      }
                      label={this.translate('singleDayToggle')}
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      classes={{ label: classes.label }}
                      control={
                        <Radio
                          disabled={isInfo || isExported}
                          checked={this.state.period}
                          onChange={this.onDayPeriodChange}
                          name="mutiple"
                        />
                      }
                      label={this.translate('periodToggle')}
                      labelPlacement="end"
                    />
                  </div>
                )}
                <div>
                  {this.renderDayRow(deviation.transactions[0], 0)}
                  <div className={classes.durationBlock}>
                    {period && !isInfo &&
                    selectedCompensation.reportingType === 'DURATION' && (!status || status === OPEN || status === REJECTED) && (
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        control={
                          <Checkbox
                            checked={applyToAll}
                            onChange={() =>
                              this.setState({ applyToAll: !applyToAll })
                            }
                          />
                        }
                        label={this.translate('copyDatesBelow')}
                      />
                    )}
                    {deviation.transactions.length > 2 && (
                      <Button
                        style={{ marginLeft: '10%' }}
                        onClick={() => this.setState({ collapsed: !collapsed })}
                      >
                        {collapsed
                          ? this.translate('showLess')
                          : this.translate('showMore')}
                      </Button>
                    )}
                  </div>
                  <Collapse component="div" in={collapsed}>
                    {deviation.transactions.map((transaction, index) =>
                      index && index !== deviation.transactions.length - 1
                        ? this.renderDayRow(transaction, index)
                        : null,
                    )}
                  </Collapse>
                  {deviation.transactions.length > 1 &&
                  this.renderDayRow(
                    deviation.transactions[
                    deviation.transactions.length - 1
                      ],
                    deviation.transactions.length - 1,
                  )}
                </div>
              </div>
            )}

            {selectedCompensation &&
            selectedCompensation.reportingType === 'FULLMONTH' && (
              <Typography style={{ padding: '10px 0' }} variant="subheading">
                {getDateTimeString({
                  start: deviation.transactions[0].date,
                  end: moment(deviation.transactions[0].date)
                    .endOf('month'),
                  date: null,
                  duration: null,
                  format: 'LL',
                })}
              </Typography>
            )}
            {selectedCompensation &&
            (selectedCompensation.comment === 'REQUIRED' ||
              selectedCompensation.comment === 'OPTIONAL') && (
              <div style={{ margin: '16px 0' }}>
                <InputCounter
                  limit={COMMENT_MAX_LENGTH}
                  len={deviation.comment ? deviation.comment.length : 0}
                >
                  <TextField
                    value={deviation.comment}
                    fullWidth
                    onChange={event =>
                      this.setState({
                        deviation: {
                          ...deviation,
                          comment: event.target.value,
                        },
                      })
                    }
                    multiline
                    rows={2}
                    rowsMax={4}
                    inputProps={{
                      maxLength: COMMENT_MAX_LENGTH,
                    }}
                    label={
                      this.translate('comment') +
                      (selectedCompensation.comment === 'REQUIRED' ? '*' : '')
                    }
                    disabled={isInfo}
                    variant="outlined"
                    style={{ zIndex: 0 }}
                    InputProps={{
                      classes: { root: classes.comment },
                    }}
                  />
                </InputCounter>
              </div>
            )}
            {selectedCompensation && status === REJECTED && rejectMessage && (
              <div style={{ margin: '10px 0' }}>
                <h4>{this.translate('rejectMessage')}</h4>
                <p>{rejectMessage}</p>
              </div>
            )}
            {!isInfo && (
              <DialogActions style={{ margin: '14px -4px 10px' }}>
                <Button
                  type="button"
                  onClick={onClose}
                  variant="outlined"
                  classes={{ outlined: classes.outlined }}
                  className="ga-create-deviation-cancel-button"
                >
                  {intl.formatMessage(payrollAdminBase['app.base.btns.cancel'])}
                </Button>
                <EcitMutation
                  mutation={CHANGE_TRANSACTIONS_STATUS}
                  action="update"
                  ignoreGlobalNotifySuccess={
                    status !== REJECTED &&
                    status !== APPROVED &&
                    status !== ARCHIVED
                  }
                >
                  {mutation => (
                    <Button
                      className="ga-create-deviation-confirm-button"
                      disabled={isConfirmDisabled}
                      variant="contained"
                      classes={{ contained: classes.contained }}
                      style={{ borderColor: isConfirmDisabled ? 'rgba(0, 0, 0, 0)' : '#62B081' }}
                      type="submit"
                      onClick={() => {
                        if (
                          status !== APPROVED &&
                          status !== ARCHIVED
                        ) {
                          runQuery(this.getQueryVars())
                            .then(() => {
                              refetchFun();
                              onClose();
                            })
                            .catch(error => {
                              this.setState({ error });
                            });
                        } else if (
                          status === REJECTED &&
                          this.props.currentUser.role === 'employee'
                        ) {
                          runQuery(this.getQueryVars())
                            .then(() =>
                              mutation({
                                variables: {
                                  input: {
                                    status: SUBMITTED,
                                    transactions: [
                                      { id: this.state.deviation.parentId },
                                    ],
                                  },
                                },
                              }),
                            )
                            .then(() => {
                              refetchFun();
                              onClose();
                            })
                            .catch(error => {
                              this.setState({ error });
                            });
                        }
                      }}
                      color="primary"
                    >
                      {intl.formatMessage(
                        payrollAdminBase['app.base.btns.confirm'],
                      )}
                    </Button>
                  )}
                </EcitMutation>
              </DialogActions>
            )}
          </SimpleDialog>
        )}
      </EcitMutation>
    );
  }
}

const styles = () => ({
  label: {
    fontSize: '14px',
    color: '#000000',
  },
  outlined: {
    flex: 0.7,
    padding: '10px',
    borderRadius: '3px',
  },
  contained: {
    backgroundColor: '#62B081',
    border: '1px solid',
    color: '#FFFFFF',
    flex: 1.3,
    padding: '10px',
    borderRadius: '3px',
  },
  datePicker: {
    '& fieldset' : {
      height: 56,
      borderRadius: 4,
    }
  },
  comment: {
    margin: '4px 0 8px',
    '& fieldset' : {
      borderRadius: 4,
    }
  },
  durationBlock: {
    marginTop: '-10px'
  }
});

export default withStyles(styles)(injectIntl(CreateDeviationModal));
