import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Delete = props => (
  <SvgIcon {...props} viewBox="0 0 16 21">
    <path
      d={`
        M10.4177 0C11.1626 0 11.7765 0.618722 11.7765 1.36957V2.28261L14.0412
        2.28261C14.7862 2.28261 15.4 2.90133 15.4 3.65217V5.25C15.4 6.00084
        14.7862 6.61957 14.0412 6.61957H13.8147L13.8147 19.6304C13.8147 20.3813
        13.2009 21 12.4559 21H2.94414C2.19919 21 1.58532 20.3813 1.58532
        19.6304L1.58532 6.61957H1.35885C0.613894 6.61957 2.47955e-05 6.00084
        2.47955e-05 5.25V3.65217C2.47955e-05 2.90133 0.613894 2.28261 1.35885
        2.28261H3.62355V1.36957C3.62355 0.618722 4.23742 0 4.98238 0L10.4177
        0ZM10.4177 0.913043L4.98238 0.913043C4.72361 0.913043 4.52944 1.10876
        4.52944 1.36957V2.28261L10.8706 2.28261V1.36957C10.8706 1.10876 10.6764
        0.913043 10.4177 0.913043ZM14.0412 3.19565L1.35885 3.19565C1.10008
        3.19565 0.905907 3.39137 0.905907 3.65217V5.25C0.905907 5.51081 1.10008
        5.70652 1.35885 5.70652L14.0412 5.70652C14.3 5.70652 14.4941 5.51081
        14.4941 5.25V3.65217C14.4941 3.39137 14.3 3.19565 14.0412
        3.19565ZM12.9088 6.61957L2.4912 6.61957L2.4912 19.6304C2.4912
        19.8912 2.68538 20.087 2.94414 20.087H12.4559C12.7147 20.087 12.9088
        19.8912 12.9088 19.6304L12.9088 6.61957ZM10.5804 9.1233C10.693 9.13577
        10.7969 9.19029 10.8715 9.27611C10.9462 9.36193 10.9863 9.47284
        10.9838 9.58696L10.9838 17.3478C10.9847 17.4083 10.9736 17.4684
        10.9512 17.5245C10.9288 17.5807 10.8956 17.6318 10.8535 17.6748C10.8113
        17.7179 10.7611 17.7521 10.7057 17.7755C10.6504 17.7988 10.5909 17.8109
        10.5309 17.8109C10.4709 17.8109 10.4115 17.7988 10.3561 17.7755C10.3007
        17.7521 10.2505 17.7179 10.2083 17.6748C10.1662 17.6318 10.133 17.5807
        10.1106 17.5245C10.0882 17.4684 10.0771 17.4083 10.078 17.3478L10.078
        9.58696C10.0766 9.52197 10.089 9.45743 10.1143 9.39766C10.1397 9.33789
        10.1774 9.28426 10.225 9.24037C10.2725 9.19647 10.3289 9.16332 10.3902
        9.14313C10.4515 9.12295 10.5163 9.11618 10.5804 9.1233ZM7.74956
        9.1233C7.86214 9.13577 7.966 9.19029 8.04067 9.27611C8.11534 9.36193
        8.1554 9.47284 8.15297 9.58696L8.15297 17.3478C8.15381 17.4083 8.14273
        17.4684 8.12034 17.5245C8.09796 17.5807 8.06473 17.6318 8.02259
        17.6748C7.98045 17.7179 7.93023 17.7521 7.87485 17.7755C7.81948 17.7988
        7.76005 17.8109 7.70002 17.8109C7.64 17.8109 7.58057 17.7988 7.5252
        17.7755C7.46982 17.7521 7.4196 17.7179 7.37746 17.6748C7.33531 17.6318
        7.30209 17.5807 7.27971 17.5245C7.25732 17.4684 7.24623 17.4083 7.24708
        17.3478V9.58696C7.24569 9.52197 7.25809 9.45743 7.28344 9.39766C7.30879
        9.33789 7.34651 9.28426 7.39408 9.24037C7.44165 9.19647 7.49797 9.16332
        7.55928 9.14313C7.62058 9.12295 7.68546 9.11618 7.74956 9.1233ZM4.91868
        9.1233C5.03126 9.13577 5.13511 9.19029 5.20978 9.27611C5.28445 9.36193
        5.32452 9.47284 5.32208 9.58696V17.3478C5.32293 17.4083 5.31184 17.4684
        5.28946 17.5245C5.26708 17.5807 5.23385 17.6318 5.19171 17.6748C5.14956
        17.7179 5.09935 17.7521 5.04397 17.7755C4.9886 17.7988 4.92917 17.8109
        4.86914 17.8109C4.80912 17.8109 4.74969 17.7988 4.69431 17.7755C4.63894
        17.7521 4.58872 17.7179 4.54658 17.6748C4.50443 17.6318 4.4712 17.5807
        4.44882 17.5245C4.42644 17.4684 4.41535 17.4083 4.4162
        17.3478V9.58696C4.41481 9.52197 4.42721 9.45743 4.45256
        9.39766C4.47791 9.33789 4.51563 9.28426 4.5632 9.24037C4.61077
        9.19647 4.66709 9.16332 4.7284 9.14313C4.7897 9.12295 4.85458 9.11618 4.91868 9.1233Z
      `}
      fill="#EE7676"
    />
  </SvgIcon>
);

export default Delete;
