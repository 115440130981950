import React from 'react';
import { DownloadButton } from '../../../../../components/DownloadButton';

const columns = [
  {
    id: 'employmentNumber',
    displayName: 'employmentNumber'
  },
  {
    id: 'employmentCategoryName',
    displayName: 'employmentCategoryName'
  },
  {
    id: 'firstName',
    displayName: 'firstName'
  },
  {
    id: 'lastName',
    displayName: 'lastName'
  },
  {
    id: 'email',
    displayName: 'email'
  },
  {
    id: 'role',
    displayName: 'role'
  },
  {
    id: 'departmentName',
    displayName: 'departmentName'
  },
  {
    id: 'employeeStart',
    displayName: 'employeeStart'
  }
];

const data = [
  {
    employmentNumber: 1,
    employmentCategoryName: 'Category',
    firstName: 'John',
    lastName: 'Doe',
    email: 'email@gmail.com',
    role: 'manager',
    departmentName: 'Department',
    employeeStart: '2020-01-22',
  },
  {
    employmentNumber: 2,
    employmentCategoryName: 'Category 2',
    firstName: 'Jon',
    lastName: 'Smith',
    email: 'email2@gmail.com',
    role: 'employee',
    departmentName: 'Department 2',
    employeeStart: '2020-01-23',
  }
];

const DownloadExampleButton = () => (
  <DownloadButton
    columns={columns}
    data={data}
  />
)

export default DownloadExampleButton;
