import { ROLES } from '../config/role-configs';
import { pathOr } from "ramda";

const rolesWithModulesConfig = [ROLES.EMPLOYEE, ROLES.MANAGER, ROLES.CUSTOMER_ADMIN];

const hasModuleConfigured = (user = {}, key) => {
  if(!rolesWithModulesConfig.includes(user.role)) {
    return true;
  }

  const modulesConfig = pathOr({ timeReports: false, payslips: false }, ['employmentCategory', 'modulesConfig'], user);

  return modulesConfig[key];
}

export const hasTimeReports = (user = {}) => { return hasModuleConfigured(user, 'timeReports'); }
