export default {
  "app.departments.createDepartment": "Create department",
  "app.departments.department": "Department",
  "app.departments.approvers": "Approvers",
  "app.departments.approver": "Approver",
  "app.departments.noApproverAssigned": "No approver assigned",
  "app.departments.employees": "Employees",
  "app.departments.firstName": "First Name",
  "app.departments.lastName": "Last Name",
  "app.departments.phone": "Phone",
  "app.departments.role": "Role",
  "app.departments.email": "Email",
  "app.departments.createUserInsideDepartment": "Create employee inside department",
  "app.departments.addExistingUserDepartment": "Add existing employee to the department",
  "app.departments.removeUserFromDepartment": "Remove user from department",
  "app.departments.removeDepartment": "Are you sure you want to remove this department? Employees won't be able to time report until they have been reassinged to another department.",
  "app.departments.removeDepartmentTitle": "Remove Department",
  "app.departments.remove.user": "Remove user",
  "app.departments.edit.user": "Edit user"
}
