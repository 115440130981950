import types from './types';

export function showNotification(payload) {
  return {
    type: types.SHOW_NOTIFICATION,
    payload,
  };
}

export function hideNotification(payload) {
  return {
    type: types.HIDE_NOTIFICATION,
    payload,
  };
}
