import React from 'react';
import { getIn } from 'formik';
import { withStyles, TextField, InputAdornment } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const BaseLine = withStyles(
  () => ({
    root: {
      cursor: 'pointer !important',
      '&$disabled': { cursor: 'default' },
    },
    disabled: {},
    iconPosition: {
      position: 'relative',
      right: -14,
    },
    iconColor: { color: 'rgba(0, 0, 0, 0.54)' },
  }),
)(({ disabled, onClick, classes, form, field, ...props }) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <TextField
      fullWidth
      variant="outlined"
      disabled={disabled}
      onClick={disabled ? null : onClick}
      onBlur={() => form.setFieldTouched(field.name)}
      FormHelperTextProps={{ style: { position: 'absolute', top: '52px' } }}
      classes={{ root: classes.root }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end" className={classes.iconPosition}>
            <ArrowDropDownIcon className={classes.iconColor} />
          </InputAdornment>
        ),
      }}
      error={touch && !!error}
      helperText={touch && error ? error : null}
      {...props}
    />
  );
});

export default BaseLine;
