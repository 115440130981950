import { graphql } from 'react-apollo';
import { GET_CUSTOMERS_V2 } from '../../../../queries/customers';
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/table";

export const DEFAULT_ORDER = ['name', 'asc'];

export default graphql(GET_CUSTOMERS_V2, {
  options: () => ({
    variables: {
      state: 'ALL',
      order: DEFAULT_ORDER.join(' '),
      limit: TABLE_ROWS_PER_PAGE,
      offset: 0,
    },
  }),
  props: ({
    data: {
      fetchMore,
      variables: { tenantId },
      variables,
      customersV2: {
        customers,
        count,
      } = { customers: [], count: 0 },
      loading,
    },
  }) => ({
    count,
    customers,
    variables,
    loading,
    onFiltersChange: ({ limit, offset, order, state, search }) => fetchMore({
      variables: {
        tenantId,
        state,
        limit,
        offset,
        order,
        search,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return ({
          ...previousResult,
          customersV2: fetchMoreResult.customersV2,
        });
      },
    }),
  }),
});
