import * as moment from 'moment';
import timeReportIcon from '../../styles/sidebar/time-report.svg';
import timeReportIconActive from '../../styles/sidebar/time-report-active.svg';
import { pageUrl } from '../urls';
import { DEFAULT_DATE_FORMAT } from "../../utils/times";

export default function (user) {
  const { departmentId, id } = user;

  const { root, timeReportDetails } = pageUrl;
  const routes = [root, timeReportDetails];

  const manageRoles = ['employee'];
  const createUsers = false;
  const editUsers = false;
  const deleteUsers = false;
  const createDepartments = false;
  const editDepartments = false;
  const deleteDepartments = false;

  const today = moment();
  const startOfMonth = today.startOf('month').format(DEFAULT_DATE_FORMAT);
  const endOfMonth = today.endOf('month').format(DEFAULT_DATE_FORMAT);

  const renderNavigation = [{
    name: 'TimeReport',
    activeIcon: timeReportIconActive,
    icon: timeReportIcon,
    path: `/time-report-details/${startOfMonth},${endOfMonth}/${id}`,
    selector: 'app.navigation.timeReport',
  }];

  const indexToRedirectTo = '/time-report-details';

  return {
    usersToApprove: [id],
    departmentsToApprove: [],
    departmentId,
    indexToRedirectTo,
    routes,
    manageRoles,
    createUsers,
    editUsers,
    deleteUsers,
    editDepartments,
    deleteDepartments,
    createDepartments,
    renderNavigation,
  };
}
