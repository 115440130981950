import React, { useRef, useState } from 'react';
import { compose, equals, pathOr } from 'ramda';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { InfoRounded as InfoIcon } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { CSVReader } from 'react-papaparse';
import { connect } from 'react-redux';
import { useMutation } from 'react-apollo'
import { ChoseFileButton } from '../../../components/ChoseFileButton';
import { DownloadExampleButton } from './components/DownloadExampleButton';
import { ImportDialog } from './components/ImportDialog';
import { ImportDialogReminder } from './components/ImportDialogReminder';
import { NOTIFICATION } from '../../../../../components/NotificationSnackbar';
import { showNotification } from "../../../../../actions/notifications";
import pan from '../../../../../config/intlMessageSelectors/payroll-admin-notifications';
import pac
  from '../../../../../config/intlMessageSelectors/payroll-admin-customers';
import { NettlonButton } from './components/NettlonButton';
import { VALIDATE_EMPLOYEES_FROM_FILE } from '../../../../../queries/users'
import { DEFAULT_DATE_FORMAT } from '../../../../../utils/times';

const COLUMNS = [
  'employmentNumber',
  'employmentCategoryName',
  'firstName',
  'lastName',
  'email',
  'role',
  'departmentName',
  'employeeStart'
];
const blueColor = '#3B77B5';
const styles = {
  container: {
    display: 'flex',
    height: 36
  },
  importButtonContainer: {
    marginLeft: 10,
  },
  tooltipContainer: {
    margin: '0 0 3px 10px',
    display: 'flex',
    alignItems: 'center',
  },
  downloadButtonContainer: {
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
  }
};

const DEFAULT_STATE = { valid: [], invalid: [] };

const UsersImportActions = ({ showNotification, onChange, tenantId, customerId, isNettlon, organizationNumber }) => {
  const {
    container,
    importButtonContainer,
    tooltipContainer,
    downloadButtonContainer,
  } = styles;
  const [validateEmployees] = useMutation(VALIDATE_EMPLOYEES_FROM_FILE)
  const { formatMessage: f } = useIntl();
  const buttonRef = useRef(null);
  const [users, setUsers] = useState(DEFAULT_STATE);
  const [isOpen, setIsOpen] = useState(false);
  const [isReminderOpen, setIsReminderOpen] = useState(false);

  const handleOpenDialog = (e) => {
    if (buttonRef && buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (result) => {
    try {
      const columns = result[0].data;

      if(equals(columns, COLUMNS)) {
        const users = result
          .slice(1, result.length)
          .map((item) => {
            const user = columns.reduce((acc, col, rowIndex) => {
              acc[col] = item.data[rowIndex].trim();
              return acc;
            }, {});

            return {
              ...user,
              tenantId,
              customerId,
              employmentNumber: user.employmentNumber.toString().replace(/[^0-9]/g, ''),
              employeeStart: moment(user.employeeStart).toISOString(),
            }
          })

        const { data } = await validateEmployees({ variables: { input: users }})

        setUsers({
          valid: pathOr([], ['validateUsersFromFileImport', 'validUsers'], data).map(item => ({
            role: item.role,
            departmentName: item.departmentName,
            email: item.email,
            employmentCategoryName: item.employmentCategoryName,
            firstName: item.firstName,
            lastName: item.lastName,
            employmentNumber: item.employmentNumber.toString(),
            employeeStart: moment(item.employeeStart).format(DEFAULT_DATE_FORMAT),
          })),
          invalid: pathOr([], ['validateUsersFromFileImport', 'invalidUsers'], data).map(item => ({
            role: item.role,
            departmentName: item.departmentName,
            email: item.email,
            employmentCategoryName: item.employmentCategoryName,
            firstName: item.firstName,
            lastName: item.lastName,
            employmentNumber: item.employmentNumber.toString(),
            employeeStart: moment(item.employeeStart).toISOString(),
            errors: item.errors
          }))
        })
      } else {
        setUsers(DEFAULT_STATE);
      }

      setIsOpen(!isOpen);
    } catch (e) {
      showNotification({
        [Date.now()]: {
          message: f(pan['app.notification.error.default']),
          type: NOTIFICATION.ERROR,
        },
      });
    }
  };

  const handleOnNettlonFetch = (data = []) => {
    setUsers({
      valid: data.validUsers.map(item => ({
        role: item.role,
        departmentName: item.departmentName || '',
        email: item.email,
        employmentCategoryName: item.employmentCategoryName,
        firstName: item.firstName,
        lastName: item.lastName,
        employmentNumber: item.employmentNumber.toString(),
        employeeStart: moment(item.employeeStart).toISOString(),
      })),
      invalid: data.invalidUsers.map(item => ({
        role: item.role,
        departmentName: item.departmentName,
        email: item.email,
        employmentCategoryName: item.employmentCategoryName,
        firstName: item.firstName,
        lastName: item.lastName,
        employmentNumber: item.employmentNumber.toString(),
        employeeStart: moment(item.employeeStart).toISOString(),
        errors: item.errors
      }))
    });

    setIsOpen(!isOpen);
  };

  const handleOnError = (msg = '') => {
    showNotification({
      [Date.now()]: {
        message: msg || f(pan['app.notification.error.default']),
        type: NOTIFICATION.ERROR,
      },
    });
  };

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  const onImport = () => {
    setIsReminderOpen(true);
    onChange();
  }

  const onReminderDialogClose = () => {
    setIsReminderOpen(false);
  }

  return (
    <>
      <CSVReader
        style={container}
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        progressBarColor={blueColor}
        config={{ skipEmptyLines: true }}
        noClick
        noDrag
      >
        {() => (
          <aside style={container}>
            {
              isNettlon && (
                <NettlonButton
                  onChange={handleOnNettlonFetch}
                  onError={handleOnError}
                  organizationNumber={organizationNumber}
                />
              )
            }
            <div style={tooltipContainer}>
              <Tooltip
                title={f(pac['admin.page.users.import.step.tooltip'])}
              >
                <InfoIcon
                  color="secondary"
                  style={{ fontSize: 17 }}
                />
              </Tooltip>
            </div>
            <div style={importButtonContainer}>
              <ChoseFileButton
                onClick={handleOpenDialog}
              />
            </div>
            <div style={downloadButtonContainer}>
              <DownloadExampleButton />
            </div>
          </aside>
        )}
      </CSVReader>
      {
        !!isOpen && (
          <ImportDialog
            isOpen={isOpen}
            onClose={toggleDialog}
            onError={handleOnError}
            data={users}
            onChange={onImport}
            tenantId={tenantId}
          />
        )
      }
      {
        !!isReminderOpen && (
          <ImportDialogReminder
            isOpen={isReminderOpen}
            onClose={onReminderDialogClose}
          />
        )
      }
    </>
  );
}

export default compose(
  connect(null, { showNotification })
)(UsersImportActions);
