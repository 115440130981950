export default {
  "admin.page.title": "Admin. Customers management",
  "admin.tabs.all": "All",
  "admin.tabs.inactive": "Inactive",
  "admin.page.add.button": "add new customer",
  "admin.page.back.button": "Customer List",
  "admin.page.edit.button": "Edit",
  "admin.page.table.column.org.number": "Org number",
  "admin.page.table.column.name": "Name",
  "admin.page.table.column.payroll.system": "Payroll System",
  "admin.page.table.column.tenant": "Tenant",
  "admin.page.table.column.employees": "Employees",
  "admin.page.table.column.status": "Status",
  "admin.page.table.column.empty": "undefined",
  "admin.page.table.customer.status.active": "Active",
  "admin.page.table.customer.status.inactive": "Inactive",
  "admin.page.table.customer.status.incomplete": "Incomplete",
  "admin.page.general.step.title": "General",
  "admin.page.compensation.types.step.title": "Compensation Types",
  "admin.page.employment.categories.step.title": "Employment categories",
  "admin.page.users.import.step.title": "Users import",
  "admin.page.dimensions.worktypes.step.title": "Dimensions and worktypes",
  "admin.page.step.next.button": "Next",
  "admin.page.step.back.button": "Back",
  "admin.page.step.finish.button": "Finish",
  "admin.page.step.save.button": "Save",
  "admin.page.step.general.copy.button": "Copy settings from existing customer",
  "admin.page.step.general.customer.name": "Customer name",
  "admin.page.step.general.org.number": "Org. number",
  "admin.page.step.general.payroll.system": "Payroll System",
  "admin.page.step.general.payroll.tenant": "Tenant",
  "admin.page.step.general.payroll.is.active": "Is Active",
  "admin.page.step.general.inactive.date": "Inactive Date",
  "admin.page.step.general.period.reports": "Periods",
  "admin.page.step.general.label.monthly": "Monthly",
  "admin.page.step.general.label.bi-weekly": "Bi-weekly",
  "admin.page.step.general.label.weekly": "Weekly",
  "admin.page.step.general.label.start.from": "Start from",
  "admin.page.step.general.generated.periods": "Generated periods",
  "admin.page.step.general.notifications": "Notifications",
  "admin.page.step.general.add.notification.button": "Add new notification",
  "admin.page.step.general.notification.type.employee": "Employee",
  "admin.page.step.general.notification.type.manager": "Manager",
  "admin.page.step.general.notification.label.type": "Type",
  "admin.page.step.general.label.notification.deadline": "Deadline",
  "admin.page.step.general.label.notification.time": "Time",
  "admin.page.step.general.label.notification.repeatable": "Repeatable",
  "admin.page.step.general.label.notification.repeatable.tooltip": "Repeatable ON indicates that notifications will repeat every month\n      in the same date and time. Repeatable OFF indicates that notification will\n      be sent only once at specified date.",
  "admin.page.step.general.holidays": "Public holidays",
  "admin.page.step.general.add.holiday.button": "Add new holiday",
  "admin.page.step.general.label.holidays.day": "Day",
  "admin.page.step.compensation.types.column.code": "Salary code",
  "admin.page.step.compensation.types.column.name": "Name",
  "admin.page.step.compensation.types.column.type": "Type",
  "admin.page.step.compensation.types.column.comment": "Comment",
  "admin.page.step.compensation.types.column.reporting.type": "Reporting type",
  "admin.page.step.compensation.types.column.intervals": "Interval",
  "admin.page.step.compensation.types.column.periodic": "Periodic",
  "admin.page.step.compensation.types.column.in.summary": "Show summarized",
  "admin.page.step.compensation.types.column.global.type": "Global type",
  "admin.page.step.compensation.types.column.description": "Description",
  "admin.page.step.compensation.types.add.compensation.type": "Add new compensation type",
  "admin.page.step.compensation.types.type.absence": "Absence",
  "admin.page.step.compensation.types.type.presence": "Presence",
  "admin.page.step.compensation.types.comment.required": "Required",
  "admin.page.step.compensation.types.comment.no": "No",
  "admin.page.step.compensation.types.comment.optional": "Optional",
  "admin.page.step.compensation.types.reporting.type.overlapping": "Overlapping",
  "admin.page.step.compensation.types.reporting.type.full.day": "Full day",
  "admin.page.step.compensation.types.reporting.type.interval": "Interval",
  "admin.page.step.compensation.types.reporting.type.duration": "Duration",
  "admin.page.step.compensation.types.reporting.type.full.month": "Full month",
  "admin.page.step.compensation.types.time.type.vacation": "Vacation",
  "admin.page.step.compensation.types.time.type.overtime": "Overtime",
  "admin.page.step.compensation.types.time.type.off.duty": "Off duty",
  "admin.page.step.compensation.types.time.type.more.time": "More time",
  "admin.page.step.compensation.types.time.type.sickleave": "Sickleave",
  "admin.page.step.compensation.types.time.type.workhours": "Workhours",
  "admin.page.step.compensation.types.time.type.no.deviation": "No deviation",
  "admin.page.step.compensation.types.time.type.care.of.child": "Care of child",
  "admin.page.step.compensation.types.time.type.parental.leave": "Parental leave",
  "admin.page.step.compensation.types.delete.button": "Delete",
  "admin.page.step.employment.categories.add.employment.category": "Add new employment category",
  "admin.page.step.employment.categories.column.category": "Category",
  "admin.page.step.employment.categories.column.project.reporting": "Project reporting",
  "admin.page.step.employment.categories.column.norm.time": "Norm time",
  "admin.page.step.employment.categories.column.exclude.weekends": "Exclude weekends",
  "admin.page.step.employment.categories.column.reporting.method": "Reporting method",
  "admin.page.step.employment.categories.column.compensation.types": "Compensation Types",
  "admin.page.step.employment.categories.delete.button": "Delete",
  "admin.error.remove.compensation.type": "Can not remove compensation type which is already in use",
  "admin.error.remove.used.employment.category": "Can not remove employment category which is already in use",
  "admin.success.notification.saved": "Saved",
  "admin.error.notification.ops.something.went.wrong": "Oops something went wrong",
  "admin.stepper.page.customer.name": "Customer name",
  "admin.page.stepper.customer.org.number": "Org. number",
  "admin.page.stepper.custom.error.duplicated.notifications": "Oops, you have duplicate notifications. Please ensure they are unique.",
  "admin.page.stepper.custom.error.duplicated.holidays": "Oops, you have holidays on the same date. Ensure they are on unique dates.",
  "admin.page.step.general.label.notification.when": "When",
  "admin.page.step.general.notification.when.before.deadline": "Before deadline",
  "admin.page.step.general.notification.when.after.deadline": "After deadline",
  "admin.page.step.general.notification.when.on.deadline": "On deadline",
  "admin.page.step.general.label.notification.period": "Period",
  "admin.page.step.general.notification.period.day": "day",
  "admin.page.step.general.notification.period.days": "days",
  "admin.page.step.employment.categories.column.work.day": "Work day",
  "admin.success.notification.save": "Save"
}
