export default {
  "app.departments.createDepartment": "Skapa avdelning",
  "app.departments.department": "Avdelning",
  "app.departments.approvers": "Attestorer",
  "app.departments.approver": "Attestor",
  "app.departments.noApproverAssigned": "Ingen attestor tilldelad",
  "app.departments.employees": "Anställda",
  "app.departments.firstName": "Förnamn",
  "app.departments.lastName": "Efternamn",
  "app.departments.phone": "Telefon",
  "app.departments.role": "Roll",
  "app.departments.email": "E-post",
  "app.departments.createUserInsideDepartment": "Skapa anställd i avdelningen",
  "app.departments.addExistingUserDepartment": "Lägg till anställd i avdelningen",
  "app.departments.removeUserFromDepartment": "Ta bort användare från avdelning",
  "app.departments.removeDepartment": "Är du säker på att du vill ta bort avdelningen? Anställda i denna avdeling kan inte tidrapportera förräns de har flyttas i en annan avdelning.",
  "app.departments.removeDepartmentTitle": "Ta bort avdelning",
  "app.departments.remove.user": "Ta bort",
  "app.departments.edit.user": "Ändra"
}
