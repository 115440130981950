import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { CustomerLoader } from '../components/CustomerLoader';
import { ProjectsActions } from './components/ProjectsActions';
import { ProjectsFooter } from './components/ProjectsFooter';
import { ProjectsEditableTable } from './components/ProjectsEditableTable';

import {
  GET_WIZARD_PROJECTS
} from '../../../queries/customers';

const Projects = ({
 setupRightPlace,
 step,
 tenantId,
 params = {},
 id,
}) => {
  const customerId = id || +params.id;
  const [projects, setProjects] = useState([]);
  const { loading, data, refetch } = useQuery(GET_WIZARD_PROJECTS, {
    skip: !customerId,
    variables: {
      customerId,
      limit: 100,
      offset: 0,
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    setupRightPlace(() => (
      <ProjectsActions
        onChange={refetch}
        tenantId={tenantId}
        customerId={customerId}
      />
    ));
    // eslint-disable-next-line
  }, [tenantId, customerId, refetch]);

  useEffect(() => {
    const { dimensionsByCustomer = [] } = data || {};

    setProjects(dimensionsByCustomer.map(item => ({
      projectNumber: item.dimensionNumber,
      projectName: item.name,
      billable: item.billable,
      enabled: item.enabled,
      activities: item.workTypes.map(item => ({
        name: item.name,
        id: item.id,
        enabled:  item.enabled,
      })),
      id: item.id
    })));
    // eslint-disable-next-line
  }, [data]);

  return (
    <React.Fragment>
      {
        loading ?
          (<CustomerLoader />) : (
            <ProjectsEditableTable
              data={projects}
              refetch={refetch}
              customerId={customerId}
            />
          )
      }
      {
        !!step && (
          <ProjectsFooter
            loading={loading}
            customerId={customerId}
            step={step}
          />
        )
      }

    </React.Fragment>
  );
};

export default withRouter(Projects);
