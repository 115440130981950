import React from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const STATUSES = {
  GENERAL: 'GENERAL',
  TYPES: 'TYPES',
  CATEGORIES: 'CATEGORIES',
  USERS: 'USERS'
};

const STATUS_MAPPER = {
  1: STATUSES.GENERAL.toLowerCase(),
  2: STATUSES.TYPES.toLowerCase(),
  3: STATUSES.CATEGORIES.toLowerCase(),
  4: STATUSES.USERS.toLowerCase()
};

const CustomersVerticalMenu = ({ row: { id, status, isFlowCompleted } }) => {
  const history = useHistory();

  const onEdit = React.useCallback(async () => {
    if (!isFlowCompleted || (isFlowCompleted && status === 3)) {
      history.push(`/customers/${id}/step/${STATUS_MAPPER[status + 1]}`);
      return;
    }

    history.push(`/customers/${id}/step/general`);
    // eslint-disable-next-line
  }, [id, isFlowCompleted, status]);

  return (
    <IconButton
      aria-label="Edit"
      onClick={onEdit}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );
};

export default CustomersVerticalMenu;
