import React from 'react';
import Table from '@material-ui/core/Table';
import {
  TableHead,
  TableBody,
  TablePagination,
} from './components';

const EnhancedTable = ({
  headers,
  rows,
  mapper,
  passDown,
  count,
  page = 0,
  order = 'asc',
  orderBy = 'id',
  sortable = false,
  pagination = false,
  rowsPerPage,
  rowsPerPageOptions,
  TablePaginationProps,
  onRowClick = () => {},
  onChangePage = () => {},
  onChangeRowsPerPage = () => {},
  onRequestSort = () => {},
}) => (
  <Table>
    <TableHead
      headers={headers}
      passDown={passDown}
      sorting={{
        onRequestSort,
        order,
        orderBy,
      }}
      sortable={sortable}
    />

    <TableBody
      schema={mapper || headers}
      rows={rows}
      passDown={passDown}
      onRowClick={onRowClick}
    />

    {pagination && (
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        PaginationProps={TablePaginationProps}
      />
    )}
  </Table>
);

export default React.memo(EnhancedTable);
