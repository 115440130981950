import types from '../actions/types';

const initialState = {};

export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_NOTIFICATION:
      return {
        ...state,
        ...action.payload,
      };
    case types.HIDE_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};
