import React from 'react';
import { DatePicker as MuiDatePicker } from 'material-ui-pickers';
import { InputAdornment, TextField, withStyles } from '@material-ui/core';
import { CheckboxOutlined as CheckboxOutlinedIcon } from '../../icons'

const DatePicker = withStyles(
  () => ({
    input: { padding: '8px' },
    root: { maxWidth: '164px' },
  }),
)(({ classes, ...rest }) => (
  <MuiDatePicker
    autoOk
    format="YYYY/MM/DD"
    classes={{ root: classes.root }}
    {...rest}
    TextFieldComponent={props => (
      <TextField
        {...props}
        variant="outlined"
        color="disabled"
        InputProps={{
          classes: { input: classes.input },
          endAdornment: (
            <InputAdornment>
              <CheckboxOutlinedIcon
                fontSize="small"
                color="primary"
              />
            </InputAdornment>
          ),
        }}
      />
    )}
  />
));

export default DatePicker
