import manager from './manager';
import approver from './approver';
import employee from './employee';

import superadmin from './super-admin';
import customeradmin from './customer-admin';
import admin from './admin';

const toMap = {
  manager,
  admin,
  employee,
  superadmin,
  approver,
  customeradmin,
};

export default function (user) {
  if (toMap[user.role]) return toMap[user.role](user);

  return {};
}
