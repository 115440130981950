import React from 'react';
import { Table, Paper } from '@material-ui/core';
import CompensationTypeTbody from './CompensationTypeTbody';
import { TableHead } from '../components/Table';

const CompensationTypeTable = ({
  headers,
  intervals,
  types,
  AddNewRowComponent,
  onRowUpdate,
  onRowRemove,
}) => (
  <Paper
    style={{
      height: '100%',
      width: '100%',
      overflow: 'auto',
      marginBottom: '72px',
    }}
  >
    <Table>
      <TableHead headers={headers} />
      <CompensationTypeTbody
        types={types}
        intervals={intervals}
        AddNewRowComponent={AddNewRowComponent}
        onRowUpdate={onRowUpdate}
        onRowRemove={onRowRemove}
      />
    </Table>
  </Paper>
);

export default CompensationTypeTable;
