import React, { Component } from 'react';
import { compose, not, pick, whereEq, __ } from 'ramda';
import { TableHead, TableRow } from '@material-ui/core';
import { TableHeadCell } from './components';

class Head extends Component {
  shouldComponentUpdate(nextProps) {
    return compose(not, whereEq(__, nextProps), pick(['sorting']))(this.props);
  }

  render() {
    const { headers, passDown, sorting, sortable } = this.props;

    return (
      <TableHead>
        <TableRow>
          {headers.map(({ tableCellProps, ...header }, i) => (
            <TableHeadCell
              key={i}
              header={header}
              passDown={passDown}
              sortable={sortable}
              sorting={sorting}
              tableCellProps={tableCellProps}
            />
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default Head;
