import gql from 'graphql-tag';


export const GET_TRANSACTIONS = gql`
  query GET_TRANSACTIONS($input: ListInputType!){
    transactionsWithChildren(input:$input){
      id
      userId
      compensationTypeId
      start
      end
      status
      duration
      isParent
      date
      periodStatus
      children{
        date
        duration
        status
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($input: CreateTransactionInputType!) {
    createTransaction(input: $input) {
      id
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation DeteleTransaction($input:DeleteInputType!) {
    deleteTransaction(input: $input) {
      id
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
 mutation UpdateTransaction($input: UpdateTransactionInputType!){
  updateTransaction(input:$input){
    id
    userId
    duration
    start
    end
  }
 }
`;

export const UPDATE_EMPLOYEE_DEVIATION_REPORT = gql`
  mutation UpdateEmployeeDeviationReport($input: EmployeeUpdateDeviationReportInput!) {
    updateEmployeeDeviationReport(input: $input) {
      id
    }
  }
`;

export const CREATE_EMPLOYEE_DEVIATION_REPORT = gql`
  mutation CreateEmployeeDeviationReport($input: EmployeeCreateDeviationReportInput!) {
    createEmployeeDeviationReport(input: $input) {
      id
    }
  }
`;

export const CREATE_EMPLOYEE_PROJECT_REPORT = gql`
  mutation CreateEmployeeProjectReport($input: EmployeeProjectReportInput!) {
    createEmployeeProjectReport(input: $input) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_PROJECT_REPORT = gql`
  mutation UpdateEmployeeProjectReport($input: EmployeeUpdateProjectReportInput!) {
    updateEmployeeProjectReport(input: $input) {
      id
    }
  }
`;

export const DELETE_EMPLOYEE_REPORT = gql`
  mutation DeleteEmployeeReport($input: EmployeeDeleteReportInput!) {
    deleteEmployeeReport(input: $input) {
      id
    }
  }
`;

export const APPROVE_TRANSACTIONS = gql`
  mutation ApproveTransactions($input: ApproveTransactionsInputType!){
    approveTransactions(input: $input){
      id
    }
  }
`;
export const REJECT_TRANSACTIONS = gql`
  mutation RejectTransactions($input: [RejectTransactionsInputType]!){
    rejectTransactions(input: $input){
      id
    }
  }
`;

export const CHANGE_TRANSACTIONS_STATUS = gql`
  mutation ChangeTransactionStatus($input: ChangeTransactionStatusInputType!){
    changeTransactionsStatus(input: $input){
      id
    }
  }
`;

export const PERIOD_TAKE_BACK = gql`
  mutation PeriodTakeBack($employeeId: Int!, $employeePeriodId: Int!){
    periodTakeBack(
      employeeId: $employeeId,
      employeePeriodId: $employeePeriodId
    ) {
      id,
      status
    }
  }
`;

export const PERIOD_APPROVE = gql`
  mutation PeriodApprove($employeeId: Int!, $employeePeriodId: Int!){
    periodApprove(
      employeeId: $employeeId,
      employeePeriodId: $employeePeriodId
    ) {
      id,
      status
    }
  }
`;

export const PERIOD_SUBMIT = gql`
  mutation PeriodSubmit($employeeId: Int!, $employeePeriodId: Int!){
    periodSubmit(
      employeeId: $employeeId,
      employeePeriodId: $employeePeriodId
    ) {
      id,
      status
    }
  }
`;

export const PERIOD_REJECT = gql`
  mutation PeriodReject($employeeId: Int!, $employeePeriodId: Int!, $comment: String){
    periodReject(
      employeeId: $employeeId,
      employeePeriodId: $employeePeriodId,
      comment: $comment
    ) {
      id,
      status
    }
  }
`;

export const GET_DAILY_EFFICIENCY_TRANSACTIONS = gql`
  query GET_DAILY_EFFICIENCY_TRANSACTIONS(
    $input: ListInputType!,
    $input2:ListInputType!,
    $input3:ListInputType!,
    $count: CountInputType
  ){
    users(input:$input3){
      id
      employmentCategory{
        reportingMethod
        compensationTypes{
          name,
          id,
          reportingType
          multipleDays
          interval{
            id
            type
            value
          }
        }
      }
      dailyEfficiencies(input:$input){
        id
        userId
        date
        overWork
        sickleave
        hoursBank
        workHours
        transactionsWithChildren(input:$input2){
          id
          start
          comment
          end
          date
          duration
          compensationTypeId
          status
          dimension{
            name
            id
          }
        }
      }
    }
    transactionsCount(input: $count){
      count
    }
    dimensions{
      id
      name
    }
  }
`;

export const GET_TRANSACTIONS_REPORT_BY_DEVIATION_AS_BASE64 = gql`
  query GetTransactionsReportAsBase64($input: ReportInputType!) {
    getTransactionsReportBase64(input: $input)
  }
`

export const GET_TRANSACTIONS_REPORT_BY_PROJECT_AS_BASE64 = gql`
  query getProjectsReportBase64($input: ReportInputType!) {
    getProjectsReportBase64(input: $input)
  }
`

export const GET_TRANSACTIONS_REPORT_DETAILED_AS_BASE64 = gql`
  query projectReportingDetailedReport($filter: ProjectReportingDetailedReportArgs!) {
    projectReportingDetailedReport(filter: $filter) {
      employmentNumber
      firstName
      lastName
      projectNumber
      project
      activity
      status
      date
      duration
      compensationType
      billable
      comment
    }
  }
`
