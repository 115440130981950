import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Paper, Dialog, DialogTitle, DialogContent, Grid, Button, } from '@material-ui/core';
import pae from '../../../../config/intlMessageSelectors/payroll-admin-employees';
import pad from '../../../../config/intlMessageSelectors/payroll-admin-departments';
import pab from '../../../../config/intlMessageSelectors/payroll-admin-base';
import Table from '../../../../components/Table/Table';
import MoreVerticalMenu from '../../../../components/MoreVerticalMenu';
import { DELETE_EMPLOYEE, DELETE_EMPLOYEE_FROM_DEPARTMENT } from '../../../../queries/users';
import { showNotification } from '../../../../actions/notifications';
import { NOTIFICATION } from '../../../../components/NotificationSnackbar';
import { getErrorMessageCode } from '../../../../utils/error';
import { TABLE_ROWS_OPTION } from "../../../../utils/table";

const ERROR_CODES = { CANNOT_REMOVE_USER_HAS_TRANSACTIONS: 7 };
const ERROR_MESSAGES = {
  [ERROR_CODES.CANNOT_REMOVE_USER_HAS_TRANSACTIONS]: 'app.employees.cannotRemoveHasTransaction'
};
const getErrorMessage = code => ERROR_MESSAGES[code];

const headers = [
  {
    id: 'employmentNumber',
    label: pae['app.employees.employmentNumber'],
    tableCellProps: { style: { width: '15%', minWidth: '226px' } },
  },
  {
    id: 'employmentCategory.name',
    label: pae['app.employees.employmentCategory'],
    tableCellProps: { style: { minWidth: '237px' } },
  },
  {
    id: 'personalInfo.firstName',
    label: pae['app.employees.firstName'],
    tableCellProps: { style: { minWidth: '172px' } },
  },
  {
    id: 'personalInfo.lastName',
    label: pae['app.employees.lastName'],
    tableCellProps: { style: { minWidth: '172px' } },
  },
  {
    id: 'department.name',
    label: pae['app.employees.department'],
    tableCellProps: { style: { width: '15%' } },
  },
  {
    id: 'role',
    label: pae['app.employees.role'],
  },
  {
    id: 'menu',
    label: '',
  },
];

const mapper = [
  { id: 'employmentNumber' },
  { id: 'employmentCategory.name' },
  { id: 'personalInfo.firstName' },
  { id: 'personalInfo.lastName' },
  { id: 'department.name' },
  { id: 'role' },
  { id: 'menu', component: compose(
    injectIntl,
    graphql(DELETE_EMPLOYEE_FROM_DEPARTMENT, { name: 'removeEmployeeFromDepartment'}),
    graphql(DELETE_EMPLOYEE, { name: 'removeEmployee'}),
    withRouter,
    connect(({ auth: { user }}) => user, { showNotification }),
  )(({ row, passDown, history, removeEmployee, removeEmployeeFromDepartment, selectedCustomer, showNotification, intl }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const onConfirmRemoveUser = async () => {
      try {
        await removeEmployee({
          variables: {
            input: {
              id: row.id,
              filters: {
                customerId: selectedCustomer,
              }
            },
          },
        });
        setIsOpen(false);

        passDown.refetch();
      } catch (e) {
        const errorCode = getErrorMessageCode(e);
        const message = pae[getErrorMessage(errorCode)]
          && intl.formatMessage(pae[getErrorMessage(errorCode)]);

        showNotification({
          [Date.now()]: {
            message: message || 'Ops something went wrong',
            type: NOTIFICATION.ERROR,
          },
        });

        setIsOpen(false);
      }
    };

    return (
      <React.Fragment>
        <MoreVerticalMenu
          options={[
            {
              label: intl.formatMessage(pad['app.departments.removeUserFromDepartment']),
              action: async () => {
                await removeEmployeeFromDepartment({
                  variables: {
                    id: row.id,
                    customerId: selectedCustomer,
                  },
                });

                passDown.refetch();
              },
              disabled: !passDown.permissions.editDepartments,
            },
            {
              label: intl.formatMessage(pad['app.departments.edit.user']),
              action: () => history.push({
                pathname: `/employees/${row.id}`,
                state: { redirect: `/departments/${row.department.id}` }
              }),
              disabled: !passDown.permissions.editUsers,
            },
          ]}
        />
        <Dialog fullWidth open={isOpen} onClose={() => setIsOpen(false)}>
          <DialogTitle>
            {intl.formatMessage(pae['app.employees.removeEmployeeTitle'])}
          </DialogTitle>
          <DialogContent>
            <Grid container justify="space-between" spacing={16}>
              <Grid item xs={12}>
                {intl.formatMessage(pae['app.employees.removeEmployeeConfirmationText'])}
              </Grid>

              <Grid
                item
                container
                xs={12}
                justify="flex-end"
              >
                <Grid item xs={3}>
                  <Button fullWidth variant="outlined" onClick={() => setIsOpen(false)}>
                    {intl.formatMessage(pab['app.base.btns.cancel'])}
                  </Button>
                </Grid>

                <Grid item xs={4} style={{ marginLeft: '10px' }}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={onConfirmRemoveUser}
                  >
                    {intl.formatMessage(pab['app.base.btns.confirm'])}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

      </React.Fragment>
    )
  })},
];

const EmployeesTable = ({
  employees = [],
  count,
  onChangePage,
  onChangeRowsPerPage,
  onRequestSort,
  rowsPerPage,
  page,
  orderBy,
  order,
  passDown,
}) => (
  <Paper style={{ width: '100%' }}>
    <div style={{ overflow: 'auto' }}>
      <Table
        sortable
        pagination
        passDown={passDown}
        count={count}
        headers={headers}
        rows={employees}
        mapper={mapper}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        rowsPerPageOptions={TABLE_ROWS_OPTION}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRequestSort={onRequestSort}
      />
    </div>
  </Paper>
)

export default EmployeesTable
