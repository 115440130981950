import React from 'react';
import {
  withStyles,
  MenuItem,
  MenuList
} from '@material-ui/core';

const withLeadingZero = (number) => {
  const s = number.toString();
  return s.length === 1 ? `0${s}` : s
}

const styles = () => ({
  menu: {
    height: 104,
    minWidth: 48,
    padding: 8,
    overflowY: 'scroll',
  },
  items: {
    height: 8,
    width: 8,
    textAlign: 'center',
    paddingLeft: 8,
    paddingRight: 14,
  }
})

const Hours = ({ hours, selected, onHourClick, classes }) => (
  <MenuList className={classes.menu}>
    {hours.map(hour => (
      <MenuItem
        key={hour}
        value={hour}
        selected={selected === hour}
        onClick={onHourClick}
        className={classes.items}
      >
        {withLeadingZero(hour)}
      </MenuItem>
    ))}
  </MenuList>
)

export default withStyles(styles)(Hours)
