import React from 'react';
import { connect } from 'react-redux';
import { hideNotification } from '../actions/notifications';
import NotificationSnackbar from './NotificationSnackbar';

const Notification = ({ notifications, hideNotification }) => (
  <div>
    {Object.keys(notifications)
      .map(key => (
        <NotificationSnackbar
          {...notifications[key]}
          key={key}
          open
          notificationKey={key}
          handleClose={hideNotification}
        />
      ))}
  </div>
);

export default connect(
  ({ notifications }) => ({ notifications }),
  { hideNotification },
)(Notification);
