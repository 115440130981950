import React from 'react';
import { Grid, Typography } from '@material-ui/core'

const CookieChecker = ({ children }) => {
  if (!navigator.cookieEnabled) {
    return (
      <Grid container alignContent="center" justify="center" style={{ height: '100%' }}>
        <Typography variant="h5" align="center">
          The cookies are disabled in your browser. <br />
          For this service to function, please enable this setting and try again.
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default CookieChecker
