export default {
  "admin.page.title": "Administrasjon kunder",
  "admin.tabs.all": "Alle",
  "admin.tabs.inactive": "Inaktiv",
  "admin.page.add.button": "Legg til ny kunde",
  "admin.page.back.button": "Kundeliste",
  "admin.page.edit.button": "Rediger",
  "admin.page.table.column.org.number": "Org. nummer",
  "admin.page.table.column.name": "Navn",
  "admin.page.table.column.payroll.system": "Lønnssystem",
  "admin.page.table.column.tenant": "Kontor",
  "admin.page.table.column.employees": "Ansatte",
  "admin.page.table.column.status": "Status",
  "admin.page.table.column.empty": "Udefinert",
  "admin.page.table.customer.status.active": "Aktiv",
  "admin.page.table.customer.status.inactive": "Inaktiv",
  "admin.page.table.customer.status.incomplete": "Ufullstendig",
  "admin.page.general.step.title": "Generelt",
  "admin.page.compensation.types.step.title": "Kompenasjonstyper",
  "admin.page.employment.categories.step.title": "Ansattkategorier",
  "admin.page.users.import.step.title": "Importer brukere",
  "admin.page.dimensions.worktypes.step.title": "Dimensjoner og arbeidstyper",
  "admin.page.step.next.button": "Neste",
  "admin.page.step.back.button": "Tilbake",
  "admin.page.step.finish.button": "Avslutt",
  "admin.page.step.save.button": "Lagre",
  "admin.page.step.general.copy.button": "Kopier innstillinger fra eksisterende kunde",
  "admin.page.step.general.customer.name": "Kundenavn",
  "admin.page.step.general.org.number": "Org. nummer",
  "admin.page.step.general.payroll.system": "Lønnssystem",
  "admin.page.step.general.payroll.tenant": "Kontor",
  "admin.page.step.general.payroll.is.active": "Aktive",
  "admin.page.step.general.inactive.date": "Inaktiv dato",
  "admin.page.step.general.period.reports": "Perioder",
  "admin.page.step.general.label.monthly": "Månedlig",
  "admin.page.step.general.label.bi-weekly": "Annenhver uke",
  "admin.page.step.general.label.weekly": "Ukentlig",
  "admin.page.step.general.label.start.from": "Start fra",
  "admin.page.step.general.generated.periods": "Genererte perioder",
  "admin.page.step.general.notifications": "Varsler",
  "admin.page.step.general.add.notification.button": "Legg til ny varsling",
  "admin.page.step.general.notification.type.employee": "Ansatt",
  "admin.page.step.general.notification.type.manager": "Leder",
  "admin.page.step.general.notification.label.type": "Type",
  "admin.page.step.general.label.notification.deadline": "Frist",
  "admin.page.step.general.label.notification.time": "Tid",
  "admin.page.step.general.label.notification.repeatable": "Gjentakende",
  "admin.page.step.general.label.notification.repeatable.tooltip": "Aktivert gjentakelse indikerer at varsler gjentas månedlig på samme tidspunkt. Deaktivert gjentakelse indikerer at varsling kun vil bli sendt en gang på spesifisert tidspunkt.",
  "admin.page.step.general.holidays": "Offentlige fridager",
  "admin.page.step.general.add.holiday.button": "Legg til ny ferie",
  "admin.page.step.general.label.holidays.day": "Dag",
  "admin.page.step.compensation.types.column.code": "Lønnsart",
  "admin.page.step.compensation.types.column.name": "Navn",
  "admin.page.step.compensation.types.column.type": "Type",
  "admin.page.step.compensation.types.column.comment": "Kommentar",
  "admin.page.step.compensation.types.column.reporting.type": "Rapporteringstype",
  "admin.page.step.compensation.types.column.intervals": "Intervall",
  "admin.page.step.compensation.types.column.periodic": "Periodisk",
  "admin.page.step.compensation.types.column.in.summary": "Vis sammendrag",
  "admin.page.step.compensation.types.column.global.type": "Global type",
  "admin.page.step.compensation.types.column.description": "Beskrivelse",
  "admin.page.step.compensation.types.add.compensation.type": "Legg til ny kompensasjonstype",
  "admin.page.step.compensation.types.type.absence": "Fravær",
  "admin.page.step.compensation.types.type.presence": "Tilstedeværelse",
  "admin.page.step.compensation.types.comment.required": "Påkrevd",
  "admin.page.step.compensation.types.comment.no": "Nei",
  "admin.page.step.compensation.types.comment.optional": "Valgfri",
  "admin.page.step.compensation.types.reporting.type.overlapping": "Overlappende",
  "admin.page.step.compensation.types.reporting.type.full.day": "Full dag",
  "admin.page.step.compensation.types.reporting.type.interval": "Intervall",
  "admin.page.step.compensation.types.reporting.type.duration": "Varighet",
  "admin.page.step.compensation.types.reporting.type.full.month": "Full måned",
  "admin.page.step.compensation.types.time.type.vacation": "Ferie",
  "admin.page.step.compensation.types.time.type.overtime": "Overtid",
  "admin.page.step.compensation.types.time.type.off.duty": "Ikke på jobb",
  "admin.page.step.compensation.types.time.type.more.time": "Mer tid",
  "admin.page.step.compensation.types.time.type.sickleave": "Sykefravær",
  "admin.page.step.compensation.types.time.type.workhours": "Arbeidstimer",
  "admin.page.step.compensation.types.time.type.no.deviation": "Ingen avvik",
  "admin.page.step.compensation.types.time.type.care.of.child": "Sykt barn",
  "admin.page.step.compensation.types.time.type.parental.leave": "Foreldrepermisjon",
  "admin.page.step.compensation.types.delete.button": "Slett",
  "admin.page.step.employment.categories.add.employment.category": "Legg til ny ansattkategori",
  "admin.page.step.employment.categories.column.category": "Kategori",
  "admin.page.step.employment.categories.column.project.reporting": "Prosjektrapportering",
  "admin.page.step.employment.categories.column.norm.time": "Normaltid",
  "admin.page.step.employment.categories.column.exclude.weekends": "Ekskluder helger",
  "admin.page.step.employment.categories.column.reporting.method": "Rapporteringsmetode",
  "admin.page.step.employment.categories.column.compensation.types": "Kompensasjonstyper",
  "admin.page.step.employment.categories.delete.button": "Slett",
  "admin.error.remove.compensation.type": "Kan ikke slette kompensasjonstype som allerede er i bruk",
  "admin.error.remove.used.employment.category": "Kan ikke slette ansattkategori som allerede er i bruk",
  "admin.success.notification.saved": "Lagret",
  "admin.error.notification.ops.something.went.wrong": "Obs, noe gikk galt",
  "admin.stepper.page.customer.name": "Kundenavn",
  "admin.page.stepper.customer.org.number": "Org.nummer",
  "admin.page.stepper.custom.error.duplicated.notifications": "Obs, du har dupliserte varslinger. Vær vennlig å forsikre deg om at varslingene er unike",
  "admin.page.stepper.custom.error.duplicated.holidays": "Obs, du har helligdager på samme dato. Vær vennlig å forsikre deg om at det er unike datoer.",
  "admin.page.step.general.label.notification.when": "Når",
  "admin.page.step.general.notification.when.before.deadline": "Før tidsfrist",
  "admin.page.step.general.notification.when.after.deadline": "Etter tidsfrist",
  "admin.page.step.general.notification.when.on.deadline": "På tidsfrist",
  "admin.page.step.general.label.notification.period": "Periode",
  "admin.page.step.general.notification.period.day": "dag",
  "admin.page.step.general.notification.period.days": "dager",
  "admin.page.step.employment.categories.column.work.day": "Arbeidsdag",
  "admin.success.notification.save": "Lagre"
}
