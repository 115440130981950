import React, {useEffect, useState} from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { CustomerLoader } from '../components/CustomerLoader';
import { UsersImportActions } from './components/UsersImportActions';
import { UsersImportFooter } from './components/UsersImportFooter';
import { Paper } from '@material-ui/core';
import { headers } from './components/UsersImportTable/UsersImportTable';
import Table from '../../../components/Table/Table';

import {
  GET_WIZARD_USERS
} from '../../../queries/customers';
import { TABLE_ROWS_OPTION, TABLE_ROWS_PER_PAGE } from "../../../utils/table";
import { DEFAULT_DATE_FORMAT } from "../../../utils/times";

const DEFAULT_ORDER = ['employmentNumber', 'asc'];
const styles = {
  height: '100%',
  width: '100%',
  overflow: 'auto',
  marginBottom: '72px'
};
const tableCellProps = {
  style: { paddingLeft: '15px' }
};

const mapper = [
  { id: 'employmentNumber', tableCellProps },
  { id: 'employmentCategoryName', tableCellProps },
  { id: 'firstName', tableCellProps },
  { id: 'lastName', tableCellProps },
  { id: 'email', tableCellProps },
  { id: 'role', tableCellProps },
  { id: 'departmentName', tableCellProps },
  { id: 'employeeStart', tableCellProps },
];

const getSortKey = key => {
  switch(key) {
    case 'employmentCategoryName':
      return 'employmentCategory.name';

    case 'departmentName':
      return 'department.name';

    default:
      return key;
  }
};

const UsersImport = ({
  setupRightPlace,
  step,
  tenantId,
  params,
  isNettlon,
  organizationNumber,
}) => {
  const customerId = +params.id;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [limit, setLimit] = useState(TABLE_ROWS_PER_PAGE);
  const [users, setUsers] = useState([]);
  const { loading, data, refetch } = useQuery(GET_WIZARD_USERS, {
    variables: {
      customerId,
      limit,
      offset: limit * page,
      order: `${getSortKey(order[0])} ${order[1]}`
    }
  })
  const { wizardUsers: { count = 0 } = {} } = data || {};

  useEffect(() => {
    setupRightPlace(() => (
      <UsersImportActions
        onChange={refetch}
        tenantId={tenantId}
        customerId={customerId}
        isNettlon={isNettlon}
        organizationNumber={organizationNumber}
      />
    ));
    // eslint-disable-next-line
  }, [tenantId, organizationNumber]);

  useEffect(() => {
    const { wizardUsers: { users: usersData = [] } = {}} = data || {};

    setUsers(usersData.map(item => ({
      ...item,
      employeeStart: moment(item.employeeStart).format(DEFAULT_DATE_FORMAT)
    })));
  }, [data]);

  return (
    <React.Fragment>
      {
        loading ?
          (<CustomerLoader />) : (
            <Paper
              style={styles}
            >
              <Table
                sortable
                pagination
                count={count}
                headers={headers}
                rows={users}
                mapper={mapper}
                page={page}
                rowsPerPage={limit}
                order={order[1]}
                orderBy={order[0]}
                rowsPerPageOptions={TABLE_ROWS_OPTION}
                onChangePage={num => setPage(num)}
                onChangeRowsPerPage={num => setLimit(num)}
                onRequestSort={sort => setOrder(sort)}
              />
            </Paper>
          )
      }
      <UsersImportFooter
        loading={loading}
        customerId={customerId}
        step={step}
      />
    </React.Fragment>
  );
};

export default withRouter(UsersImport);
