import React from 'react';
import {
  compose,
  head,
  filter,
  path,
  pathEq,
  pathOr,
  isEmpty,
  isNil,
  either,
  propEq,
  allPass,
} from 'ramda';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';
import { Grid, Paper, Typography } from '@material-ui/core'
import Loader from '../../components/LoadingCircle';
import {
  MainLayout,
  CreateDepartmentButton,
  Department,
} from './components'
import { GET_ALL_DEPARTMENTS_WITH_INFO } from '../../queries/departments';

const Departments = ({
  defaultCustomer,
  roleConfigs: permissions,
  canManage,
  isManager,
  userId,
  selectedCustomer,
  selectedTenant,
  additional
}) => {
  const history = useHistory();
  const { data, loading, refetch } = useQuery(GET_ALL_DEPARTMENTS_WITH_INFO, {
    variables: {
      input: { filters: { customerId: defaultCustomer }},
    },
  })

  React.useEffect(() => {
    if (!isManager || canManage) return;

    const periodId = compose(
      path([0, 'periodId']),
      filter(allPass([
        propEq('id', selectedCustomer),
        propEq('tenantId', selectedTenant),
      ])),
    )(additional.customers);

    history.push(`/time-report-details/${periodId}/${userId}`);
    // eslint-disable-next-line
  }, [])

  const hasNoDepartments = compose(
    either(isEmpty, isNil),
    pathOr([], ['departments']),
  )(data);

  const isCreatable = pathOr(false, ['createDepartments'], permissions);

  return (
    <Grid container alignItems="center">
      {isCreatable && (
        <Paper style={{ width: '100%', padding: '20px 24px' }}>
          <Grid item container justify="space-between" alignItems="center">
            <CreateDepartmentButton permissions={permissions} refetch={refetch} />
          </Grid>
        </Paper>
      )}

      <Grid item container xs={12} spacing={16} style={{ margin: isCreatable ? '20px 0' : 0 }}>
        {loading && <Loader />}
        {!loading && hasNoDepartments && (
          <Grid item xs={12} style={{ padding: 20 }}>
            <Typography align="center" variant="subheading">No departments</Typography>
          </Grid>
        )}
        {!loading && !hasNoDepartments && data.departments.map(department => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={department.id}>
            <Department permissions={permissions} refetch={refetch} {...department} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({
  auth,
  context: additional,
}) => {
  const selectedCustomer = path(['user', 'selectedCustomer'], auth);
  const selectedTenant = path(['user', 'selectedTenant'], auth);
  const isManager = pathEq(['user', 'role'], 'manager', auth);

  const canManage = compose(
    pathOr(false, ['isManager']),
    head,
    filter(pathEq(['id'], selectedCustomer)),
    path(['customers']),
  )(additional);

  return {
    additional,
    selectedCustomer,
    isManager,
    canManage,
    selectedTenant,
    userId: auth.user.id,
  };
};

const DepartmentsPage = compose(
  connect(mapStateToProps),
)(Departments);

export default (props) => (
  <MainLayout {...props}>
    {(r) => <DepartmentsPage {...r} />}
  </MainLayout>
)
