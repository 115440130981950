export default {
  "app.notification.success.create": "Oprettet",
  "app.notification.success.delete": "Slettet",
  "app.notification.success.update": "Opdateret",
  "app.notification.success.default": "Succes",
  "app.notification.error.default": "Noget gik galt",
  "app.notification.error.notNull": "Må ikke være tom",
  "app.notification.error.invalid": "Er ugyldig",
  "app.notification.error.field": "Data ikke gyldig",
  "app.notification.default": "Noget gik galt",
  "notification.error.notNull": "Kan ikke være tom",
  "notification.error.invalid": "Er ugyldig"
}
