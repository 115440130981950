import React, { useState } from 'react';
import Drawer from '../../../../../components/Drawer/Drawer';
import { DrawerListItem } from './components/DrawerListItem';
import { AddDrawerListItem } from './components/AddDrawerListItem';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { StyledMuiTextField } from '../../../components/StyledFields';
import pac from '../../../../../config/intlMessageSelectors/payroll-admin-customers';
import { useField } from "formik";

const styles = () => ({
  root: {
    padding: 10,
    width: '100%'
  }
});

const ProjectsDrawer = ({
  field,
  form,
  onChange,
  classes,
}) => {
  const { value, name } = field;
  const { root } = classes;
  const initialList = value.map(item => ({
    ...item,
  }));
  const [open, setOpen] = useState(false);
  const [list, setList] = useState(initialList);
  const { formatMessage: f } = useIntl();
  const [fieldEnabled] = useField('enabled');

  const onRemove = index => {
    setList(list.filter((item, itemIndex) => itemIndex !== index));
  }

  const onAdd = item => {
    setList([
      ...list,
      item
    ]);
  };

  const onEnter = item => {
    const newList = [
      ...list,
      {
        name: item,
        enabled: true
      },
    ];

    form.setFieldValue(name, newList);
    if (onChange) {
      onChange({
        key: name,
        value: newList,
        form,
        setListInit: () => setList(initialList),
        isDrawer: true,
      });
    }
  };

  const onSave = () => {
    form.setFieldValue(name, list);

    if (onChange) {
      onChange({
        key: name,
        value: list,
        form,
        setListInit: () => setList(initialList),
        isDrawer: true,
      });
    }

    toggleDrawer();
  };

  const toggleDrawer = () => {
    setOpen(!open);
  }

  const toggleSelectOne = (checked, selectedItem) => {
    setList(
      list.reduce((acc, item) => {
        if(item.name === selectedItem.name) {
          acc.push({...item, enabled: checked});
        } else {
          acc.push(item);
        }
        return acc;
      }, [])
    )
  }

  const renderList = data => data.map((item, index) => (
    <DrawerListItem
      key={index}
      name={item.name}
      onRemove={() => onRemove(index)}
      checked={item.enabled}
      onChange={({ target: { checked } }) => toggleSelectOne(checked, item)}
    />
  ));

  const onClose = () => {
    setList(initialList);
    toggleDrawer();
  }

  return (
    <>
      <StyledMuiTextField
        value={list.map(item => item.name)}
        onClick={fieldEnabled.value ? toggleDrawer : () => {}}
        disabled={!fieldEnabled.value}
      />
      <Drawer
        open={open}
        onClose={onClose}
        title={f(pac['admin.page.step.employment.projects.column.activities'])}
        onChange={onSave}
      >
        <div className={root}>
          <AddDrawerListItem onChange={onAdd} onEnter={onEnter} list={list} />
          { renderList(list) }
        </div>
      </Drawer>
    </>
  );
};

export default withStyles(styles)(ProjectsDrawer);
