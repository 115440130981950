import React from 'react';
import { fromPairs } from 'ramda';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import {
  Snackbar,
  SnackbarContent,
  IconButton,
} from '@material-ui/core';
import { green, amber } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import ERROR from '@material-ui/icons/Error';
import SUCCESS from '@material-ui/icons/CheckCircle';
import WARNING from '@material-ui/icons/Warning';
import INFO from '@material-ui/icons/Info';

export const NOTIFICATION = fromPairs([
  'SUCCESS',
  'WARNING',
  'ERROR',
  'INFO',
].map(k => [k, k]));

const variantIcon = {
  ERROR,
  SUCCESS,
  WARNING,
  INFO,
};

const styles = theme => ({
  [NOTIFICATION.SUCCESS]: { backgroundColor: green[600] },
  [NOTIFICATION.WARNING]: { backgroundColor: amber[700] },
  [NOTIFICATION.ERROR]: { backgroundColor: theme.palette.error.dark },
  [NOTIFICATION.INFO]: { backgroundColor: theme.palette.primary.dark },
  icon: { fontSize: 20 },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const MySnackbarContent = ({
  classes,
  className,
  message,
  onClose,
  variant,
  ...other
}) => {
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      )}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <Close className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

const NotificationSnackbar = ({
  message,
  type,
  handleClose,
  notificationKey,
  open,
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={open}
    autoHideDuration={3000}
    onClose={() => handleClose(notificationKey)}
  >
    <MySnackbarContentWrapper
      onClose={() => handleClose(notificationKey)}
      variant={type}
      message={message}
    />
  </Snackbar>
);

export default NotificationSnackbar;
