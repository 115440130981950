import React from 'react';
import {
  Table,
  Paper,
  withStyles,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { pluck, compose, join, map, prepend } from 'ramda'
import { TableHead } from '../../../components/Table';
import pac from '../../../../../config/intlMessageSelectors/payroll-admin-customers';

const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  bodyCell: {
    paddingLeft: 16,
    lineHeight: '20px',
  },
  status: {
    paddingLeft: 16,
    paddingRight: 0,
    lineHeight: '20px',
  },
  tabs: {
    marginBottom: 8,
  }
});

const getLabel = title => pac[`admin.page.step.employment.users.column.${title}`];
const columnStyles = {
  style: { paddingLeft: '15px' }
};

export const headers = [
  {
    id: 'employmentNumber',
    label: getLabel('employmentNumber'),
    tableCellProps: columnStyles,
  },
  {
    id: 'employmentCategoryName',
    label: getLabel('employmentCategoryName'),
    tableCellProps: columnStyles,
  },
  {
    id: 'firstName',
    label: getLabel('firstName'),
    tableCellProps: columnStyles,
  },
  {
    id: 'lastName',
    label: getLabel('lastName'),
    tableCellProps: columnStyles,
  },
  {
    id: 'email',
    label: getLabel('email'),
    tableCellProps: columnStyles,
  },
  {
    id: 'role',
    label: getLabel('role'),
    tableCellProps: columnStyles,
  },
  {
    id: 'departmentName',
    label: getLabel('departmentName'),
    tableCellProps: columnStyles,
  },
  {
    id: 'employeeStart',
    label: getLabel('employeeStart'),
    tableCellProps: columnStyles,
  },
];

const ERROR_HEADER = {
  id: 'errors',
  label: getLabel('status'),
  tableCellProps: columnStyles
}

const HEADERS_MAPPER = {
  departmentName: getLabel('departmentName'),
  employeeStart: getLabel('employeeStart'),
  role: getLabel('role'),
  lastName: getLabel('lastName'),
  firstName: getLabel('firstName'),
  employmentCategoryName: getLabel('employmentCategoryName'),
  employmentNumber: getLabel('employmentNumber'),
  email: getLabel('email'),
}

const USERS_TAB = {
  VALID: 'valid',
  INVALID: 'invalid'
}

const ERROR_MAPPER = {
  '#/properties/role/enum': (row) => 'Role should be employee or manager',
  '#/properties/departmentName/minLength': () => 'Department name should not be empty',
  '#/properties/employmentNumber/minimum': () => 'Employment Number should not be empty',
  '#/properties/employmentNumber/isCreateUserEmploymentNumberUnique': () => 'Employment Number should be uniq within customer',
  '#/properties/employmentCategoryName/minLength': () => 'Employment Category should not be empty',
  '#/properties/firstName/minLength': () => 'User first name should not be empty',
  '#/properties/firstName/maxLength': () => 'User first name is too long',
  '#/properties/lastName/minLength': () => 'User last name should not be empty',
  '#/properties/lastName/maxLength': () => 'User last name is too long',
  '#/properties/email/pattern': () => 'Email should have valid format i.e. test@gmail.com',
  '#/properties/email/isUniqEmail': ({ email }) => `Duplicated email ${email}, it should be uniq globally`,
  '#/properties/employmentNumber/maximum': (row) => 'Employment number is too big'
}

const UsersImportTable = ({
  data = [],
  onTabChange,
  classes,
}) => {
  const { formatMessage: f } = useIntl()
  const [activeTab, setActiveTab] = React.useState(USERS_TAB.VALID)
  const hasRows = Boolean(data[activeTab].length)
  const isInvalid = activeTab === USERS_TAB.INVALID

  const getError = (row) =>  compose(
    join(', '),
    map(k => {
      const getLabel = ERROR_MAPPER[k]

      if (!getLabel) { return '' }

      return getLabel(row)
    }),
    pluck('schemaPath'),
  )(row[ERROR_HEADER.id])


  return (
    <>
      <Tabs
        indicatorColor="primary"
        className={classes.tabs}
        value={activeTab}
        onChange={(_, active) => {
          setActiveTab(active)
          onTabChange(active);
        }}
      >
        <Tab value={USERS_TAB.VALID} label="Valid"/>
        <Tab value={USERS_TAB.INVALID} label="Invalid"/>
      </Tabs>

      <br />

      <Paper className={classes.root}>
        <Table>
          <TableHead headers={isInvalid ? [...headers, ERROR_HEADER] : headers} />
          <TableBody>
            {!hasRows && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No Data
                </TableCell>
              </TableRow>
            )}
            {hasRows && data[activeTab].map((row, index) => (
              <TableRow key={row.id || index}>
                {
                  headers.map(item => (
                    <TableCell
                      key={row.employmentNumber + item.id}
                      className={classes.bodyCell}
                    >
                      {row[item.id]}
                    </TableCell>
                  ))
                }
                {isInvalid && (
                  <TableCell className={classes.status}>
                    {getError(row)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

export default withStyles(styles)(UsersImportTable);
