import React from 'react';
import { graphql } from 'react-apollo';
import { Route, Switch, withRouter } from 'react-router-dom';
import { path, compose, toUpper } from 'ramda';
import { Typography, Grid } from '@material-ui/core';

import MainLayout from './components/MainLayout';
import { General } from './General';
import { CompensationTypes } from './CompensationTypes';
import { EmploymentCategories } from './EmploymentCategories';
import { Projects } from './Projects';
import { UsersImport } from './UsersImport';
import { WizzardContext } from '../../providers/WizzardProvider';
import { CustomerStepper } from './components/CustomerStepper';

import { GET_CUSTOMER_BY_ID } from '../../queries/customers';
import pac from '../../config/intlMessageSelectors/payroll-admin-customers';

export const CUSTOMER_STATUSES = {
  GENERAL: 1,
  COMPENSATION_TYPES: 2,
  EMPLOYMENT_CATEGORIES: 3,
  PROJECTS: 4,
  USERS_IMPORT: 5,
};

export const MAIN_CUSTOMER_WIZARD_FLOW = {
  GENERAL: {
    id: 0,
    label: pac['admin.page.general.step.title'],
    back: '/customers',
    next: 'types',
    path: 'general',
  },
  TYPES: {
    id: 1,
    label: pac['admin.page.compensation.types.step.title'],
    back: 'general',
    next: 'categories',
    path: 'types',
  },
  CATEGORIES: {
    id: 2,
    label: pac['admin.page.employment.categories.step.title'],
    back: 'types',
    next: ['projects', 'users'],
    path: 'categories',
  },
  USERS: {
    id: 4,
    label: pac['admin.page.users.import.step.title'],
    back: ['projects', 'categories'],
    next: '/customers',
    path: 'users',
  },
};

export const PROJECT_CUSTOMER_WIZARD_FLOW = {
  PROJECTS: {
    id: 3,
    label: pac['admin.page.projects.step.title'],
    back: 'categories',
    next: 'users',
    path: 'projects',
  }
};

const NETTLON_CODE = 2;

export const CUSTOMER_WIZARD_FLOW = {
  ...MAIN_CUSTOMER_WIZARD_FLOW, ...PROJECT_CUSTOMER_WIZARD_FLOW
};

const Information = React.memo(({ name, number }) => (
  <Grid container item xs={12} alignItems="center" justify="flex-end">
    <Typography variant="h6">
      {name}&nbsp;
    </Typography>
    {number && (
      <Typography variant="subtitle1">
        ({number})
      </Typography>
    )}
  </Grid>
), () => true);

class CustomerWizard extends React.Component {
  static contextType = WizzardContext;
  constructor(props) {
    super(props);

    this.state = {
      steps: CUSTOMER_WIZARD_FLOW,
      RightPlace: null,
    };

    this.paths = Object.values(CUSTOMER_WIZARD_FLOW).reduce((acc, item) => {
      acc[item.id] = item.path;

      return acc;
    }, {});
    this.setupRightPlace = this.setupRightPlace.bind(this);
    this.goToStep = this.goToStep.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { hasProjectReporting } = this.props;

    if (typeof hasProjectReporting !== 'undefined' && this.context?.showProjectsStep !== hasProjectReporting) {
      this.context?.setShowProjectsStep(hasProjectReporting);
    }
  }

  goToStep(id) {
    const {
      history,
      match: { params: { id: customerId } },
      isFlowCompleted,
    } = this.props;

    if (isFlowCompleted) {
      history.push(`/customers/${customerId}/step/${this.paths[id]}`);
    }
  }

  getStepDetails() {
    return path(
      [compose(toUpper, path(['match', 'params', 'step']))(this.props)],
      path(['steps'], this.state),
    );
  }

  setupRightPlace(RightPlace) {
    this.setState({ RightPlace });
  }

  render() {
    const { RightPlace } = this.state;
    const {
      name,
      organizationNumber: number,
      loading,
      match,
      tenantId,
      match: { params: { id: customerId } },
      payrollSystem,
      refetch,
    } = this.props;
    const { id, back, next } = this.getStepDetails();

    const toPass = {
      setupRightPlace: this.setupRightPlace,
      step: { back, next },
    };

    return (
      <React.Fragment>
        <Grid container justify="space-between" alignItems="center" style={{ minHeight: 72 }}>
          <Grid item xs={7}>
            <CustomerStepper
              id={id}
              onChange={this.goToStep}
            />
          </Grid>

          <Grid
            container
            direction="column"
            item
            xs={3}
            style={{ marginBottom: 8 }}
            alignItems="flex-end"
          >
            {!loading && id !== 0 && (
              <Information name={name} number={number} />
            )}

            {RightPlace && (
              <Grid item xs={12}>
                <RightPlace />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Switch>
          <Route
            path={`/customers/${customerId}/step/${CUSTOMER_WIZARD_FLOW.GENERAL.path}`}
            render={() => <General {...toPass} {...match} />}
          />

          <Route
            path={`/customers/${customerId}/step/${CUSTOMER_WIZARD_FLOW.TYPES.path}`}
            render={() => <CompensationTypes {...toPass} {...match} />}
          />

          <Route
            path={`/customers/${customerId}/step/${CUSTOMER_WIZARD_FLOW.CATEGORIES.path}`}
            render={() => <EmploymentCategories {...toPass} {...match} customerDataRefetch={refetch} />}
          />

          <Route
            path={`/customers/${customerId}/step/${CUSTOMER_WIZARD_FLOW.PROJECTS.path}`}
            render={() => <Projects {...toPass} {...match} />}
          />

          <Route
            path={`/customers/${customerId}/step/${CUSTOMER_WIZARD_FLOW.USERS.path}`}
            render={() => <UsersImport
              {...toPass}
              {...match}
              tenantId={tenantId}
              isNettlon={payrollSystem?.code === NETTLON_CODE}
              organizationNumber={number}
            />}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

const Customer = compose(
  withRouter,
  graphql(GET_CUSTOMER_BY_ID, {
    skip: ({ match: { params: { id } } }) => id === 'new',
    options: ({ match: { params: { id } } }) => ({
      variables: { customerId: +id },
      fetchPolicy: 'no-cache',
    }),
    props: ({ data: { customerById, loading, refetch } }) => ({
      ...customerById,
      loading,
      refetch,
    }),
  }),
)(CustomerWizard);

export default props => (
  <MainLayout {...props}>
    <Customer {...props} />
  </MainLayout>
);
