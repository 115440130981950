import React from 'react';
import { TableBody as MuiTableBody, TableRow, TableCell, Grid } from '@material-ui/core';

const TableBody = ({
  rows = [],
  onRowUpdate,
  onRowRemove,
  AddNewRowComponent,
  PreFilledRow,
  preFilledRowProps,
}) => (
  <MuiTableBody>
    {rows.map(row => (
      <PreFilledRow
        key={row.id}
        data={row}
        onUpdate={onRowUpdate}
        onRemove={onRowRemove}
        {...preFilledRowProps}
      />
    ))}
    <TableRow>
      <TableCell colSpan={11}>
        <Grid container justify="center">
          {AddNewRowComponent && <AddNewRowComponent />}
        </Grid>
      </TableCell>
    </TableRow>
  </MuiTableBody>
);

export default TableBody;
