import React from 'react';
import {
  uniq,
  splitEvery,
  pathOr,
  compose,
  flatten,
  pluck,
  includes,
  difference,
  concat,
  filter,
  join,
  isEmpty,
} from 'ramda';
import { Grid, Typography } from '@material-ui/core';
import { Popup, Menu } from './components';

const initialState = {
  target: null,
  isOpen: false,
  options: [],
  selected: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        selected: pathOr([], ['payload', 'selected'], action),
        options: compose(
          splitEvery(10),
          pathOr([], ['payload', 'options']),
        )(action),
      };
    case 'OPEN_MENU':
      return {
        ...state,
        target: action.payload,
        isOpen: true,
      };
    case 'CLOSE_MENU':
      return {
        ...state,
        target: null,
        isOpen: false,
      };
    case 'CHECK_ALL':
      return {
        ...state,
        selected: action.payload
          ? compose(uniq, pluck('value'), flatten)(state.options)
          : [],
      };
    case 'CHECK_ONE':
      return {
        ...state,
        selected: action.payload.checked
          ? concat([action.payload.value], state.selected)
          : difference(state.selected, [action.payload.value]),
      };
    default:
      return state;
  }
};

const MultiMenu = ({ options, selected, onSelect, onCancel, BaseLine = 'div', BaseLineProps }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    dispatch({ type: 'INITIALIZE', payload: { options, selected } });
    // eslint-disable-next-line
  }, [options]);

  React.useEffect(() => {
    if(state.isOpen) {
      dispatch({ type: 'INITIALIZE', payload: { options, selected } });
    }
    // eslint-disable-next-line
  }, [state.isOpen]);

  const close = React.useCallback(() => {
    dispatch({ type: 'CLOSE_MENU' });
    // eslint-disable-next-line
  }, []);

  const open = React.useCallback(
    ({ target }) => dispatch({ type: 'OPEN_MENU', payload: target }),
    // eslint-disable-next-line
    [],
  );

  const isNoOptions = React.useMemo(
    () => compose(
      isEmpty,
      pathOr([], ['options']),
    )(state),
    // eslint-disable-next-line
    [state.options.length],
  );

  return (
    <React.Fragment>
      <BaseLine
        onClick={open}
        value={compose(
          join(', '),
          pluck('label'),
          filter(({ value }) => includes(value, selected)),
          flatten,
        )(state.options)}
        {...BaseLineProps}
      />
      <Popup element={state.target} isOpen={state.isOpen} onClose={close}>
        {isNoOptions && (
          <Grid container justify="center" alignItems="center" style={{ padding: 40 }}>
            <Typography>
              Ops, seems like there are not options available
            </Typography>
          </Grid>
        )}
        {!isNoOptions && (
          <Menu
            options={state.options}
            selected={state.selected}
            onCheckAll={checked => dispatch({ type: 'CHECK_ALL', payload: checked })}
            onCheckOne={({ checked, value }) =>
              dispatch({
                type: 'CHECK_ONE',
                payload: { value, checked },
              })}
            onSelect={e => {
              e.preventDefault();
              onSelect(state.selected);
              close();
            }}
            onCancel={() => {
              if (onCancel) {
                onCancel();
              }
              close();
            }}
          />
        )}
      </Popup>
    </React.Fragment>
  );
};

export default MultiMenu;
