import React from 'react';
import { Paper } from '@material-ui/core';
import EmployeesVerticalMenu from '../EmployeesVerticalMenu';
import Table from '../../../../components/Table/Table';
import pae from '../../../../config/intlMessageSelectors/payroll-admin-employees';
import { TABLE_ROWS_OPTION } from "../../../../utils/table";

const roles = {
  employee: 'employee',
  manager: 'manager',
  approver: 'approver',
  customeradmin: 'customer admin',
}

const headers = [
  {
    id: 'menu',
    label: '',
    tableCellProps: { style: { padding: '4px 16px' } }
  },
  {
    id: 'employmentNumber',
    label: pae['app.employees.employmentNumber'],
    tableCellProps: { style: { width: '15%', minWidth: '186px', padding: '4px 16px' } },
  },
  {
    id: 'employmentCategory.name',
    label: pae['app.employees.employmentCategory'],
    tableCellProps: { style: { minWidth: '237px', padding: '4px 16px' } },
  },
  {
    id: 'personalInfo.firstName',
    label: pae['app.employees.firstName'],
    tableCellProps: { style: { minWidth: '172px', padding: '4px 16px' } },
  },
  {
    id: 'personalInfo.lastName',
    label: pae['app.employees.lastName'],
    tableCellProps: { style: { minWidth: '172px', padding: '4px 16px' } },
  },
  {
    id: 'email',
    label: pae['app.employees.email'],
    tableCellProps: { style: { padding: '4px 16px' } }
  },
  {
    id: 'department.name',
    label: pae['app.employees.department'],
    tableCellProps: { style: { width: '15%', padding: '4px 16px' } },
  },
  {
    id: 'role',
    label: pae['app.employees.role'],
    tableCellProps: { style: { padding: '4px 16px', minWidth: '100px' } }
  },
];

const mapper = [
  { id: 'menu', component: EmployeesVerticalMenu },
  { id: 'employmentNumber' },
  { id: 'employmentCategory.name' },
  { id: 'personalInfo.firstName' },
  { id: 'personalInfo.lastName' },
  { id: 'email' },
  { id: 'department.name' },
  { id: 'role', component: ({ row: { role }}) => roles[role] },
];

const EmployeesTable = ({
  employees = [],
  count,
  onChangePage,
  onChangeRowsPerPage,
  onRequestSort,
  rowsPerPage,
  page,
  orderBy,
  order,
  passDown,
}) => (
  <Paper style={{ width: '100%' }}>
    <div style={{ overflow: 'auto' }}>
      <Table
        sortable
        pagination
        passDown={passDown}
        count={count}
        headers={headers}
        rows={employees}
        mapper={mapper}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        rowsPerPageOptions={TABLE_ROWS_OPTION}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRequestSort={onRequestSort}
      />
    </div>
  </Paper>
);

export default EmployeesTable;
