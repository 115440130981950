import React from 'react';
import {
  Table,
  Paper,
  withStyles,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { Check } from '../../../../../components/Check';
import { TableHead } from '../../../components/Table';
import pac from '../../../../../config/intlMessageSelectors/payroll-admin-customers';

const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    marginBottom: '24px',
  },
  bodyCell: {
    paddingLeft: '15px'
  }
});

const getLabel = title => pac[`admin.page.step.employment.projects.column.${title}`];
const columnStyles = {
  style: { paddingLeft: '15px' }
};

const errorRowStyles = {
  outline: '2px solid red',
  borderLeft: '2px solid red',
  borderRight: '2px solid red'
};

export const headers = [
  {
    id: 'projectNumber',
    label: getLabel('projectNumber'),
    tableCellProps: columnStyles,
  },
  {
    id: 'projectName',
    label: getLabel('projectName'),
    tableCellProps: columnStyles,
  },
  {
    id: 'billable',
    label: getLabel('billable'),
    tableCellProps: columnStyles,
    isBoolean: true,
  },
  // {
  //   id: 'enabled',
  //   label: getLabel('enabled'),
  //   tableCellProps: columnStyles,
  //   isBoolean: true,
  // },
];

const ProjectsTable = ({
  data = [],
  invalidRows = [],
  classes,
}) => (
  <Paper
    className={classes.root}
  >
    <Table>
      <TableHead headers={headers} />
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={row.id || index} style={invalidRows.includes(index) ? errorRowStyles : {}}>
            {
              headers.map(item => (
                <TableCell
                  key={row.projectNumber + item.id}
                  className={classes.bodyCell}
                >
                  {item.isBoolean ? ( <Check value={+row[item.id]} /> ) : row[item.id]}
                </TableCell>
              ))
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Paper>
);

export default withStyles(styles)(ProjectsTable);
