import React from 'react';
import MuiTableRow from '@material-ui/core/TableRow';

const TableRow = ({ children }) => (
  <MuiTableRow variant="body">
    {children}
  </MuiTableRow>
);

export default TableRow;
