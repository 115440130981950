import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  omit,
  isEmpty,
  isNil,
  compose,
  pathOr,
  pathEq,
  path,
  not,
  find,
  identity,
  concat,
  ifElse,
} from 'ramda';
import ReactSelect from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import moment from 'moment';
import {
  Button,
  DialogActions,
  TextField,
  Grid,
  withStyles,
  FormControlLabel,
  Radio,
  Checkbox,
  Collapse,
} from '@material-ui/core';
import { withApollo } from 'react-apollo';
import { useIntl } from 'react-intl';
import { DatePicker } from 'material-ui-pickers';
import { findLastIndex } from "lodash";

import InputCounter from '../InputCounter';
import IntervalBasedTimePicker from './IntervalBasedTimePicker';
import SimpleDialog from '../Dialog';
import { showNotification } from '../../actions/notifications';
import { NOTIFICATION } from '../NotificationSnackbar';
import {
  getErrorBackendMessage,
  getErrorMessageCode,
  getErrorStr
} from '../../utils/error';
import pab from '../../config/intlMessageSelectors/payroll-admin-base';
import ptr from '../../config/intlMessageSelectors/payroll-admin-timeReport';
import pan from '../../config/intlMessageSelectors/payroll-admin-notifications';
import { GET_EMPLOYEE_DIMENSIONS, GET_PROJECT_ACTIVITIES } from '../../queries/time-report';
import { CREATE_EMPLOYEE_PROJECT_REPORT, UPDATE_EMPLOYEE_PROJECT_REPORT } from '../../queries/transactions';
import { TRANSACTION_STATUSES } from "../../utils/timeReports";
import { DEFAULT_DATE_FORMAT } from "../../utils/times";

const DESC_MAX_LENGTH = 255;
const ERROR_CODES = {
  REPORTING_MORE_THAN_24H: 5,
  dimension_not_active: 'dimension_not_active',
  work_type_disabled: 'work_type_disabled',
};

const ERROR_MESSAGES = {
  [ERROR_CODES.REPORTING_MORE_THAN_24H]: 'app.timeReport.reportingMoreThan24H',
  [ERROR_CODES.dimension_not_active]: 'app.timeReport.dimensionNotActive',
  [ERROR_CODES.work_type_disabled]: 'app.timeReport.workTypeDisabled',
};

const { OPEN, REJECTED} = TRANSACTION_STATUSES;

const getErrorMessage = code => ERROR_MESSAGES[code];

const CreateProjectModal = ({
  onClose,
  classes,
  userId,
  compensationTypes,
  data,
  refetchFun,
  client,
  showNotification,
  defaultDate,
  normalWorkDay,
  currentUser,
}) => {
  const { formatMessage: f } = useIntl();

  const [isValid, setIsValid] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [applyToAll, setApplyToAll] = useState(true);
  const [workTypes, setWorkTypes] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [project, setProject] = useState({
    userId: Number(userId),
    transactions: [
      {
        date: defaultDate
          .startOf('day')
          .format(DEFAULT_DATE_FORMAT),
        duration: 0,
        status: OPEN
      },
    ]
  });

  const selectedCompensation = compensationTypes.find(
    ({ value }) => value === project.compensationTypeId,
  );

  const isInfo = pathOr(false, ['isInfo'], data);
  const isEdit = pathOr(false, ['isEdit'], data);
  const rejectMessage = pathOr(false, ['rejectMessage'], data);

  useEffect(() => {
    if (isEmpty(data) || isNil(data)) return;

    const {
      dimensionId,
      compensationTypeId,
      workTypeId,
      comment,
      id,
      date,
      duration,
      children,
      isEdit,
      isInfo,
      status,
      parentStatus
    } = data;

    if (isEdit || isInfo) {
      let transactions = [
        {
          date: date,
          duration: duration,
          status: parentStatus || status || OPEN,
        }
      ];

      if (children.length > 0) {
        setIsPeriod(true);
        children.forEach(tr => {
          transactions.push({
            date: tr.date,
            duration: tr.duration,
            status: tr.status
          })
        });
      }

      setProject({
        ...project,
        dimensionId,
        compensationTypeId,
        comment,
        workTypeId,
        parentId: id,
        transactions
      });
    } else {
      setProject({
        ...project,
      });
    }
  }, []);

  // re-fetch work types when dimension id has changed
  useEffect(() => {
    fetchWorkTypesByDimension(project.dimensionId);
    // eslint-disable-next-line
  }, [project.dimensionId]);

  useEffect(() => {
    if (!isEdit) {
      let transactions = [
        {
          date: project.transactions[0].date,
          duration: 0,
          status: project.transactions[0].status,
        },
      ];

      if (['HALF_DAY', 'FULL_DAY'].includes(selectedCompensation?.interval)) {
        transactions = [
          {
            date: project.transactions[0].date,
            duration: normalWorkDay,
            status: project.transactions[0].status,
          },
        ];
      }

      setProject({
        ...project,
        transactions
      })
    }
  }, [selectedCompensation?.interval]);

  // validation checks
  useEffect(() => {
    const shouldShowDescription = compose(
      not,
      pathEq(['comment'], 'NO'),
      find(({ value }) => value === project.compensationTypeId),
    )(compensationTypes);

    setIsDescriptionRequired(shouldShowDescription);

    setIsValid(
      project.compensationTypeId
        && project.dimensionId
        && project.workTypeId
        && (!shouldShowDescription || (shouldShowDescription && project.comment))
        && (project.transactions[0].duration !== 0 || selectedCompensation?.timeType === 'NO_DEVIATIONS'),
    );
    // eslint-disable-next-line
  }, [
    project.compensationTypeId,
    project.dimensionId,
    project.workTypeId,
    project.comment,
    project.transactions
  ]);

  useEffect(() => {
    if (isPeriod && project.transactions.length === 1) {
      const nextDay = moment(project.transactions[0].date)
        .add(1, 'day')
        .startOf('day')
        .format(DEFAULT_DATE_FORMAT);

      const transactions = [
        {
          date: project.transactions[0].date,
          duration: project.transactions[0].duration,
          status: project.transactions[0].status,
        },
        {
          date: nextDay,
          duration: project.transactions[0].duration,
          status: OPEN
        },
      ];

      setProject({
        ...project,
        transactions
      })
    } else if (!isPeriod && project.transactions.length > 1) {
      const transactions = [
        {
          date: project.transactions[0].date,
          duration: project.transactions[0].duration,
          status: project.transactions[0].status,
        },
      ];

      setProject({
        ...project,
        transactions
      })
    }
  }, [isPeriod]);

  const fetchWorkTypesByDimension = async dimensionId => {
    if (!dimensionId) return;

    const { data: { projectActivities } } = await client.query({
      query: GET_PROJECT_ACTIVITIES,
      variables: {
        filter: {
          projectId: project.dimensionId,
          includeIds: isEdit ? [project.workTypeId] : undefined,
        },
      },
    });

    const mappedWorkTypes = projectActivities.map(({ id, name }) => ({
      label: name,
      value: +id,
    }));

    setWorkTypes(mappedWorkTypes);
  };

  const fetchDimensions = async () => {
    const { data: { employeeDimensions } } = await client.query({
      query: GET_EMPLOYEE_DIMENSIONS,
      variables: {
        filter: {
          employeeId: Number(userId),
          includeIds: isEdit ? [project.dimensionId] : undefined,
        },
      },
    });

    const d = employeeDimensions.map(({ dimensionNumber, name, id }) => ({
      label: `${name} - ${dimensionNumber}`,
      value: +id,
    }));

    setDimensions(d);
    return d;
  };

  const onConfirm = async () => {
    const transactions = [];
    // do not pass these fields to update query
    const omitOnEdit = ['userId'];

    const toSkip = ifElse(
      () => isEdit,
      concat(omitOnEdit),
      identity,
    )([]);

    project.transactions.forEach(tr =>
      transactions.push({
        date: tr.date,
        duration: tr.duration
      }),
    );

    const toSubmit = {
      ...omit(toSkip, {...project, transactions}),
      ...(!isDescriptionRequired && { comment: undefined }),
    };

    try {
      const mutation = isEdit ? UPDATE_EMPLOYEE_PROJECT_REPORT : CREATE_EMPLOYEE_PROJECT_REPORT;

      await client.mutate({
        mutation,
        variables:  { input: toSubmit },
      });

      showNotification({
        [Date.now()]: {
          message: f(
            pan[`app.notification.success.${isEdit ? 'update' : 'create'}`],
          ),
          type: NOTIFICATION.SUCCESS,
        },
      });

      refetchFun();
      onClose();
    } catch (e) {
      const errorCode = getErrorMessageCode(e);
      const errorBackendCode = getErrorStr(e);
      const errorBackendMessage = getErrorBackendMessage(e);

      const message = ptr[getErrorMessage(errorCode || errorBackendCode)]
        && f(ptr[getErrorMessage(errorCode || errorBackendCode)]);

      showNotification({
        [Date.now()]: {
          message: message || errorBackendMessage || 'Ops something went wrong',
          type: NOTIFICATION.ERROR,
        },
      });
    }
  };

  const renderDayRow = (transaction, index) => {
    const hours = selectedCompensation.interval === 'FULL_DAY' ? normalWorkDay : 0;
    const isOverlapping = selectedCompensation.reportingType === 'OVERLAPPING';
    const isRange = index !== 0 && index !== project.transactions.length - 1;
    const canChange = transaction?.status === OPEN || transaction?.status === REJECTED;
    let minDate;
    let maxDate;

    if(index === project.transactions.length - 1) {
      const lastNotOpenTransactionIdx = findLastIndex(project.transactions, n => n.status !== OPEN);
      const lastNotOpenTransaction = project.transactions[lastNotOpenTransactionIdx];
      minDate = lastNotOpenTransaction && moment(lastNotOpenTransaction.date).add(1, 'days').format(DEFAULT_DATE_FORMAT)
    }

    if (currentUser.employeeEnd) {
      maxDate = new Date(currentUser.employeeEnd);
    }

    return (
      <div
        key={+moment(transaction.date)}
        style={{ margin: '10px 0', display: 'flex' }}
      >
        <DatePicker
          error={null}
          helperText={null}
          disabled={isInfo || isRange || !canChange }
          variant="outlined"
          label={isRange
            ? ''
            : index === 0
              ? f(ptr['app.timeReport.formStart'])
              : f(ptr['app.timeReport.formEnd'])}
          value={transaction.date}
          onChange={date => generateDeviations(date, index)}
          style={{ zIndex: 0 }}
          InputProps={{ classes: { root: classes.datePicker } }}
          minDate={minDate}
          maxDate={maxDate}
        />
        <div className="materialish-wrapper">
          <label className="materialish-input">
            Duration
          </label>
          <div className="materialish-container">
            <fieldset className="materialish-fieldset">
              <legend className="materialish-legend">
                <span>&#8203;</span>
              </legend>
            </fieldset>
            <IntervalBasedTimePicker
              interval={selectedCompensation.interval}
              normalWorkDay={normalWorkDay}
              maxTime={{
                hour: isOverlapping
                  ? 24
                  : parseInt((hours / (1000 * 60 * 60)) % 24, 10) || 24,
                minutes: isOverlapping
                  ? 0
                  : parseInt((hours / (1000 * 60)) % 60, 10),
              }}
              value={transaction.duration}
              onChange={value => setTimeWithHours(value, index)}
              disabled={isInfo || !canChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const setTimeWithHours = (duration, index) => {
    const transactions = [...project.transactions];

    if (applyToAll) {
      transactions
        .filter(({ status }) => (status === OPEN || status === REJECTED))
        .forEach(transaction => (transaction.duration = duration))
    } else {
      transactions[index].duration = duration;
    }

    setProject({
        ...project,
        transactions,
    });
  };

  const generateDeviations = (date, index) => {
    let transactions = [];
    if (isPeriod) {
      if (index) {
        let i = 0;
        let current = project.transactions[0].date;
        let lastTransaction = project.transactions[project.transactions.length-1];

        while (
          +moment(current) <=
          +moment(date).startOf('day')
          ) {
          // eslint-disable-next-line
          const copyExistingDev = project.transactions.find(dev => dev.date === current);

          let duration = (copyExistingDev && copyExistingDev.duration) ||
          selectedCompensation.interval === 'FULL_DAY' ? normalWorkDay : 0;
          if (isEdit) {
            duration = project.transactions[i]?.duration || duration || lastTransaction.duration
          }

          const status = project.transactions[i]?.status || lastTransaction.status;

          transactions.push({
            date: current,
            duration,
            status,
          });

          current = moment(current)
            .add(1, 'day')
            .startOf('day')
            .format(DEFAULT_DATE_FORMAT);
          i++;
        }
      } else {
        let current = moment(date)
          .startOf('day')
          .format(DEFAULT_DATE_FORMAT);
        if (
          +moment(current) <
          +moment(
            project.transactions[project.transactions.length - 1].date,
          )
        ) {
          while (
            current <=
            project.transactions[project.transactions.length - 1].date
            ) {
            // eslint-disable-next-line
            const copyExistingDev = project.transactions.find(dev => dev.date === current);
            transactions.push({
              date: current,
              duration:
                (copyExistingDev && copyExistingDev.duration) ||
                selectedCompensation.interval === 'FULL_DAY' ? normalWorkDay : 0,
              status: OPEN,
            });
            current = moment(current)
              .add(1, 'day')
              .startOf('day')
              .format(DEFAULT_DATE_FORMAT);
          }
        } else {
          transactions = [
            {
              date: current,
              duration: 0,
              status: OPEN,
            },
            {
              date: moment(current)
                .add(1, 'day')
                .startOf('day')
                .format(DEFAULT_DATE_FORMAT),
              duration: 0,
              status: OPEN,
            },
          ];
        }
      }
    } else if (!isPeriod) {
      transactions.push({
        date: moment(date)
          .startOf('day')
          .format(DEFAULT_DATE_FORMAT),
        duration:
          project.transactions[0].duration ||
          0,
        status: project.transactions[0].status || OPEN
      });
    }
    setProject({
      ...project,
      transactions,
    });
  }

  return (
    <SimpleDialog fullWidth maxWidth="sm" open onClose={onClose}>
      <Grid container direction="column" spacing={8}>
        <Grid item style={{ margin: '4px 0' }}>
          <ReactAsyncSelect
            isDisabled={isInfo}
            placeholder={f(ptr['app.timeReport.project'])}
            defaultOptions
            loadOptions={fetchDimensions}
            onChange={({ value }) => {
              setProject({
                ...project,
                dimensionId: value,
              });
            }}
            value={dimensions.find(
              ({ value }) => value === project.dimensionId,
            )}
          />
        </Grid>
        <Grid item style={{ margin: '4px 0' }}>
          <ReactSelect
            isDisabled={isInfo}
            placeholder={f(ptr['app.timeReport.projectCompensationType'])}
            options={compensationTypes}
            onChange={({ value }) => {
              const interval = compose(
                path(['interval']),
                find(pathEq(['value'], value)),
              )(compensationTypes);

              let transactions = [...project.transactions];

              if (interval === 'FULL_DAY') {
                transactions
                  .filter(({ status }) => (status === OPEN))
                  .forEach(transaction => (transaction.duration = normalWorkDay))
              }

              setProject({
                ...project,
                compensationTypeId: value,
                transactions
              });
            }}
            value={compensationTypes.find(
              ({ value }) => value === project.compensationTypeId,
            )}
          />
        </Grid>

        <Grid item style={{ margin: '4px 0' }}>
          <ReactSelect
            key={Date.now()}
            isDisabled={isInfo}
            placeholder={f(ptr['app.timeReport.workType'])}
            options={workTypes}
            onChange={({ value }) =>
              setProject({
                ...project,
                workTypeId: value,
              })}
            value={workTypes.find(({ value }) => value === project.workTypeId)}
          />
        </Grid>

        {selectedCompensation &&
          selectedCompensation.reportingType !== 'FULLMONTH' && (
            <div style={{ padding: '0 4px' }}>
              {selectedCompensation.multipleDays && (!data?.status || data?.status === OPEN) &&
                (
                  <div>
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      control={
                        <Radio
                          disabled={isInfo}
                          checked={!isPeriod}
                          onChange={() => setIsPeriod(false)}
                          name="single"
                        />
                      }
                      label={f(ptr['app.timeReport.singleDayToggle'])}
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      classes={{ label: classes.label }}
                      control={
                        <Radio
                          disabled={isInfo}
                          checked={isPeriod}
                          onChange={() => setIsPeriod(true)}
                          name="multiple"
                        />
                      }
                      label={f(ptr['app.timeReport.periodToggle'])}
                      labelPlacement="end"
                    />
                  </div>
                )}
              <div>
                {renderDayRow(project.transactions[0], 0)}
                {isPeriod && !isInfo &&
                  selectedCompensation.reportingType === 'DURATION' && (!data?.status || data?.status === OPEN || data?.status === REJECTED) &&
                  (
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      control={
                        <Checkbox
                          checked={applyToAll}
                          onChange={() => setApplyToAll(!applyToAll)}
                        />
                      }
                      label={f(ptr['app.timeReport.copyDatesBelow'])}
                    />
                  )}

                {project.transactions.length > 2 && (
                  <Button
                    style={{ marginLeft: '10%' }}
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    {collapsed
                      ? f(ptr['app.timeReport.showLess'])
                      : f(ptr['app.timeReport.showMore'])}
                  </Button>
                )}

                <Collapse component="div" in={collapsed}>
                  {project.transactions.map((transaction, index) =>
                    index && index !== project.transactions.length - 1
                      ? renderDayRow(transaction, index)
                      : null,
                  )}
                </Collapse>

                {project.transactions.length > 1 &&
                  renderDayRow(
                    project.transactions[
                    project.transactions.length - 1
                      ],
                    project.transactions.length - 1,
                  )}
              </div>
            </div>
          )}

        {isDescriptionRequired && (
          <Grid item>
            <InputCounter
              limit={DESC_MAX_LENGTH}
              len={project.comment ? project.comment.length : 0}
            >
              <TextField
                disabled={isInfo}
                fullWidth
                onChange={({ target: { value } }) =>
                  setProject({
                    ...project,
                    comment: value,
                  })}
                inputProps={{
                  maxLength: DESC_MAX_LENGTH,
                }}
                value={project.comment}
                multiline
                rows={2}
                rowsMax={4}
                label={`${f(ptr['app.timeReport.description'])}*`}
                variant="outlined"
                style={{ zIndex: 0 }}
                InputProps={{
                  classes: { root: classes.comment },
                }}
              />
            </InputCounter>
          </Grid>
        )}
      </Grid>

      {!isInfo && (
        <DialogActions style={{ margin: '10px -4px' }}>
          <Button
            type="button"
            onClick={onClose}
            variant="outlined"
            classes={{ outlined: classes.outlined }}
            className="ga-create-project-cancel-button"
          >
            {f(pab['app.base.btns.cancel'])}
          </Button>
          <Button
            disabled={!isValid}
            variant="contained"
            classes={{ contained: classes.contained }}
            style={{ borderColor: isValid ? '#62B081' : 'rgba(0, 0, 0, 0)' }}
            type="submit"
            onClick={onConfirm}
            color="primary"
            className="ga-create-project-confirm-button"
          >
            {f(pab['app.base.btns.confirm'])}
          </Button>
        </DialogActions>
      )}

      {rejectMessage && (
        <div style={{ margin: '10px 0' }}>
          <h4>{f(ptr['app.timeReport.rejectMessage'])}</h4>
          <p>{rejectMessage}</p>
        </div>
      )}
    </SimpleDialog>
  );
};

const styles = () => ({
  label: {
    fontSize: '14px',
    color: '#000000',
  },
  outlined: {
    flex: 0.7,
    padding: '10px',
    borderRadius: '3px',
  },
  contained: {
    backgroundColor: '#62B081',
    border: '1px solid',
    color: '#FFFFFF',
    flex: 1.3,
    padding: '10px',
    borderRadius: '3px',
  },
  datePicker: {
    '& fieldset' : {
      height: 56,
      borderRadius: 4,
    }
  },
  comment: {
    zIndex: 0,
    width: '552px',
    margin: '4px 0 8px',
    '& fieldset' : {
      borderRadius: 4,
    }
  }
});

export default compose(
  withStyles(styles),
  withApollo,
  connect(null, { showNotification }),
)(CreateProjectModal);
