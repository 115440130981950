import React from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MENU':
      return {
        ...state,
        target: action.payload,
        isOpen: true,
      };
    case 'CLOSE_MENU':
      return {
        ...state,
        target: null,
        isOpen: false,
      };
    default:
      return state;
  }
};

const MoreVerticalMenu = ({ options, moreVertIconProps, ...menuProps }) => {
  const [{ target, isOpen }, dispatch] = React.useReducer(reducer, {
    target: null,
    isOpen: false,
  });

  const onOpen = ({ currentTarget }) =>
    dispatch({ type: 'OPEN_MENU', payload: currentTarget });
  const close = () => dispatch({ type: 'CLOSE_MENU' });

  return (
    <React.Fragment>
      <IconButton
        aria-label="More"
        aria-owns={isOpen ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={onOpen}
      >
        <MoreVertIcon {...moreVertIconProps} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={target}
        open={isOpen}
        onClose={close}
        {...menuProps}
      >
        {options.map(({ action, label, disabled }) => (
          <MenuItem
            key={label}
            onClick={() => {
              close();
              action();
            }}
            disabled={disabled}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default MoreVerticalMenu;
