export default {
  "admin.page.title": "Admin. Kundernes ledelse",
  "admin.tabs.all": "Alle",
  "admin.tabs.inactive": "Inaktiv",
  "admin.page.add.button": "Tilføj ny kunde",
  "admin.page.back.button": "Kundeliste ",
  "admin.page.edit.button": "rediger",
  "admin.page.table.column.org.number": "Org nummer",
  "admin.page.table.column.name": "Navn",
  "admin.page.table.column.payroll.system": "lønsystem",
  "admin.page.table.column.tenant": "Lejer",
  "admin.page.table.column.employees": "Medarbejdere",
  "admin.page.table.column.status": "Status",
  "admin.page.table.column.empty": "Ikke deffineret ",
  "admin.page.table.customer.status.active": "Aktiv",
  "admin.page.table.customer.status.inactive": "Inaktiv ",
  "admin.page.table.customer.status.incomplete": "ufuldstændig",
  "admin.page.general.step.title": "Generelt ",
  "admin.page.compensation.types.step.title": "Kompensationstyper",
  "admin.page.employment.categories.step.title": "Beskæftigelseskategorier",
  "admin.page.users.import.step.title": "Brugere import",
  "admin.page.dimensions.worktypes.step.title": "Dimensioner og arbejdstyper",
  "admin.page.step.next.button": "Næste",
  "admin.page.step.back.button": "Tilbage",
  "admin.page.step.finish.button": "Færdig",
  "admin.page.step.save.button": "Gem",
  "admin.page.step.general.copy.button": "Kopier indstillinger fra eksisterende kunde",
  "admin.page.step.general.customer.name": "Kundenavn ",
  "admin.page.step.general.org.number": "Org. nummer",
  "admin.page.step.general.payroll.system": "Lønsystem",
  "admin.page.step.general.payroll.tenant": "Lejer",
  "admin.page.step.general.payroll.is.active": "Er aktiv",
  "admin.page.step.general.inactive.date": "Inaktiv dato",
  "admin.page.step.general.period.reports": "Perioder",
  "admin.page.step.general.label.monthly": "Månedlige",
  "admin.page.step.general.label.bi-weekly": "BI-ugentligt ",
  "admin.page.step.general.label.weekly": "Ugentligt",
  "admin.page.step.general.label.start.from": "Start fra",
  "admin.page.step.general.generated.periods": "Genererede perioder ",
  "admin.page.step.general.notifications": "Notifikationer ",
  "admin.page.step.general.add.notification.button": "Tilføj ny notifikation",
  "admin.page.step.general.notification.type.employee": "Medarbejder",
  "admin.page.step.general.notification.type.manager": "Leder",
  "admin.page.step.general.notification.label.type": "Type",
  "admin.page.step.general.label.notification.deadline": "Deadline",
  "admin.page.step.general.label.notification.time": "Tid",
  "admin.page.step.general.label.notification.repeatable": "Gentagelse",
  "admin.page.step.general.label.notification.repeatable.tooltip": "Gentagelse ON angiver, at anmeldelse/notifikationer gentages hver måned på samme dato og tidspunkt. \n\nGentagelse FRA angiver, at anmeldelse/notifikation vil ske\nsendes kun én gang på specificeret dato.",
  "admin.page.step.general.holidays": "helligdage ",
  "admin.page.step.general.add.holiday.button": "Tilføj ny helligdag",
  "admin.page.step.general.label.holidays.day": "Dag",
  "admin.page.step.compensation.types.column.code": "Løn kode",
  "admin.page.step.compensation.types.column.name": "Navn",
  "admin.page.step.compensation.types.column.type": "Type",
  "admin.page.step.compensation.types.column.comment": "Kommentar",
  "admin.page.step.compensation.types.column.reporting.type": "Rapporterings type",
  "admin.page.step.compensation.types.column.intervals": "interval ",
  "admin.page.step.compensation.types.column.periodic": "Periodiske ",
  "admin.page.step.compensation.types.column.in.summary": "Vis opsummeret",
  "admin.page.step.compensation.types.column.global.type": "Global type",
  "admin.page.step.compensation.types.column.description": "Beskrivelse",
  "admin.page.step.compensation.types.add.compensation.type": "Tilføj ny kompensations type",
  "admin.page.step.compensation.types.type.absence": "Fravær",
  "admin.page.step.compensation.types.type.presence": "tilstedeværelse",
  "admin.page.step.compensation.types.comment.required": "Kræver ",
  "admin.page.step.compensation.types.comment.no": "Nej",
  "admin.page.step.compensation.types.comment.optional": "Valgfri",
  "admin.page.step.compensation.types.reporting.type.overlapping": "Overlappende",
  "admin.page.step.compensation.types.reporting.type.full.day": "Fuld dag",
  "admin.page.step.compensation.types.reporting.type.interval": "Interval",
  "admin.page.step.compensation.types.reporting.type.duration": "Normal tid ",
  "admin.page.step.compensation.types.reporting.type.full.month": "Fuld måned",
  "admin.page.step.compensation.types.time.type.vacation": "Ferie",
  "admin.page.step.compensation.types.time.type.overtime": "Overtid",
  "admin.page.step.compensation.types.time.type.off.duty": "Har fri",
  "admin.page.step.compensation.types.time.type.more.time": "Mere tid",
  "admin.page.step.compensation.types.time.type.sickleave": "Sygeorlov",
  "admin.page.step.compensation.types.time.type.workhours": "arbejdstider ",
  "admin.page.step.compensation.types.time.type.no.deviation": "Ingen afvigelser",
  "admin.page.step.compensation.types.time.type.care.of.child": "Pleje af barn ",
  "admin.page.step.compensation.types.time.type.parental.leave": "Forældreorlov",
  "admin.page.step.compensation.types.delete.button": "Slet",
  "admin.page.step.employment.categories.add.employment.category": "Tilføj ny ansættelses kategori ",
  "admin.page.step.employment.categories.column.category": "Kategori ",
  "admin.page.step.employment.categories.column.project.reporting": "Projekt Rapportering",
  "admin.page.step.employment.categories.column.norm.time": "Normal  tid",
  "admin.page.step.employment.categories.column.exclude.weekends": "Ekskluderet weekender ",
  "admin.page.step.employment.categories.column.reporting.method": "Rapporterings metode",
  "admin.page.step.employment.categories.column.compensation.types": "Kompensations typer",
  "admin.page.step.employment.categories.delete.button": "Slet",
  "admin.error.remove.compensation.type": "Kan ikke fjerne kompensationstype, der allerede er i brug",
  "admin.error.remove.used.employment.category": "Kan ikke fjerne beskæftigelseskategori, som allerede er i brug",
  "admin.success.notification.saved": "Gemt",
  "admin.error.notification.ops.something.went.wrong": "Obs, noget er galt",
  "admin.stepper.page.customer.name": "Kundenavn",
  "admin.page.stepper.customer.org.number": "Org nummer",
  "admin.page.stepper.custom.error.duplicated.notifications": "Obs: Du har du dublerede meddelelser. Sørg for, at de er unikke.",
  "admin.page.stepper.custom.error.duplicated.holidays": "Ups, du har ferie på samme dato. Sørg for, at de er på unikke datoer.",
  "admin.page.step.general.label.notification.when": "Hvornår",
  "admin.page.step.general.notification.when.before.deadline": "Før deadline",
  "admin.page.step.general.notification.when.after.deadline": "Efter deadline",
  "admin.page.step.general.notification.when.on.deadline": "På deadline",
  "admin.page.step.general.label.notification.period": "Periode",
  "admin.page.step.general.notification.period.day": "dag",
  "admin.page.step.general.notification.period.days": "dage",
  "admin.page.step.employment.categories.column.work.day": "Arbejdsdag",
  "admin.success.notification.save": "Gem"
}
