import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles/index.css';

export class Loading extends Component {
  render() {
    let { root, loadingCircle, loadingMessage } = this.props.adjustClasses;
    return (
      <div className={`loading-container ${root || ''}`}>
        <div className={`loading ${loadingCircle || ''}`} />
        <div className={`loading-text ${loadingMessage || ''}`}>loading</div>
      </div>
    );
  }
}

Loading.propTypes = {
  /** This is a high-level object */
  adjustClasses: PropTypes.shape({
    /** Child of adjustClasses object */
    root: PropTypes.string,
    /** Child of adjustClasses object */
    loadingCircle: PropTypes.string,
    /** Child of adjustClasses object */
    loadingMessage: PropTypes.string,
  }),
};

Loading.defaultProps = { adjustClasses: {} };

export default Loading;
