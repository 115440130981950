import React,  { useState } from 'react';
import { useIntl } from 'react-intl';
import { compose, isEmpty } from 'ramda';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import Loader from '../../../../../../../components/Loader';
import { ProjectsTable } from '../../../ProjectsTable';
import pab from '../../../../../../../config/intlMessageSelectors/payroll-admin-base';
import pac from '../../../../../../../config/intlMessageSelectors/payroll-admin-customers';
import { graphql } from 'react-apollo';
import { CREATE_WIZARD_PROJECTS } from '../../../../../../../queries/customers';
import { getErrorObjs, getErrorMessage } from '../../../../../../../utils/error';
import { prepareProjectData } from '../../../ProjectsEditableTable/ProjectsEditableTable';

const styles = {
  contentWrapper: {
    marginTop: 1
  },
  noContentWrapper: {
    minHeight: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};


// TODO
const isValidData = (data = {}) => Object.values(data).reduce((acc, item) => {
  if(item) {
    acc++;
  }
  return acc;
}, 0);

const ProjectDialog = ({
  isOpen,
  onClose,
  data,
  onError,
  onChange,
  createWizardProjects,
  customerId,
}) => {
  const { formatMessage: f } = useIntl();
  const [invalidRows, setInvalidRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const filteredData = data.filter(item => !isEmpty(item) && isValidData(item));

      await createWizardProjects({
        variables: {
          input: [
            ...filteredData.map(item => {
              return {
                ...prepareProjectData(item),
                customerId,
              }
            })
          ]
        }
      });
      onClose();
      onChange(filteredData);
    } catch(e) {
      const errorObj = getErrorObjs(e)[0];

      if(errorObj && errorObj.messageCode) {
        onError(getErrorMessage(errorObj, f));
        if(errorObj.payload) {
          setInvalidRows([errorObj.payload.index]);
        }
      } else {
        onError();
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>
          {f(pac['admin.page.projects.step.title'])}
        </DialogTitle>
        <DialogContent>
          {
            !!data.length ? (
              <div style={styles.contentWrapper}>
                <ProjectsTable
                  data={data}
                  invalidRows={invalidRows}
                />
              </div>
            ) : (
              <div style={styles.noContentWrapper}>
                {f(pac['admin.page.users.import.noData'])}
              </div>
            )
          }

          <DialogActions>
            <Button variant="outlined" onClick={onClose} disabled={loading}>
              {f(pab['app.base.btns.cancel'])}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={!data.length || !!invalidRows.length || loading}
              >
              {f(pab['app.base.btns.confirm'])}
            </Button>
          </DialogActions>
        </DialogContent>
        <Loader open={loading} />
      </Dialog>
    </>
  );
}

export default compose(
  graphql(CREATE_WIZARD_PROJECTS, { name: 'createWizardProjects' }),
)(ProjectDialog);
