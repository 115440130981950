export default {
  "app.employees": "Anställda",
  "app.departments": "Avdelningar",
  "app.projects": "Projekt",
  "app.efficiency.timeReport": "Tidrapport",
  "app.efficiency.admin": "Admin",
  "app.base.importInfo": "Importera från fil",
  "app.base.month.january": "Januari",
  "app.base.month.february": "Februari",
  "app.base.month.march": "Mars",
  "app.base.month.april": "April",
  "app.base.month.may": "Maj",
  "app.base.month.june": "Juni",
  "app.base.month.july": "Juli",
  "app.base.month.august": "Augusti",
  "app.base.month.september": "September",
  "app.base.month.october": "Oktober",
  "app.base.month.november": "November",
  "app.base.month.december": "December",
  "app.base.btns.confirm": "Bekräfta",
  "app.base.btns.cancel": "Ångra",
  "app.base.btns.ok": "OK",
  "app.base.btns.remove": "Ta bort",
  "app.base.select.placeholder": "Välj..",
  "app.base.tablePaginationRowsPerPage": "Rader per sida",
  "app.base.tablePaginationDisplayedRows": "av",
  "app.base.tableNoData": "Ingen information",
  "app.base.tenantSelect": "VÄLJ FÖRETAG",
  "app.base.customerSelect": "VÄLJ KUND",
  "app.base.logout": "LOGGA UT",
  "app.base.button.apply": "Välj",
  "app.base.button.add": "Lägg till",
  "app.base.button.cancel": "Avbryt",
  "app.base.button.import": "Importera",
  "app.base.button.choseFile": "Välj fil",
  "app.base.button.mark.all": "Välj alla",
  "app.base.message.noModuleConfig": "Du har inga moduler aktiverade. Vänligen kontakta din chef eller ECIT för att korrigera detta.",
  "app.base.message.noProjectReporting": "Projektrapportering är inte aktiverat, vänligen kontakta din chef eller kontakt hos ECIT.",
  "app.base.message.noPeriods": "Inga perioder tillgängliga. Vänligen kontakta ECIT.",
  "app.base.label.search": "Sök",
  "app.base.message.noEmployees": "Du saknar tillgång till anställda. Vänligen kontakta din administratör.",
  "admin.page.modules.config.timeReport": "Tidrapportering",
  "admin.page.modules.config.projectReporting": "Projektrapportering",
  "admin.page.modules.config.payslips": "Lönespecifikationer",
  "admin.page.title": "Kundadministration",
  "admin.page.add.button": "Lägg till kund",
  "admin.page.back.button": "Lista Kunder",
  "admin.page.edit.button": "Ändra",
  "admin.page.table.column.org.number": "Org nummer",
  "admin.page.table.column.name": "Namn",
  "admin.page.table.column.payroll.system": "Lönesystem",
  "admin.page.table.column.tenant": "Tenant",
  "admin.page.table.column.employees": "Anställda",
  "admin.page.table.column.status": "Status",
  "admin.page.table.column.empty": "Odefinierad",
  "admin.page.table.customer.status.active": "Aktiv",
  "admin.page.table.customer.status.inactive": "Inaktiv",
  "admin.page.table.customer.status.incomplete": "Ofullständig",
  "admin.page.general.step.title": "Allmän",
  "admin.page.compensation.types.step.title": "Kompensationstyper",
  "admin.page.employment.categories.step.title": "Anställningskategorier",
  "admin.page.users.import.success": "Import klar!",
  "admin.page.users.import.reminder": "Glöm inte att ange vilka avdelningar som chefer och attestorer skall godkänna. Detta görs på listan av anställda.",
  "admin.page.users.import.step.title": "Användarimport",
  "admin.page.projects.step.title": "Projekt",
  "admin.page.users.import.step.tooltip": "När man importerar användare med rollen \"Approver\", vänligen lämna fälten för Anställningsnummer, anställningskategori samt avdelning blankt.",
  "admin.page.users.import.step.example.tooltip": "Ladda ner exempelfil",
  "admin.page.users.import.noData": "Inget att importera. Vänligen populera filen med data och säkerställ att namnen på kolumnerna i filen överensstämmer med exempelfilen.",
  "admin.page.dimensions.worktypes.step.title": "Dimensioner och arbetstyper",
  "admin.page.step.next.button": "Nästa",
  "admin.page.step.back.button": "Tillbaka",
  "admin.page.step.finish.button": "Slutför",
  "admin.page.step.save.button": "Spara",
  "admin.page.step.general.copy.button": "Kopiera inställningar från befintlig kund",
  "admin.page.step.general.copy.customer": "Kopiera inställningar",
  "admin.page.step.general.copy.placeholder": "Sök på kundnamn eller organisationsnummer",
  "admin.page.step.general.copy.confirmation": "Bekräfta kopiering av inställningar från",
  "admin.page.step.general.customer.name": "Kundnamn",
  "admin.page.step.general.org.number": "Org nummer",
  "admin.page.step.general.payroll.system": "Lönesystem",
  "admin.page.step.general.payroll.tenant": "Tenant",
  "admin.page.step.general.payroll.is.active": "Aktiv",
  "admin.page.step.general.payroll.is.billable": "Faktureras?",
  "admin.page.step.general.inactive.date": "Inaktiveringsdatum",
  "admin.page.step.general.country": "Land",
  "admin.page.step.general.period.reports": "Perioder",
  "admin.page.step.general.label.monthly": "Månadsvis",
  "admin.page.step.general.label.bi-weekly": "Varannan vecka",
  "admin.page.step.general.label.weekly": "Veckovis",
  "admin.page.step.general.label.start.from": "Börja från",
  "admin.page.step.general.generated.periods": "Genererade perioder",
  "admin.page.step.general.notifications": "Påminnelser",
  "admin.page.step.general.add.notification.button": "Ny påminnelse",
  "admin.page.step.general.notification.type.employee": "Anställd",
  "admin.page.step.general.notification.type.manager": "Attestor",
  "admin.page.step.general.notification.label.type": "Typ",
  "admin.page.step.general.label.notification.deadline": "Deadline",
  "admin.page.step.general.label.notification.time": "Tid",
  "admin.page.step.general.label.notification.repeatable": "Repeterbar",
  "admin.page.step.general.label.notification.repeatable.tooltip": "Repeterbar PÅ innebär att notifieringar kommer att upprepas varje månad vid samma datum och tid. Repeterbar AV innebär att notifieringar endast skickas ut vid specifierat datum.",
  "admin.page.step.general.holidays": "Helgdagar",
  "admin.page.step.general.add.holiday.button": "Lägg till helgdag",
  "admin.page.step.general.label.holidays.day": "Dag",
  "admin.page.step.general.reset.button": "Återställ",
  "admin.page.step.general.holiday.full.day": "Heldag",
  "admin.page.step.general.holiday.half.day": "Halvdag",
  "admin.page.step.general.holiday.menu.edit": "Ändra",
  "admin.page.step.general.holiday.menu.remove": "Ta bort",
  "admin.page.step.general.popup.change.country.title": "Byt land",
  "admin.page.step.general.popup.change.country.text": "Om du byter land, påverkar det helgdagarna. Vill du behålla befintliga helgdagar?",
  "admin.page.step.general.popup.change.country.reset.button": "Ändra",
  "admin.page.step.general.popup.change.country.keep.button": "Behåll helgdagar",
  "admin.page.step.general.popup.reset.holidays.title": "Återställ helgdagar",
  "admin.page.step.general.popup.reset.holidays.text": "Är du säker på att du önskar att återställa helgdagarna?",
  "admin.page.step.general.popup.reset.holidays.reset.button": "Återställ",
  "admin.page.step.general.popup.reset.holidays.cancel.button": "Avbryt",
  "admin.page.step.general.popup.edit.holiday.title": "Helgdag",
  "admin.page.step.general.popup.edit.holiday.field.name": "Namn",
  "admin.page.step.general.popup.edit.holiday.field.date": "Datum",
  "admin.page.step.general.popup.edit.holiday.field.full.day": "Heldag",
  "admin.page.step.general.popup.edit.holiday.field.half.day": "Halvdag",
  "admin.page.step.general.popup.edit.holiday.cancel.button": "Ångra",
  "admin.page.step.general.popup.edit.holiday.save.button": "Spara",
  "admin.page.step.compensation.types.column.code": "Löneart",
  "admin.page.step.compensation.types.column.name": "Namn",
  "admin.page.step.compensation.types.column.type": "Typ",
  "admin.page.step.compensation.types.column.comment": "Kommentar",
  "admin.page.step.compensation.types.column.reporting.type": "Rapporteringstyp",
  "admin.page.step.compensation.types.column.intervals": "Intervall",
  "admin.page.step.compensation.types.column.periodic": "Flerdagars",
  "admin.page.step.compensation.types.column.in.summary": "Visa summerat",
  "admin.page.step.compensation.types.column.global.type": "Global typ",
  "admin.page.step.compensation.types.column.description": "Beskrivning",
  "admin.page.step.compensation.types.add.compensation.type": "Lägg till kompensationstyp",
  "admin.page.step.compensation.types.type.absence": "Frånvaro",
  "admin.page.step.compensation.types.type.presence": "Närvaro",
  "admin.page.step.compensation.types.comment.required": "Obligatorisk",
  "admin.page.step.compensation.types.comment.no": "Ingen kommentar",
  "admin.page.step.compensation.types.comment.optional": "Valfri",
  "admin.page.step.compensation.types.reporting.type.overlapping": "Överlappande",
  "admin.page.step.compensation.types.reporting.type.full.day": "Heldag",
  "admin.page.step.compensation.types.reporting.type.interval": "Intervall",
  "admin.page.step.compensation.types.reporting.type.duration": "Normtid",
  "admin.page.step.compensation.types.reporting.type.full.month": "Hel månad",
  "admin.page.step.compensation.types.time.type.vacation": "Semester",
  "admin.page.step.compensation.types.time.type.overtime": "Övertid",
  "admin.page.step.compensation.types.time.type.off.duty": "Inte i tjänst",
  "admin.page.step.compensation.types.time.type.more.time": "Mertid",
  "admin.page.step.compensation.types.time.type.sickleave": "Sjukfrånvaro",
  "admin.page.step.compensation.types.time.type.workhours": "Arbetstimmar",
  "admin.page.step.compensation.types.time.type.no.deviation": "Inga avvikelser",
  "admin.page.step.compensation.types.time.type.care.of.child": "Vård av barn",
  "admin.page.step.compensation.types.time.type.parental.leave": "Föräldraledigt",
  "admin.page.step.compensation.types.delete.button": "Ta bort",
  "admin.page.step.employment.categories.add.employment.category": "Lägg till anställningskategori",
  "admin.page.step.employment.categories.column.category": "Kategori",
  "admin.page.step.employment.categories.column.modules.config": "Moduler",
  "admin.page.step.employment.categories.column.norm.time": "Normtid",
  "admin.page.step.employment.categories.column.exclude.weekends": "Exludera helger",
  "admin.page.step.employment.categories.column.reporting.method": "Rapporteringsmetod",
  "admin.page.step.employment.categories.column.compensation.types": "Kompensationstyper",
  "admin.page.step.employment.categories.delete.button": "Ta bort",
  "admin.page.step.employment.users.employmentNumber": "Anställningsnummer",
  "admin.page.step.employment.users.column.employmentCategoryName": "Anställningskategori",
  "admin.page.step.employment.users.column.firstName": "Förnamn",
  "admin.page.step.employment.users.column.lastName": "Efternamn",
  "admin.page.step.employment.users.column.email": "Epost",
  "admin.page.step.employment.users.column.role": "Roll",
  "admin.page.step.employment.users.column.status": "Status",
  "admin.page.step.employment.users.column.departmentName": "Avdelning",
  "admin.page.step.employment.users.column.employeeStart": "Startdatum",
  "admin.page.step.employment.projects.column.projectNumber": "Projektnummer",
  "admin.page.step.employment.projects.column.projectName": "Projekt",
  "admin.page.step.employment.projects.column.billable": "Fakturerbar",
  "admin.page.step.employment.projects.column.enabled": "Aktiv",
  "admin.page.step.employment.projects.column.activities": "Aktiviteter",
  "admin.page.step.employment.projects.add.project": "Lägg till projekt",
  "admin.page.step.employment.projects.add.activity": "Lägg till aktivitet",
  "admin.error.remove.compensation.type": "Kompensationstyp används och kan ej tas bort",
  "admin.error.update.duplicated.project.number": "Projektnumret är redan upptaget. Vänligen välj ett unikt nummer.",
  "admin.error.remove.used.employment.category": "Anställningskategori används och kan ej tas bort",
  "admin.error.remove.used.project": "Projekt som det har rapporterats på kan inte tas bort.",
  "admin.error.add.project.validation": "Vänligen fyll i alla obligatoriska fält",
  "admin.error.edit.project.number.validation": "Projektnummer kan endast innehålla siffror",
  "admin.error.edit.project.number.same.validation": "Projektnumret är taget",
  "admin.error.remove.activity": "Aktiviteter som det har rapporterats på kan inte tas bort. ",
  "admin.success.notification.saved": "Sparad",
  "admin.error.notification.ops.something.went.wrong": "Något gick fel",
  "admin.stepper.page.customer.name": "Kundnamn",
  "admin.page.stepper.customer.org.number": "Organisationsnummer",
  "admin.page.stepper.custom.error.duplicated.notifications": "Oj, det ser ut som att du har dubbla notifieringar",
  "admin.page.stepper.custom.error.duplicated.holidays": "Oj, det ser ut som att det finns dubblett av helgdag",
  "admin.page.stepper.custom.error.invalid.org.number": "Felaktigt format på organisationsnummer. Endats nummer och bindestreck är tillåtna.",
  "admin.error.org.number.not.found.in.nettlonn": "Ingen träff på organisationsnummer i Nettlønn",
  "admin.page.stepper.custom.error.duplicated.org.number.within.country": "Orgnumret är redan aktivt på en annan kund",
  "app.departments.createDepartment": "Skapa avdelning",
  "app.departments.department": "Avdelning",
  "app.departments.approvers": "Attestorer",
  "app.departments.approver": "Attestor",
  "app.departments.noApproverAssigned": "Ingen attestor tilldelad",
  "app.departments.employees": "Anställda",
  "app.departments.firstName": "Förnamn",
  "app.departments.lastName": "Efternamn",
  "app.departments.phone": "Telefon",
  "app.departments.role": "Roll",
  "app.departments.email": "E-post",
  "app.departments.createUserInsideDepartment": "Skapa anställd i avdelningen",
  "app.departments.addExistingUserDepartment": "Lägg till anställd i avdelningen",
  "app.departments.removeUserFromDepartment": "Ta bort användare från avdelning",
  "app.departments.removeDepartment": "Är du säker på att du vill ta bort avdelningen? Anställda i denna avdeling kan inte tidrapportera förräns de har flyttas i en annan avdelning.",
  "app.departments.removeDepartmentTitle": "Ta bort avdelning",
  "app.departments.remove.user": "Ta bort",
  "app.departments.edit.user": "Ändra",
  "app.employees.userModalCreate": "Skapa anställd",
  "app.employees.userModalEdit": "Ändra anställningsuppgifter",
  "app.employees.userModalConfirm": "Bekräfta",
  "app.employees.userModalCancel": "Ångra",
  "app.employees.timeReportingMethod": "Metod för tidrapportering",
  "app.employees.userModalGeneral": "Generellt",
  "app.employees.employmentDetails": "Anställningsuppgifter",
  "app.employees.employmentCategory": "Anställningskategori",
  "app.employees.employmentNumber": "Anställningsnummer",
  "app.employees.firstName": "Förnamn",
  "app.employees.lastName": "Efternamn",
  "app.employees.phone": "Telefonnummer",
  "app.employees.department": "Avdelning",
  "app.employees.role": "Roll",
  "app.employees.createUserButton": "Skapa anställd",
  "app.employees.email": "E-post",
  "app.employees.personalId": "Personnummer",
  "app.employees.bankAccount": "Kontonummer",
  "app.employees.contactPerson": "Kontaktperson",
  "app.employees.contactPersonPhone": "Telefon kontaktperson",
  "app.employees.authLogin": "Auth Login",
  "app.employees.smsLogin": "Sms login",
  "app.employees.address": "Adress",
  "app.employees.postalCity": "Postort",
  "app.employees.postalNumber": "Postnummer",
  "app.employees.userTimezone": "Tidszon",
  "app.employees.userNationality": "Nationalitet",
  "app.employees.userLanguage": "Språk",
  "app.employees.admin": "Admin",
  "app.employees.manager": "Chef",
  "app.employees.approver": "Attestor",
  "app.employees.employee": "Anställd",
  "app.employees.superadmin": "Super admin",
  "app.employees.employmentType": "Anställningstyp",
  "app.employees.fullTime": "Heltid",
  "app.employees.partTime": "Deltid",
  "app.employees.yearSalary": "Årslön",
  "app.employees.employeeStartDate": "Anställningens startdatum",
  "app.employees.suspended": "Avslutad",
  "app.employees.all": "alla",
  "app.employees.noDepartmentAssigned": "avdelning saknas",
  "app.employees.employeeEndDate": "Anställningens slutdatum",
  "app.employees.country": "Land",
  "app.employees.city": "Ort",
  "app.employees.home.address": "Hemaddress",
  "app.employees.removeEmployeeTitle": "Ta bort anställd",
  "app.employees.removeEmployeeConfirmationText": "Är du säker på att du vill ta bort denna anställd?",
  "app.employees.cannotRemoveHasTransaction": "Kan inte ta bort anställda med registrerade transaktioner. Vänligen ange ett slutdatum på den anställde istället för att avsluta den anställde.",
  "app.employees.errorEmploymentNumber": "Anställningsnumret är redan taget, vänligen välj ett annat",
  "app.employees.emailAddressInUse": "E-postadressen används redan",
  "app.employees.create.employee": "Skapa anställd",
  "app.employees.edit.employee": "Ändra anställd",
  "app.employees.approve.own.timereport": "Attestera sin egna tidrapport",
  "app.employees.access.rights": "Tillgångsrättigheter",
  "app.employees.button.add.new.department.to.approve": "Lägg till avdelning för attestering",
  "app.employees.button.remove.department.to.approve": "Ta bort avdelning från attestering",
  "app.employees.tenant": "Tenant",
  "app.employees.customer": "Kund",
  "app.employees.departments": "Avdelningar/attestgrupper",
  "app.employees.subtitle.location": "Plats",
  "app.employees.button.save": "Spara",
  "app.employees.notitication.success.save": "Sparad",
  "app.employees.error.no.deviation": "Kan ej registrera Inga avvikelser, transaktioner för denna period är redan rapporterade.",
  "app.employees.error.national.holiday": "Frånvaro kan inte rapporteras på röda dagar",
  "app.employees.dialog.future.reports.title": "Tidrapportering i framtiden",
  "app.employees.dialog.future.reports.description": "har rapporterat tider efter det angivna slutdatumet. Vänligen kontrollera om de skall tas bort eller bevaras. Användaren kommer inte att kunna tidrapportera efter slutdatumet.",
  "app.employees.dialog.future.reports.date": "Rapporterade datum",
  "app.notification.success.create": "Skapad",
  "app.notification.success.delete": "Borttagen",
  "app.notification.success.update": "Uppdaterad",
  "app.notification.success.default": "Genomfört",
  "app.notification.error.default": "Något gick fel",
  "app.notification.error.notNull": "Kan inte vara tomt",
  "app.notification.error.invalid": "Är ogiltig",
  "app.notification.error.field": "Fält",
  "app.notification.period.reminded": "Påminnelse skickat",
  "app.notification.periods.reminded": "Alla perioder påmint",
  "app.timeReport.compensationType": "Typ",
  "app.timeReport.dateRange": "Datumintervall",
  "app.timeReport.amount": "Antal",
  "app.timeReport.transactionStatus": "Status",
  "app.timeReport.transactionStatusRejectBtn": "Neka",
  "app.timeReport.period": "Period",
  "app.timeReport.closed": "Stängd",
  "app.timeReport.open": "Öppen",
  "app.timeReport.closePeriodBtn": "Klarmarkera period",
  "app.timeReport.periodIsClosedBtn": "Perioden är redan stängd",
  "app.timeReport.periodIsClosedStatus": "Stängd period",
  "app.timeReport.transactionStatusApproveBtn": "Godkänn",
  "app.timeReport.periodTakeBackBtn": "Öppna",
  "app.timeReport.transactionStatusApproved": "GODKÄND",
  "app.timeReport.transactionStatusExported": "EXPORTERAD",
  "app.timeReport.transactionStatusRejected": "NEKAD",
  "app.timeReport.transactionStatusPendingApproval": "Inväntar godkännande",
  "app.timeReport.transactionStatusArchived": "Arkiverat",
  "app.timeReport.transactionStatusSubmitted": "Skickat",
  "app.timeReport.createDeviation": "Skapa avvikelse",
  "app.timeReport.createPresence": "Närvaro",
  "app.timeReport.comment": "Kommentar",
  "app.timeReport.showLess": "Visa mindre",
  "app.timeReport.showMore": "Visa mer",
  "app.timeReport.rejectMessage": "Anledning",
  "app.timeReport.deviationsTable": "AVVIKELSER",
  "app.timeReport.details": "DETALJER",
  "app.timeReport.singleDayToggle": "En dag",
  "app.timeReport.periodToggle": "Period",
  "app.timeReport.copyDatesBelow": "Kopiera till alla dagar",
  "app.timeReport.formDuration": "Varaktighet",
  "app.timeReport.formStart": "Start",
  "app.timeReport.formEnd": "Slut",
  "app.timeReport.formDate": "Datum",
  "app.timeReport.user": "Användare",
  "app.timeReport.employees": "Anställda",
  "app.timeReport.status": "Status",
  "app.timeReport.absence": "Frånvaro",
  "app.timeReport.presence": "Närvaro",
  "app.timeReport.employee": "Anställd",
  "app.timeReport.employeeNumber": "Anställnings nr",
  "app.timeReport.allDepartments": "Alla avdelningar",
  "app.timeReport.department": "Avdelning",
  "app.timeReport.all": "Alla",
  "app.timeReport.notReported": "Ej rapporterade",
  "app.timeReport.waitingForApproval": "Väntar på godkännande",
  "app.timeReport.approved": "Godkänt",
  "app.timeReport.exported": "Exporterat",
  "app.timeReport.timeReportsTitle": "Tidrapporter",
  "app.timeReport.description": "Beskrivning",
  "app.timeReport.project": "Projekt",
  "app.timeReport.projectCompensationType": "Tidstyp",
  "app.timeReport.dimensionNumber": "Projekt #",
  "app.timeReport.workType": "Aktivitet",
  "app.timeReport.projectHours": "Projektrapportering",
  "app.timeReport.active": "Aktiv",
  "app.timeReport.archived": "Arkiverat",
  "app.timeReport.reportingMoreThan24H": "Det är endast möjligt att registrera 24 timmar på ett dygn.",
  "app.timeReport.overlappedAbsence": "Antalet frånvarotimmar kan maximalt vara lika mycket timmarna på en arbetsdag.",
  "app.timeReport.reportingOnWeekendIsNotAllowed": "Det är inte möjligt att rapportera frånvaro på dessa dagar. Vänligen välj en annan dag.",
  "app.time.report.transactions.reports.subtitle": "Tidrapporter - Excel",
  "app.time.report.transactions.report.by.deviation.menu.item": "Avvikelser",
  "app.time.report.transactions.report.by.project.menu.item": "Projekt",
  "app.time.report.transactions.error.no.transactions": "Inga transaktioner i denna period",
  "app.time.report.transactions.error.no.departments": "Inga avdelningar finns på denna kund",
  "app.time.report.transactions.removeTransactionModal.title": "Är du säker på att du vill ta bort denna?",
  "app.time.report.transactions.removeTransactionModal.body": "Rapporterade transaktioner kommer att tas bort. Tidigare exporterade transaktioner bevaras.",
  "app.timeReport.nextEmployeeBtn": "NÄSTA",
  "app.timeReport.prevEmployeeBtn": "FÖREGÅENDE",
  "app.timeReport.periodStatusOpen": "ÖPPEN",
  "app.timeReport.periodStatusApproved": "GODKÄND",
  "app.timeReport.periodStatusRejected": "NEKAD",
  "app.timeReport.periodStatusSubmitted": "SKICKAD",
  "app.timeReport.reviewedBy": "Granskats av",
  "app.timeReport.approvedBy": "Godkänt av",
  "app.timeReport.rejectedBy": "Nekat av",
  "app.timeReport.periodTypeDeviation": "Avvikelser",
  "app.timeReport.periodTypeDimension": "Registrerad närvaro",
  "app.timeReport.expectedHours": "Beräknade timmar",
  "app.timeReport.periodRemindAllBtn": "Påminn alla",
  "app.timeReport.deviationOnWeekend": "Avvikelsen överlappar en helg",
  "app.timeReport.deviationOnHoliday": "Avvikelsen överlappar med en helgdag",
  "app.timeReport.departmentNotFound": "Det går inte att rapportera avvikelser utan tillhörighet till avdelning.",
  "app.timeReport.deviationOverlapsWithExisting": "Frånvaron överskrider den tillåtna mängden av frånvaro på en dag.",
  "admin.page.step.general.label.notification.when": "När",
  "admin.page.step.general.notification.when.before.deadline": "Före deadline",
  "admin.page.step.general.notification.when.after.deadline": "Efter deadline",
  "admin.page.step.general.notification.when.on.deadline": "På deadline",
  "admin.page.step.general.label.notification.period": "Period",
  "admin.page.step.general.notification.period.day": "dag",
  "admin.page.step.general.notification.period.days": "dagar",
  "admin.page.step.employment.categories.column.project.reporting": "Projektrapportering",
  "admin.page.step.employment.categories.column.work.day": "Arbetsdag",
  "admin.page.step.projects.add.project": "Nytt projekt",
  "app.time.report.transations.report.subtitle": "Rapport Transaktioner per Avdelning/Attesgrupp",
  "app.time.report.transation.report.download.button": "Ladda ner rapport",
  "app.time.report.transations.error.no.transactions": "Inga transaktioner hittade för denna period",
  "app.time.report.transations.error.no.departments": "Inga avdelningar/attestgrupper hittades för denna kund",
  "app.time.report.transations.removeTransactionModal.title": "Är du säker på att ta bort denna avvikelse?",
  "app.time.report.transations.removeTransactionModal.body": "Rapporterade tider kommer att tas bort. Eventuella exporterade delar av registreringen kommer att bevaras."
}
