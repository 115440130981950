export default {
  "app.employees.userModalCreate": "Skapa anställd",
  "app.employees.userModalEdit": "Ändra anställningsuppgifter",
  "app.employees.userModalConfirm": "Bekräfta",
  "app.employees.userModalCancel": "Ångra",
  "app.employees.timeReportingMethod": "Metod för tidrapportering",
  "app.employees.userModalGeneral": "Generellt",
  "app.employees.employmentDetails": "Anställningsuppgifter",
  "app.employees.employmentCategory": "Anställningskategori",
  "app.employees.employmentNumber": "Anställningsnummer",
  "app.employees.firstName": "Förnamn",
  "app.employees.lastName": "Efternamn",
  "app.employees.phone": "Telefonnummer",
  "app.employees.department": "Avdelning",
  "app.employees.role": "Roll",
  "app.employees.createUserButton": "Skapa anställd",
  "app.employees.email": "E-post",
  "app.employees.personalId": "Personnummer",
  "app.employees.bankAccount": "Kontonummer",
  "app.employees.contactPerson": "Kontaktperson",
  "app.employees.contactPersonPhone": "Telefon kontaktperson",
  "app.employees.authLogin": "Auth Login",
  "app.employees.smsLogin": "Sms login",
  "app.employees.address": "Adress",
  "app.employees.postalCity": "Postort",
  "app.employees.postalNumber": "Postnummer",
  "app.employees.userTimezone": "Tidszon",
  "app.employees.userNationality": "Nationalitet",
  "app.employees.userLanguage": "Språk",
  "app.employees.admin": "Admin",
  "app.employees.manager": "Chef",
  "app.employees.approver": "Attestor",
  "app.employees.employee": "Anställd",
  "app.employees.superadmin": "Super admin",
  "app.employees.employmentType": "Anställningstyp",
  "app.employees.fullTime": "Heltid",
  "app.employees.partTime": "Deltid",
  "app.employees.yearSalary": "Årslön",
  "app.employees.employeeStartDate": "Anställningens startdatum",
  "app.employees.suspended": "Avslutad",
  "app.employees.all": "alla",
  "app.employees.noDepartmentAssigned": "avdelning saknas",
  "app.employees.employeeEndDate": "Anställningens slutdatum",
  "app.employees.country": "Land",
  "app.employees.city": "Ort",
  "app.employees.home.address": "Hemaddress",
  "app.employees.removeEmployeeTitle": "Ta bort anställd",
  "app.employees.removeEmployeeConfirmationText": "Är du säker på att du vill ta bort denna anställd?",
  "app.employees.cannotRemoveHasTransaction": "Kan inte ta bort anställda med registrerade transaktioner. Vänligen ange ett slutdatum på den anställde istället för att avsluta den anställde.",
  "app.employees.errorEmploymentNumber": "Anställningsnumret är redan taget, vänligen välj ett annat",
  "app.employees.emailAddressInUse": "E-postadressen används redan",
  "app.employees.create.employee": "Skapa anställd",
  "app.employees.edit.employee": "Ändra anställd",
  "app.employees.approve.own.timereport": "Attestera sin egna tidrapport",
  "app.employees.access.rights": "Tillgångsrättigheter",
  "app.employees.button.add.new.department.to.approve": "Lägg till avdelning för attestering",
  "app.employees.button.remove.department.to.approve": "Ta bort avdelning från attestering",
  "app.employees.tenant": "Tenant",
  "app.employees.customer": "Kund",
  "app.employees.departments": "Avdelningar/attestgrupper",
  "app.employees.subtitle.location": "Plats",
  "app.employees.button.save": "Spara",
  "app.employees.notitication.success.save": "Sparad",
  "app.employees.error.no.deviation": "Kan ej registrera Inga avvikelser, transaktioner för denna period är redan rapporterade.",
  "app.employees.error.national.holiday": "Frånvaro kan inte rapporteras på röda dagar",
  "app.employees.tableHeader.firstName": "Förnamn",
  "app.employees.suspend": "Stäng av"
}
