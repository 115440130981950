import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core'
import {
  InsertDriveFile as InsertDriveFileIcon,
  Description as DescriptionIcon,
  LibraryBooks as LibraryBooksIcon,
} from '@material-ui/icons';
import { useApolloClient } from 'react-apollo';
import ptr from '../../../../config/intlMessageSelectors/payroll-admin-timeReport';
import { showNotification } from '../../../../actions/notifications';
import { downloadExcel } from '../../../../utils/download';
import { NOTIFICATION } from '../../../../components/NotificationSnackbar';
import { getErrorEntityCode, getErrorMessageCode } from '../../../../utils/error';
import {
  GET_TRANSACTIONS_REPORT_BY_PROJECT_AS_BASE64,
  GET_TRANSACTIONS_REPORT_BY_DEVIATION_AS_BASE64,
  GET_TRANSACTIONS_REPORT_DETAILED_AS_BASE64
} from '../../../../queries/transactions';
import { map, omit } from "ramda";
import { DEFAULT_DATE_FORMAT } from "../../../../utils/times";

const reducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_START_DATE_CHANGE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'REQUEST_END_DATE_CHANGE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'FETCH_COMPENSATION_FILE_REQUEST':
      return {
        ...state,
        isCompensationReportLoading: true,
      };
    case 'FETCH_COMPENSATION_FILE_SUCCESS':
      return {
        ...state,
        isCompensationReportLoading: false,
      };
    case 'FETCH_COMPENSATION_FILE_FAILURE':
      return {
        ...state,
        isCompensationReportLoading: false,
      };
    case 'FETCH_PROJECT_FILE_REQUEST':
      return {
        ...state,
        isProjectReportLoading: true,
      };
    case 'FETCH_PROJECT_FILE_SUCCESS':
      return {
        ...state,
        isProjectReportLoading: false,
      };
    case 'FETCH_PROJECT_FILE_FAILURE':
      return {
        ...state,
        isProjectReportLoading: false,
      };
    case 'FETCH_DETAILED_FILE_REQUEST':
      return {
        ...state,
        isDetailedReportLoading: true,
      };
    case 'FETCH_DETAILED_FILE_SUCCESS':
      return {
        ...state,
        isDetailedReportLoading: false,
      };
    case 'FETCH_DETAILED_FILE_FAILURE':
      return {
        ...state,
        isDetailedReportLoading: false,
      };
    default:
      return state;
  }
};

const ERROR_MESSAGES = {
  '14': ptr['app.time.report.transactions.error.no.departments'],
  '24': ptr['app.time.report.transactions.error.no.transactions'],
};

export const TransactionsReports = connect(
  ({ context: { selectedDepartment } }) => ({ selectedDepartment }),
  { showNotification },
)(
  ({ start, end, customerId, showNotification, selectedDepartment, ...rest }) => {
    const client = useApolloClient();
    const [reportMenuAnchorEl, setReportMenuAnchorEl] = React.useState(null)
    const { formatMessage: f } = useIntl();

    const {
      components: {
        StartDateComponent,
        EndDateComponent,
      },
    } = rest;

    const [state, dispatch] = React.useReducer(reducer, {
      startDate: start,
      endDate: end,
      isCompensationReportLoading: false,
      isProjectReportLoading: false,
      isDetailedReportLoading: false,
    });

    React.useEffect(() => {
      dispatch({ type: 'REQUEST_START_DATE_CHANGE', payload: start });
      dispatch({ type: 'REQUEST_END_DATE_CHANGE', payload: end });
      // eslint-disable-next-line
    }, [start.format(DEFAULT_DATE_FORMAT), end.format(DEFAULT_DATE_FORMAT)]);

    const downloadReportByCompensationType = React.useCallback(async () => {
      dispatch({ type: 'FETCH_COMPENSATION_FILE_REQUEST' });

      try {
        const { data } = await client.query({
          query: GET_TRANSACTIONS_REPORT_BY_DEVIATION_AS_BASE64,
          variables: {
            input: {
              startDate: state.startDate.format(DEFAULT_DATE_FORMAT),
              endDate: state.endDate.format(DEFAULT_DATE_FORMAT),
              customerId,
            }
          }
        })

        dispatch({ type: 'FETCH_COMPENSATION_FILE_SUCCESS' });

        downloadExcel({
          content: JSON.parse(atob(data.getTransactionsReportBase64)),
          filename: [
            state.startDate.format(DEFAULT_DATE_FORMAT),
            '_',
            state.endDate.format(DEFAULT_DATE_FORMAT),
            '.xlsx',
          ].join(''),
          headers: [
            'Employment #', 'First Name',
            'Last Name', 'Compensation Type',
            'Status', 'Start Date',
            'End Date', 'Comment',
            'Worked Hours', 'Full Day',
          ],
          sheetName: 'Report By Deviation'
        })
      } catch (e) {
        dispatch({ type: 'FETCH_COMPENSATION_FILE_FAILURE' });

        const c = getErrorEntityCode(e);
        const m = getErrorMessageCode(e)

        const message = (ERROR_MESSAGES[`${c}${m}`] && f(ERROR_MESSAGES[`${c}${m}`]))

        showNotification({
          [Date.now()]: {
            message,
            type: NOTIFICATION.ERROR,
          },
        });
      } finally {
        setReportMenuAnchorEl(null)
      }
      // eslint-disable-next-line
    }, [selectedDepartment, state.startDate.format(DEFAULT_DATE_FORMAT), state.endDate.format(DEFAULT_DATE_FORMAT)]);

    const downloadReportByProject = React.useCallback(async () => {
      dispatch({ type: 'FETCH_PROJECT_FILE_REQUEST' });

      try {
        const { data } = await client.query({
          query: GET_TRANSACTIONS_REPORT_BY_PROJECT_AS_BASE64,
          variables: {
            input: {
              startDate: state.startDate.format(DEFAULT_DATE_FORMAT),
              endDate: state.endDate.format(DEFAULT_DATE_FORMAT),
              customerId,
            }
          }
        })

        dispatch({ type: 'FETCH_PROJECT_FILE_SUCCESS' });

        downloadExcel({
          content: JSON.parse(atob(data.getProjectsReportBase64)),
          filename: [
            state.startDate.format(DEFAULT_DATE_FORMAT),
            '_',
            state.endDate.format(DEFAULT_DATE_FORMAT),
            '.xlsx',
          ].join(''),
          headers: [
            'Employment #', 'First Name',
            'Last Name', 'Project #', 'Project Name', 'Activity',
            'Status', 'Start Date',
            'End Date', 'Worked Hours',
            'Full Day', 'Compensation Type',
            'Comment', 'Billable'
          ],
          sheetName: 'Report By Project Summary'
        })
      } catch (e) {
        dispatch({ type: 'FETCH_PROJECT_FILE_FAILURE' });

        const c = getErrorEntityCode(e);
        const m = getErrorMessageCode(e)

        const message = (ERROR_MESSAGES[`${c}${m}`] && f(ERROR_MESSAGES[`${c}${m}`]))

        showNotification({
          [Date.now()]: {
            message,
            type: NOTIFICATION.ERROR,
          },
        });
      } finally {
        setReportMenuAnchorEl(null)
      }
      // eslint-disable-next-line
    }, [selectedDepartment, state.startDate.format(DEFAULT_DATE_FORMAT), state.endDate.format(DEFAULT_DATE_FORMAT)]);

    const downloadReportDetailed = React.useCallback(async () => {
      dispatch({ type: 'FETCH_DETAILED_FILE_REQUEST' });

      try {
        const { data } = await client.query({
          query: GET_TRANSACTIONS_REPORT_DETAILED_AS_BASE64,
          variables: {
            filter: {
              startDate: state.startDate.format(DEFAULT_DATE_FORMAT),
              endDate: state.endDate.format(DEFAULT_DATE_FORMAT),
            }
          }
        })

        dispatch({ type: 'FETCH_DETAILED_FILE_SUCCESS' });

        downloadExcel({
          content: map(omit(['__typename']), data.projectReportingDetailedReport),
          filename: [
            state.startDate.format(DEFAULT_DATE_FORMAT),
            '_',
            state.endDate.format(DEFAULT_DATE_FORMAT),
            '.xlsx',
          ].join(''),
          headers: [
            'Employment #', 'First Name',
            'Last Name', 'Project #', 'Project Name',
            'Activity', 'Status',
            'Date', 'Duration',
            'Compensation Type', 'Billable',
            'Comment'
          ],
          sheetName: 'Report By Project Detailed'
        })
      } catch (e) {
        dispatch({ type: 'FETCH_DETAILED_FILE_FAILURE' });

        const c = getErrorEntityCode(e);
        const m = getErrorMessageCode(e)

        const message = (ERROR_MESSAGES[`${c}${m}`] && f(ERROR_MESSAGES[`${c}${m}`]))

        showNotification({
          [Date.now()]: {
            message,
            type: NOTIFICATION.ERROR,
          },
        });
      } finally {
        setReportMenuAnchorEl(null)
      }
      // eslint-disable-next-line
    }, [selectedDepartment, state.startDate.format(DEFAULT_DATE_FORMAT), state.endDate.format(DEFAULT_DATE_FORMAT)]);

    return (
      <React.Fragment>
        <StartDateComponent
          value={state.startDate}
          shouldDisableDate={d => d.format('YY-MM-DD') > state.endDate.format('YY-MM-DD')}
          onChange={v => dispatch({ type: 'REQUEST_START_DATE_CHANGE', payload: v })}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <EndDateComponent
          value={state.endDate}
          shouldDisableDate={d => d.format('YY-MM-DD') < state.startDate.format('YY-MM-DD')}
          onChange={v => dispatch({ type: 'REQUEST_END_DATE_CHANGE', payload: v })}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          onClick={(e) => setReportMenuAnchorEl(e.target)}
          variant="outlined"
        >
          <LibraryBooksIcon fontSize="small" color="primary" />
          &nbsp;&nbsp;
          {f(ptr['app.time.report.transactions.reports.subtitle'])}
        </Button>
        <Menu
          open={Boolean(reportMenuAnchorEl)}
          onClose={() => setReportMenuAnchorEl(null)}
          anchorEl={reportMenuAnchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem
            onClick={downloadReportByCompensationType}
            disabled={state.isCompensationReportLoading}
          >
            <ListItemIcon>
              {state.isCompensationReportLoading
                ? <CircularProgress size={20} thickness={3} />
                : <InsertDriveFileIcon color="primary"/>
              }
            </ListItemIcon>
            <ListItemText
              inset
              primary={f(ptr['app.time.report.transactions.report.by.deviation.menu.item'])}
            />
          </MenuItem>
          <MenuItem
            onClick={downloadReportByProject}
            disabled={state.isProjectReportLoading}
          >
            <ListItemIcon>
              {state.isProjectReportLoading
                ? <CircularProgress size={20} thickness={3} />
                : <DescriptionIcon color="primary" />
              }
            </ListItemIcon>
            <ListItemText
              inset
              primary={f(ptr['app.time.report.transactions.report.by.project.menu.item'])}
            />
          </MenuItem>
          <MenuItem
            onClick={downloadReportDetailed}
            disabled={state.isDetailedReportLoading}
          >
            <ListItemIcon>
              {state.isDetailedReportLoading
                ? <CircularProgress size={20} thickness={3} />
                : <DescriptionIcon color="primary" />
              }
            </ListItemIcon>
            <ListItemText
              inset
              primary={f(ptr['app.time.report.transactions.report.by.detailed.menu.item'])}
            />
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  },
);

export default TransactionsReports
