import React, { Component } from 'react';
import {
  compose,
  split,
  path,
  not,
  __,
  equals,
  type,
} from 'ramda';
import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    paddingRight: '4px',
    whiteSpace: 'nowrap',
    '&:last-child': { paddingRight: '4px' },
  },
});

class TableRowCell extends Component {
  shouldComponentUpdate(nextProps) {
    return compose(
      not,
      equals(nextProps.row),
      path(['row']),
    )(this.props);
  }

  isComponent() {
    return compose(
      equals('Function'),
      type,
      path(['config', 'component']),
    )(this.props);
  }

  content() {
    const {
      config: { id, noSplit },
      row,
    } = this.props;

    if (noSplit) return  row[id]

    return compose(
      path(__, row),
      split('.'),
    )(id);
  }

  render() {
    const {
      row,
      i,
      passDown,
      config: {
        tableCellProps,
        component: ComponenT,
      },
      classes,
    } = this.props;

    return (
      <TableCell
        {...tableCellProps}
        variant="body"
        classes={{ root: classes.root }}
      >
        {this.isComponent()
          ? <ComponenT row={row} i={i} passDown={passDown} />
          : this.content()}
      </TableCell>
    );
  }
}

export default withStyles(styles)(TableRowCell);
