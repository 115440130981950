import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { isNil } from 'ramda';
import { Typography } from '@material-ui/core';
import pac from '../../../../config/intlMessageSelectors/payroll-admin-customers';
import { DEFAULT_DATE_FORMAT } from "../../../../utils/times";

const ColoredStatus = ({ color, text }) => <Typography color={color}>{text}</Typography>;

const CustomersStatus = ({ row: { inactiveDate, isFlowCompleted } }) => {
  const { formatMessage: f } = useIntl();

  const isActive = isNil(inactiveDate)
    || moment().format(DEFAULT_DATE_FORMAT) < moment(inactiveDate).format(DEFAULT_DATE_FORMAT);

  if (!isActive) {
    return (
      <ColoredStatus
        color="error"
        text={f(pac['admin.page.table.customer.status.inactive'])}
      />
    );
  }

  if (isFlowCompleted) {
    return (
      <ColoredStatus
        color="primary"
        text={f(pac['admin.page.table.customer.status.active'])}
      />
    );
  }

  return (
    <ColoredStatus
      color="secondary"
      text={f(pac['admin.page.table.customer.status.incomplete'])}
    />
  );
};

export default CustomersStatus;
