import React from 'react';
import { withStyles } from '@material-ui/core';
import { Select } from '../../../../../components/FormikFields';

const StyledSelect = withStyles(() => ({
  root: { marginTop: '12px' },
  underline: { '&:before': { borderBottom: 'none' } },
}))(({ classes: { underline, ...rest }, ...props }) => (
  <Select
    classes={rest}
    InputProps={{ classes: { underline } }}
    {...props}
  />
));

export default StyledSelect;
