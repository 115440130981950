import React from 'react';
import { compose, path, map, reduce, anyPass, values, isEmpty, all } from 'ramda';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { CustomerLoader } from '../components/CustomerLoader';
import EmploymentCategoryTable from './EmploymentCategoryTable';
import AddNew from '../components/NewButton';
import EmloymentCategoryFooter from './EmploymentCategoryFooter';
import { MODULES_CONFIG } from './EmploymentCategoryTbody';

import {
  CREATE_EMPLOYMENT_CATEGORY,
  DELETE_EMPLOYMENT_CATEGORY,
  GET_COMPENSATION_TYPES_AND_EMPLOYMENT_CATEGORIES_BY_CUSTOMER,
} from '../../../queries/customers';
import { showNotification } from '../../../actions/notifications';
import { NOTIFICATION } from '../../../components/NotificationSnackbar';
import { getErrorMessageCode } from '../../../utils/error';

import pac from '../../../config/intlMessageSelectors/payroll-admin-customers';

const ERROR_CODES = { REMOVE_EMPLOYMENT_CATEGORY_IS_NOT_ALLOWED: 12 };

const ERROR_MESSAGES = { [ERROR_CODES.REMOVE_EMPLOYMENT_CATEGORY_IS_NOT_ALLOWED]: 'admin.error.remove.used.employment.category' };

const getErrorMessage = code =>
  ERROR_MESSAGES[code] || 'Ops something went wrong';

const headers = [
  {
    id: 'category',
    label: pac['admin.page.step.employment.categories.column.category'],
    tableCellProps: { style: { minWidth: '200px', width: '200px', paddingLeft: '15px' } },
  },
  {
    id: 'modulesConfig',
    label:
      pac['admin.page.step.employment.categories.column.modules.config'],
    tableCellProps: { style: { width: '280px', minWidth: '280px' } },
  },
  {
    id: 'normalWorkDay',
    label: pac['admin.page.step.employment.categories.column.norm.time'],
    tableCellProps: { style: { width: '105px', minWidth: '105px' } },
  },
  {
    id: 'reportingMethod',
    label: pac['admin.page.step.employment.categories.column.reporting.method'],
    tableCellProps: { style: { width: '135px', minWidth: '135px' } },
  },
  {
    id: 'compensationTypes',
    label:
      pac['admin.page.step.employment.categories.column.compensation.types'],
    tableCellProps: { style: { width: '35%', minWidth: '300px' } },
  },
  {
    id: 'menu',
    label: '',
    tableCellProps: {
      style: {
        width: '56px',
        minHeight: '56px',
        paddingRight: '4px',
        paddingLeft: '4px',
      },
    },
  },
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'TEMPORARY_CATEGORIES_SETUP':
      return {
        ...state,
        categories: reduce(
          (r, { id, temporary }) => ({
            ...r,
            [id]: temporary,
          }),
          {},
          action.payload,
        ),
      };
    case 'TEMPORARY_CATEGORIES_CHANGE':
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

const EmploymentCategories = ({
  setupRightPlace,
  loading,
  types,
  categories,
  step,
  history,
  customerId,
  createOrUpdateCategory,
  deleteCategory,
  refetch,
  customerDataRefetch,
  showNotification,
}) => {
  const { formatMessage: f } = useIntl();
  const [state, dispatch] = React.useReducer(reducer, { categories: {} });
  const add = async () => {
    await createOrUpdateCategory({ variables: { input: { customerId } } });
    refetch();
  };

  const remove = async id => {
    try {
      await deleteCategory({ variables: { id, customerId } });
      refetch();
    } catch (e) {
      const errorCode = getErrorMessageCode(e);

      showNotification({
        [Date.now()]: {
          message: f(pac[getErrorMessage(errorCode)]),
          type: NOTIFICATION.ERROR,
        },
      });
    }
  };

  const isNextDisabled = compose(
    anyPass([isEmpty, all(t => !!t)]),
    values,
    path(['categories']),
  )(state);

  const update = debounce(async ({ key, value, form }) => {
    const isModulesConfigUpdate = key === 'modulesConfig';

    const { data: { addEmploymentCategoryTemp: { id, temporary } } } = await createOrUpdateCategory({
      variables: {
        input: {
          customerId,
          [key]: isModulesConfigUpdate ?
            MODULES_CONFIG.reduce((acc, item) => {
              acc[item] = value.includes(item);

              return acc;
            }, {}) : value,
          id: path(['values', 'id'], form),
        },
      },
    });

    if (id && isModulesConfigUpdate) {
      customerDataRefetch();
    }

    dispatch({
      type: 'TEMPORARY_CATEGORIES_CHANGE',
      payload: { key: id, value: temporary },
    });
  }, 300);

  React.useEffect(() => {
    setupRightPlace(() => (
      <AddNew
        onClick={add}
        text={
          pac['admin.page.step.employment.categories.add.employment.category']
        }
      />
    ));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch({ type: 'TEMPORARY_CATEGORIES_SETUP', payload: categories });
    // eslint-disable-next-line
  }, [categories.length]);

  return (
    <React.Fragment>
      {loading ? (
        <CustomerLoader />
      ) : (
        <EmploymentCategoryTable
          headers={headers}
          types={types}
          categories={categories}
          onRowRemove={remove}
          onRowUpdate={update}
          AddNewRowComponent={() => (
            <AddNew
              onClick={add}
              text={
                pac['admin.page.step.employment.categories.add.employment.category']
              }
            />
          )}
        />
      )}

      <EmloymentCategoryFooter
        isNextDisabled={isNextDisabled}
        loading={loading}
        customerId={customerId}
        step={step}
        history={history}
      />
    </React.Fragment>
  );
};

export default compose(
  connect(null, { showNotification }),
  withRouter,
  graphql(DELETE_EMPLOYMENT_CATEGORY, { name: 'deleteCategory' }),
  graphql(CREATE_EMPLOYMENT_CATEGORY, { name: 'createOrUpdateCategory' }),
  graphql(GET_COMPENSATION_TYPES_AND_EMPLOYMENT_CATEGORIES_BY_CUSTOMER, {
    options: ({ params: { id } }) => ({ variables: { customerId: +id, enabled: true } }),
    props: ({
      // ql props
      data: {
        loading,
        compensationTypesV2: types = [],
        employmentCategoriesV2: categories = [],
        refetch,
      },
      // our props
      ownProps: {
        params: { id },
        history,
        step,
        setupRightPlace,
        setupCustomerNameAndNumber,
        name,
        organizationNumber,
      },
    }) => ({
      loading,
      types: map(({ id, name }) => ({ value: id, label: name }), types),
      categories,
      customerId: +id,
      history,
      step,
      setupRightPlace,
      setupCustomerNameAndNumber,
      name,
      organizationNumber,
      refetch,
    }),
  }),
)(EmploymentCategories);
