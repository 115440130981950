import { defineMessages } from 'react-intl';

export default defineMessages({
  'app.employees.userModalCreate': {
    id: 'app.employees.userModalCreate',
    defaultMessage: 'Create employee ',
    description: 'Title of the create employee pop-up window',
  },
  'app.employees.userModalEdit': {
    id: 'app.employees.userModalEdit',
    defaultMessage: 'Edit employment details',
    description: 'Title of the edit employee pop-up window',
  },
  'app.employees.userModalConfirm': {
    id: 'app.employees.userModalConfirm',
    defaultMessage: 'Confirm',
  },
  'app.employees.userModalCancel': {
    id: 'app.employees.userModalCancel',
    defaultMessage: 'Cancel',
  },
  'app.employees.timeReportingMethod': {
    id: 'app.employees.timeReportingMethod',
    defaultMessage: 'Time reporting method',
    description: 'Column in the employees table',
  },
  'app.employees.userModalGeneral': {
    id: 'app.employees.userModalGeneral',
    defaultMessage: 'General',
    description: 'Create Employee Pop-up - General information block title',
  },
  'app.employees.employmentDetails': {
    id: 'app.employees.employmentDetails',
    defaultMessage: 'Employment details',
    description: 'Create Employee Pop-up - Employment details block title',
  },

  'app.employees.employmentCategory': {
    id: 'app.employees.employmentCategory',
    defaultMessage: 'Employment category',
  },
  'app.employees.employmentNumber': {
    id: 'app.employees.employmentNumber',
    defaultMessage: 'Employment number',
  },
  'app.employees.firstName': {
    id: 'app.employees.firstName',
    defaultMessage: 'First Name',
  },
  'app.employees.lastName': {
    id: 'app.employees.lastName',
    defaultMessage: 'Last Name',
  },
  'app.employees.phone': {
    id: 'app.employees.phone',
    defaultMessage: 'Phone',
  },
  'app.employees.department': {
    id: 'app.employees.department',
    defaultMessage: 'Department',
  },
  'app.employees.role': {
    id: 'app.employees.role',
    defaultMessage: 'Role',
  },
  'app.employees.createUserButton': {
    id: 'app.employees.createUserButton',
    defaultMessage: 'Create Employee ',
  },
  'app.employees.email': {
    id: 'app.employees.email',
    defaultMessage: 'Email',
  },
  'app.employees.personalId': {
    id: 'app.employees.personalId',
    defaultMessage: 'Personal Id',
  },
  'app.employees.bankAccount': {
    id: 'app.employees.bankAccount',
    defaultMessage: 'Bank account number',
  },
  'app.employees.contactPerson': {
    id: 'app.employees.contactPerson',
    defaultMessage: 'Contact person',
  },
  'app.employees.contactPersonPhone': {
    id: 'app.employees.contactPersonPhone',
    defaultMessage: 'Contact person phone',
  },
  'app.employees.authLogin': {
    id: 'app.employees.authLogin',
    defaultMessage: 'Auth login',
  },
  'app.employees.smsLogin': {
    id: 'app.employees.smsLogin',
    defaultMessage: 'Sms login',
  },
  'app.employees.address': {
    id: 'app.employees.address',
    defaultMessage: 'Address',
  },
  'app.employees.postalCity': {
    id: 'app.employees.postalCity',
    defaultMessage: 'Postal city',
  },
  'app.employees.postalNumber': {
    id: 'app.employees.postalNumber',
    defaultMessage: 'Postal number',
  },
  'app.employees.userTimezone': {
    id: 'app.employees.userTimezone',
    defaultMessage: 'Timezone',
  },
  'app.employees.userNationality': {
    id: 'app.employees.userNationality',
    defaultMessage: 'Nationality',
  },
  'app.employees.userLanguage': {
    id: 'app.employees.userLanguage',
    defaultMessage: 'Language',
  },
  'app.employees.admin': {
    id: 'app.employees.admin',
    defaultMessage: 'Admin',
  },
  'app.employees.manager': {
    id: 'app.employees.manager',
    defaultMessage: 'Manager',
  },
  'app.employees.approver': {
    id: 'app.employees.approver',
    defaultMessage: 'Approver',
  },
  'app.employees.employee': {
    id: 'app.employees.employee',
    defaultMessage: 'Employee',
  },
  'app.employees.superadmin': {
    id: 'app.employees.superadmin',
    defaultMessage: 'Super admin',
  },
  'app.employees.employmentType': {
    id: 'app.employees.employmentType',
    defaultMessage: 'Employment type',
  },
  'app.employees.fullTime': {
    id: 'app.employees.fullTime',
    defaultMessage: 'Full time',
  },
  'app.employees.partTime': {
    id: 'app.employees.partTime',
    defaultMessage: 'Part time',
  },
  'app.employees.yearSalary': {
    id: 'app.employees.yearSalary',
    defaultMessage: 'Year salary',
  },
  'app.employees.employeeStartDate': {
    id: 'app.employees.employeeStartDate',
    defaultMessage: 'Employee start date',
    description: 'Start date of the employee, used in the user modal',
  },
  'app.employees.suspended': {
    id: 'app.employees.suspended',
    defaultMessage: 'Suspended',
    description: 'Suspended employee, used as employee tab',
  },
  'app.employees.all': {
    id: 'app.employees.all',
    defaultMessage: 'all',
    description: 'All employee, used as employee tab',
  },
  'app.employees.noDepartmentAssigned': {
    id: 'app.employees.noDepartmentAssigned',
    defaultMessage: 'No department assigned',
    description: 'No department assigned, used when user does not have department',
  },
  'app.employees.employeeEndDate': {
    id: 'app.employees.employeeEndDate',
    defaultMessage: 'Employee end date',
    description: 'End date of the employee, used in the user modal',
  },
  'app.employees.country': {
    id: 'app.employees.country',
    defaultMessage: 'Country',
    description: 'Label for field on employee form (Country)',
  },
  'app.employees.city': {
    id: 'app.employees.city',
    defaultMessage: 'City',
    description: 'Label for field on employee form (City)',
  },
  'app.employees.home.address': {
    id: 'app.employees.home.address',
    defaultMessage: 'Home Address',
    description: 'Label for field on employee form (Home Address)',
  },
  'app.employees.removeEmployeeTitle': {
    id: 'app.employees.removeEmployeeTitle',
    defaultMessage: 'Remove Employee',
    description: 'Title for Remove Employee confirmation dialog',
  },
  'app.employees.removeEmployeeConfirmationText': {
    id: 'app.employees.removeEmployeeConfirmationText',
    defaultMessage: 'Are you sure you want to remove this employee?',
    description: 'Confirmation message for removing employee',
  },
  'app.employees.cannotRemoveHasTransaction': {
    id: 'app.employees.cannotRemoveHasTransaction',
    defaultMessage: 'Cannot remove the user with transactions',
    description: 'An error message when removing user with transaction',
  },
  'app.employees.errorEmploymentNumber': {
    id: 'app.employees.errorEmploymentNumber',
    defaultMessage: 'Employment number is already used, please select another one',
    description: 'An error message when user tries to use same employment number '
      + 'which is already taken',
  },
  'app.employees.emailAddressInUse': {
    id: 'app.employees.emailAddressInUse',
    defaultMessage: 'Email address is already in use.',
    description: 'Error message when creating the user with the email that already exists',
  },
  'app.employees.create.employee': {
    id: 'app.employees.create.employee',
    defaultMessage: 'Create employee',
    description: 'Subtitle for back arrow on top of employee form (Create mode)',
  },
  'app.employees.edit.employee': {
    id: 'app.employees.edit.employee',
    defaultMessage: 'Edit employee',
    description: 'Subtitle for back arrow on top of employee form (Edit mode)',
  },
  'app.employees.approve.own.timereport': {
    id: 'app.employees.approve.own.timereport',
    defaultMessage: 'Approve own timereport',
    description: 'Label for field on employee form (Approve own timerepot)',
  },
  'app.employees.access.rights': {
    id: 'app.employees.access.rights',
    defaultMessage: 'Access Rights',
    description: 'Access rights sub title on employee form',
  },
  'app.employees.button.add.new.department.to.approve': {
    id: 'app.employees.button.add.new.department.to.approve',
    defaultMessage: 'Add new department to approve',
    description: 'Button text for adding new department to approve',
  },
  'app.employees.button.remove.department.to.approve': {
    id: 'app.employees.button.remove.department.to.approve',
    defaultMessage: 'Remove department to approve',
    description: 'Button text for removing department to approve',
  },
  'app.employees.tenant': {
    id: 'app.employees.tenant',
    defaultMessage: 'Tenant',
    description: 'Label for field on employee form (Tenant, Departments to approve)',
  },
  'app.employees.customer': {
    id: 'app.employees.customer',
    defaultMessage: 'Customer',
    description: 'Label for field on employee form (Customer, Departments to approve)',
  },
  'app.employees.departments': {
    id: 'app.employees.departments',
    defaultMessage: 'Departments',
    description: 'Label for field on employee form (Departments, Departments to approve)',
  },
  'app.employees.subtitle.location': {
    id: 'app.employees.subtitle.location',
    defaultMessage: 'Location',
    description: 'Subtitle group of fields on employee form (Location)',
  },
  'app.employees.button.save': {
    id: 'app.employees.button.save',
    defaultMessage: 'Save',
    description: 'Save button on employee form',
  },
  'app.employees.notitication.success.save': {
    id: 'app.employees.notitication.success.save',
    defaultMessage: 'Saved',
    description: 'Success notification on employee creation',
  },
  'app.employees.error.no.deviation': {
    id: 'app.employees.error.no.deviation',
    defaultMessage: 'Can not report No Deviations, transactions already exist for this period',
    description: `Error message for no deviation report when transactions already
      exist for this period`,
  },
  'app.employees.error.national.holiday': {
    id: 'app.employees.error.national.holiday',
    defaultMessage: 'Absence can not be reported on national holidays',
    description: 'Error message when user tries reports on national holidays',
  },
  'app.employees.dialog.future.reports.title': {
    id: 'app.employees.dialog.future.reports.title',
    defaultMessage: 'Employee future reports',
    description: 'Dialog title for suspended employee that has reports in a future',
  },
  'app.employees.dialog.future.reports.description': {
    id: 'app.employees.dialog.future.reports.description',
    defaultMessage: `has some transactions reported on and/or after the given end date.
     Please review if they should be kept or removed. User will not be able to report on dates after the selected end date.`,
    description: 'Dialog description for suspended employee that has reports after end of employment',
  },
  'app.employees.dialog.future.reports.reported.date': {
    id: 'app.employees.dialog.future.reports.date',
    defaultMessage: 'Reported dates',
    description: 'Label for reported date',
  },
  'admin.error.notification.user.isNotActive': {
    id: 'admin.error.notification.user.isNotActive',
    defaultMessage: 'User is not activated yet',
    description: 'Error to show when user is not active',
  },
});
