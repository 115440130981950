import React from 'react';
import { useQuery } from 'react-apollo';
import {
  pathOr,
  compose,
  map,
  concat,
  filter,
  propEq,
} from 'ramda';
import { useIntl } from 'react-intl';
import { Select } from '../../../../components/Select';
import { GET_ALL_DEPARTMENTS_WITH_INFO } from '../../../../queries/departments';
import ptr from '../../../../config/intlMessageSelectors/payroll-admin-timeReport';

export default ({ selectedCustomer, setSelectedDepartment, selectedDepartment }) => {
  const { formatMessage: f } = useIntl();
  const { data, loading } = useQuery(
    GET_ALL_DEPARTMENTS_WITH_INFO,
    { variables: { input: { filters: { customerId: selectedCustomer } } } },
  );

  const options = compose(
    concat([{ label: f(ptr['app.timeReport.allDepartments']), value: 0 }]),
    map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    pathOr([], ['departments']),
  )(data);

  const value = compose(
    pathOr(null, [0, 'value']),
    filter(propEq('value', selectedDepartment)),
  )(options);

  return (
    <Select
      value={value || 0}
      options={options}
      onChange={(_, { key }) => setSelectedDepartment(Number(key))}
      disabled={loading}
      minWidth="150px"
    />
  )
}
