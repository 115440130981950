import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Approved = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <svg fill="none">
      <path
        d="M10.0013 18.3337C14.6037 18.3337 18.3346 14.6027 18.3346 10.0003C18.3346 5.39795 14.6037 1.66699 10.0013 1.66699C5.39893 1.66699 1.66797 5.39795 1.66797 10.0003C1.66797 14.6027 5.39893 18.3337 10.0013 18.3337Z"
        stroke="#4AAF62"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2702 7.34375L8.82747 12.7865L6.35352 10.3125"
        stroke="#4AAF62"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default Approved;
