import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent, Grid, Button,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import pab
  from '../../../../../../../config/intlMessageSelectors/payroll-admin-base';
import pac
  from '../../../../../../../config/intlMessageSelectors/payroll-admin-customers';

const ProjectDialogReminder = ({ isOpen, onClose }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="projects-reminder"
    >
      <DialogTitle id="projects-reminder-title">
        {f(pac['admin.page.projects.step.title'])}
      </DialogTitle>
      <DialogContent>
        <Grid container justify="space-between" spacing={16}>
          <Grid item xs={12}>
            {
              `${f(pac['admin.page.users.import.success'])}`
            }
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="flex-end"
          >
            <Grid item xs={3}>
              <Button fullWidth variant="outlined" onClick={onClose}>
                {f(pab['app.base.btns.ok'])}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ProjectDialogReminder;
