import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { TableBody } from '../components/Table';

import EmploymentCategoryTrow from './EmploymentCategoryTrow';

export const MODULES_CONFIG = ['projectReporting', 'payslips', 'timeReports'];
const EmploymentCategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Too Short')
    .max(32, 'Too Long')
    .required('Required'),
  normalWorkDay: Yup.number()
    .moreThan(0, 'Invalid Time'),
  compensationTypes: Yup.array()
    .of(Yup.number().min(1))
    .required('Required'),
});

const PreFilledRow = React.memo(
  withFormik({
    mapPropsToValues: ({ data }) => {
      const { modulesConfig = {}} = data;

      return {
        ...data,
        modulesConfig: Object.keys(modulesConfig || [])
          .filter(item => MODULES_CONFIG.includes(item) && !!modulesConfig[item])
      }
    },
    validateOnMount: true,
    displayName: 'PreFilledEmploymentCategoryRow',
    validationSchema: EmploymentCategorySchema,
  })(EmploymentCategoryTrow),
  () => true,
);

const EmploymentCategoryTbody = ({
  types,
  categories,
  AddNewRowComponent,
  onRowUpdate,
  onRowRemove,
}) => (
  <TableBody
    rows={categories}
    onRowUpdate={onRowUpdate}
    onRowRemove={onRowRemove}
    AddNewRowComponent={AddNewRowComponent}
    PreFilledRow={PreFilledRow}
    preFilledRowProps={{ types }}
  />
);

export default EmploymentCategoryTbody;
