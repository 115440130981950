import React, { useState } from 'react';
import { IconButton } from "@material-ui/core";
import { Delete, Edit, Info } from "@material-ui/icons";
import { useMutation } from "react-apollo";
import { compose } from "ramda";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import DialogRemoveDeviation from "./DialogRemoveDeviation";
import { DELETE_EMPLOYEE_REPORT } from "../../queries/transactions";
import { PERIOD_STATUSES, TRANSACTION_STATUSES } from "../../utils/timeReports";
import { NOTIFICATION } from "../NotificationSnackbar";
import { showNotification } from "../../actions/notifications";
import pan from "../../config/intlMessageSelectors/payroll-admin-notifications";

const { OPEN, SUBMITTED, APPROVED, REJECTED, ARCHIVED, EXPORTED } = TRANSACTION_STATUSES;

const ColumnActions = ({
  compensationType,
  isEditDisabled,
  el,
  refetch,
  classes,
  onInfo,
  onEdit,
  showNotification,
  periodStatus,
}) => {
  const { formatMessage: f } = useIntl();

  const [showDeleteDeviationModal, setShowDeleteDeviationModal] = useState(false);
  const [deviationIdToRemove, setDeviationIdToRemove] = useState(undefined);
  const { status } = el;

  const [deleteEmployeeReport] = useMutation(DELETE_EMPLOYEE_REPORT);

  const onDeleteEmployeeReport = async (id) => {
    try {
      await deleteEmployeeReport({
        variables: {
          input: { id },
        },
      });
      refetch();
    } catch (e) {
      showNotification({
        [Date.now()]: {
          message: f(pan['app.notification.error.default']),
          type: NOTIFICATION.ERROR,
        },
      });
    } finally {
      setShowDeleteDeviationModal(false);
      setDeviationIdToRemove(undefined);
    }
  }

  const canChangePeriod = periodStatus === PERIOD_STATUSES.OPEN || periodStatus === PERIOD_STATUSES.REJECTED;

  return (
    <>
      {(status === OPEN || status === REJECTED) &&
      compensationType.timeType !== 'NO_DEVIATIONS' && (
        <IconButton
          disabled={isEditDisabled || status === EXPORTED || !canChangePeriod }
          onClick={onEdit}
          aria-label="Edit"
        >
          <Edit />
        </IconButton>
      )}

      {(status === SUBMITTED ||
        status === ARCHIVED ||
        status === APPROVED ||
        status === EXPORTED) && (
        <IconButton
          onClick={onInfo}
          aria-label="Info"
        >
          <Info />
        </IconButton>
      )}
      {status !== ARCHIVED &&
      status !== APPROVED &&
      status !== EXPORTED &&
      compensationType.timeType !== 'NO_DEVIATIONS' && (
        <IconButton
          disabled={status === EXPORTED || !canChangePeriod}
          aria-label="Delete"
          onClick={e => {
            e.stopPropagation();

            setShowDeleteDeviationModal(true);
            setDeviationIdToRemove(el.id);
          }}
        >
          <Delete />
        </IconButton>
      )}

      {showDeleteDeviationModal && (
        <DialogRemoveDeviation
          deviationId={deviationIdToRemove}
          classes={classes}
          onClose={() => setShowDeleteDeviationModal(false)}
          onConfirm={onDeleteEmployeeReport}
        />
      )}
    </>
  );
};

export default compose(
  connect(null, { showNotification }),
)(ColumnActions);
