import React from 'react';
import { getEnvs } from '../utils/getEnvs';

export const EnvironmentConfigContext = React.createContext({});

export const EnvironmentConfigProvider = ({ children }) => {
  const envs = getEnvs()

  return (
    <EnvironmentConfigContext.Provider value={envs}>
      {children}
    </EnvironmentConfigContext.Provider>
  )
};
