import React from 'react';
import { getIn } from 'formik';
import MuiTextField from '@material-ui/core/TextField';

class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(e) {
    const {
      field,
      onChange,
      form,
    } = this.props;

    field.onChange(e);

    if (onChange) {
      onChange({
        value: e.target.value,
        key: field.name,
        form,
        prevValue: field.value
      });
    }
  }

  onBlur(e) {
    const {
      field,
      onBlur,
      form,
    } = this.props;

    field.onBlur(e);

    if (onBlur) {
      onBlur({
        value: e.target.value,
        key: field.name,
        form,
      });
    }
  }

  render() {
    const {
      field,
      children,
      form,
      overrided = false,
      ...props
    } = this.props;

    const error = getIn(form.errors, field.name);
    const touch = getIn(form.touched, field.name);

    return (
      <MuiTextField
        {...props}
        {...field}
        FormHelperTextProps={{
          // make sure that error doesn't shit the input itself
          ...!overrided
            ? { style: { position: 'absolute', top: '24px' } }
            : { style: { position: 'absolute', top: '52px' } },
        }}
        error={touch && !!error}
        helperText={touch && error ? error : null}
        value={field.value || ''}
        onChange={this.onChange}
        onBlur={this.onBlur}
      >
        {children}
      </MuiTextField>
    );
  }
}

export default TextField;
