import React from 'react';
import { connect } from 'react-redux';
import {
  compose,
  isEmpty,
  not,
  take,
  gt,
  __,
  length,
  splitEvery,
  ifElse,
  identity,
  pathOr,
} from 'ramda';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Card,
  Divider,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  Button,
  TextField,
  withStyles,
} from '@material-ui/core';
import { Edit, Delete, Warning, Info } from '@material-ui/icons';
import { useMutation } from 'react-apollo';
import pab from '../../../../config/intlMessageSelectors/payroll-admin-base';
import { DELETE_DEPARTMENT, UPDATE_DEPARTMENT } from '../../../../queries/departments';
import pad from '../../../../config/intlMessageSelectors/payroll-admin-departments';

const MAX_LENGTH_OF_NAME = 20;
const MAX_NUMBER_OF_APPROVERS = 8;

const Department = ({ name, id, customerId, usersCount, approvers, permissions, selectedCustomer, refetch, classes }) => {
  const [isRemoveOpen, setRemoveIsOpen] = React.useState(false);
  const [isEditOpen, setEditIsOpen] = React.useState(false);
  const [dname, setName] = React.useState(name);
  const { formatMessage: f } = useIntl();
  const [remove] = useMutation(DELETE_DEPARTMENT);
  const [update] = useMutation(UPDATE_DEPARTMENT);

  const onRemove = async () => {
    await remove({ variables: { input: { id, filters: { customerId: selectedCustomer } } } });
    await refetch();
  }

  const onSubmit = async () => {
    await update({
      variables: { input: {
        name: dname,
        id,
        customerId: selectedCustomer,
        filters: { customerId: selectedCustomer }
      }
    }});
    setName('');
    setEditIsOpen(false);
    await refetch();
  }

  const isEditable = pathOr(false, ['editDepartments'], permissions);
  const isRemovable = pathOr(false, ['deleteDepartments'], permissions);

  const hasApprovers = compose(
    not,
    isEmpty,
  )(approvers);

  const hasLongName = compose(
    gt(__, MAX_LENGTH_OF_NAME),
    length,
  )(name);

  const hasMoreThanOneApprover = compose(
    gt(__, 1),
    length,
  )(approvers);

  const hasUsers = compose(
    gt(__, 0),
    pathOr(0, ['count']),
  )(usersCount);

  const spliteed = compose(
    splitEvery(2),
    ifElse(
      compose(
        gt(__, MAX_NUMBER_OF_APPROVERS),
        length
      ),
      take(MAX_NUMBER_OF_APPROVERS),
      identity
    ),
  )(approvers);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12} container justify="space-between" alignItems="center">
            <Link
              to={`/departments/${id}`}
              className={classes.link}
            >
              <Tooltip title={`${f(pad['app.departments.department'])}: ${name}`}>
                <Typography variant="headline">
                  {hasLongName ? `${take(MAX_LENGTH_OF_NAME, name)}...` : name}
                </Typography>
              </Tooltip>
            </Link>
            <IconButton disabled={!isEditable} onClick={() => setEditIsOpen(true)}>
              <Edit fontSize="small" />
            </IconButton>
            <Dialog fullWidth open={isEditOpen} onClose={() => setEditIsOpen(false)}>
              <DialogTitle>
                {f(pad['app.departments.createDepartment'])}
              </DialogTitle>
              <DialogContent>
                <Grid container justify="space-between" spacing={16}>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      fullWidth
                      value={dname}
                      onChange={e => setName(e.target.value)}
                      onKeyDown={e => e.keyCode === 13 && onSubmit()}
                      label={f(pad['app.departments.department'])}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justify="flex-end"
                  >
                    <Grid item xs={3}>
                      <Button fullWidth variant="outlined" onClick={() => setEditIsOpen(false)}>
                        {f(pab['app.base.btns.cancel'])}
                      </Button>
                    </Grid>

                    <Grid item xs={4} className={classes.confirm}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                      >
                        {f(pab['app.base.btns.confirm'])}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </Grid>

          {!hasApprovers && (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justify="center"
              className={classes.noApproversBlock}
              spacing={16}
            >
              <Grid item xs={2}>
                <Warning color="error" />
              </Grid>
              <Typography variant="subtitle1" align="center">
                {f(pad['app.departments.noApproverAssigned'])}
              </Typography>
            </Grid>
          )}

          {hasApprovers && (
            <Grid item container xs={12} justify="center" className={classes.approversBlock}>
              <Typography variant="subtitle1" align="center">
                {hasMoreThanOneApprover && f(pad['app.departments.approvers'])}
                {!hasMoreThanOneApprover && f(pad['app.departments.approver'])}
              </Typography>

              <Grid item xs={12} container direction="column">
                {spliteed.map((group, i) => (
                  <Grid key={i} item spacing={16} container justify="space-between">
                    {group.map(({ firstName, lastName, customerId: acId, customerName }, i) => {
                      const fullName = `${firstName} ${lastName}`;
                      const isAnotherCustomer = customerId !== acId;

                      return (
                        <Grid key={i} item xs={6}>
                          <Typography variant="body1" className={classes.approver}>
                            {fullName}
                            {isAnotherCustomer && (
                              <Tooltip title={customerName} placement="top-end">
                                <Info className={classes.infoIcon} />
                              </Tooltip>
                            )}
                          </Typography>
                          <Divider />
                        </Grid>
                      )
                    })}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} container justify="space-between" alignItems="center">
            <Typography variant="subtitle1">
              {`${f(pad['app.departments.employees'])}: ${usersCount.count}`}
            </Typography>
            <IconButton disabled={!isRemovable} onClick={() => {
              if (hasUsers) {
                setRemoveIsOpen(true);
                return
              }

              onRemove();
            }}>
              <Delete fontSize="small" />
            </IconButton>
            <Dialog fullWidth open={isRemoveOpen} onClose={() => setRemoveIsOpen(false)}>
              <DialogTitle>
                {f(pad['app.departments.removeDepartmentTitle'])}
              </DialogTitle>
              <DialogContent>
                <Grid container justify="space-between" spacing={16}>
                  <Grid item xs={12}>
                    {f(pad['app.departments.removeDepartment'])}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justify="flex-end"
                  >
                    <Grid item xs={3}>
                      <Button fullWidth variant="outlined" onClick={() => setRemoveIsOpen(false)}>
                        {f(pab['app.base.btns.cancel'])}
                      </Button>
                    </Grid>

                    <Grid item xs={4} className={classes.confirm}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={onRemove}
                      >
                        {f(pab['app.base.btns.confirm'])}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default compose(
  connect(({ auth: { user: { selectedCustomer } }}) => ({ selectedCustomer })),
  withStyles(() => ({
    approver: { display: 'flex' },
    cardContent: { padding: 16 },
    confirm: { marginLeft: 10 },
    paper: {
      minWidth: '320px',
      maxWidth: '560px',
    },
    infoIcon: {
      color: 'rgba(0, 0, 0, 0.3)',
      fontSize: 18,
      marginLeft: 4,
    },
    link: { textDecoration: 'none'  },
    noApproversBlock: { minHeight: 116 },
    approversBlock: { minHeight: 100 },
  }))
)(Department);
