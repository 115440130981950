import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { TableBody } from '../components/Table';

import CompensationTypeTrow from './CompensationTypeTrow';

const CompensationTypeSchema = Yup.object().shape({
  code: Yup.string()
    .min(1, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required'),
  name: Yup.string()
    .min(1, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required'),
  intervalId: Yup.number()
    .nullable()
    .required('Required'),
  reportingType: Yup.string()
    .nullable()
    .required('Required'),
  type: Yup.string()
    .nullable()
    .required('Required'),
  timeType: Yup.string()
    .nullable()
    .required('Required'),
});

const PreFilledRow = React.memo(
  withFormik({
    mapPropsToValues: ({ data, intervals }) => ({
      ...data,
      ...{ intervalId: data.intervalId || intervals[0].value }
    }),
    validateOnMount: true,
    displayName: 'PreFilledCompensationTypeRow',
    validationSchema: CompensationTypeSchema,
  })(CompensationTypeTrow),
  () => true,
);

const CompensationTypeTbody = ({
  types = [],
  intervals = [],
  AddNewRowComponent,
  onRowUpdate,
  onRowRemove,
}) => (
  <TableBody
    rows={types}
    onRowUpdate={onRowUpdate}
    onRowRemove={onRowRemove}
    AddNewRowComponent={AddNewRowComponent}
    PreFilledRow={PreFilledRow}
    preFilledRowProps={{ intervals }}
  />
);

export default CompensationTypeTbody;
