import React from 'react';
import { useQuery } from 'react-apollo';
import {
  pathOr,
  compose,
  map,
  propEq,
  filter,
  path,
} from 'ramda';
import moment from 'moment';
import { Select } from '../../../../components/Select';
import { GET_PERIODS } from '../../../../queries/time-report';

export default ({ selectedCustomer, selectedPeriod, setSelectedPeriod, customers, setPeriods }) => {
  const { data, loading } = useQuery(
    GET_PERIODS,
    {
      variables: {
        input: {
          filters: { customerId: selectedCustomer },
          order: 'startDate ASC',
        },
      },
    },
  );

  const options = compose(
    map(({ id, startDate }) => ({
      value: id,
      label: moment(startDate).format('MMM YYYY'),
    })),
    pathOr([], ['periods']),
  )(data);

  React.useEffect(() => {
    if (loading && !data) return;

    setPeriods(data.periods);
    // eslint-disable-next-line
  }, [loading]);

  let value = selectedPeriod;

  if (!value) {
    value = compose(
      path([0, 'periodId']),
      filter(propEq('id', selectedCustomer)),
    )(customers);
  }

  if (!value) {
    options.push({ label: 'No Period', value: 'none' });
  }

  return (
    <Select
      value={value || 'none'}
      options={options}
      onChange={(_, { key }) => setSelectedPeriod(Number(key))}
      disabled={loading}
    />
  );
}
