import React from 'react';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button } from '@material-ui/core';
import pac from '../../../../config/intlMessageSelectors/payroll-admin-customers';

const styles = () => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    boxShadow: `
      0px -1px 3px rgba(0, 0, 0, 0.05),
      0px -2px 2px rgba(0, 0, 0, 0.12),
      0px 0px 2px rgba(0, 0, 0, 0.14)
    `,
    zIndex: 1100,
  },
});

const Footer = ({
  isNextDisabled,
  isBackDisabled,
  shouldShowSaveButton,
  onBack,
  onNext,
  isLastStep,
  classes,
}) => {
  const { formatMessage: f } = useIntl();
  return (
    <Paper classes={{ root: classes.footer }}>
      <Grid container justify="center" alignItems="center" style={{ height: '76px' }}>
        <Grid item xs={2} md={2} lg={1} style={{ marginRight: '20px' }}>
          <Button
            fullWidth
            variant="outlined"
            disabled={isBackDisabled}
            onClick={onBack}
          >
            {f(pac['admin.page.step.back.button'])}
          </Button>
        </Grid>
        {shouldShowSaveButton && (
          <Grid item xs={2} md={2} lg={1} style={{ marginRight: '20px' }}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              disabled={isNextDisabled}
              onClick={onNext}
            >
              {f(pac['admin.page.step.save.button'])}
            </Button>
          </Grid>
        )}
        <Grid item xs={2} md={2} lg={1}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={isNextDisabled}
            onClick={onNext}
          >
            {isLastStep
              ? f(pac['admin.page.step.finish.button'])
              : f(pac['admin.page.step.next.button'])}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const StyledFooter = withStyles(styles)(Footer);

export default StyledFooter;
