import React from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { useApolloClient } from 'react-apollo';
import { useIntl } from 'react-intl';
import { pluck, pathOr, compose, reject, isNil } from 'ramda';
import { getIn, FastField, Field, FieldArray } from 'formik';
import {
  Grid,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { ROLES } from '../../../../config/role-configs';
import {
  DatePicker,
  Select,
  TextField,
} from '../../../../components/FormikFields';
import { GET_CUSTOMER_DEPARTMENTS } from '../../../../queries/customers';
import { AccessRules } from './components';
import pae from '../../../../config/intlMessageSelectors/payroll-admin-employees';

const EmployeeEmploymentDetails = ({
  roles,
  tenants,
  departments,
  employmentCategories,
  values,
  selectedCustomer,
  selectedTenant,
  options,
}) => {
  const client = useApolloClient();
  const { formatMessage: f } = useIntl();

  const selected = compose(
    reject(isNil),
    pluck('customerId'),
    pathOr([], ['accessRules']),
  )(values);

  const onRoleChange = React.useCallback(async ({ value, form }) => {
    if ([ROLES.APPROVER].includes(value)) {
      form.setFieldValue('employmentCategoryId', null);
      form.setFieldValue('departmentId', null);
      form.setFieldValue('employmentNumber', null);
    }

    if (![ROLES.MANAGER, ROLES.APPROVER, ROLES.CUSTOMERADMIN].includes(value)) {
      return form.setFieldValue('accessRules', []);
    }

    const rights = getIn(form.values, 'accessRules');

    if (rights.length === 1 && ![ROLES.CUSTOMERADMIN].includes(value)) {
      form.setFieldValue('accessRules', [{ ...rights[0], departmentIds: [] }])
    }

    if (rights.length === 1 && [ROLES.CUSTOMERADMIN].includes(value)) {
      const { data: { departments } } = await client.query({
        query: GET_CUSTOMER_DEPARTMENTS,
        variables: { input: { filters: { customerId: rights[0].customerId } } },
      });

      form.setFieldValue('accessRules', [{
        ...rights[0],
        departmentIds: departments.map(({ id }) => id),
      }]);
    }

    if (rights.length !== 0) return

    form.setFieldValue('accessRules', [{
      tenantId: selectedTenant,
      customerId: selectedCustomer,
      departmentIds: [],
    }]);

    if (![ROLES.CUSTOMERADMIN].includes(value)) return;

    const { data: { departments } } = await client.query({
      query: GET_CUSTOMER_DEPARTMENTS,
      variables: { input: { filters: { customerId: selectedCustomer } } },
    });

    form.setFieldValue('accessRules', [{
      tenantId: selectedTenant,
      customerId: selectedCustomer,
      departmentIds: departments.map(({ id }) => id),
    }]);

    // eslint-disable-next-line
  }, [values.role])

  return (
    <Grid item container alignItems="center" xs={12} spacing={32}>
      <Grid item container xs={12}>
        <Typography
          variant="h6"
          color="default"
          style={{ marginTop: '16px' }}
        >
          {f(pae['app.employees.employmentDetails'])}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          variant="outlined"
          overrided
          disabled={[ROLES.APPROVER].includes(values.role)}
          label={f(pae['app.employees.employmentNumber'])}
          name="employmentNumber"
          component={TextField}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          overrided
          label={f(pae['app.employees.role'])}
          name="role"
          variant="outlined"
          component={Select}
          options={roles}
          onChange={onRoleChange}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          overrided
          disabled={[ROLES.APPROVER].includes(values.role)}
          label={f(pae['app.employees.employmentCategory'])}
          name="employmentCategoryId"
          variant="outlined"
          component={Select}
          options={employmentCategories}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          overrided
          disabled={[ROLES.APPROVER].includes(values.role)}
          label={f(pae['app.employees.department'])}
          name="departmentId"
          variant="outlined"
          component={Select}
          options={departments}
        />
      </Grid>

      <Grid item xs={3}>
        <FastField
          fullWidth
          type="number"
          label={f(pae['app.employees.yearSalary'])}
          name="yearSalary"
          component={TextField}
        />
      </Grid>
      <Grid item xs={3}>
        <FastField
          fullWidth
          label={f(pae['app.employees.employeeStartDate'])}
          name="employeeStart"
          component={DatePicker}
          noHelperText
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          control={(
            <Field name="suspended">
              {({ field, form: { setFieldValue } }) => (
                <Switch
                  color="primary"
                  name="suspended"
                  checked={field.value}
                  onChange={async (v) => {
                    await setFieldValue('suspended', v.target.checked);
                    if (!v.target.checked) {
                      setFieldValue('employeeEnd', null);
                    }
                  }}
                />
              )}
            </Field>
          )}
          label={f(pae['app.employees.suspended'])}
        />
      </Grid>
      <Grid item xs={3}>
        <Field name="employeeEnd">
          {({ field, form }) => (
            <DatePicker
              form={form}
              field={field}
              disabled={!form.values.suspended}
              label={f(pae['app.employees.employeeEndDate'])}
              name="employeeEnd"
              shouldDisableDate={d => d.format('YY-MM-DD') < moment(values.employeeStart).format('YY-MM-DD')}
              format={"Do MMMM YYYY"}
            />
          )}
        </Field>
      </Grid>

      {![ROLES.EMPLOYEE].includes(values.role) && (
        <React.Fragment>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="default"
            >
              {f(pae['app.employees.access.rights'])}
            </Typography>
          </Grid>
          <FieldArray name="accessRules">
            {h => (
              <React.Fragment>
                {values.accessRules.map((v, i) => (
                  <React.Fragment key={i}>
                    <AccessRules
                      tenants={tenants}
                      i={i}
                      v={v}
                      selected={selected}
                      options={options}
                      onAdd={() => {
                        h.push({
                          tenantId: null,
                          customerId: null,
                          departmentIds: [],
                        });
                      }}
                      onRemove={() => h.remove(i)}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </FieldArray>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default connect(
  ({ auth: { user: { selectedCustomer, selectedTenant }} }) => ({
    selectedCustomer,
    selectedTenant,
  })
)(EmployeeEmploymentDetails);
