import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Dialog,
  TextField,
  withStyles,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import { useMutation } from 'react-apollo';
import { useIntl } from 'react-intl';
import { CREATE_DEPARTMENT } from '../../../../queries/departments';

import pab from '../../../../config/intlMessageSelectors/payroll-admin-base';
import pad from '../../../../config/intlMessageSelectors/payroll-admin-departments';

const CreateDepartmentButton = ({ refetch, classes, selectedCustomer }) => {
  const { formatMessage: f } = useIntl()
  const [isOpen, setIsOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [create] = useMutation(CREATE_DEPARTMENT);

  const onSubmit = async () => {
    await create({
      variables: { input: { name, customerId: selectedCustomer } },
    });
    setName('');
    setIsOpen(false);
    await refetch();
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setIsOpen(!isOpen)}
      >
        {f(pad['app.departments.createDepartment'])}
      </Button>
      <Dialog fullWidth open={isOpen} onClose={() => setIsOpen(false)} classes={classes}>
        <DialogTitle>
          {f(pad['app.departments.createDepartment'])}
        </DialogTitle>
        <DialogContent>
          <Grid container justify="space-between" spacing={16}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyDown={e => e.keyCode === 13 && onSubmit()}
                label={f(pad['app.departments.department'])}
              />
            </Grid>

            <Grid
              item
              container
              xs={12}
              justify="flex-end"
            >
              <Grid item xs={3}>
                <Button fullWidth variant="outlined" onClick={() => setIsOpen(false)}>
                  {f(pab['app.base.btns.cancel'])}
                </Button>
              </Grid>

              <Grid item xs={4} style={{ marginLeft: '10px' }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                >
                  {f(pab['app.base.btns.confirm'])}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default compose(
  withStyles(() => ({
    paper: {
      minWidth: '320px',
      maxWidth: '560px',
    },
  })),
  connect(({ auth: { user: { selectedCustomer } }}) => ({ selectedCustomer }))
)(CreateDepartmentButton);
