import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';
import TimeReportDetails from './pages/TimeReportDetails';
import Departments from './pages/Departments';
import { Projects } from './pages/Projects';
import DepartmentDetails from './pages/DepartmentDetails';
import { TimeReportsOverview } from './pages/TimeReportsOverview';
import { Customers } from './pages/Customers';
import { Customer } from './pages/Customer';
import { Employees } from './pages/Employees';
import { Employee } from './pages/Employee';
import { useLoggedUser } from './providers/IdentityProvider';

const Routes = ({ selectedPeriod }) => {
  const { role, id } = useLoggedUser();

  if (['employee'].includes(role)) {
    return (
      <Switch>
        <ProtectedRoute
          path="/time-report-details/:month/:userId"
          component={TimeReportDetails}
        />
        <Route path="/forbidden" component={() => <h1>Page forbidden</h1>} />
        <Redirect to={`/time-report-details/${selectedPeriod}/${id}`} />
      </Switch>
    )
  }

  if (['approver', 'manager', 'customeradmin'].includes(role)) {
    return (
      <Switch>
        <ProtectedRoute
          exact
          path="/employees"
          component={Employees}
        />
        <ProtectedRoute
          exact
          path="/employees/:id"
          component={Employee}
        />
        <ProtectedRoute path="/time-reports" component={TimeReportsOverview} />
        <ProtectedRoute
          path="/time-report-details/:month/:userId"
          component={TimeReportDetails}
        />
        <ProtectedRoute
          exact
          path="/departments"
          component={Departments}
        />
        <ProtectedRoute
          exact
          path="/departments/:departmentId"
          component={DepartmentDetails}
        />
        <ProtectedRoute
          exact
          path="/projects"
          component={Projects}
        />
        <Route path="/forbidden" component={() => <h1>Page forbidden</h1>} />
        <Redirect to="/employees" />
      </Switch>
    )
  }

  return (
    <Switch>
      <ProtectedRoute exact path="/" component={Employees} />
      <ProtectedRoute
        exact
        path="/employees"
        component={Employees}
      />
      <ProtectedRoute
        exact
        path="/employees/:id"
        component={Employee}
      />
      <ProtectedRoute path="/time-reports" component={TimeReportsOverview} />
      <ProtectedRoute
        path="/time-report-details/:month/:userId"
        component={TimeReportDetails}
      />
      <ProtectedRoute
        exact
        path="/departments"
        component={Departments}
      />
      <ProtectedRoute
        exact
        path="/departments/:departmentId"
        component={DepartmentDetails}
      />
      <ProtectedRoute
        exact
        path="/customers"
        component={Customers}
      />
      <ProtectedRoute
        exact
        path="/customers/:id/step/:step(general|types|categories|users|projects)"
        component={Customer}
      />
      <Route path="/forbidden" component={() => <h1>Page forbidden</h1>} />
      <Redirect to="/employees" />
    </Switch>
  )
};

export default connect(
  ({ context: { selectedPeriod } }) => ({ selectedPeriod }),
  null,
)(Routes)
