import React from 'react';
import { useIntl } from "react-intl";
import { useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ptr from "../config/intlMessageSelectors/payroll-admin-timeReport";
import { formatTimeToHours, PERIOD_TYPES } from "../utils/timeReports";
import { GET_EMPLOYEE_PERIOD_EXPECTATION } from "../queries/users";

const PeriodSummary = ({ periodSummary, classes }) => {
  const { formatMessage: f } = useIntl();
  const { userId, month } = useParams();

  const { data : {employeePeriodExpectations: expectations = {}} = {} } = useQuery(GET_EMPLOYEE_PERIOD_EXPECTATION,{
    variables: {
      employeeId: +userId,
      employeePeriodId: +month,
    }
  });

  const defaultReports = { total: 0 }

  const absence = periodSummary.find(el => el.type === PERIOD_TYPES.ABSENCE) || defaultReports
  const presence = periodSummary.find(el => el.type === PERIOD_TYPES.PRESENCE) || defaultReports

  return expectations.expected ? (
    <Grid container spacing={24} alignItems="center" className={classes.wrapper}>
    {expectations.expected && (
      <Grid item>
        <Grid container item direction="column">
        <span className={classes.expected}>
          {expectations.expected.hours.toString().length > 1
            ? expectations.expected.hours
            : `0${expectations.expected.hours}`}:
          {expectations.expected.minutes.toString().length > 1
              ? expectations.expected.minutes
              : `0${expectations.expected.minutes}`}
        </span>
          <span>{f(ptr['app.timeReport.expectedHours'])}</span>
        </Grid>
      </Grid>
    )}
      <Grid item>
        <Grid container item direction="column">
          <span className={classes.working}>
            {presence
              ? formatTimeToHours(presence.total)
              : '00:00'}
          </span>
          <span>{f(ptr['app.timeReport.periodTypeDimension'])}</span>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container item direction="column">
          <span className={classes.deviations}>
            {absence
              ? formatTimeToHours(absence.total)
              : '00:00'}
          </span>
          <span>{f(ptr['app.timeReport.periodTypeDeviation'])}</span>
        </Grid>
      </Grid>
    </Grid>
  ) : (<></>);
};

export default withStyles(() => ({
  wrapper: { height: 52 },
  expected: { fontSize: 16, color: '#000', fontWeight: 'bold' },
  working: { fontSize: 16, color: '#4AAF62', fontWeight: 'bold' },
  deviations: { fontSize: 16, color: '#F55448', fontWeight: 'bold' }
}))(PeriodSummary);
