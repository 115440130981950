export default {
  "app.timeReport.compensationType": "Type",
  "app.timeReport.dateRange": "Datointerval",
  "app.timeReport.transactionStatus": "Status",
  "app.timeReport.transactionStatusRejectBtn": "Afvise",
  "app.timeReport.period": "Periode",
  "app.timeReport.closed": "Lukket",
  "app.timeReport.open": "Åben",
  "app.timeReport.closePeriodBtn": "Luk periode",
  "app.timeReport.periodIsClosedBtn": "Perioden er allerede lukket",
  "app.timeReport.periodIsClosedStatus": "Perioden er lukket\n",
  "app.timeReport.transactionStatusApproveBtn": "Godkende",
  "app.timeReport.transactionStatusApproved": "GODKENDT",
  "app.timeReport.transactionStatusExported": "EKSPORTERET",
  "app.timeReport.transactionStatusRejected": "AFVIST",
  "app.timeReport.transactionStatusPendingApproval": "AFVENTER GODKENDELSE",
  "app.timeReport.transactionStatusArchived": "ARKIVERET",
  "app.timeReport.transactionStatusSubmitted": "TILFØJET",
  "app.timeReport.createDeviation": "Opret afvigelse",
  "app.timeReport.comment": "Kommentar",
  "app.timeReport.showLess": "Vis mindre",
  "app.timeReport.showMore": "Vis mere",
  "app.timeReport.rejectMessage": "Årsag til afvisning",
  "app.timeReport.deviationsTable": "AFVIGELSER",
  "app.timeReport.details": "DETALJER",
  "app.timeReport.singleDayToggle": "En dag",
  "app.timeReport.periodToggle": "Periode",
  "app.timeReport.formDuration": "Varighed",
  "app.timeReport.formStart": "Start",
  "app.timeReport.formEnd": "Slut",
  "app.timeReport.formDate": "Dato",
  "app.timeReport.user": "Bruger",
  "app.timeReport.employees": "Medarbejdere",
  "app.timeReport.status": "Status",
  "app.timeReport.absence": "Fravær",
  "app.timeReport.presence": "Tilstedeværelse",
  "app.timeReport.employee": "Medarbejder",
  "app.timeReport.allDepartments": "Alle afdelinger",
  "app.timeReport.department": "Afdeling",
  "app.timeReport.all": "Alle",
  "app.timeReport.notReported": "Ikke rapporteret",
  "app.timeReport.waitingForApproval": "Venter på godkendelse",
  "app.timeReport.approved": "Godkendt",
  "app.timeReport.exported": "Eksporteret",
  "app.timeReport.timeReportsTitle": "Tidsrapporter",
  "app.timeReport.description": "Beskrivelse",
  "app.timeReport.project": "Projekt",
  "app.timeReport.projectCompensationType": "Tidstype",
  "app.timeReport.workType": "Aktivitet",
  "app.timeReport.projectHours": "Projekt timer",
  "app.timeReport.active": "Aktiv",
  "app.timeReport.archived": "Arkiveret",
  "app.timeReport.reportingMoreThan24H": "Der kan maks registreres 24 timer på en dag",
  "app.timeReport.overlappedAbsence": "Du kan kun registrere fravær der har samme eller færre antal timer svarende til en arbejdsdag",
  "app.timeReport.reportingOnWeekendIsNotAllowed": "Ikke muligt at rapportere fravær i disse dage. Vælg en anden dag",
  "app.time.report.transations.report.subtitle": "Afdelingens transaktionsrapport",
  "app.time.report.transation.report.download.button": "Download Rapport",
  "app.time.report.transations.error.no.transactions": "Ingen transaktioner fundet i den givne periode ",
  "app.time.report.transations.error.no.departments": "Ingen afdelinger fundet for denne kunde",
  "app.time.report.transations.removeTransactionModal.title": "Er du sikker på, at du vil fjerne denne afvigelse?",
  "app.time.report.transations.removeTransactionModal.body": "Indsendte transaktioner fjernes. Eventuelt tidligere eksporterede dele af afvigelserne opbevares.",
  "app.timeReport.userNotes": "Bruger notater",
  "app.timeReport.transactionStatusPendingSubmit": "AFVENTER INDSENDELSE",
  "app.timeReport.createTransaction": "Opret transaktion",
  "app.timeReport.formTime": "Tid",
  "app.timeReport.editTransaction": "Rediger transaktion",
  "app.timeReport.createTransactionModalTitle": "Opret transaktion",
  "app.timeReport.editTransactionModalTitle": "Rediger transaktion"
}
