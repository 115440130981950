import React from 'react';
import { useIntl } from 'react-intl';
import pab from '../../../../config/intlMessageSelectors/payroll-admin-base';

import {
  Button,
} from '@material-ui/core';

const styles = {
  color: '#3B77B5',
  textTransform: 'inherit',
  border: '1px solid #3B77B5'
};

const ChoseFileButton = ({ onClick }) => {
  const { formatMessage: f } = useIntl();

  return (
    <Button
      style={styles}
      variant="outlined"
      onClick={onClick}
    >
      { `${f(pab['app.base.button.import'])}...` }
    </Button>
  );
}

export default ChoseFileButton;
