import React from 'react';
import { withStyles } from '@material-ui/core';

import { Checkbox } from '../../../../../components/FormikFields';

// compare next & prev values
// eslint-disable-next-line
const areEqual = ({ field: pField, form: pForm }, { field: nField, form: nForm }) => {
  return pField.value === nField.value
    && nForm.touched[nField.name] === pForm.touched[pField.name];
};

const StyledCheckbox = React.memo(
  withStyles(() => ({ root: { padding: '0' } }))(Checkbox),
  areEqual,
);

export default StyledCheckbox;
