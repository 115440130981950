import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from "moment";
import { DatePicker } from 'material-ui-pickers';
import { CalendarTodayOutlined as CalendarIcon } from '@material-ui/icons';
import { HOLIDAY_DURATION } from "./HolidaysSection";
import { useIntl } from "react-intl";
import pac from "../../../config/intlMessageSelectors/payroll-admin-customers";
import { DEFAULT_DATE_FORMAT } from "../../../utils/times";

const styles = () => ({
  formControl: { minWidth: 150 },
  dialogActions: {
    display: "flex",
    justifyContent: 'space-between',
    margin: '0 20px 20px'
  },
});

const initHoliday = {
  id: null,
  name: '',
  date: '',
  duration: 'FULL_DAY',
};

const HolidayDialog = ({
  classes,
  holidays,
  selectedHoliday,
  isOpen,
  setDialog,
  setFieldValue,
  setSelectedHoliday,
  selectedYear
}) => {
  const { formatMessage: f } = useIntl();
  const [holiday, setHoliday] = useState(initHoliday);

  useEffect(() => {
    if (selectedHoliday) {
      const holiday = holidays.find(el => el.id === selectedHoliday)
      setHoliday(holiday);
    } else {
      setHoliday({...initHoliday, date: `${selectedYear}-01-01`});
    }
  },[selectedHoliday]);

  const updateHoliday = () => {
    let updatedHolidays;

    if (holiday.id) {
      updatedHolidays = holidays.map(day => (day.id === holiday.id) ? holiday : day);
    } else {
      updatedHolidays = [...holidays, {...holiday, id: `n${new Date().valueOf()}`}]
    }

    setFieldValue('holidays', updatedHolidays);
    setHoliday(initHoliday);
    setSelectedHoliday(null);
  };

  return holidays ? (
      <Dialog
        maxWidth={"xs"}
        fullWidth
        open={isOpen}
        onClose={() => {
          setHoliday(initHoliday);
          setSelectedHoliday(null);
          setDialog(false);
        }}
      >
        <DialogTitle>
          {f(pac['admin.page.step.general.popup.edit.holiday.title'])}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={24}>
            <Grid item>
              <MuiTextField
                fullWidth
                value={holiday?.name}
                onChange={e => setHoliday({...holiday, name: e.target.value})}
                label={f(pac['admin.page.step.general.popup.edit.holiday.field.name'])}
              />
            </Grid>
            <Grid item>
              <DatePicker
                autoOk
                fullWidth
                label={f(pac['admin.page.step.general.popup.edit.holiday.field.date'])}
                value={holiday?.date}
                format="DD MMMM YYYY"
                onChange={date => {
                  setHoliday({
                    ...holiday,
                    date: moment(date).format(DEFAULT_DATE_FORMAT)
                  })
                }}
                InputProps={{
                  endAdornment: <CalendarIcon fontSize="small" />,
                }}
                minDate={new Date(`${selectedYear}-01-01`)}
                maxDate={new Date(`${selectedYear}-12-31`)}
              />
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <RadioGroup
                  row
                  aria-label="duration"
                  name="duration"
                  className={classes.group}
                  value={holiday?.duration}
                  onChange={({ target: { value } }) => {
                    setHoliday({
                      ...holiday,
                      duration: value
                    })
                  }}
                >
                  <FormControlLabel
                    value={HOLIDAY_DURATION.FULL_DAY}
                    control={<Radio />}
                    label={f(pac['admin.page.step.general.popup.edit.holiday.field.full.day'])}
                  />
                  <FormControlLabel
                    value={HOLIDAY_DURATION.HALF_DAY}
                    control={<Radio />}
                    label={f(pac['admin.page.step.general.popup.edit.holiday.field.half.day'])}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => {
              setHoliday(initHoliday);
              setSelectedHoliday(null);
              setDialog(false);
            }}
          >
            {f(pac['admin.page.step.general.popup.edit.holiday.cancel.button'])}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateHoliday();
              setDialog(false);
            }}
          >
            {f(pac['admin.page.step.general.popup.edit.holiday.save.button'])}
          </Button>
        </DialogActions>
    </Dialog>
  ) : (<></>);
};

export default withStyles(styles)(HolidayDialog);
