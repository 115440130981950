import React from 'react';
import { Select as MuiSelect, withStyles, MenuItem } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'

const styles = theme => ({
  item: {
    padding: '4px 8px',
    minWidth: 82,
  },
  select: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '3px',
    minWidth: 104,
    '&::before': { border: 'none' },
    '&::after': { borderBottom: `1px solid ${theme.palette.primary.main}` },
  },
  icon: {
    color: theme.palette.primary.main,
    top: 'calc(50% - 8px)',
    fontSize: 16,
  },
  input: { paddingLeft: 8 },
  menu: { marginTop: 8, maxHeight: 240 },
});

const Select = ({ classes, options, minWidth, ...rest }) =>  (
  <MuiSelect
    variant="outlined"
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
      className: classes.menu,
    }}
    className={classes.select}
    IconComponent={KeyboardArrowDown}
    classes={{ icon: classes.icon }}
    inputProps={{ className: classes.input }}
    {...rest}
    style={{ minWidth }}
  >
    {options.map(({ label, value }) => (
      <MenuItem className={classes.item} key={value} value={value}>{label}</MenuItem>
    ))}
  </MuiSelect>
);

export default withStyles(styles)(Select);
