import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField, Typography,
  withStyles
} from '@material-ui/core';
import SelectCustomer from '../SelectCustomer';
import pac from '../../../../config/intlMessageSelectors/payroll-admin-customers';
import pab from '../../../../config/intlMessageSelectors/payroll-admin-base';
import SimpleDialog from '../../../../components/Dialog';

const CopyCustomerDialog = ({ open, classes, onClose, customers, onChange, dataToCopy }) => {
  const { formatMessage: f } = useIntl();
  const [ openConfirmDialog, setOpenConfirmDialog ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ list, setList ] = useState([]);
  const [ selectedCustomerId, setSelectedCustomerId ] = useState(null);

  const onSubmit = () => {
    onClose();
    setOpenConfirmDialog(true);
  };

  const closeDialogs = () => {
    onClose();
    setOpenConfirmDialog(false);
  }

  useEffect(() => {
    if (open) {
      setSearch('');
      setList(customers);
    }
  }, [open]);

  useEffect(() => {
    const filteredList = customers.filter(item => item && item.name.includes(search) || item && item.organizationNumber.toString().includes(search));
    const ids = filteredList.map(item => item.id);
    setList(filteredList);
    if (!ids.includes(selectedCustomerId)) {
      setSelectedCustomerId(null);
    }
  }, [search]);

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose} classes={classes}>
        <DialogTitle>
          {f(pac['admin.page.step.general.copy.customer'])}
        </DialogTitle>
        <DialogContent>
          <Grid container justify="space-between" spacing={16}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={f(pac['admin.page.step.general.copy.placeholder'])}
              />
              {
                !!list.length && (
                  <SelectCustomer
                    list={list}
                    selectedCustomerId={selectedCustomerId}
                    onChange={setSelectedCustomerId}
                  />
                )
              }
            </Grid>

            <Grid
              item
              container
              xs={12}
              justify="flex-end"
            >
              <Grid item xs={3}>
                <Button fullWidth variant="outlined" onClick={onClose}>
                  {f(pab['app.base.btns.cancel'])}
                </Button>
              </Grid>

              <Grid item xs={4} style={{ marginLeft: '10px' }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={!list.length || !selectedCustomerId}
                >
                  {f(pab['app.base.btns.confirm'])}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <SimpleDialog
        fullWidth
        maxWidth="sm"
        open={openConfirmDialog}
        onClose={closeDialogs}
      >
        <Typography
          variant="h6"
          style={{ padding: '10px 0' }}
        >
          { `${f(pac['admin.page.step.general.copy.confirmation'])} ${ list.find(item => item.id === selectedCustomerId)?.name } ?`}
          <br />
        </Typography>
        <DialogActions style={{ margin: '10px -4px' }}>
          <Button
            type="button"
            onClick={() => {
              closeDialogs();
            }}
            variant="outlined"
            classes={{ outlined: classes.outlined }}
          >
            {f(pab['app.base.btns.cancel'])}
          </Button>

          <Button
            variant="contained"
            type="submit"
            classes={{ contained: classes.contained }}
            style={{ marginLeft: '10px' }}
            onClick={() => {
              onChange(selectedCustomerId, () => {
                closeDialogs();
              }, dataToCopy);
            }}
          >
            {f(pab['app.base.btns.confirm'])}
          </Button>
        </DialogActions>
      </SimpleDialog>
    </>
  );
};

export default compose(
  withStyles(() => ({
    paper: {
      minWidth: '320px',
      maxWidth: '560px',
    },
  })),
  connect(({ context: { customers }}) => ({ customers })),
)(CopyCustomerDialog);
