import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange({ target: { checked } }) {
    const {
      field: { name },
      form,
      onChange,
    } = this.props;

    form.setFieldValue(name, checked);

    if (onChange) {
      onChange({
        value: checked,
        key: name,
        form,
      });
    }
  }

  render() {
    const {
      field: {
        value,
        ...field
      },
      ...props
    } = this.props;

    return (
      <MuiCheckbox
        {...field}
        {...props}
        checked={value || false}
        onChange={this.onChange}
      />
    );
  }
}

export default Checkbox;
