import { fromPairs } from 'ramda';

export const ROLES = fromPairs([
  'SUPERADMIN',
  'ADMIN',
  'MANAGER',
  'EMPLOYEE',
  'APPROVER',
  'CUSTOMERADMIN'
].map(k => [k, k.toLowerCase()]));

export const COMMON = fromPairs([
  'VIEW',
  'CHANGE',
  'DELETE',
  'ADD',
  'EDIT',
].map(k => [k, k.toLowerCase()]));

export const ENTITIES = fromPairs([
  'TENANT',
  'CUSTOMER',
].map(k => [k, k.toLowerCase()]));

export const RULES = {
  [ROLES.SUPERADMIN]: {
    [ENTITIES.TENANT]: [COMMON.CHANGE],
    [ENTITIES.CUSTOMER]: [COMMON.CHANGE],
  },
  [ROLES.ADMIN]: {
    [ENTITIES.TENANT]: [COMMON.CHANGE],
    [ENTITIES.CUSTOMER]: [COMMON.CHANGE],
  },
  [ROLES.CUSTOMERADMIN]: {
    [ENTITIES.TENANT]: [COMMON.CHANGE],
    [ENTITIES.CUSTOMER]: [COMMON.CHANGE],
  },
  [ROLES.APPROVER]: {
    [ENTITIES.TENANT]: [COMMON.CHANGE],
    [ENTITIES.CUSTOMER]: [COMMON.CHANGE],
  },
  [ROLES.MANAGER]: {
    [ENTITIES.TENANT]: [COMMON.CHANGE],
    [ENTITIES.CUSTOMER]: [COMMON.CHANGE],
  },
  [ROLES.EMPLOYEE]: {
    [ENTITIES.TENANT]: [],
    [ENTITIES.CUSTOMER]: [COMMON.VIEW],
  },
};
